import {
  Paper,
  Typography,
  Stack,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  IconButton,
  Button,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StyledDashboardTableHead } from "../pages/dashboard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import {
  getSelectedItemByCategoryId,
  saveSelectedItemByCategoryId,
} from "../data/categories";
import { RoleContext } from "../role-provider";
import {
  ArrowForward,
  DeleteOutline,
  ArrowDownward,
  LowPriority,
  ArrowUpward,
} from "@mui/icons-material";
import { DataContext } from "../data-provider";
import {
  ApiError,
  ItemByCategoryData,
  ItemWithCategories,
  createSortByNumberFn,
  createSortByStringFn,
  formatMappedCategoryItems,
} from "../utils";
import { Loader } from "./loader/Loader";
import { SortOrder } from "../data/mock/types";
import { SearchInput } from "./SearchInput";
import { useForm } from "react-hook-form";
import { useCategory } from "../pages/category";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { useNavigate } from "react-router-dom";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

type ItemTableSortField = "itemId" | "itemName";

const sortByName = createSortByStringFn<ItemWithCategories>("itemName");
const sortById = createSortByNumberFn<ItemWithCategories>("itemId");

export const CategoryItemMapping = () => {
  const { editable, categoryId, isLive } = useCategory();
  const { handleSubmit } = useForm();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const queryClient = useQueryClient();
  const { apiClient } = useAxios();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const { data } = useCustomQuery(
    [
      "getSelectedItemByCategoryId",
      {
        categoryId,
        countryCode: selectedCountry,
      },
    ],
    () =>
      getSelectedItemByCategoryId(apiClient)({
        countryCode: selectedCountry!,
        categoryId: String(categoryId!),
      }),
    selectedCountry !== null
  );

  const categoryItems: ItemByCategoryData[] = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    const validCategoryItems: ItemByCategoryData[] = formatMappedCategoryItems(
      data?.data.dataList
    );
    return validCategoryItems;
  }, [data]);

  const [mappedCategoryItems, setMappedCategoryItems] = useState<
    ItemByCategoryData[] | null
  >(null);

  useEffect(() => {
    if (categoryItems) {
      setMappedCategoryItems(categoryItems);
    }
  }, [categoryItems]);

  const handleAddToMappedItems = useCallback(
    (item: ItemWithCategories) => {
      if (mappedCategoryItems !== null) {
        const newItem: ItemByCategoryData = {
          itemId: item.itemId,
          itemName: item.itemName,
          displayOrder: mappedCategoryItems.length,
        };
        setMappedCategoryItems([...mappedCategoryItems, newItem]);
      }
    },
    [mappedCategoryItems]
  );

  const handleMappedItemUpdate = useCallback(
    (updatedMappedItems: ItemByCategoryData[]) => {
      setMappedCategoryItems(updatedMappedItems);
    },
    []
  );

  const saveSelectedItemByCategoryIdRequest =
    saveSelectedItemByCategoryId(apiClient);
  const { mutate, isLoading } = useMutation(
    (mappedItems: ItemByCategoryData[]) => {
      const itemCategoryMapping = {
        categoryId: Number(categoryId),
        dataList: mappedItems.map((mappedItem, index) => ({
          ...mappedItem,
          displayOrder: index + 1, // start with displayOrder = 1
        })),
      };
      const metaData = {
        ...(typeof categoryId !== "undefined"
          ? { categoryId: Number(categoryId) }
          : {}),
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      };

      return saveSelectedItemByCategoryIdRequest(itemCategoryMapping, metaData);
    },
    {
      onMutate: () => saveSelectedItemByCategoryIdRequest,
      onSuccess: () => {
        queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
        queryClient.invalidateQueries(["getSelectedItemByCategoryId"]);
        queryClient.invalidateQueries(["category/getCompareCurrent"]);
        setSuccessMessage(`Category ${categoryId} items saved successfully`);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const onSubmit = () => {
    if (mappedCategoryItems !== null) {
      mutate(mappedCategoryItems);
    }
  };

  return (
    <StyledRelativeContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading && <Loader />}
        <Grid container mobile={12}>
          <Grid
            container
            mobile={12}
            sx={{ mb: 6, display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h2">Category Item Mapping</Typography>
            <StyledSecondaryButton
              variant="contained"
              disabled={!isLive}
              onClick={() => {
                navigate(`/categories/${categoryId}/compare-with-live`);
              }}
            >
              Compare with live
            </StyledSecondaryButton>
          </Grid>
          {mappedCategoryItems !== null && (
            <StyledGridContainer container mobile={12}>
              <StyledLeftGrid mobile={5}>
                <AllItemsTable
                  mappedItems={mappedCategoryItems}
                  onClickAdd={handleAddToMappedItems}
                />
              </StyledLeftGrid>
              <StyledRightGrid mobile={7}>
                <SelectedItemsTable
                  mappedItems={mappedCategoryItems}
                  onChange={handleMappedItemUpdate}
                />
                <StyledGrid container mobile={12}>
                  <StyledSaveButton
                    type="submit"
                    disabled={!!isReaderRole || !editable}
                    variant="contained"
                  >
                    Submit
                  </StyledSaveButton>
                </StyledGrid>
              </StyledRightGrid>
            </StyledGridContainer>
          )}
        </Grid>
      </form>
      <ResendFormModal
        description="An error occurred saving the configuration."
        open={isResendModalOpen}
        onResend={() => {
          onSubmit();
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

interface AllItemsTableProps {
  mappedItems: ItemByCategoryData[];
  onClickAdd: (item: ItemWithCategories) => void;
}

const AllItemsTable = ({ mappedItems, onClickAdd }: AllItemsTableProps) => {
  const { items, itemsLoading } = useContext(DataContext);
  const { isReaderRole } = useContext(RoleContext);
  const { editable } = useCategory();

  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [sortField, setSortField] = useState<ItemTableSortField>("itemName");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredAndSortedItems, setFilteredAndSortedItems] =
    useState<ItemWithCategories[]>(items);

  useEffect(() => {
    const filteredItems =
      searchQuery.length > 0
        ? items?.filter((item) => {
            return (
              item.itemName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.itemId.toString().includes(searchQuery)
            );
          })
        : items;
    if (sortField === "itemId") {
      setFilteredAndSortedItems(sortById(filteredItems, sortOrder));
    } else {
      setFilteredAndSortedItems(sortByName(filteredItems, sortOrder));
    }
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchQuery, sortField, sortOrder]);

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    [setPage]
  );
  const handleSortingChange = useCallback(
    (field: ItemTableSortField) => {
      if (sortField === field) {
        // reverse sort on same field
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // set new sort field
        setSortField(field);
      }
      // set to first page in any case
      setPage(0);
    },
    [sortField, sortOrder, setSortField, setSortOrder]
  );

  const getRow = useCallback(
    (index: number) => {
      if (!filteredAndSortedItems || !filteredAndSortedItems[index]) {
        return (
          <TableRow
            sx={{
              height: 48,
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="items-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="items-td-name"
              aria-hidden="true"
            ></TableCell>
            <TableCell align="right" aria-hidden="true"></TableCell>
            <TableCell align="right" aria-hidden="true"></TableCell>
          </TableRow>
        );
      }
      const item = filteredAndSortedItems[index];
      return (
        <TableRow
          key={`${JSON.stringify(item)}`}
          sx={{
            height: 48,
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="item-tr"
        >
          <TableCell component="th" scope="row" data-testid="item-td-name">
            {item.itemName}
          </TableCell>

          <TableCell align="right">{item.itemId}</TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              onClick={() => onClickAdd(item)}
              disabled={
                !!isReaderRole ||
                !editable ||
                mappedItems.some(
                  (mappedItem) => mappedItem.itemId === item.itemId
                )
              }
            >
              <ArrowForward />
            </Button>
          </TableCell>
        </TableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mappedItems, filteredAndSortedItems, isReaderRole, editable]
  );

  return (
    <Grid padding={0}>
      {itemsLoading && <Loader />}
      <Grid
        container
        padding={0}
        marginBottom={2}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid>
          <Typography variant="h2">Items List</Typography>
        </Grid>
        <Grid>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Items"
            id="item-search-input"
            testId="item-search"
          />
        </Grid>
      </Grid>

      <Grid mobile={12} padding={0}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>
                  Name
                  <TableSortLabel
                    active={sortField === "itemName"}
                    direction={sortOrder}
                    onClick={() => handleSortingChange("itemName")}
                    data-testid="sort-by-item-name"
                  ></TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  Id
                  <TableSortLabel
                    active={sortField === "itemId"}
                    direction={sortOrder}
                    onClick={() => handleSortingChange("itemId")}
                    data-testid="sort-by-item-id"
                  ></TableSortLabel>
                </TableCell>
                <TableCell align="right">Add</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
          </Table>
          <Table>
            <TableBody>
              {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                getRow(page * pageSize + i)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={filteredAndSortedItems.length}
            onPageChange={handlePageChange}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10]}
            data-testid="items-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "items-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>
      </Grid>
    </Grid>
  );
};

interface SelectedItemsTableProps {
  mappedItems: ItemByCategoryData[];
  onChange: (updatedMappedItems: ItemByCategoryData[]) => void;
}

const SelectedItemsTable = ({
  mappedItems,
  onChange,
}: SelectedItemsTableProps) => {
  const { isReaderRole } = useContext(RoleContext);
  const { editable } = useCategory();

  const handleMoveItem = (index: number, direction: string) => {
    const newIndex = direction === "up" ? index - 1 : index + 1;
    const updatedItems = [...mappedItems];
    [updatedItems[index], updatedItems[newIndex]] = [
      updatedItems[newIndex],
      updatedItems[index],
    ];
    updatedItems.forEach((item, idx) => {
      item.displayOrder = idx + 1;
    });
    onChange(updatedItems);
  };

  const handleMoveToTop = (index: number) => {
    const updatedItems = [...mappedItems];
    const [itemToMove] = updatedItems.splice(index, 1);
    updatedItems.unshift(itemToMove);
    updatedItems.forEach((item, idx) => {
      item.displayOrder = idx + 1;
    });
    onChange(updatedItems);
  };

  const handleRemoveFromSelectedItems = (item: ItemByCategoryData) => {
    const updatedItems = mappedItems.filter((i) => i !== item);
    onChange(updatedItems);
  };

  return (
    <Grid mobile={12} padding={0}>
      <Typography variant="h2">Selected Items</Typography>
      <Stack marginTop={2}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Remove</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>id</TableCell>
                <TableCell align="center">Move</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            {mappedItems.length > 0 && (
              <TableBody>
                {mappedItems.map((catItem, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <IconButton
                        sx={{ color: "#DA291C" }}
                        onClick={() => handleRemoveFromSelectedItems(catItem)}
                        disabled={!!isReaderRole || !editable}
                        aria-label={`Remove ${catItem.itemName}`}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {catItem.itemName}
                    </TableCell>
                    <TableCell>{catItem.itemId}</TableCell>
                    <TableCell
                      align="right"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        color="primary"
                        disabled={!!isReaderRole || !editable || index === 0}
                        onClick={() => handleMoveItem(index, "up")}
                        aria-label={`Move ${catItem.itemName} up`}
                      >
                        <ArrowUpward />
                      </IconButton>
                      <IconButton
                        color="primary"
                        disabled={
                          !!isReaderRole ||
                          !editable ||
                          index === mappedItems.length - 1
                        }
                        onClick={() => handleMoveItem(index, "down")}
                        aria-label={`Move ${catItem.itemName} down`}
                      >
                        <ArrowDownward />
                      </IconButton>
                      <Tooltip title="Move to Top">
                        <IconButton
                          color="primary"
                          disabled={!!isReaderRole || !editable || index === 0}
                          onClick={() => handleMoveToTop(index)}
                          aria-label={`Move ${catItem.itemName} down`}
                          style={{ transform: "rotate(180deg)" }}
                        >
                          <LowPriority />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {mappedItems.length === 0 && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No mapped category items`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </Stack>
    </Grid>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});
const StyledGrid = styled(Grid)({
  justifyContent: "flex-end",
  marginTop: "16px",
});
const StyledSaveButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
const StyledGridContainer = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
  padding: 0,
});
const StyledLeftGrid = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  paddingLeft: 0,
}));
const StyledRightGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
}));