import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Button,
  styled,
  IconButton,
  TableContainer,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { useContext, useEffect, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { ArrowDownward, ArrowUpward, DeleteOutline } from "@mui/icons-material";
import { StyledDashboardTableHead } from "./CategoryMapping";
import {
  getSelectedItemsGoesWellWith,
  saveItemGoesWellWith,
} from "../data/items";
import { useItem } from "../pages/item";
import { Loader } from "./loader/Loader";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SelectItemsTable } from "./SelectItemsTable";
import { ApiError, ValidGoesWellWith, isGoesWellWith } from "../utils";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { StyledDangerButton, UnsavedChangesModal } from "./UnsavedChangesModal";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

export const GoesWellWith = () => {
  const { itemId, editable, isLive } = useItem();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const queryClient = useQueryClient();
  const { apiClient } = useAxios();
  const navigate = useNavigate();

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getSelectedItemsGoesWellWith", { itemId }],
    () =>
      getSelectedItemsGoesWellWith(apiClient)({
        itemId: Number(itemId),
        countryCode: selectedCountry!,
      })
  );
  const goesWellWithItems = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    const validGoesWellWithItems = data?.data.dataList?.filter(
      (maybeGoesWellWith): maybeGoesWellWith is ValidGoesWellWith => {
        return isGoesWellWith(maybeGoesWellWith) === true;
      }
    );
    return validGoesWellWithItems;
  }, [data]);
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      mappedElements: goesWellWithItems,
    },
  });
  useEffect(() => {
    setValue("mappedElements", goesWellWithItems);
  }, [goesWellWithItems, setValue]);

  const editableGoesWellWithItems = watch("mappedElements");

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && editableGoesWellWithItems.length === 0;
  }, [isFetching, isSuccess, editableGoesWellWithItems]);

  const saveGoesWellWithRequest = saveItemGoesWellWith(apiClient);
  const { mutate, isLoading } = useMutation(
    () => {
      const itemGoesWith = {
        itemId: Number(itemId),
        dataList: editableGoesWellWithItems.map((item) => ({
          itemId: Number(item.itemId),
        })),
      };
      return saveGoesWellWithRequest(itemGoesWith, {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },

    {
      onMutate: () => saveGoesWellWithRequest,
      onSuccess: () => {
        setSuccessMessage(`Item ${itemId} was saved successfully`);
        queryClient.invalidateQueries([
          "getSelectedItemsGoesWellWith",
          { itemId },
        ]);
        queryClient.invalidateQueries(["item/getCompareCurrent", { itemId }]);
        queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
        reset({
          mappedElements: goesWellWithItems,
        });
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const onSubmit = () => mutate();

  const handleLeaveNavigation = () => {
    reset({
      mappedElements: goesWellWithItems,
    });
    setShowUnsavedChangesModal(false);
  };
  const handleAddToChosenGoesWellWith = (item: {
    id: number;
    name: string;
  }) => {
    setValue(
      "mappedElements",
      [
        ...editableGoesWellWithItems,
        {
          itemId: item.id,
          menuItemName: item.name,
        },
      ],
      { shouldDirty: true }
    );
  };

  const ChosenGoesWellWithTable = () => {
    const moveItemUp = (index: number) => {
      if (index === 0) return;
      const newItems = [...editableGoesWellWithItems];
      const temp = newItems[index];
      newItems[index] = newItems[index - 1];
      newItems[index - 1] = temp;
      setValue("mappedElements", newItems, { shouldDirty: true });
    };

    const moveItemDown = (index: number) => {
      if (index === editableGoesWellWithItems.length - 1) return;
      const newItems = [...editableGoesWellWithItems];
      const temp = newItems[index];
      newItems[index] = newItems[index + 1];
      newItems[index + 1] = temp;
      setValue("mappedElements", newItems, { shouldDirty: true });
    };
    const deleteItem = (index: number) => {
      const newItems = [...editableGoesWellWithItems];
      newItems.splice(index, 1);
      setValue("mappedElements", newItems, { shouldDirty: true });
    };

    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="item-goes-well-with-selected"
        >
          <Grid marginTop="20px" marginBottom="20px" padding={0}>
            <Typography variant="h2">Selected Items</Typography>
          </Grid>
          {isLoading && <Loader />}

          <TableContainer component={Paper}>
            <Table>
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell width={"50%"}>Name</TableCell>
                  <TableCell width={"10%"}>Id</TableCell>
                  <TableCell width={"20%"} align="center">
                    Move
                  </TableCell>
                  <TableCell width={"10%"} align="right">
                    Delete
                  </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              <TableBody>
                {editableGoesWellWithItems.map((item, index) => (
                  <TableRow
                    role="row"
                    key={index}
                    data-testid="item-goes-well-with-selected-tr"
                  >
                    <TableCell width={"50%"}>{item.menuItemName}</TableCell>
                    <TableCell width={"10%"}>{item.itemId}</TableCell>
                    <TableCell
                      width={"20%"}
                      align="center"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {!isReaderRole && editable && (
                        <Grid container justifyContent="flex-end">
                          <Grid>
                            <IconButton
                              color="primary"
                              aria-labelledby="move-header"
                              onClick={() => moveItemUp(index)}
                              sx={index === 0 ? { visibility: "hidden" } : {}}
                            >
                              <ArrowUpward />
                            </IconButton>
                            <IconButton
                              color="primary"
                              aria-labelledby="move-header"
                              onClick={() => moveItemDown(index)}
                              sx={
                                index === editableGoesWellWithItems.length - 1
                                  ? { visibility: "hidden" }
                                  : {}
                              }
                            >
                              <ArrowDownward />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                    <TableCell width={"10%"} align="right">
                      {!isReaderRole && editable && (
                        <IconButton
                          sx={{ color: "#DA291C", ml: 4 }}
                          aria-labelledby="delete-header"
                          onClick={() => deleteItem(index)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isEmpty && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No items currently belong to this Goes Well With relationship`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
          {!isEmpty && !isReaderRole && editable && (
            <Grid container sx={{ pr: 1, pt: 3 }} justifyContent="flex-end">
              <StyledDangerButton
                variant="contained"
                size="large"
                aria-label="cancel"
                disabled={!isDirty}
                onClick={() => setShowUnsavedChangesModal(true)}
              >
                Cancel
              </StyledDangerButton>
              <StyledSaveButton
                type="submit"
                sx={{ ml: 4 }}
                variant="contained"
                size="large"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </StyledSaveButton>
            </Grid>
          )}
        </form>
      </>
    );
  };
  return (
    <StyledRelativeContainer>
      <Grid container>
        <Grid
          container
          mobile={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: 3,
            px: 4,
          }}
        >
          <Typography variant="h2">Goes Well With</Typography>
          <StyledSecondaryButton
            variant="contained"
            disabled={!isLive}
            onClick={() => {
              navigate(`/items/${itemId}/compare-with-live`);
            }}
          >
            Compare with live
          </StyledSecondaryButton>
        </Grid>
        <Grid mobile={6} data-testid="item-goes-well-with-selectable">
          <SelectItemsTable
            selectedItemIds={editableGoesWellWithItems.map((gwwItem) =>
              Number(gwwItem.itemId)
            )}
            selectEnabled={!isReaderRole && editable}
            onSelect={handleAddToChosenGoesWellWith}
          />
        </Grid>
        <Grid mobile={6}>
          <ChosenGoesWellWithTable />
        </Grid>
      </Grid>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        title="Confirmation"
        confirmButton="Yes"
        denyButton="No"
        description="Are you sure you want to cancel?"
        onClose={() => setShowUnsavedChangesModal(false)}
        onLeave={handleLeaveNavigation}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          onSubmit();
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledSaveButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 180,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
