import {
  realGetAuditTrailData,
  realGetSearchedAuditTrailData,
} from "./api/audittrail";
import { paths } from "./auditTrail.types";
import {
  mockGetAuditTrailData,
  mockGetSearchedAuditTrailData,
} from "./mock/audittrail";
import { mockableGetRequest } from "./mockable-api-request";

export const getAuditTrailData = mockableGetRequest<
  paths["/exp/v1/dna/auditTrail/getAuditTrailData"]
>(realGetAuditTrailData, mockGetAuditTrailData);
export const getSearchedAuditTrailData = mockableGetRequest<
  paths["/exp/v1/dna/auditTrail/getSearchedAuditTrailData"]
>(realGetSearchedAuditTrailData, mockGetSearchedAuditTrailData);
