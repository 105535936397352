import {
  realAddFoodPromotion,
  realShowRecentFeedUpdates,
  realGetFoodPromotionList,
  realGetPendingActionList,
  realGetReadyPushLiveList,
  realGetRecentFeedUpdates,
  realGetSchedulePushLiveList,
  realGetTopTenRecentMenu,
  realDeleteFoodPromotion,
  realGetEmailPreferences,
  realSaveEmailPreferences,
} from "../api/dashboard";
import { AxiosHeaders } from "axios";
import items from "./json/items.json";
import products from "../mock/json/products.json";
import genesisUpdates from "../mock/json/genesis-updates.json";
import pendingUpdates from "../mock/json/pending-updates.json";
import { components } from "../dashboard.types";
import { delay } from ".";

type RecentFeedUpdatesData = components["schemas"]["RecentFeedUpdatesData"];

export const mockGetPendingActionList: ReturnType<
  typeof realGetPendingActionList
> = async ({ countryCode, roleId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      // return empty for UK - Nutritionist, for a Cypress test
      dataList: countryCode === "UK" && roleId === 3 ? [] : pendingUpdates,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetRecentFeedUpdates: ReturnType<
  typeof realGetRecentFeedUpdates
> = async ({ countryCode = "US", languageCode = "EN" }) => {
  const recentGenesisUpdates = getRecentGenesisUpdates();
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: recentGenesisUpdates,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetReadyPushLiveList: ReturnType<
  typeof realGetReadyPushLiveList
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetSchedulePushLiveList: ReturnType<
  typeof realGetSchedulePushLiveList
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockShowRecentFeedUpdates: ReturnType<
  typeof realShowRecentFeedUpdates
> = async ({ countryCode }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      // return "N" for Bahrain only (for testing)
      showFeedUpdates: countryCode === "BH" ? "N" : "Y",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetTopTenRecentMenu: ReturnType<
  typeof realGetTopTenRecentMenu
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {},
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetFoodPromotionList: ReturnType<
  typeof realGetFoodPromotionList
> = async ({ countryCode = "US" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockAddFoodPromotion: ReturnType<
  typeof realAddFoodPromotion
> = async ({ countryCode, projectName, startDate, endDate }) => {
  // simulate save only
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: { status: "200", message: "Food Promotion was saved successfully" },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteFoodPromotion: ReturnType<
  typeof realDeleteFoodPromotion
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

function getRecentGenesisUpdates(): RecentFeedUpdatesData[] {
  const getElementNameForGenesisUpdate = (update: RecentFeedUpdatesData) => {
    if (update.type === "item") {
      const item = items.find((item) => {
        return update.importId && Number(update.importId) === item.genesisId;
      });
      if (item) {
        return item.name;
      }
    } else {
      const product = products.find((product) => {
        return update.importId && Number(update.importId) === product.genesisId;
      });
      if (product) {
        return product.productName;
      }
    }
    return update.name;
  };

  return genesisUpdates.map((update) => ({
    ...update,
    importId: String(update.importId),
    name: getElementNameForGenesisUpdate({
      ...update,
      importId: String(update.importId),
    }),
  }));
}

export const mockGetEmailPreferences: ReturnType<
  typeof realGetEmailPreferences
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      notification: "N",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveEmailPreferences: ReturnType<
  typeof realSaveEmailPreferences
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
