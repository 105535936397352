import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Loader } from "./loader/Loader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RestorePage from "@mui/icons-material/RestorePage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import { ApiError, ValidItemArchive, isItemArchive } from "../utils";
import { withLayout } from "../hoc/with-layout";
import { getArchiveItem, hardDeleteItem, restoreItem } from "../data/items";
import { SuccessAlertSnackbar, ErrorAlertSnackbar } from "./AlertSnackbar";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ConfirmActionModal } from "./ConfirmActionModal";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

export const ArchiveItem = withLayout(() => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const queryClient = useQueryClient();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getArchiveItems"],
    () =>
      getArchiveItem(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  const archivedItems = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    const validArchivedItems = data?.data.dataList.filter(
      (MaybeArchivedItem): MaybeArchivedItem is ValidItemArchive => {
        return isItemArchive(MaybeArchivedItem) === true;
      }
    );
    return validArchivedItems;
  }, [data]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && archivedItems.length === 0;
  }, [isFetching, isSuccess, archivedItems]);

  const [itemToDeleteId, setItemToDeleteId] = useState<number | null>(null);
  const [itemToRestoreId, setItemToRestoreId] = useState<number | null>(null);
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] =
    useState<boolean>(false);
  const [isRetryRestoreModalOpen, setIsRetryRestoreModalOpen] =
    useState<boolean>(false);

  const deleteItemRequest = hardDeleteItem(apiClient);
  const restoreItemRequest = restoreItem(apiClient);

  const { mutate: doHardDelete, isLoading: isHardDeleteLoading } = useMutation(
    (itemId: number) => {
      return deleteItemRequest({
        itemId,
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Item was successfully deleted`);
        setItemToDeleteId(null);
        setIsRetryDeleteModalOpen(false);
        queryClient.invalidateQueries(["getArchiveItems"]);
        queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryDeleteModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const { mutate: doRestore, isLoading: isRestoreLoading } = useMutation(
    (itemId: number) => {
      return restoreItemRequest(
        {},
        {
          itemId,
          countryCode: selectedCountry!,
        }
      );
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Item was successfully restored`);
        setItemToRestoreId(null);
        setIsRetryRestoreModalOpen(false);
        queryClient.invalidateQueries(["getArchiveItems"]);
        queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryRestoreModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  return (
    <StyledRelativeContainer container spacing={1}>
      {isFetching && <Loader />}
      <Grid
        container
        mobile={12}
        sx={{ my: 3, justifyContent: "space-between" }}
      >
        <Typography variant="h2">Restore/Delete Item(s)</Typography>
      </Grid>

      <Grid container mobile={12}>
        <TableContainer component={Paper}>
          <Table aria-label="Item Archive Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell width="55%">Item Name</TableCell>
                <TableCell width="30%">Item ID</TableCell>
                <TableCell align="right">Restore</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {archivedItems.map((archive) => {
                return (
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {archive.name}
                    </TableCell>
                    <TableCell>{archive.id}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Restore Item">
                        <IconButton
                          disabled={!!isReaderRole}
                          aria-label="restore button"
                          onClick={() => setItemToRestoreId(archive.id)}
                          role="button"
                        >
                          <RestorePage />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Delete Item">
                        <IconButton
                          sx={{ color: "#DA291C" }}
                          disabled={!!isReaderRole}
                          aria-label="delete button"
                          onClick={() => setItemToDeleteId(archive.id)}
                          role="button"
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          {isEmpty && (
            <StyledEmptyBox>
              <Typography variant="h6">{`Archived Items not found`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </Grid>
      {typeof itemToRestoreId === "number" && (
        <ConfirmActionModal
          open={!isRetryRestoreModalOpen}
          loading={isRestoreLoading}
          message="Are you sure you want to restore this item from archive?"
          onConfirm={() => doRestore(itemToRestoreId!)}
          onCancel={() => setItemToRestoreId(null)}
        />
      )}
      {typeof itemToDeleteId === "number" && (
        <ConfirmActionModal
          open={!isRetryDeleteModalOpen}
          loading={isHardDeleteLoading}
          message="Are you sure you want to delete this item?"
          onConfirm={() => doHardDelete(itemToDeleteId!)}
          onCancel={() => setItemToDeleteId(null)}
        />
      )}
      <ResendFormModal
        open={isRetryDeleteModalOpen}
        onResend={() => {
          setIsRetryDeleteModalOpen(false);
          if (typeof itemToDeleteId === "number") {
            doHardDelete(itemToDeleteId);
          }
        }}
        onCancel={() => {
          setItemToDeleteId(null);
          setIsRetryDeleteModalOpen(false);
        }}
        description="An error occurred while deleting this item"
      />
      <ResendFormModal
        open={isRetryRestoreModalOpen}
        onResend={() => {
          setIsRetryRestoreModalOpen(false);
          if (typeof itemToRestoreId === "number") {
            doRestore(itemToRestoreId);
          }
        }}
        onCancel={() => {
          setItemToRestoreId(null);
          setIsRetryRestoreModalOpen(false);
        }}
        description="An error occurred while restoring this item"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "Item Archive");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 180,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
