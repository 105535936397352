import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Loader } from "./loader/Loader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RestorePage from "@mui/icons-material/RestorePage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import { ApiError, ValidCategoryArchive, isCategoryArchive } from "../utils";
import { withLayout } from "../hoc/with-layout";
import {
  getArchiveCategory,
  hardDeleteCategory,
  restoreCategory,
} from "../data/categories";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { useCustomQuery } from "../hooks/use-custom-query";
import { AxiosError } from "axios";
import { ResendFormModal } from "./ResendFormModal";
import { ConfirmActionModal } from "./ConfirmActionModal";

export const ArchiveCategory = withLayout(() => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const queryClient = useQueryClient();

  const [categoryToDeleteId, setCategoryToDeleteId] = useState<number | null>(
    null
  );
  const [categoryToRestoreId, setCategoryToRestoreId] = useState<number | null>(
    null
  );
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] =
    useState<boolean>(false);
  const [isRetryRestoreModalOpen, setIsRetryRestoreModalOpen] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const deleteCategoryRequest = hardDeleteCategory(apiClient);
  const restoreCategoryRequest = restoreCategory(apiClient);

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getArchiveCategory"],
    () =>
      getArchiveCategory(apiClient)({
        countryCode: selectedCountry!,
      })
  );
  const { mutate: doHardDelete, isLoading: isHardDeleteLoading } = useMutation(
    (categoryId: number) => {
      return deleteCategoryRequest({
        categoryId,
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Category was successfully deleted`);
        setCategoryToDeleteId(null);
        setIsRetryDeleteModalOpen(false);
        queryClient.invalidateQueries(["getArchiveCategory"]);
        queryClient.invalidateQueries([
          "getAllCategoriesList",
          { selectedCountry },
        ]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryDeleteModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const { mutate: doRestore, isLoading: isRestoreLoading } = useMutation(
    (categoryId: number) => {
      return restoreCategoryRequest(
        {},
        {
          categoryId,
          countryCode: selectedCountry!,
        }
      );
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Category was successfully restored`);
        setCategoryToRestoreId(null);
        setIsRetryRestoreModalOpen(false);
        queryClient.invalidateQueries(["getArchiveCategory"]);
        queryClient.invalidateQueries([
          "getAllCategoriesList",
          { selectedCountry },
        ]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryRestoreModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const archivedCategories = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    const validArchivedCategories = data?.data.dataList.filter(
      (
        MaybeArchivedCategory
      ): MaybeArchivedCategory is ValidCategoryArchive => {
        return isCategoryArchive(MaybeArchivedCategory) === true;
      }
    );
    return validArchivedCategories;
  }, [data]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && archivedCategories.length === 0;
  }, [isFetching, isSuccess, archivedCategories]);
  return (
    <StyledRelativeContainer container spacing={1}>
      {(isHardDeleteLoading || isFetching || isRestoreLoading) && <Loader />}
      <Grid
        container
        mobile={12}
        sx={{ my: 3, justifyContent: "space-between" }}
      >
        <Typography variant="h2">Restore/Delete Categories(s)</Typography>
      </Grid>

      <Grid container mobile={12}>
        <TableContainer component={Paper}>
          <Table aria-label="Category Archive Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell width="55%">Category Name</TableCell>
                <TableCell width="30%">Category ID</TableCell>
                <TableCell align="right">Restore</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {archivedCategories.map((archive) => {
                return (
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {archive.name}
                    </TableCell>
                    <TableCell>{archive.id}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Restore Category">
                        <IconButton
                          disabled={!!isReaderRole}
                          aria-label="restore button"
                          onClick={() => setCategoryToRestoreId(archive.id)}
                          role="button"
                        >
                          <RestorePage />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Delete Category">
                        <IconButton
                          sx={{ color: "#DA291C" }}
                          disabled={!!isReaderRole}
                          aria-label="delete button"
                          onClick={() => setCategoryToDeleteId(archive.id)}
                          role="button"
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          {isEmpty && (
            <StyledEmptyBox>
              <Typography variant="h6">{`Archived Categories not found`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </Grid>
      {typeof categoryToRestoreId === "number" && (
        <ConfirmActionModal
          open={!isRetryRestoreModalOpen}
          loading={isRestoreLoading}
          message="Are you sure you want to restore this category from archive?"
          onConfirm={() => doRestore(categoryToRestoreId!)}
          onCancel={() => setCategoryToRestoreId(null)}
        />
      )}
      {typeof categoryToDeleteId === "number" && (
        <ConfirmActionModal
          open={!isRetryDeleteModalOpen}
          loading={isHardDeleteLoading}
          message="Are you sure you want to delete this category?"
          onConfirm={() => doHardDelete(categoryToDeleteId!)}
          onCancel={() => setCategoryToDeleteId(null)}
        />
      )}
      <ResendFormModal
        open={isRetryDeleteModalOpen}
        onResend={() => {
          setIsRetryDeleteModalOpen(false);
          if (typeof categoryToDeleteId === "number") {
            doHardDelete(categoryToDeleteId);
          }
        }}
        onCancel={() => {
          setCategoryToDeleteId(null);
          setIsRetryDeleteModalOpen(false);
        }}
        description="An error occurred while deleting this category"
      />
      <ResendFormModal
        open={isRetryRestoreModalOpen}
        onResend={() => {
          setIsRetryRestoreModalOpen(false);
          if (typeof categoryToRestoreId === "number") {
            doRestore(categoryToRestoreId);
          }
        }}
        onCancel={() => {
          setCategoryToRestoreId(null);
          setIsRetryRestoreModalOpen(false);
        }}
        description="An error occurred while restoring this category"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "Category Archive");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 180,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
