import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  styled,
  Grid,
} from "@mui/material";

type ResendFormModalProps = {
  open: boolean;
  onResend: () => void;
  onCancel: () => void;
  title?: string;
  description?: string;
};

export const ResendFormModal = ({
  open,
  onResend,
  onCancel,
  title = "Oops!",
  description = "An error occurred while submitting the form.",
}: ResendFormModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="resend-modal-title"
      aria-describedby="resend-modal-description"
    >
      <StyledRelativeContainer>
        <DialogTitle id="resend-modal-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="resend-modal-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-evenly" }}>
          <StyledButton variant="contained" size="large" onClick={onCancel}>
            Cancel
          </StyledButton>
          <StyledDangerButton
            variant="contained"
            size="large"
            color="error"
            onClick={onResend}
            autoFocus
          >
            Try Again
          </StyledDangerButton>
        </DialogActions>
      </StyledRelativeContainer>
    </Dialog>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
