import { createContext, useMemo, useContext, useCallback } from "react";
import { useOutlet } from "react-router-dom";
import { components as itemsComponents } from "./data/items-v2.types";
import { useAxios } from "./axios-provider";
import { RoleContext } from "./role-provider";
import { getSearchableProducts } from "./data/products";
import { getAllCategoriesList } from "./data/categories";
import { getItemList } from "./data/items";
import {
  ItemData,
  ItemWithCategories,
  ValidCategory,
  formatItemForDataProvider,
  isValidCategory,
  isValidItem,
} from "./utils";
import { useCustomQuery } from "./hooks/use-custom-query";
import {
  SearchableProduct,
  isSearchableProduct,
} from "./util/searchable-products";

export type OptionalItemData =
  itemsComponents["schemas"]["SelectableOptionalItem"];

interface DataProviderProps {
  products: SearchableProduct[];
  productsLoading: boolean;
  categoryList: ValidCategory[];
  categoryListLoading: boolean;
  items: ItemWithCategories[];
  itemsLoading: boolean;
  getItemsByCategoryId: (categoryId: string) => ItemWithCategories[];
}

export const DataContext = createContext<DataProviderProps>({
  products: [],
  productsLoading: false,
  categoryList: [],
  categoryListLoading: false,
  items: [],
  itemsLoading: false,
  getItemsByCategoryId: () => [],
});

export const DataProvider = () => {
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const outlet = useOutlet();

  // PRODUCTS
  const productsQuery = useCustomQuery(
    ["getAllProductsList", { selectedCountry }],
    () =>
      getSearchableProducts(apiClient)({
        countryCode: selectedCountry!,
      }),
    selectedCountry !== null
  );

  const products: SearchableProduct[] = useMemo(() => {
    if (productsQuery.data?.data.dataList === undefined) {
      return [];
    }
    const searchableProductsList: SearchableProduct[] =
      productsQuery.data?.data.dataList.filter(
        (
          maybeSearchableProduct: any
        ): maybeSearchableProduct is SearchableProduct => {
          return isSearchableProduct(maybeSearchableProduct);
        }
      );
    return searchableProductsList;
  }, [productsQuery.data]);

  // CATEGORIES
  const categoryListQuery = useCustomQuery(
    ["getAllCategoriesList", { selectedCountry }],
    () =>
      getAllCategoriesList(apiClient)({
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      }),
    selectedCountry !== null
  );

  const categoryList: ValidCategory[] = useMemo(() => {
    if (categoryListQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validCategoryList: ValidCategory[] =
      categoryListQuery.data?.data.dataList
        .filter(
          (
            maybeValidCategoryList: any
          ): maybeValidCategoryList is ValidCategory => {
            return isValidCategory(maybeValidCategoryList);
          }
        )
        .filter((category) => category.status !== "Archived");
    return validCategoryList;
  }, [categoryListQuery.data]);

  // ITEMS
  const itemsQuery = useCustomQuery(
    ["getItemsList", { selectedCountry, selectedRole }],
    () =>
      getItemList(apiClient)({
        countryCode: selectedCountry!,
        roleId: selectedRole!,
      }),
    selectedCountry !== null && selectedRole !== null
  );

  const items: ItemWithCategories[] = useMemo(() => {
    if (itemsQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validItems: ItemData[] = itemsQuery.data.data.dataList
      .filter((maybeValidItem: any): maybeValidItem is ItemData => {
        return isValidItem(maybeValidItem) === true;
      })
      .filter((item) => item.status !== "Archived");
    return validItems.map((validItem) => formatItemForDataProvider(validItem));
  }, [itemsQuery.data]);

  const getItemsByCategoryId = useCallback(
    (categoryId: string) => {
      const numCategoryId = Number(categoryId);

      return items.filter(
        (item) => item.categories && item.categories.includes(numCategoryId)
      );
    },
    [items]
  );

  const value = useMemo(
    () => ({
      products,
      productsLoading: productsQuery.isFetching,
      categoryList,
      categoryListLoading: categoryListQuery.isFetching,
      items,
      itemsLoading: itemsQuery.isFetching,
      getItemsByCategoryId,
    }),
    [
      getItemsByCategoryId,
      products,
      productsQuery.isFetching,
      categoryList,
      categoryListQuery.isFetching,
      items,
      itemsQuery.isFetching,
    ]
  );

  return <DataContext.Provider value={value}>{outlet}</DataContext.Provider>;
};
