import createTheme from "@mui/material/styles/createTheme";

const SPEEDEE_BOLD = "Speedee-Bold, Arial, Helvetica, sans-serif";
const SPEEDEE = "Speedee, Arial, Helvetica, sans-serif";
const MCD_BLACK = "#292929";
const MCD_RED = "#DA291C";
const MCD_YELLOW = "#FFBC0D";
const MCD_LIGHT_YELLOW = "#FCEFCC";
const LINK_BLUE = "#006BAE";
const DARK_GREY = "#6F6F6F";
const ACTIVE_GREY = "#0000001F";
const LIGHT_GREY = "#F9F6F6";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }

  interface TypographyVariants {
    small: React.CSSProperties;
    normal: React.CSSProperties;
    normalBold: React.CSSProperties;
    medium: React.CSSProperties;
    large: React.CSSProperties;
    largeBold: React.CSSProperties;
    extraLarge: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    small?: React.CSSProperties;
    normal?: React.CSSProperties;
    normalBold?: React.CSSProperties;
    large?: React.CSSProperties;
    largeBold?: React.CSSProperties;
    extraLarge?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    small: true;
    normal: true;
    normalBold: true;
    large: true;
    largeBold: true;
    extraLarge: true;
  }
}

let baseTheme = createTheme({
  breakpoints: {
    values: {
      desktop: 1220,
      laptop: 1024,
      tablet: 832,
      mobile: 0,
    },
  },
});
let muiTheme = createTheme(baseTheme, {
  typography: {
    small: {
      fontFamily: SPEEDEE,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    normal: {
      fontFamily: SPEEDEE,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    normalBold: {
      fontFamily: SPEEDEE_BOLD,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    large: {
      fontFamily: SPEEDEE,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    largeBold: {
      fontFamily: SPEEDEE_BOLD,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    extraLarge: {
      fontFamily: SPEEDEE,
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body1: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE,
      fontSize: 16,
      letterSpacing: "-0.15px",
      lineHeight: "24px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 14,
      },
    },
    body2: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE,
      fontSize: 14,
      letterSpacing: "-0.15px",
      lineHeight: "24px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 12,
        lineHeight: "16px",
      },
    },
    button: {
      color: LINK_BLUE,
    },
    nav: {
      color: MCD_BLACK,
      backgroundColor: MCD_RED,
      fontFamily: SPEEDEE,
      fontSize: 16,
      letterSpacing: "-0.15px",
      lineHeight: "20px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 14,
        lineHeight: "20px",
      },
    },
    navActive: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE_BOLD,
      fontSize: 16,
      letterSpacing: "-0.15px",
      lineHeight: "20px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 14,
        lineHeight: "20px",
      },
    },
    eyebrow: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE,
      fontSize: 12,
      letterSpacing: "-0.15px",
      lineHeight: "15px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 12,
        lineHeight: "15px",
      },
    },
    link: {
      color: LINK_BLUE,
      fontFamily: SPEEDEE,
      fontSize: 16,
      letterSpacing: "-0.15px",
      lineHeight: "20px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 14,
        lineHeight: "20px",
      },
    },
    utility: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE,
      fontSize: 11,
      letterSpacing: "-0.15px",
      lineHeight: "16px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 10,
        lineHeight: "16px",
      },
    },
    h1: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE_BOLD,
      fontSize: 54,
      letterSpacing: "-0.15px",
      lineHeight: "56px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 36,
        lineHeight: "40px",
      },
    },
    h2: {
      color: MCD_BLACK,

      fontFamily: SPEEDEE_BOLD,
      fontSize: 36,
      letterSpacing: "-0.15px",
      lineHeight: "40px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 28,
        lineHeight: "32px",
      },
    },
    h3: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE,
      fontSize: 36,
      letterSpacing: "-0.15px",
      lineHeight: "40px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 28,
        lineHeight: "32px",
      },
    },
    h4: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE_BOLD,
      fontSize: 24,
      letterSpacing: "-0.15px",
      lineHeight: "32px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 20,
        lineHeight: "24px",
      },
    },
    h5: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE,
      fontSize: 24,
      letterSpacing: "-0.15px",
      lineHeight: "28px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 20,
        lineHeight: "24px",
      },
    },
    h6: {
      color: MCD_BLACK,
      fontFamily: SPEEDEE_BOLD,
      fontSize: 16,
      letterSpacing: "-0.15px",
      lineHeight: "24px",
      [baseTheme.breakpoints.down("tablet")]: {
        fontSize: 16,
        lineHeight: "24px",
      },
    },
  },
});

muiTheme = createTheme(muiTheme, {
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
          [muiTheme.breakpoints.down("desktop")]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        },
      },
      defaultProps: {
        maxWidth: "desktop",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#292929",
          "&.Mui-focused": {
            color: "#292929",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#292929",
        },
      },
    },
    MuiGrid2: {
      defaultProps: {
        columnSpacing: {
          mobile: 1,
          tablet: 2.5,
          laptop: 2.5,
          desktop: 2.5,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "4px 16px",
          ...muiTheme.typography.small,
        },
        head: {
          ...muiTheme.typography.normalBold,
        },
      },
    },
  },
  palette: {
    background: {
      paper: MCD_YELLOW,
      default: MCD_YELLOW,
    },
    text: {
      primary: MCD_BLACK,
    },
    primary: {
      main: MCD_YELLOW,
      light: ACTIVE_GREY,
      dark: MCD_YELLOW,
    },
    secondary: {
      main: LIGHT_GREY,
      light: MCD_LIGHT_YELLOW,
      dark: DARK_GREY,
    },
  },
});
muiTheme.spacing(2);

export { muiTheme };
