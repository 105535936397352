import { components } from "../data/products.types";
import { RequireFields } from "../utils";

export type SearchableProduct = RequireFields<
  components["schemas"]["SearchableProduct"]
>;

export function isSearchableProduct(
  maybeSearchableProduct: any
): maybeSearchableProduct is SearchableProduct {
  const { productId, importId, productName } = maybeSearchableProduct;
  return (
    typeof productId === "number" &&
    typeof importId === "string" &&
    typeof productName === "string"
  );
}
