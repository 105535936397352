import {
  Button,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useMemo, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { StyledDashboardTableHead } from "../pages/dashboard";
import React from "react";
import { ValidOptionalItem } from "../utils";
import { FixedHeightTableLoader } from "./FixedHeightLoader";
import { Loader } from "./loader/Loader";

type SelectOptionalItemsTableProps = {
  itemId: number;
  selectedOptionalItemIds: number[];
  selectEnabled: Boolean;
  allOptionalItems: ValidOptionalItem[];
  searchQuery: string;
  isFetching: boolean;
  isSuccess: boolean;
  onSelect: (optionalItem: { id: number; name: string }) => void;
  defaultPageSize?: number;
};
export const SelectOptionalItemsTable = ({
  itemId,
  isFetching,
  isSuccess,
  selectedOptionalItemIds,
  selectEnabled,
  allOptionalItems,
  searchQuery,
  onSelect,
  defaultPageSize,
}: SelectOptionalItemsTableProps) => {
  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && allOptionalItems.length === 0;
  }, [allOptionalItems, isFetching, isSuccess]);
  const [page, setPage] = useState(0);
  const [filteredOptionalItems, setFilteredOptionalItems] =
    useState<ValidOptionalItem[]>(allOptionalItems);
  const pageSize = typeof defaultPageSize === "number" ? defaultPageSize : 10;

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  useEffect(() => {
    const searchLower = searchQuery.toLowerCase();
    setFilteredOptionalItems(
      searchQuery.length > 0
        ? allOptionalItems?.filter((oi) => {
            return (
              oi.itemName?.toLowerCase().includes(searchLower) ||
              oi.itemId.toString().includes(searchQuery)
            );
          })
        : allOptionalItems
    );
    setPage(0);
  }, [allOptionalItems, searchQuery]);

  const getRow = React.useCallback(
    (index: number) => {
      if (!filteredOptionalItems || !filteredOptionalItems[index]) {
        return (
          <StyledFixedHeightTableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row"></TableCell>
            <TableCell align="right"></TableCell>
          </StyledFixedHeightTableRow>
        );
      }
      const optionalItem = filteredOptionalItems[index];
      return (
        <StyledFixedHeightTableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="mappable-optional-item-tr"
        >
          <TableCell component="th" scope="row">
            {optionalItem.itemName}
          </TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              onClick={() =>
                onSelect({
                  id: Number(optionalItem.itemId),
                  name: optionalItem.itemName,
                })
              }
              disabled={
                selectedOptionalItemIds.includes(Number(optionalItem.itemId)) ||
                !selectEnabled
              }
              data-testid="add-optional-item-to-mapped-elements"
            >
              <ArrowForward />
            </Button>
          </TableCell>
        </StyledFixedHeightTableRow>
      );
    },
    [filteredOptionalItems, selectedOptionalItemIds, selectEnabled, onSelect]
  );
  return (
    <Grid
      mobile={12}
      padding={0}
      role="region"
      aria-label="Optional Items Table"
      data-testid="mappable-optional-items-table"
    >
      <Stack>
        {isFetching && (
          <FixedHeightTableLoader pageSize={pageSize} isEmpty={isEmpty}>
            <Loader />
          </FixedHeightTableLoader>
        )}
        {!isFetching && isSuccess && (
          <TableContainer component={Paper}>
            <Table aria-label="List of Selected Products">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              <TableBody>
                {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isEmpty && (
          <TablePagination
            component="div"
            count={filteredOptionalItems.length}
            onPageChange={handlePageChange}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
          />
        )}
      </Stack>
    </Grid>
  );
};

const StyledFixedHeightTableRow = styled(TableRow)({
  height: 48,
});
