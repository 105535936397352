import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  IconButton,
  Stack,
  Typography,
  TextField,
  Box,
  Button,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Alert,
} from "@mui/material";
import { ArrowUpward, ArrowDownward, DeleteOutline } from "@mui/icons-material";
import {
  RelationType,
  createSortByNumberFn,
  isSelectedRelationshipMapping,
  EditableItemRelationMappingType,
  ApiError,
} from "../utils";
import { useMutation } from "@tanstack/react-query";
import {
  getSelectedItemRelationship,
  saveItemRelationship,
} from "../data/items";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { useForm } from "react-hook-form";
import { Loader } from "./loader/Loader";
import { SelectItemsTable } from "./SelectItemsTable";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { AxiosError } from "axios";

type RelationshipMappingFormValues = {
  selectedRelationId: number | null;
  mappedItems: EditableItemRelationMappingType[];
};

type EditRelationshipMappingModalProps = {
  preSelectedRelationId: number | null;
  defaultItemId: number;
  defaultItemName: string;
  relationTypes: RelationType[];
  onSaveSuccess: () => void;
  onConfirmClose: () => void;
};

const sortByDisplayOrder =
  createSortByNumberFn<EditableItemRelationMappingType>("displayOrder");

export const EditRelationshipMappingModal = ({
  preSelectedRelationId,
  defaultItemId,
  defaultItemName,
  relationTypes,
  onSaveSuccess,
  onConfirmClose,
}: EditRelationshipMappingModalProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [customError, setCustomError] = useState<string | null>(null);

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<RelationshipMappingFormValues>({
    defaultValues: {
      selectedRelationId: preSelectedRelationId,
      mappedItems: [
        {
          selectedItemId: defaultItemId,
          selectedItemName: defaultItemName,
          isDefault: "Y",
          displayOrder: 0,
        },
      ],
    },
  });
  const selectedRelationId = watch("selectedRelationId");
  const mappedItems = watch("mappedItems");

  const availableVariants = useMemo(() => {
    const selectedRelation = relationTypes.find(
      (relationType) => relationType.relationId === Number(selectedRelationId)
    );
    if (selectedRelation) {
      return selectedRelation.variantList;
    }
    return [];
  }, [relationTypes, selectedRelationId]);

  const addItemEnabled = useMemo(
    () => !!selectedRelationId,
    [selectedRelationId]
  );

  const getSelectedItemRelationshipQuery = useCustomQuery(
    [
      "getSelectedItemRelationship",
      { itemId: defaultItemId, selectedRelationId },
    ],
    () =>
      getSelectedItemRelationship(apiClient)({
        countryCode: selectedCountry!,
        defaultItemId,
        selectedRelationId: selectedRelationId!,
      }),
    selectedRelationId !== null
  );

  const currentMappedItems = useMemo(() => {
    if (getSelectedItemRelationshipQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validMappedItems =
      getSelectedItemRelationshipQuery.data.data.dataList.filter(
        (
          maybeMappedItem
        ): maybeMappedItem is EditableItemRelationMappingType => {
          return isSelectedRelationshipMapping(maybeMappedItem) === true;
        }
      );
    return validMappedItems;
  }, [getSelectedItemRelationshipQuery.data]);

  useEffect(() => {
    if (currentMappedItems.length > 0) {
      setValue("mappedItems", sortByDisplayOrder(currentMappedItems, "asc"));
    }
  }, [currentMappedItems, setValue]);

  const handleAddItemToMapping = (item: { id: number; name: string }) => {
    const newMappedItem: EditableItemRelationMappingType = {
      selectedItemId: item.id,
      selectedItemName: item.name,
      isDefault: "N",
      displayOrder: mappedItems.length,
    };
    setValue("mappedItems", [...mappedItems, newMappedItem]);
  };
  const handleMoveItem = (index: number, direction: "up" | "down") => {
    const newIndex = direction === "up" ? index - 1 : index + 1;
    const updatedItems = [...mappedItems];
    [updatedItems[index], updatedItems[newIndex]] = [
      updatedItems[newIndex],
      updatedItems[index],
    ];
    updatedItems.forEach((item, idx) => {
      item.displayOrder = idx;
    });
    setValue("mappedItems", updatedItems);
  };
  const handleRemoveItemFromMapping = (
    mappedItem: EditableItemRelationMappingType
  ) => {
    const updatedMappedItems = mappedItems.filter((mi) => mi !== mappedItem);
    setValue("mappedItems", updatedMappedItems);
  };
  const saveRelationshipMappingRequest = saveItemRelationship(apiClient);
  const saveRelationshipMappingMutation = useMutation(
    (dataList: EditableItemRelationMappingType[]) => {
      if (!selectedRelationId) {
        throw new Error("Relationship type selection is required");
      }
      // assign displayOrder by the items' order within the table
      const adjustedMappedItems = dataList.map((item, index) => ({
        ...item,
        displayOrder: index + 1,
      }));
      const itemsToSave = {
        dataList: adjustedMappedItems,
      };

      return saveRelationshipMappingRequest(itemsToSave, {
        defaultItemId,
        selectedRelationId,
        countryCode: selectedCountry!,
        roleId: String(selectedRole)!,
      });
    },
    {
      onMutate: () => saveRelationshipMappingRequest,
      onSuccess: () => onSaveSuccess(),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setCustomError(
            "An error occurred saving the Relationship mapping. Please try again."
          );
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setCustomError(String(errorMessage.message));
        } else {
          setCustomError(String(error));
        }
      },
    }
  );
  const isWorking = useMemo(
    () =>
      getSelectedItemRelationshipQuery.isFetching ||
      saveRelationshipMappingMutation.isLoading,
    [
      getSelectedItemRelationshipQuery.isFetching,
      saveRelationshipMappingMutation.isLoading,
    ]
  );

  const saveMapping = (data: RelationshipMappingFormValues) => {
    saveRelationshipMappingMutation.mutate(data.mappedItems);
  };

  const onRequestClose = useCallback(() => {
    if (isDirty) {
      setConfirmDialogOpen(true);
    } else {
      onConfirmClose();
    }
  }, [isDirty, onConfirmClose]);

  return (
    <>
      <StyledDialog open={!confirmDialogOpen} onClose={onRequestClose}>
        <form
          onSubmit={handleSubmit(saveMapping)}
          data-testid="edit-item-relationship-mapping"
        >
          <DialogContent>
            <StyledRelativeContainer>
              <Grid container mobile={12}>
                {isWorking && <Loader />}
                <Grid
                  container
                  mobile={12}
                  sx={{ justifyContent: "flex-start" }}
                >
                  <Grid item mobile={5.5}>
                    <SelectItemsTable
                      selectedItemIds={mappedItems.map((mappedItem) =>
                        Number(mappedItem.selectedItemId)
                      )}
                      selectEnabled={addItemEnabled}
                      onSelect={handleAddItemToMapping}
                    />
                  </Grid>
                  <Grid item mobile={6.5} sx={{ pl: 3 }}>
                    <Stack>
                      <Grid item columns={12}>
                        <Stack spacing={1} marginBottom="20px" marginTop="20px">
                          <Grid
                            container
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Typography variant="h2">
                              Relationship Mapping
                            </Typography>
                          </Grid>
                        </Stack>
                      </Grid>
                      <TextField
                        label="Select Relationship Type"
                        {...register("selectedRelationId", {
                          required: true,
                        })}
                        error={!!errors.selectedRelationId}
                        helperText={
                          errors.selectedRelationId &&
                          "Please select a relationship type"
                        }
                        select
                        disabled={!!preSelectedRelationId}
                        SelectProps={{ native: true }}
                        defaultValue={selectedRelationId}
                        data-testid="open-relationship-type-select"
                      >
                        <option key="" value=""></option>
                        {relationTypes &&
                          relationTypes.map((relationshipType) => (
                            <option
                              key={relationshipType.relationId}
                              value={relationshipType.relationId}
                            >
                              {relationshipType.relationName}
                            </option>
                          ))}
                      </TextField>
                      {availableVariants.length > 0 && (
                        <Box sx={{ mt: 4 }} data-testid="mapped-items-table">
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <StyledDashboardTableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell
                                    style={{ whiteSpace: "nowrap" }}
                                    align="center"
                                  >
                                    Is Default
                                  </TableCell>
                                  <TableCell align="center">Variant</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </StyledDashboardTableHead>
                              <TableBody>
                                {mappedItems.map((mappedItem, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    data-testid="relationship-mapped-item-tr"
                                  >
                                    <TableCell component="th" scope="row">
                                      <Box
                                        component="span"
                                        display="inline-block"
                                        width="80%"
                                        whiteSpace="nowrap"
                                        textOverflow="ellipsis"
                                      >
                                        {mappedItem.selectedItemName}
                                      </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Checkbox
                                        checked={mappedItem.isDefault === "Y"}
                                        disabled
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <TextField
                                        label="Select Variant"
                                        size="small"
                                        select
                                        SelectProps={{ native: true }}
                                        data-testid="variant-selector"
                                        InputLabelProps={{ shrink: true }}
                                        {...register(
                                          `mappedItems.${index}.variantId`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        error={
                                          !!errors.mappedItems &&
                                          !getValues(
                                            `mappedItems.${index}.variantId`
                                          )
                                        }
                                        helperText={
                                          errors.mappedItems &&
                                          !getValues(
                                            `mappedItems.${index}.variantId`
                                          ) &&
                                          "Please assign a variant"
                                        }
                                      >
                                        <option key="" value=""></option>
                                        {availableVariants.map((variant) => (
                                          <option
                                            key={variant.variantId}
                                            value={variant.variantId}
                                          >
                                            {variant.variantName}
                                          </option>
                                        ))}
                                      </TextField>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={() =>
                                          handleMoveItem(index, "up")
                                        }
                                        disabled={index === 0}
                                        sx={
                                          index === 0
                                            ? { visibility: "hidden" }
                                            : {}
                                        }
                                      >
                                        <ArrowUpward />
                                      </IconButton>
                                      {mappedItem.displayOrder !==
                                      mappedItems.length - 1 ? (
                                        <IconButton
                                          color="primary"
                                          size="small"
                                          onClick={() =>
                                            handleMoveItem(index, "down")
                                          }
                                          disabled={
                                            index === mappedItems.length - 1
                                          }
                                        >
                                          <ArrowDownward />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          sx={{ visibility: "hidden" }}
                                          size="small"
                                          onClick={() =>
                                            handleMoveItem(index, "down")
                                          }
                                          disabled={
                                            index === mappedItems.length - 1
                                          }
                                        >
                                          <ArrowDownward />
                                        </IconButton>
                                      )}
                                      <IconButton
                                        sx={{ color: "#DA291C" }}
                                        size="small"
                                        onClick={() =>
                                          handleRemoveItemFromMapping(
                                            mappedItem
                                          )
                                        }
                                        disabled={mappedItem.isDefault === "Y"}
                                      >
                                        <DeleteOutline />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            {mappedItems.length === 0 && (
                              <StyledEmptyBox>
                                <Typography variant="h6">{`Add items to this relationship mapping using the arrows`}</Typography>
                              </StyledEmptyBox>
                            )}
                          </TableContainer>
                          {customError && (
                            <Alert variant="outlined" severity="error">
                              {customError}
                            </Alert>
                          )}
                        </Box>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </StyledRelativeContainer>
          </DialogContent>
          <StyledSaveActions>
            <StyledSecondaryButton variant="contained" onClick={onRequestClose}>
              Cancel
            </StyledSecondaryButton>
            <StyledButton variant="contained" size="large" type="submit">
              Save
            </StyledButton>
          </StyledSaveActions>
        </form>
      </StyledDialog>
      <Dialog open={confirmDialogOpen}>
        <StyledCenteredDialogContent>
          <DialogContentText id="confirm-close-mapping-modal">
            You have unsaved changes. Are you sure you want to close the form?
          </DialogContentText>
        </StyledCenteredDialogContent>
        <StyledConfirmActions>
          <StyledButton onClick={() => setConfirmDialogOpen(false)}>
            Continue Editing
          </StyledButton>
          <StyledButton
            variant="contained"
            size="large"
            onClick={onConfirmClose}
          >
            Confirm Close
          </StyledButton>
        </StyledConfirmActions>
      </Dialog>
    </>
  );
};

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    height: "90%",
    width: "100%",
  },
});
const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 380,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledSaveActions = styled(DialogActions)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
}));
const StyledConfirmActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "space-between",
});
const StyledCenteredDialogContent = styled(DialogContent)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
