import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const EmptyState = ({ children }: { children: React.ReactNode }) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell align="center" colSpan={12} sx={{ padding: 0 }}>
        <StyledCenteredDiv>{children}</StyledCenteredDiv>
      </TableCell>
    </TableRow>
  );
};

const StyledCenteredDiv = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(3),
}));
