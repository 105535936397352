import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { Logo } from "../components/Logo";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { AuthContext } from "../auth-provider";
import { useAxios } from "../axios-provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCountryAndRoleList } from "../data/rolecountry";
import { useForm } from "react-hook-form";
import { RoleContext } from "../role-provider";
import { useNavigate } from "react-router-dom";
import { ValidLanguage, isLanguage } from "../utils";
import { StyledDangerButton } from "../components/UnsavedChangesModal";
import { expireRefreshToken } from "../data/auth";
import { useCustomQuery } from "../hooks/use-custom-query";
import packageInfo from "../../package.json";

function findDefaultLanguage(
  languages: ValidLanguage[]
): ValidLanguage | undefined {
  const language = languages.find((language) => {
    return (
      language.isDefaultLang && language?.isDefaultLang.toLowerCase() === "y"
    );
  });
  if (language) {
    return language;
  }
  if (languages.length > 0) {
    return languages[0];
  }
}

type CountryRoleFormValues = {
  selectedCountryCode: string;
  selectedRoleId: number;
};

export const SwitchRoleView: React.FC = () => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { redirectDestination, refreshToken, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const version = packageInfo.version;
  const countryAndRoleListQuery = useCustomQuery(
    ["getCountryAndRoleList"],
    () => getCountryAndRoleList(apiClient)({})
  );
  const countries = useMemo(() => {
    const records = countryAndRoleListQuery.data?.data.dataList?.filter(
      (maybeCountry) => maybeCountry !== undefined
    );
    return records ?? [];
  }, [countryAndRoleListQuery.data]);

  const {
    selectedCountry: initialSelectedCountry,
    selectedRole,
    setSelectedCountry,
    setSelectedRole,
    setDefaultLanguage,
    setIsItemLevelMarket,
    setSelectableLanguages,
    setShowRecentFeedUpdatesTable,
  } = useContext(RoleContext);

  const onSubmit = useCallback(
    (data: CountryRoleFormValues) => {
      const selectedCountry = countries.find((country) => {
        return String(country.countryCode) === String(data.selectedCountryCode);
      });
      if (!selectedCountry || !selectedCountry.languageList) {
        throw new Error(
          `No country found for country code ${data.selectedCountryCode}`
        );
      }
      const validLanguages: ValidLanguage[] =
        selectedCountry.languageList.filter(
          (maybeLanguage): maybeLanguage is ValidLanguage =>
            isLanguage(maybeLanguage) === true
        );
      const marketLevel = selectedCountry.marketLevel;
      setIsItemLevelMarket(marketLevel === "item");
      setSelectableLanguages(validLanguages);

      const marketDefaultLanguage = findDefaultLanguage(validLanguages);
      const languageCode =
        marketDefaultLanguage && marketDefaultLanguage.languageCode
          ? marketDefaultLanguage.languageCode
          : "en";

      setSelectedCountry(data.selectedCountryCode);
      setDefaultLanguage(languageCode);
      setSelectedRole(data.selectedRoleId);
      setShowRecentFeedUpdatesTable(null);
      queryClient.invalidateQueries(["recentFeedUpdates"]);
      queryClient.invalidateQueries(["showRecentFeedUpdates"]);
      queryClient.invalidateQueries(["getPendingActionList"]);
      queryClient.invalidateQueries(["getTopTenRecentMenu"]);
      queryClient.invalidateQueries(["readyPushLiveList"]);
      queryClient.invalidateQueries(["schedulePushLiveList"]);
      const redirectUrl = redirectDestination ?? "/dashboard";
      navigate(redirectUrl);
    },
    [
      countries,
      setIsItemLevelMarket,
      setSelectableLanguages,
      setSelectedCountry,
      setDefaultLanguage,
      setSelectedRole,
      setShowRecentFeedUpdatesTable,
      queryClient,
      redirectDestination,
      navigate,
    ]
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CountryRoleFormValues>();
  const watchSelectedCountry = watch("selectedCountryCode");

  const selectableRoles = useMemo(() => {
    if (watchSelectedCountry) {
      const selectedCountry = countries.find((country) => {
        return String(country.countryCode) === String(watchSelectedCountry);
      });
      if (selectedCountry) {
        return selectedCountry.roleList;
      }
    }
    return [];
  }, [watchSelectedCountry, countries]);

  useEffect(() => {
    if (countries.length === 1) {
      const country = countries[0];
      if (country.roleList?.length === 1) {
        onSubmit({
          selectedCountryCode: country.countryCode!,
          selectedRoleId: country.roleList[0].roleId!,
        });
      }
    } else if (initialSelectedCountry) {
      setValue("selectedCountryCode", initialSelectedCountry);
    }
  }, [countries, initialSelectedCountry, onSubmit, setValue]);

  useEffect(() => {
    if (selectableRoles && selectedRole) {
      setValue("selectedRoleId", Number(selectedRole));
    }
  }, [selectableRoles, selectedRole, setValue]);

  const expireRefreshTokenRequest = expireRefreshToken(apiClient);
  const expireRefreshTokenMutation = useMutation(
    ({ token }: { token: string }) => {
      return expireRefreshTokenRequest({
        token,
      });
    },
    {
      onSuccess: () => {
        return logout();
      },
      onError: () => {
        // swallow error - failed request indicates refresh token is already expired on API side
        return logout();
      },
    }
  );

  const handleLogoutClick = () => {
    if (typeof refreshToken !== "string") {
      return logout("Refresh token does not exist");
    }
    return expireRefreshTokenMutation.mutate({ token: refreshToken });
  };

  return (
    <StyledFullHeightGrid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item mobile={3}>
        <StyledSwitchRoleFormOuter>
          <StyledSwitchRoleFormInner>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={1} sx={{ p: 4 }}>
                <Logo />
                <Grid container sx={{ justifyContent: "center" }}>
                  <Typography variant="body2">{`Version: ${version}`}</Typography>
                </Grid>
                <TextField
                  fullWidth
                  label="Select Country"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("selectedCountryCode", {
                    required: true,
                  })}
                  error={!!errors.selectedCountryCode}
                  helperText={
                    errors.selectedCountryCode && "Please select a country"
                  }
                  select
                  aria-label="select country"
                  SelectProps={{ native: true }}
                  defaultValue={initialSelectedCountry}
                  data-testid="open-country-select"
                >
                  <option key="" value=""></option>
                  {countries.map((country) => (
                    <option
                      key={country.countryCode}
                      value={country.countryCode}
                    >
                      {country.countryName}
                    </option>
                  ))}
                </TextField>
                {selectableRoles && (
                  <TextField
                    fullWidth
                    label="Select Role"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("selectedRoleId", {
                      required: true,
                    })}
                    error={!!errors.selectedRoleId}
                    helperText={errors.selectedRoleId && "Please select a role"}
                    select
                    aria-label="select role"
                    defaultValue={selectedRole}
                    SelectProps={{ native: true }}
                    data-testid="open-role-select"
                  >
                    <option key="" value=""></option>
                    {selectableRoles.map((role) => (
                      <option key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </option>
                    ))}
                  </TextField>
                )}
                <Grid>
                  <StyledSubmitButton
                    fullWidth
                    size="large"
                    data-testid="submit-country-role-btn"
                    type="submit"
                    variant="contained"
                    aria-label="continue"
                  >
                    Continue
                  </StyledSubmitButton>
                  <StyledDangerButton
                    fullWidth
                    size="large"
                    onClick={handleLogoutClick}
                    variant="contained"
                    aria-label="sign-out"
                  >
                    Log Out
                  </StyledDangerButton>
                </Grid>
              </Stack>
            </form>
          </StyledSwitchRoleFormInner>
        </StyledSwitchRoleFormOuter>
      </Grid>
    </StyledFullHeightGrid>
  );
};

const StyledFullHeightGrid = styled(Grid)({
  minHeight: "100vh",
});

const StyledSwitchRoleFormOuter = styled(Box)({
  minWidth: 550,
  backgroundColor: "white",
  flex: "1 1 auto",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});
const StyledSwitchRoleFormInner = styled(Box)({
  maxWidth: 300,
  px: 3,
  py: 3,
  width: "100%",
});
const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
  marginBottom: theme.spacing(3),
}));
