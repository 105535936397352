import { Input, InputProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

export const SearchInput = (props: InputProps & { testId: string }) => {
  const {
    value,
    onChange,
    placeholder,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    id,
    testId,
    ...otherProps
  } = props;
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>(
    placeholder ?? "Search"
  );
  return (
    <Input
      value={value}
      onChange={onChange}
      onFocus={() => setSearchPlaceholder("")}
      onBlur={() => {
        if (String(value).length === 0) {
          setSearchPlaceholder("Search");
        }
      }}
      endAdornment={<SearchIcon sx={{ opacity: 0.5 }} />}
      placeholder={searchPlaceholder}
      data-testid={testId}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      id={id}
      {...otherProps}
    />
  );
};
