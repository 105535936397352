import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import spriteSheet from "./mcd-fries-animation-1x.png";

const fryAnimation = keyframes(`
  0% {
    background-position: 0;
  }
  100% {
    background-position: -2761px;
  }
`);

const Shade = styled("div")({
  position: "absolute",
  zIndex: 1099,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(255, 255, 255, 0.6)",
});

const Fries = styled("div")({
  position: "relative",
  width: 122,
  height: 220,
  top: "calc(50% - 110px)",
  left: "calc(50% - 61px)",
  background: `url(${spriteSheet}) no-repeat`,
  animationName: fryAnimation,
  animationDuration: "1s",
  animationIterationCount: "infinite",
  animationTimingFunction: "steps(22)",
});

export const Loader = () => {
  return (
    <Shade data-testid="fries-loader">
      <Fries />
    </Shade>
  );
};
