import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthContext } from "../auth-provider";
import { useLocation, useNavigate } from "react-router-dom";
import { useAxios } from "../axios-provider";
import { gasAuthenticationToken } from "../data/auth";
import { useQueryClient } from "@tanstack/react-query";

export const Auth: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const {
    pkceCodeVerifier,
    redirectDestination,
    setAccessToken,
    setRefreshToken,
    setEid,
    logout,
  } = useContext(AuthContext);
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get("code");

  const [attempted, setAttempted] = useState(false);
  const isRequestSent = useRef(false);

  const gasTokenRequest = gasAuthenticationToken(apiClient);
  const exchangeToken = useCallback(async () => {
    if (isRequestSent.current) {
      return;
    }
    isRequestSent.current = true;
    if (process.env.REACT_APP_AUTH_TYPE !== "gas") {
      return logout("GAS Authentication is not enabled");
    }
    if (!code) {
      return logout("Auth code from GAS server is missing or invalid");
    }
    if (!pkceCodeVerifier) {
      return logout("PKCE code verifier is missing or invalid");
    }

    try {
      const response = await gasTokenRequest({
        code,
        codeVerifier: pkceCodeVerifier,
      });

      if (response.data.accessToken) {
        setAccessToken(response.data.accessToken);
      }
      if (response.data.refreshToken) {
        setRefreshToken(response.data.refreshToken);
      }
      if (response.data.eid) {
        setEid(response.data.eid);
      }
      queryClient.invalidateQueries(["getCountryAndRoleList"]);

      const redirectUrl = redirectDestination ?? "/";
      navigate(redirectUrl);
    } catch (error) {
      logout(
        `Failed to exchange auth code for access token due to error: ${error}`
      );
    }
    setAttempted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, pkceCodeVerifier, redirectDestination]);

  useEffect(() => {
    if (!attempted) {
      exchangeToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempted]);

  return null;
};
