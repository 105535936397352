import {
  createRealDeleteRequest,
  createRealGetRequest,
  createRealPostFileRequest,
  createRealPostRequest,
  createRealPutRequest,
} from ".";
import { paths } from "../miscellaneous.types";

export const realGetPushLiveDetails = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getPushLiveDetails"]
>("/miscellaneous/getPushLiveDetails");
export const realGetApprovedChanges = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getApprovedChanges"]
>("/miscellaneous/getApprovedChanges");
export const realGetUnapprovedChanges = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUnapprovedChanges"]
>("/miscellaneous/getUnapprovedChanges");
export const realGetTimeZones = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getTimeZones"]
>("/miscellaneous/getTimeZones");
export const realEditPushLive = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/editPushLive"]
>("/miscellaneous/editPushLive");
export const realGetPushliveEventDetails = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getPushliveEventDetails"]
>("/miscellaneous/getPushliveEventDetails");
export const realDeletePushLive = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deletePushLive"]
>("/miscellaneous/deletePushLive");
export const realSaveReadyForBulkPushlive = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveReadyForBulkPushlive"]
>("/miscellaneous/saveReadyForBulkPushlive");
export const realRevertWorkflow = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/revertWorkflow"]
>("/miscellaneous/revertWorkflow");
export const realSchedulePushLive = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/schedulePushLive"]
>("/miscellaneous/schedulePushLive");
export const realEditSchedulePushLive = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/editSchedulePushLive"]
>("/miscellaneous/editSchedulePushLive");
export const realInstantPushLive = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/instantPushLive"]
>("/miscellaneous/instantPushLive");
export const realEditInstantPushLive = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/editInstantPushLive"]
>("/miscellaneous/editInstantPushLive");
export const realGetUOMByCountry = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUOMByCountry"]
>("/miscellaneous/getUOMByCountry");
export const realDeleteUOM = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteUOM"]
>("/miscellaneous/deleteUOM");
export const realSaveUOM = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveUOM"]
>("/miscellaneous/saveUOM");
export const realBulkFileUpload = createRealPostFileRequest<
  paths["/exp/v1/dna/miscellaneous/bulkFileUpload"]
>("/miscellaneous/bulkFileUpload");
export const realGetViewLogo = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getViewLogo"]
>("/miscellaneous/getViewLogo");
export const realSaveLogoDetails = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveLogoDetails"]
>("/miscellaneous/saveLogoDetails");
export const realDeleteLogo = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteLogo"]
>("/miscellaneous/deleteLogo");
export const realGetCustomFooters = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getCustomFooters"]
>("/miscellaneous/getCustomFooters");
export const realGetUsersData = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUsersData"]
>("/miscellaneous/getUsersData");
export const realGetAllRolesByCountry = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getAllRolesByCountry"]
>("/miscellaneous/getAllRolesByCountry");
export const realGetUserDetailsById = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUserDetailsById"]
>("/miscellaneous/getUserDetailsById");
export const realSaveUser = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveUser"]
>("/miscellaneous/saveUser");
export const realDeleteUser = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteUser"]
>("/miscellaneous/deleteUser");
export const realGetRolesAndPrivileges = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRolesAndPrivileges"]
>("/miscellaneous/getRolesAndPrivileges");
export const realSaveRole = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRole"]
>("/miscellaneous/saveRole");
export const realChangeRoleStatus = createRealPutRequest<
  paths["/exp/v1/dna/miscellaneous/changeRoleStatus"]
>("/miscellaneous/changeRoleStatus");
export const realChangePrivilegeStatus = createRealPutRequest<
  paths["/exp/v1/dna/miscellaneous/changePrivilegeStatus"]
>("/miscellaneous/changePrivilegeStatus");
export const realDeleteFooterId = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteFooterId"]
>("/miscellaneous/deleteFooterId");
export const realSaveCustomFooters = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveCustomFooters"]
>("/miscellaneous/saveCustomFooters");
export const realGetExceptionReportData = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getExceptionReportData"]
>("/miscellaneous/getExceptionReportData");
export const realGetNutritionFactsByCountry = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getNutritionFactsByCountry"]
>("/miscellaneous/getNutritionFactsByCountry");
export const realSaveNutrient = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveNutrient"]
>("/miscellaneous/saveNutrient");
export const realSaveDisplayOrder = createRealPutRequest<
  paths["/exp/v1/dna/miscellaneous/saveDisplayOrder"]
>("/miscellaneous/saveDisplayOrder");
export const realDeleteByNutritionFactID = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteByNutritionFactID"]
>("/miscellaneous/deleteByNutritionFactId");
export const realGetWorkflowManagementData = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getWorkflowManagementData"]
>("/miscellaneous/getWorkflowManagementData");
export const realSaveAddedStepForWorkflowManagement = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveAddedStepsForWorkflowManagement"]
>("/miscellaneous/saveAddedStepsForWorkflowManagement");
export const realDeleteWorkflowStep = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteWorkflowStep"]
>("/miscellaneous/deleteWorkflowStep");
export const realSaveExceptionsReportData = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveExceptionsReportData"]
>("/miscellaneous/saveExceptionsReportData");
export const realGetViewRelationshipDetails = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getViewRelationshipDetails"]
>("/miscellaneous/getViewRelationshipDetails");
export const realGetEditRelationshipDetails = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getEditRelationshipDetails"]
>("/miscellaneous/getEditRelationshipDetails");
export const realSaveRelationship = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRelationship"]
>("/miscellaneous/saveRelationship");
export const realDeleteRelationshipType = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRelationshipType"]
>("/miscellaneous/deleteRelationshipType");
export const realDeleteRelationship = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRelationship"]
>("/miscellaneous/deleteRelationship");
export const realSaveElementDisplayOrder = createRealPutRequest<
  paths["/exp/v1/dna/miscellaneous/saveElementDisplayOrder"]
>("/miscellaneous/saveElementDisplayOrder");
export const realGetViewStatus = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getViewStatus"]
>("/miscellaneous/getViewStatus");
export const realSaveStatusFlag = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveStatusFlag"]
>("/miscellaneous/saveStatusFlag");
export const realDeleteStatusFlag = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteStatusFlag"]
>("/miscellaneous/deleteStatusFlag");
export const realGetManageAttributes = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getManageAttribute"]
>("/miscellaneous/getManageAttribute");
export const realSaveAttributeDisplayOrder = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveAttributeDisplayOrder"]
>("/miscellaneous/saveAttributeDisplayOrder");
