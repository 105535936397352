import React, { useContext } from "react";
import { Alert, Button, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Logo } from "./Logo";
import { AuthContext } from "../auth-provider";
import { generateCodeChallenge, generateCodeVerifier } from "../utils";
import packageInfo from "../../package.json";

type GasLoginFormProps = {
  error?: string;
};

export const GasLoginForm = ({ error }: GasLoginFormProps) => {
  const { setPkceCodeVerifier } = useContext(AuthContext);

  const initGasLogin = async () => {
    const codeVerifier = generateCodeVerifier();
    setPkceCodeVerifier(codeVerifier);
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    const authUrl = `https://login.microsoftonline.com/${process.env.REACT_APP_GAS_AUTH_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_GAS_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GAS_AUTH_REDIRECT_URI}&scope=openid profile email&response_type=code&response_mode=query&code_challenge_method=S256&code_challenge=${codeChallenge}`;
    window.location.href = authUrl;
  };
  const version = packageInfo.version;

  return (
    <div>
      <Stack spacing={1} sx={{ p: 4 }}>
        <Logo />
        <Grid container sx={{ justifyContent: "center" }}>
          <Typography variant="body2">{`Version: ${version}`}</Typography>
        </Grid>
        {error && (
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        )}
        <StyledSubmitButton
          onClick={initGasLogin}
          fullWidth
          size="large"
          data-testid="login-btn"
          type="submit"
          variant="contained"
          aria-label="Login"
        >
          Login
        </StyledSubmitButton>
      </Stack>
    </div>
  );
};

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
  marginTop: theme.spacing(3),
}));
