import * as React from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  IconButton,
  Box,
  TableCellProps,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { useCallback, useContext, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { withLayout } from "../hoc/with-layout";
import { isDefined } from "../utils";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { useCustomQuery } from "../hooks/use-custom-query";
import { AddRowLink } from "../components/AddRowLink";
import { AddCircle, DeleteForever } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import {
  deleteRelationshipType,
  getViewRelationshipDetails,
} from "../data/miscellaneous";
import { AxiosError } from "axios";
import { ApiError } from "../utils";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";
import {
  RelationshipType,
  formatRelationshipType,
} from "../util/relationships";
import { ConfirmActionModal } from "../components/ConfirmActionModal";

export const RelationshipTypes = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { selectedCountry, selectableLanguages, isReaderRole } =
    useContext(RoleContext);
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [relationshipTypeToDelete, setRelationshipTypeToDelete] = useState<
    number | null
  >(null);
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] =
    useState<boolean>(false);

  const { data, isFetching } = useCustomQuery(
    ["getViewRelationshipDetails", { selectedCountry }],
    () =>
      getViewRelationshipDetails(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  const relationshipTypes: RelationshipType[] = useMemo(() => {
    if (data?.data.relationshipList === undefined) {
      return [];
    }
    return data.data.relationshipList
      .map((rawRelationshipType) => formatRelationshipType(rawRelationshipType))
      .filter(isDefined);
  }, [data]);

  const deleteRelationshipTypeRequest = deleteRelationshipType(apiClient);
  const { mutate: doDeleteRelationshipType, isLoading } = useMutation(
    () => {
      return deleteRelationshipTypeRequest({
        countryCode: selectedCountry!,
        relationshipTypeId: relationshipTypeToDelete!,
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Relationship type was successfully deleted`);
        setRelationshipTypeToDelete(null);
        setIsRetryDeleteModalOpen(false);
        queryClient.invalidateQueries(["getViewRelationshipDetails"]);
      },
      onError: (error: AxiosError) => {
        setRelationshipTypeToDelete(null);
        if (error?.response?.status === 401) {
          setIsRetryDeleteModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const getVariantsForRelationshipTypeAndLanguage = useCallback(
    (relationshipTypeId: number, language: string): string => {
      const relationshipType = relationshipTypes.find(
        (relationshipType) =>
          relationshipType.relationTypeId === relationshipTypeId
      );
      if (typeof relationshipType !== "undefined") {
        const variants = relationshipType.languages[language];
        if (typeof variants === "string") {
          return variants;
        }
      }
      return "";
    },
    [relationshipTypes]
  );

  const handleRetryDelete = useCallback(() => {
    setIsRetryDeleteModalOpen(false);
    doDeleteRelationshipType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRetryDeleteCancel = useCallback(() => {
    setIsRetryDeleteModalOpen(false);
    setRelationshipTypeToDelete(null);
  }, []);

  return (
    <StyledRelativeContainer>
      <Grid
        mobile={12}
        container
        sx={{ mb: 6, justifyContent: "space-between" }}
      >
        <Typography variant="h1">View Relationship</Typography>
        {!isReaderRole && (
          <AddRowLink
            onClick={() => {
              navigate(`/relationships/create-relationship`);
            }}
            data-testid="add-new-relationship-type-button"
          >
            <AddCircle aria-label="add new relationship type button" />
            <Typography>Add New Relationship</Typography>
          </AddRowLink>
        )}
      </Grid>

      {isFetching && <Loader />}
      <Grid
        container
        mobile={12}
        marginBottom={4}
        data-testid="relationship-types-list"
      >
        <TableContainer component={Paper}>
          <Table aria-label="relationship types table">
            <StyledDashboardTableHead>
              <TableRow>
                <StyledNameTableCell>
                  Relationship-Type Name
                </StyledNameTableCell>
                {selectableLanguages.map((language) => (
                  <StyledLanguageTableCell
                    numLanguages={selectableLanguages.length}
                  >
                    {language.languageName}
                  </StyledLanguageTableCell>
                ))}
                {!isReaderRole && (
                  <StyledActionsTableCell>Action</StyledActionsTableCell>
                )}
              </TableRow>
            </StyledDashboardTableHead>
            {relationshipTypes.length > 0 && (
              <TableBody>
                {relationshipTypes.map((relationshipType) => {
                  return (
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledNameTableCell>
                        {relationshipType.relationTypeName}
                      </StyledNameTableCell>
                      {selectableLanguages.map((language) => (
                        <StyledLanguageTableCell
                          numLanguages={selectableLanguages.length}
                        >
                          {getVariantsForRelationshipTypeAndLanguage(
                            relationshipType.relationTypeId,
                            language.languageCode
                          )}
                        </StyledLanguageTableCell>
                      ))}
                      {!isReaderRole && (
                        <StyledActionsTableCell>
                          <>
                            <IconButton
                              disabled={false}
                              onClick={() =>
                                navigate(
                                  `/relationships/${relationshipType.relationTypeId}/edit-relationship`
                                )
                              }
                            >
                              <EditIcon
                                aria-label={`Edit relationship type ${relationshipType.relationTypeName}`}
                              />
                            </IconButton>
                            <IconButton
                              sx={{ color: "#DA291C" }}
                              onClick={() =>
                                setRelationshipTypeToDelete(
                                  relationshipType.relationTypeId
                                )
                              }
                              aria-label={`Delete relationship type ${relationshipType.relationTypeName}`}
                            >
                              <DeleteForever />
                            </IconButton>
                          </>
                        </StyledActionsTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {relationshipTypes.length === 0 && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No relationship types defined in this market`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </Grid>
      {typeof relationshipTypeToDelete === "number" && (
        <ConfirmActionModal
          open={!isRetryDeleteModalOpen}
          loading={isLoading}
          message="Are you sure you want to delete this relationship type?"
          onConfirm={() => doDeleteRelationshipType()}
          onCancel={() => setRelationshipTypeToDelete(null)}
        />
      )}
      <ResendFormModal
        open={isRetryDeleteModalOpen}
        onResend={handleRetryDelete}
        onCancel={handleRetryDeleteCancel}
        description="An error occurred while deleting the relationship type"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "View Relationship");

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  fontSize: theme.typography.small.fontSize,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 36,
});
const StyledNameTableCell = styled(TableCell)({
  width: "20%",
});
const StyledLanguageTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "numLanguages",
})<TableCellProps & { numLanguages: number }>(({ numLanguages }) => ({
  flexGrow: 1,
  flexBasis: 0,
  maxWidth: `calc(70% / ${numLanguages})`,
  wordBreak: "break-word",
}));
const StyledActionsTableCell = styled(TableCell)({
  width: "10%",
});
