import * as React from "react";
import { Typography, Grid, TableHead } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useMemo, useState } from "react";
import { withLayout } from "../hoc/with-layout";
import { isDefined } from "../utils";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { useCustomQuery } from "../hooks/use-custom-query";
import { useParams } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import { getEditRelationshipDetails } from "../data/miscellaneous";
import { SuccessAlertSnackbar } from "../components/AlertSnackbar";
import {
  RelationshipVariant,
  formatRelationshipVariant,
} from "../util/relationships";
import { AddEditRelationship } from "../components/AddEditRelationshipForm";

export const EditRelationship = () => {
  const { relationshipTypeId } = useParams();
  const { apiClient } = useAxios();
  const { selectedCountry } = useContext(RoleContext);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getEditRelationshipDetails", { relationshipTypeId }],
    () =>
      getEditRelationshipDetails(apiClient)({
        countryCode: selectedCountry!,
        relationshipTypeId: Number(relationshipTypeId)!,
      }),
    typeof relationshipTypeId !== "undefined"
  );

  const relationshipTypeName: string = useMemo(() => {
    if (
      data?.data.relationTypeName &&
      typeof data.data.relationTypeName === "string"
    ) {
      return data.data.relationTypeName;
    }
    return "";
  }, [data]);
  const relationshipVariants: RelationshipVariant[] = useMemo(() => {
    if (data?.data.dataList) {
      return data.data.dataList
        .map((rawVariant) => formatRelationshipVariant(rawVariant))
        .filter(isDefined);
    }
    return [];
  }, [data]);

  const EditRelationshipWithLayout = useMemo(() => {
    return withLayout(() => {
      return (
        <StyledRelativeContainer>
          <Grid
            mobile={12}
            container
            sx={{ mb: 6, justifyContent: "space-between" }}
          >
            <Typography variant="h1">Edit Relationship</Typography>
          </Grid>
          {isFetching && <Loader />}
          {isSuccess && !isNaN(Number(relationshipTypeId)) && (
            <AddEditRelationship
              data={{
                relationTypeId: Number(relationshipTypeId),
                relationTypeName: relationshipTypeName,
                variants: relationshipVariants,
              }}
              onSaveSuccess={() =>
                setSuccessMessage("Relationship was saved successfully")
              }
            />
          )}
          <SuccessAlertSnackbar
            message={successMessage}
            onClose={() => setSuccessMessage(null)}
          />
        </StyledRelativeContainer>
      );
    }, "Edit Relationship");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isSuccess, relationshipVariants, successMessage]);
  return <EditRelationshipWithLayout />;
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  fontSize: theme.typography.small.fontSize,
}));
