import React, { useEffect, useState } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface FortifiedItemListData {
  message?: string;
  dataList?: {
    nutrientFactId?: number;
    display?: string;
    name?: string;
  }[];
}
interface MultiSelectProps {
  selectedItems: number[];
  unSelectedItems: number[];
  selectedLanguage: string | null;
  handleChange: (selectedItems: number[], unSelectedItems: number[]) => void;
  //isFortifiedItemList : any[];
  isFortifiedItemList: UseQueryResult<AxiosResponse<FortifiedItemListData>, unknown>;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  selectedItems, unSelectedItems,
  handleChange, selectedLanguage, isFortifiedItemList }) => {

  const [dataLoaded, setDataLoaded] = useState(false);
  const [prevSelected, setPrevSelected] = useState<number[]>([]);
  const [prevUnselected, setPrevUnselected] = useState<number[]>([]);


  useEffect(() => {
    let fortifiedItemMasterList = isFortifiedItemList?.data?.data?.dataList;
    const selectedIDsList = fortifiedItemMasterList?.filter(item => item.display === 'Yes')
      .map(item => item.nutrientFactId || 0) as number[];

    const unselectedIDsList = fortifiedItemMasterList?.filter(item => item.display === 'No')
      .map(item => item.nutrientFactId || 0) as number[];

    // Calculate the IDs that were selected and unselected in the current render.
    const newSelectedIDs = selectedIDsList.filter(id => !prevSelected.includes(id));
    const newUnselectedIDs = unselectedIDsList.filter(id => !prevUnselected.includes(id));

    // Calculate the IDs that were deselected and un-unselected in the current render.
    const deselectedIDs = prevSelected.filter(id => !selectedIDsList.includes(id));
    const unUnselectedIDs = prevUnselected.filter(id => !unselectedIDsList.includes(id));

    // Check if there's any change in selection.
    const selectionChanged = newSelectedIDs.length > 0 || newUnselectedIDs.length > 0 || deselectedIDs.length > 0 || unUnselectedIDs.length > 0;

    if (selectionChanged) {
      const updatedSelectedIDs = [...prevSelected, ...newSelectedIDs].filter(id => !deselectedIDs.includes(id));
      const updatedUnselectedIDs = [...prevUnselected, ...newUnselectedIDs].filter(id => !unUnselectedIDs.includes(id));
      handleChange(updatedSelectedIDs, updatedUnselectedIDs);
      // Update the state with the new selected and unselected counts.
      setPrevSelected(selectedIDsList);
      setPrevUnselected(unselectedIDsList);
    }
  }, [isFortifiedItemList, handleChange, prevSelected, prevUnselected]);

  //on each check box change and first load
  useEffect(() => {

    if (!isFortifiedItemList.isLoading && isFortifiedItemList.data && !dataLoaded) {
      let fortifiedItemMasterLIst = isFortifiedItemList?.data?.data?.dataList;
      const selectedIDsList = fortifiedItemMasterLIst?.filter(item => item.display === 'Yes')
        .map(item => item.nutrientFactId || 0) as number[];

      const UnselectedIDsList = fortifiedItemMasterLIst?.filter(item => item.display === 'No')
        .map(item => item.nutrientFactId || 0) as number[];
      handleChange(selectedIDsList, UnselectedIDsList);
      setDataLoaded(true);
    }
  }, [isFortifiedItemList, dataLoaded, handleChange, setDataLoaded]);

  const handleInternalChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const { target: { value } } = event;
    let fortifiedItemMasterLIst = isFortifiedItemList?.data?.data?.dataList;
    const selectedIds = typeof value === 'string' ? value.split(',').map(Number) : value;

    const filteredSelectedIds = selectedIds.filter(id => typeof id === 'number' && !isNaN(id));

    const filteredUnSelectedIDs = fortifiedItemMasterLIst?.filter(item => !selectedIds.includes(item.nutrientFactId || 0))
      .map(item => item.nutrientFactId || 0) as number[];

    handleChange(filteredSelectedIds, filteredUnSelectedIDs);
  };

  return (
    <div>
      {dataLoaded && (
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedItems}
            onChange={handleInternalChange}
            input={<OutlinedInput size="small" />}
            renderValue={(selected) =>
              selected.length === 1 ? (
                isFortifiedItemList?.data?.data?.dataList?.find((item) => item.nutrientFactId === selected[0])?.name
              ) : (
                'Selected Nutrient Facts'
              )
            }
            MenuProps={MenuProps}

          >
            {isFortifiedItemList?.data?.data?.dataList?.map((item) => (
              <MenuItem key={item.nutrientFactId} value={item.nutrientFactId}>
                <Checkbox checked={item.nutrientFactId !== undefined && selectedItems.indexOf(item.nutrientFactId) !== -1} />
                <ListItemText primary={item.name} />
              </MenuItem>

            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}

export default MultiSelect;