import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
  QueryFunction,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

// custom useQuery implementation with overridable default configs
export function useCustomQuery<
  TQueryFnData = unknown,
  TError = AxiosError,
  TData = TQueryFnData
>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  enabled?: boolean,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, QueryKey>,
    "queryKey" | "queryFn" | "enabled"
  >
): UseQueryResult<TData, TError> {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey,
    queryFn,
    enabled: enabled !== undefined ? enabled : true, // Default to true, override as needed
    refetchOnWindowFocus:
      options?.refetchOnWindowFocus !== undefined
        ? options.refetchOnWindowFocus
        : false,
    refetchOnReconnect:
      options?.refetchOnReconnect !== undefined
        ? options.refetchOnReconnect
        : false,
    refetchOnMount:
      options?.refetchOnMount !== undefined ? options.refetchOnMount : true,
    retry: false,
    ...options, // Spread any additional options to allow overrides
  });
}
