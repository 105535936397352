import { createRealGetRequest, createRealPostRequest } from ".";
import { paths } from "../products.types";

export const realGetProducts = createRealGetRequest<
  paths["/exp/v1/dna/product/getProductsList"]
>("/product/getProductsList");

export const realGetSearchableProducts = createRealGetRequest<
  paths["/exp/v1/dna/item/getAllProductsList"]
>("/item/getAllProductsList");

export const realGetProductDetails = createRealGetRequest<
  paths["/exp/v1/dna/product/getProductDetails"]
>("/product/getProductDetails");

export const realGetNutrientsList = createRealGetRequest<
  paths["/exp/v1/dna/product/getNutrientsList"]
>("/product/getNutrientsList");

export const realGetProductNutrients = createRealGetRequest<
  paths["/exp/v1/dna/product/getNutrientsList"]
>("/product/getNutrientsList");

export const realSaveProductDetails = createRealPostRequest<
  paths["/exp/v1/dna/product/saveProdDetails"]
>("/product/saveProdDetails");

export const realSaveProductNutrients = createRealPostRequest<
  paths["/exp/v1/dna/product/saveNutrientsList"]
>("/product/saveNutrientsList");

export const realGetCompareCurrent = createRealGetRequest<
  paths["/exp/v1/dna/product/getCompareCurrent"]
>("/product/getCompareCurrent");

export const realGetCompareLive = createRealGetRequest<
  paths["/exp/v1/dna/product/getCompareLive"]
>("/product/getCompareLive");

export const realProductGetReviewAndSubmit = createRealGetRequest<
  paths["/exp/v1/dna/product/getReviewAndSubmit"]
>("/product/getReviewAndSubmit");

export const realProductSaveReviewAndSubmit = createRealPostRequest<
  paths["/exp/v1/dna/product/saveReviewAndSubmit"]
>("/product/saveReviewAndSubmit");