import {
  Button,
  Paper,
  Typography,
  Tabs,
  Tab,
  Stack,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Input,
  IconButton,
  TextField,
  Checkbox,
  TableBody,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  DialogContentText,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DeleteOutline, ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { StyledDashboardTableHead } from "../pages/dashboard";
import React from "react";
import { RoleContext } from "../role-provider";
import {
  ApiError,
  DisplayableMappedMutexElement,
  MappedOptionalItem,
  MappedProduct,
  SANITIZE_OPTS_NO_TAGS,
  ValidOptionalItem,
  createSortByNumberFn,
  isMappedOptionalItem,
  isMappedProduct,
} from "../utils";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import {
  getOptionalItemDetailsByMutexId,
  getProductDetailsByMutexId,
  saveMutexGroup,
} from "../data/items";
import { useAxios } from "../axios-provider";
import { useItem } from "../pages/item";
import { SelectOptionalItemsTable } from "./SelectOptionalItemsTable";
import { SelectProductsTable } from "./SelectProductsTable";
import { Loader } from "./loader/Loader";
import { LanguageSelector } from "./LanguageSelector";
import { StyledDangerButton, StyledSecondaryButton } from "./ItemMarketingForm";
import DOMPurify from "dompurify";
import { useCustomQuery } from "../hooks/use-custom-query";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  localizedNumStringOrEmpty,
  localizedNumStringOrPlaceholder,
  localizedStringValueToFloat,
  standardizedNumStringOrEmpty,
} from "../util/number-localization";

export type MutexGroupFormValues = {
  mutexId: number | null;
  mutexName: string;
  languageCode: string;
  mappedElements: DisplayableMappedMutexElement[];
};

type MappedProductWithoutName = Omit<MappedProduct, "productName">;
type MappedOptionalItemWithoutName = Omit<
  MappedOptionalItem,
  "optionalItemName"
>;
type SaveMutexRequestBody = {
  mutexName: string;
  productList: MappedProductWithoutName[];
  optionalItemList: MappedOptionalItemWithoutName[];
};

function formatOptionalItemAsMappedMutexElement(
  optionalItem: MappedOptionalItem,
  marketCode: string
): DisplayableMappedMutexElement {
  return {
    id: optionalItem.optionalItemId,
    name: optionalItem.optionalItemName,
    qty: localizedNumStringOrPlaceholder(
      String(optionalItem.qty),
      marketCode,
      "1"
    ),
    isDefault: optionalItem.defaultItem === "Y",
    displayOrder: optionalItem.displayOrder,
    type: "Optional Item",
  };
}

function formatProductAsMappedMutexElement(
  product: MappedProduct,
  marketCode: string
): DisplayableMappedMutexElement {
  return {
    id: product.productId,
    name: product.productName,
    qty: localizedNumStringOrPlaceholder(String(product.qty), marketCode, "1"),
    isDefault: product.defaultProduct === "Y",
    displayOrder: product.displayOrder,
    type: "Product",
  };
}

function formatSaveMutexRequestBody(
  data: MutexGroupFormValues,
  marketCode: string
): SaveMutexRequestBody {
  const { mutexName, mappedElements } = data;
  const orderedElements = mappedElements.map(
    (me: DisplayableMappedMutexElement, index: number) => {
      return {
        ...me,
        displayOrder: index + 1,
      };
    }
  );
  const optionalItems = orderedElements
    .filter((mappedEl) => {
      return mappedEl.type === "Optional Item";
    })
    .map((mappedOptionalItem) => {
      const qty = localizedStringValueToFloat(
        mappedOptionalItem.qty,
        marketCode
      );
      return {
        optionalItemId: mappedOptionalItem.id,
        qty: !isNaN(qty) ? qty : 0,
        defaultItem: mappedOptionalItem.isDefault === true ? "Y" : "N",
        displayOrder: mappedOptionalItem.displayOrder,
      };
    });
  const products = orderedElements
    .filter((mappedEl) => {
      return mappedEl.type === "Product";
    })
    .map((mappedProduct) => {
      const qty = localizedStringValueToFloat(mappedProduct.qty, marketCode);
      return {
        productId: mappedProduct.id,
        qty: !isNaN(qty) ? qty : 0,
        defaultProduct: mappedProduct.isDefault === true ? "Y" : "N",
        displayOrder: mappedProduct.displayOrder,
      };
    });
  return {
    mutexName,
    optionalItemList: optionalItems,
    productList: products,
  };
}

const sortByDisplayOrder =
  createSortByNumberFn<DisplayableMappedMutexElement>("displayOrder");

type MutexGroupModalProps = {
  mutexId: number | null;
  mutexName: string;
  allOptionalItems: ValidOptionalItem[];
  onSaveSuccess: () => void;
  onSaveError: (error: string) => void;
  onConfirmClose: () => void;
  filteredElementIds: number[]; 
};

export const MutexGroupModal = ({
  mutexId,
  mutexName,
  onSaveSuccess,
  onSaveError,
  allOptionalItems,
  onConfirmClose,
  filteredElementIds,
}: MutexGroupModalProps) => {
  const {
    selectedCountry,
    selectedRole,
    defaultLanguage,
    isReaderRole,
    isItemLevelMarket,
  } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const { itemId, editable } = useItem();

  const [activeTab, setActiveTab] = useState<number>(isItemLevelMarket ? 1 : 0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [customError, setCustomError] = useState<string | null>(null);

  const handleDefaultSelected = (elementId: number) => {
    if (typeof elementId === "number") {
      const updatedMappedElements = editableMappedElements.map((eme) => {
        return {
          ...eme,
          isDefault: eme.id === elementId,
        };
      });
      setValue("mappedElements", updatedMappedElements, { shouldDirty: true });
    }
  };

  const productsByMutexQuery = useCustomQuery(
    ["getProductDetailsByMutexId", { countryCode: selectedCountry, mutexId }],
    () =>
      getProductDetailsByMutexId(apiClient)({
        mutexId: mutexId!,
        countryCode: selectedCountry!,
      }),
    mutexId !== null
  );

  const productMappings: MappedProduct[] = useMemo(() => {
    if (productsByMutexQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validMappedProducts = productsByMutexQuery.data.data.dataList.filter(
      (maybeMappedProduct): maybeMappedProduct is MappedProduct => {
        return isMappedProduct(maybeMappedProduct);
      }
    );
    return validMappedProducts;
  }, [productsByMutexQuery.data]);

  const optionalItemsByMutexQuery = useCustomQuery(
    [
      "getOptionalItemDetailsByMutexId",
      { countryCode: selectedCountry, mutexId },
    ],
    () =>
      getOptionalItemDetailsByMutexId(apiClient)({
        mutexId: mutexId!,
        countryCode: selectedCountry!,
      }),
    mutexId !== null
  );

  const optionalItemMappings: MappedOptionalItem[] = useMemo(() => {
    if (optionalItemsByMutexQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validMappedOptionalItems =
      optionalItemsByMutexQuery.data.data.dataList.filter(
        (
          maybeMappedOptionalItem
        ): maybeMappedOptionalItem is MappedOptionalItem => {
          return isMappedOptionalItem(maybeMappedOptionalItem);
        }
      );
    return validMappedOptionalItems;
  }, [optionalItemsByMutexQuery.data]);

  const saveMutexGroupRequest = saveMutexGroup(apiClient);
  const saveMutexGroupMutation = useMutation(
    (data: MutexGroupFormValues) => {
      const { mutexId, languageCode } = data;
      return saveMutexGroupRequest(
        formatSaveMutexRequestBody(data, selectedCountry!),
        {
          ...(typeof mutexId === "number" ? { mutexId } : {}),
          countryCode: selectedCountry!,
          roleId: String(selectedRole)!,
          languageCode,
        }
      );
    },
    {
      onMutate: () => saveMutexGroupRequest,
      onSuccess: () => onSaveSuccess(),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setCustomError(
            "An error occurred saving the Mutex group. Please try again."
          );
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setCustomError(String(errorMessage.message));
        } else {
          onSaveError(String(error));
        }
      },
    }
  );

  const isWorking = useMemo(
    () =>
      productsByMutexQuery.isFetching ||
      optionalItemsByMutexQuery.isFetching ||
      saveMutexGroupMutation.isLoading,
    [
      productsByMutexQuery.isFetching,
      optionalItemsByMutexQuery.isFetching,
      saveMutexGroupMutation.isLoading,
    ]
  );

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<MutexGroupFormValues>({
    defaultValues: {
      mutexId,
      mutexName,
      languageCode: defaultLanguage!,
      mappedElements: [],
    },
  });
  const editableMappedElements = watch("mappedElements");
  const selectedLanguage = watch("languageCode");

  // once all existing mapped elements are loaded (Products and Optional Items)
  // order them by displayOrder and identify the default
  useEffect(() => {
    if (
      mutexId &&
      productsByMutexQuery.isSuccess &&
      optionalItemsByMutexQuery.isSuccess
    ) {
      const formattedProductMappings = productMappings.map((productMapping) =>
        formatProductAsMappedMutexElement(productMapping, selectedCountry!)
      );
      const formattedOptionalItemMappings = optionalItemMappings.map(
        (optionalItemMapping) =>
          formatOptionalItemAsMappedMutexElement(
            optionalItemMapping,
            selectedCountry!
          )
      );
      // set integrated array by displayOrder
      const orderedMappedElements = sortByDisplayOrder(
        [...formattedProductMappings, ...formattedOptionalItemMappings],
        "asc"
      );
      setValue("mappedElements", orderedMappedElements, { shouldDirty: false });
    }
  }, [
    mutexId,
    productsByMutexQuery.isSuccess,
    productMappings,
    optionalItemsByMutexQuery.isSuccess,
    optionalItemMappings,
    selectedCountry,
    setValue,
  ]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setActiveTab(newTab);
  };

  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleAddProduct = (product: { id: number; name: string }) => {
    const { id, name } = product;
    const newMappedElement: DisplayableMappedMutexElement = {
      id,
      name,
      qty: localizedNumStringOrEmpty("1.0", selectedCountry!),
      isDefault: false,
      displayOrder: editableMappedElements.length,
      type: "Product",
    };
    setValue("mappedElements", [...editableMappedElements, newMappedElement], {
      shouldDirty: true,
    });
  };

  const handleAddOptionalItem = (optionalItem: {
    id: number;
    name: string;
  }) => {
    const { id, name } = optionalItem;
    const newMappedElement: DisplayableMappedMutexElement = {
      id,
      name,
      qty: localizedNumStringOrEmpty("1.0", selectedCountry!),
      isDefault: false,
      displayOrder: editableMappedElements.length,
      type: "Optional Item",
    };
    setValue("mappedElements", [...editableMappedElements, newMappedElement], {
      shouldDirty: true,
    });
  };

  const handleMoveElement = (index: number, direction: "up" | "down") => {
    const newIndex = direction === "up" ? index - 1 : index + 1;
    const updatedItems = [...editableMappedElements];
    [updatedItems[index], updatedItems[newIndex]] = [
      updatedItems[newIndex],
      updatedItems[index],
    ];
    setValue("mappedElements", updatedItems, { shouldDirty: true });
  };

  const handleRemoveElement = (idToRemove: number) => {
    const updatedMappedElements = editableMappedElements.filter(
      (el) => el.id !== idToRemove
    );
    setValue("mappedElements", updatedMappedElements, { shouldDirty: true });
  };

  const onRequestClose = useCallback(() => {
    if (isDirty) {
      setConfirmDialogOpen(true);
    } else {
      onConfirmClose();
    }
  }, [isDirty, onConfirmClose]);

  const onClickSubmit = (data: MutexGroupFormValues) => {
    setCustomError(null);
    if (data.mappedElements.length === 0) {
      setCustomError("You must select at least one item.");
      return; // Stop submission
    }
    const hasDefault = data.mappedElements.some((element) => element.isDefault);
    if (!hasDefault) {
      setCustomError("You must select at least one item as default.");
      return; // Stop submission
    }
    saveMutexGroupMutation.mutate(data);
  };

  // Combine filteredElementIds and editableMappedElements
const combinedSelectedProductIds = useMemo(() => {
  const editableProductIds = editableMappedElements
    .filter((me: DisplayableMappedMutexElement) => me.type === "Product")
    .map((elementMapping) => elementMapping.id);

  return Array.from(new Set([...filteredElementIds, ...editableProductIds]));
}, [filteredElementIds, editableMappedElements]);
// Combine filteredElementIds and editableMappedElements
const combinedOptionalProductIds = useMemo(() => {
  const editableProductIds = editableMappedElements
    .filter((me: DisplayableMappedMutexElement) => me.type === "Optional Item")
    .map((elementMapping) => elementMapping.id);

  return Array.from(new Set([...filteredElementIds, ...editableProductIds]));
}, [filteredElementIds, editableMappedElements]);

  return (
    <>
      <StyledDialog open={!confirmDialogOpen} onClose={onRequestClose}>
        <form
          onSubmit={handleSubmit(onClickSubmit)}
          data-testid="edit-mutex-group"
        >
          <DialogContent>
            <StyledRelativeContainer container mobile={12}>
              {isWorking && <Loader />}
              <Grid
                mobile={12}
                sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}
                container
              >
                <Grid mobile={8}>
                  <StyledTextField
                    fullWidth
                    disabled={false}
                    label={"Mutex Name*"}
                    InputLabelProps={{
                      shrink: true,
                      htmlFor: "edit-mutex-name",
                    }}
                    id="edit-mutex-name"
                    {...register("mutexName", {
                      required: true,
                    })}
                    onBlur={(event) =>
                      setValue(
                        "mutexName",
                        DOMPurify.sanitize(
                          event.target.value,
                          SANITIZE_OPTS_NO_TAGS
                        )
                          .toString()
                          .trim()
                      )
                    }
                    error={!!errors["mutexName"]}
                    helperText={errors["mutexName"] && "Mutex Name is required"}
                    data-testid="cy-edit-mutex-name"
                  />
                </Grid>
                <LanguageSelector
                  selectedLanguage={selectedLanguage!}
                  onChange={(language) =>
                    setValue("languageCode", language, { shouldDirty: true })
                  }
                />
              </Grid>

              <Grid container mobile={12} alignItems="end">
                <Grid
                  paddingBottom={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mobile={5}
                >
                  <Tabs
                    TabIndicatorProps={{
                      sx: { backgroundColor: "#FFBC0D" },
                    }}
                    value={activeTab}
                    onChange={handleTabChange}
                    role="tablist"
                  >
                    {!isItemLevelMarket && (
                      <StyledTab label="Products" value={0} />
                    )}
                    <StyledTab label="Optional Items" value={1} />
                  </Tabs>
                  <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    endAdornment={<SearchIcon sx={{ opacity: 0.5 }} />}
                    placeholder="Search"
                    data-testid="mappable-element-search-input"
                    id="search-input"
                    aria-labelledby="search-input"
                    aria-label="Search by Name"
                  />
                </Grid>
                <Grid mobile={7} sx={{ pb: 2 }}>
                  <Typography variant="h4">Selected Items</Typography>
                </Grid>
              </Grid>
              <Grid mobile={5}>
                <div>
                  {activeTab === 0 && !isItemLevelMarket && (
                    <Typography component="div" role="tabpanel">
                      <SelectProductsTable
                       selectedProductIds= {combinedSelectedProductIds}
                        // selectedProductIds={editableMappedElements
                        //   .filter((me: DisplayableMappedMutexElement) => {
                        //     return me.type === "Product";
                        //   })
                        //   .map((elementMapping) => elementMapping.id)}
                        selectEnabled={!isReaderRole && editable}
                        searchQuery={searchQuery}
                        onSelect={handleAddProduct}
                        defaultPageSize={5}
                      />
                    </Typography>
                  )}
                  {activeTab === 1 && (
                    <Typography component="div" role="tabpanel">
                      <SelectOptionalItemsTable
                        itemId={itemId}
                        allOptionalItems={allOptionalItems}
                        isFetching={false}
                        isSuccess={true}
                        selectedOptionalItemIds= {combinedOptionalProductIds}
                        // selectedOptionalItemIds={editableMappedElements
                        //   .filter((me: DisplayableMappedMutexElement) => {
                        //     return me.type === "Optional Item";
                        //   })
                        //   .map((elementMapping) => elementMapping.id)}
                        selectEnabled={!isReaderRole && editable}
                        searchQuery={searchQuery}
                        onSelect={handleAddOptionalItem}
                        defaultPageSize={5}
                      />
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid mobile={7}>
                <Stack>
                  <TableContainer component={Paper}>
                    <Table aria-label="Selected mutex items">
                      <StyledDashboardTableHead>
                        <TableRow>
                          <TableCell>Default</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell align="center">Qty</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </StyledDashboardTableHead>
                      <TableBody>
                        {editableMappedElements.map((mappedElement, index) => (
                          <TableRow
                            key={index}
                            role="row"
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell style={{ width: "10%" }}>
                              <Checkbox
                                checked={mappedElement.isDefault ?? false}
                                onChange={() =>
                                  handleDefaultSelected(mappedElement.id)
                                }
                              />
                            </TableCell>
                            <TableCell style={{ width: "50%" }}>
                              {mappedElement.name}
                            </TableCell>
                            <TableCell style={{ width: "15%" }} align="center">
                              <Controller
                                name={`mappedElements.${index}.qty`}
                                control={control}
                                defaultValue={mappedElement.qty}
                                rules={{ required: "Quantity is required" }}
                                render={({ field }) => (
                                  <TextField
                                    disabled={!!isReaderRole || !editable}
                                    {...field}
                                    size="small"
                                    onFocus={() => {
                                      setValue(
                                        `mappedElements.${index}.qty`,
                                        standardizedNumStringOrEmpty(
                                          getValues(
                                            `mappedElements.${index}.qty`
                                          ),
                                          selectedCountry!
                                        )
                                      );
                                    }}
                                    onBlur={(e) => {
                                      const formattedValue =
                                        localizedNumStringOrEmpty(
                                          e.target.value,
                                          selectedCountry!
                                        );
                                      setValue(
                                        `mappedElements.${index}.qty`,
                                        formattedValue,
                                        { shouldDirty: true }
                                      );
                                      field.onBlur();
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell style={{ width: "25%" }} align="right">
                              {!isReaderRole && (
                                <>
                                  {index !== 0 && (
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        handleMoveElement(index, "up")
                                      }
                                      disabled={index === 0}
                                    >
                                      <ArrowUpward />
                                    </IconButton>
                                  )}
                                  {index !==
                                  editableMappedElements.length - 1 ? (
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        handleMoveElement(index, "down")
                                      }
                                      disabled={
                                        index ===
                                        editableMappedElements.length - 1
                                      }
                                    >
                                      <ArrowDownward />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      sx={{ visibility: "hidden" }}
                                      onClick={() =>
                                        handleMoveElement(index, "down")
                                      }
                                      disabled={
                                        index ===
                                        editableMappedElements.length - 1
                                      }
                                    >
                                      <ArrowDownward />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    sx={{ color: "#DA291C", ml: 4 }}
                                    onClick={() =>
                                      handleRemoveElement(mappedElement.id)
                                    }
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {editableMappedElements.length === 0 && (
                      <StyledEmptyBox>
                        <Typography variant="h6">{`Add items to this Mutex group using the arrows`}</Typography>
                      </StyledEmptyBox>
                    )}
                  </TableContainer>
                  {customError && (
                    <Alert variant="outlined" severity="error">
                      {customError}
                    </Alert>
                  )}
                </Stack>
              </Grid>
            </StyledRelativeContainer>
          </DialogContent>
          <StyledSaveActions>
            <StyledDangerButton
              variant="contained"
              size="large"
              onClick={onRequestClose}
            >
              Cancel
            </StyledDangerButton>
            <StyledButton variant="contained" size="large" type="submit">
              Save
            </StyledButton>
          </StyledSaveActions>
        </form>
      </StyledDialog>
      <Dialog open={confirmDialogOpen}>
        <StyledCenteredDialogContent>
          <DialogContentText
            id="confirm-close-mutex-modal"
            variant="body1"
            color="#292929"
          >
            You have unsaved changes. Are you sure you want to close the form?
          </DialogContentText>
        </StyledCenteredDialogContent>
        <StyledConfirmActions>
          <StyledSecondaryButton
            size="large"
            variant="contained"
            onClick={() => setConfirmDialogOpen(false)}
          >
            Continue Editing
          </StyledSecondaryButton>
          <StyledButton
            variant="contained"
            size="large"
            onClick={onConfirmClose}
          >
            Confirm Close
          </StyledButton>
        </StyledConfirmActions>
      </Dialog>
    </>
  );
};

const StyledTab = styled(Tab)({
  color: "black",
  fontFamily: "Speedee-Bold, Arial, Helvetica, sans-serif",
  letterSpacing: "-0.15px",
  textTransform: "initial",
  lineHeight: "28px",
  borderBottom: "2px solid #F6F6F6",
  "&.Mui-selected": {
    backgroundColor: "#F6F6F6",
    borderWidth: "4px",
    color: "black",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
});
const StyledTextField = styled(TextField)({
  paddingLeft: "12px",
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    height: "90%",
    maxWidth: "none",
  },
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 200,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
const StyledSaveActions = styled(DialogActions)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
}));
const StyledConfirmActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "space-between",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledCenteredDialogContent = styled(DialogContent)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
