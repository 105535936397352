import { AxiosHeaders } from "axios";
import { realGetUserMenuList } from "../api/navmenu";

export const mockGetUserMenuList: ReturnType<
  typeof realGetUserMenuList
> = async ({ countryCode, roleId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      code: 2000,
      type: "Success",
      message: "The call was successful.",
      dataList: [
        {
          menuId: 1,
          menuName: "Home",
          menuDesc: "Home",
          menuGroup: 0,
          menuParent: 0,
          menuOrder: 0,
          subMenuList: [],
        },
        {
          menuId: 5,
          menuName: "Categories & Meal Bundles",
          menuDesc: "Categories & Meal Bundles",
          menuGroup: 5,
          menuParent: 0,
          menuOrder: 2,
          subMenuList: [
            {
              menuId: 6,
              menuName: "View Categories & Meal Bundle",
              menuDesc: "View Categories & Meal Bundle",
              menuGroup: 5,
              menuParent: 5,
              menuOrder: 1,
              subMenuList: [],
            },
            {
              menuId: 7,
              menuName: "Add Categories & Meal Bundle",
              menuDesc: "Add Categories & Meal Bundle",
              menuGroup: 5,
              menuParent: 5,
              menuOrder: 2,
              subMenuList: [],
            },
            {
              menuId: 99,
              menuName: "Delete/Restore Archive Categories",
              menuDesc: "Delete/Restore Archive Categories",
              menuGroup: 5,
              menuParent: 5,
              menuOrder: 3,
              subMenuList: [],
            },
          ],
        },
        {
          menuId: 8,
          menuName: "Items",
          menuDesc: "Items",
          menuGroup: 8,
          menuParent: 0,
          menuOrder: 3,
          subMenuList: [
            {
              menuId: 9,
              menuName: "View Menu Items",
              menuDesc: "View Menu Items",
              menuGroup: 8,
              menuParent: 8,
              menuOrder: 1,
              subMenuList: [],
            },
            {
              menuId: 10,
              menuName: "Add Menu Items",
              menuDesc: "Add Menu Items",
              menuGroup: 8,
              menuParent: 8,
              menuOrder: 2,
              subMenuList: [],
            },
            {
              menuId: 98,
              menuName: "Delete/Restore Archive Items",
              menuDesc: "Delete/Restore Archive Items",
              menuGroup: 8,
              menuParent: 8,
              menuOrder: 3,
              subMenuList: [],
            },
          ],
        },
        {
          menuId: 50,
          menuName: "Products",
          menuDesc: "Products",
          menuGroup: 50,
          menuParent: 0,
          menuOrder: 4,
          subMenuList: [
            {
              menuId: 12,
              menuName: "View Products",
              menuDesc: "View Products",
              menuGroup: 50,
              menuParent: 50,
              menuOrder: 1,
              subMenuList: [],
            },
            {
              menuId: 13,
              menuName: "Add Products",
              menuDesc: "Add Products",
              menuGroup: 50,
              menuParent: 50,
              menuOrder: 2,
              subMenuList: [],
            },
          ],
        },
        {
          menuId: 14,
          menuName: "Search",
          menuDesc: "Search",
          menuGroup: 14,
          menuParent: 0,
          menuOrder: 5,
          subMenuList: [
            {
              menuId: 15,
              menuName: "Category",
              menuDesc: "Category",
              menuGroup: 14,
              menuParent: 14,
              menuOrder: 1,
              subMenuList: [
                {
                  menuId: 16,
                  menuName: "Find By Basic Info",
                  menuDesc: "Find By Basic Info",
                  menuGroup: 14,
                  menuParent: 15,
                  menuOrder: 1,
                  subMenuList: [],
                },
                {
                  menuId: 17,
                  menuName: "Find By Pending With Role",
                  menuDesc: "Find By Pending With Role",
                  menuGroup: 14,
                  menuParent: 15,
                  menuOrder: 2,
                  subMenuList: [],
                },
                {
                  menuId: 18,
                  menuName: "Find By Ready for Push Live",
                  menuDesc: "Find By Ready for Push Live",
                  menuGroup: 14,
                  menuParent: 15,
                  menuOrder: 3,
                  subMenuList: [],
                },
              ],
            },
            {
              menuId: 19,
              menuName: "Item",
              menuDesc: "Item",
              menuGroup: 14,
              menuParent: 14,
              menuOrder: 2,
              subMenuList: [
                {
                  menuId: 20,
                  menuName: "Find By Basic Info",
                  menuDesc: "Find By Basic Info",
                  menuGroup: 14,
                  menuParent: 19,
                  menuOrder: 1,
                  subMenuList: [],
                },
                {
                  menuId: 21,
                  menuName: "Find By Nutrition Range",
                  menuDesc: "Find By Nutrition Range",
                  menuGroup: 14,
                  menuParent: 19,
                  menuOrder: 2,
                  subMenuList: [],
                },
                {
                  menuId: 22,
                  menuName: "Find Item By Product",
                  menuDesc: "Find Item By Product",
                  menuGroup: 14,
                  menuParent: 19,
                  menuOrder: 3,
                  subMenuList: [],
                },
                {
                  menuId: 24,
                  menuName: "Find By Pending With Role",
                  menuDesc: "Find By Pending With Role",
                  menuGroup: 14,
                  menuParent: 19,
                  menuOrder: 5,
                  subMenuList: [],
                },
                {
                  menuId: 25,
                  menuName: "Find By Ready for Push Live",
                  menuDesc: "Find By Ready for Push Live",
                  menuGroup: 14,
                  menuParent: 19,
                  menuOrder: 6,
                  subMenuList: [],
                },
              ],
            },
            {
              menuId: 26,
              menuName: "Product",
              menuDesc: "Product",
              menuGroup: 14,
              menuParent: 14,
              menuOrder: 3,
              subMenuList: [
                {
                  menuId: 27,
                  menuName: "Find By Basic Info",
                  menuDesc: "Find By Basic Info",
                  menuGroup: 14,
                  menuParent: 26,
                  menuOrder: 1,
                  subMenuList: [],
                },
                {
                  menuId: 28,
                  menuName: "Find By Nutrition Range",
                  menuDesc: "Find By Nutrition Range",
                  menuGroup: 14,
                  menuParent: 26,
                  menuOrder: 2,
                  subMenuList: [],
                },
                {
                  menuId: 29,
                  menuName: "Find By Pending With Role",
                  menuDesc: "Find By Pending With Role",
                  menuGroup: 14,
                  menuParent: 26,
                  menuOrder: 3,
                  subMenuList: [],
                },
                {
                  menuId: 30,
                  menuName: "Find By Ready for Push Live",
                  menuDesc: "Find By Ready for Push Live",
                  menuGroup: 14,
                  menuParent: 26,
                  menuOrder: 4,
                  subMenuList: [],
                },
              ],
            },
          ],
        },
        {
          menuId: 31,
          menuName: "Miscellaneous",
          menuDesc: "Miscellaneous",
          menuGroup: 31,
          menuParent: 0,
          menuOrder: 6,
          subMenuList: [
            {
              menuId: 32,
              menuName: "Marketing task",
              menuDesc: "Marketing task",
              menuGroup: 32,
              menuParent: 31,
              menuOrder: 1,
              subMenuList: [
                {
                  menuId: 33,
                  menuName: "View Relationship",
                  menuDesc: "View Relationship",
                  menuGroup: 32,
                  menuParent: 32,
                  menuOrder: 1,
                  subMenuList: [],
                },
                {
                  menuId: 47,
                  menuName: "Manage Common Attributes",
                  menuDesc: "Manage Common Attributes",
                  menuGroup: 32,
                  menuParent: 32,
                  menuOrder: 2,
                  subMenuList: [],
                },
                {
                  menuId: 83,
                  menuName: "Element Order",
                  menuDesc: "Element Order",
                  menuGroup: 32,
                  menuParent: 32,
                  menuOrder: 3,
                  subMenuList: [],
                },
                {
                  menuId: 94,
                  menuName: "Custom Footers",
                  menuDesc: "Custom Footers",
                  menuGroup: 32,
                  menuParent: 32,
                  menuOrder: 4,
                  subMenuList: [],
                },
                {
                  menuId: 95,
                  menuName: "View Logo",
                  menuDesc: "View Logo",
                  menuGroup: 32,
                  menuParent: 32,
                  menuOrder: 5,
                  subMenuList: [],
                },
                {
                  menuId: 96,
                  menuName: "View Status",
                  menuDesc: "View Status",
                  menuGroup: 32,
                  menuParent: 32,
                  menuOrder: 6,
                  subMenuList: [],
                },
                {
                  menuId: 97,
                  menuName: "Bulk upload",
                  menuDesc: "Bulk upload",
                  menuGroup: 32,
                  menuParent: 32,
                  menuOrder: 7,
                  subMenuList: [],
                },
              ],
            },
            {
              menuId: 48,
              menuName: "Push Live Console",
              menuDesc: "Push Live Console",
              menuGroup: 48,
              menuParent: 31,
              menuOrder: 4,
              subMenuList: [],
            },
            {
              menuId: 90,
              menuName: "Exceptions Report",
              menuDesc: "Exceptions Report",
              menuGroup: 49,
              menuParent: 31,
              menuOrder: 8,
              subMenuList: [],
            },
          ],
        },
        {
          menuId: 49,
          menuName: "Audit Trail",
          menuDesc: "Audit Trail",
          menuGroup: 49,
          menuParent: 0,
          menuOrder: 7,
          subMenuList: [],
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
