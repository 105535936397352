import { components as miscellaneousComponents } from "../data/miscellaneous.types";
import { isDefined } from "../utils";

export type Workflow = miscellaneousComponents["schemas"]["Workflow"];
export type WorkflowElement =
  miscellaneousComponents["schemas"]["WorkflowElement"];
export type WorkflowStep = miscellaneousComponents["schemas"]["WorkflowStep"];

export function formatWorkflowStepForAdmin(
  rawWorkflow: any
): WorkflowStep | undefined {
  const { level, stepName, role, stepDescription, deleteEnable } = rawWorkflow;
  if (!isNaN(Number(level))) {
    return {
      level: Number(level),
      stepName: typeof stepName === "string" ? stepName : "",
      role: typeof role === "string" ? role : "",
      stepDescription:
        typeof stepDescription === "string" ? stepDescription : "",
      deleteEnable: typeof deleteEnable === "string" ? deleteEnable : "No",
    };
  }
}

export function formatWorkflow(rawWorkflow: any): Workflow | undefined {
  const { workFlowId, workFlowName, workFlowStepsList } = rawWorkflow;
  if (!isNaN(Number(workFlowId))) {
    return {
      workFlowId: Number(workFlowId),
      workFlowName: typeof workFlowName === "string" ? workFlowName : "",
      workFlowStepsList: workFlowStepsList
        .map(formatWorkflowStepForAdmin)
        .filter(isDefined),
    };
  }
}

export function formatWorkflowElement(
  rawWorkflowElement: any
): WorkflowElement | undefined {
  const { elementId, elementType, workFlowList } = rawWorkflowElement;
  if (
    !isNaN(Number(elementId)) &&
    ["Item", "Product", "Category"].includes(elementType)
  ) {
    return {
      elementId: Number(elementId),
      elementType,
      workFlowList: workFlowList.map(formatWorkflow).filter(isDefined),
    };
  }
}
