import {
  Button,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useContext, useEffect, useMemo, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { StyledDashboardTableHead } from "../pages/dashboard";
import React from "react";
import { DataContext } from "../data-provider";
import { createSortByStringFn } from "../utils";
import { SortOrder } from "../data/mock/types";
import { FixedHeightTableLoader } from "./FixedHeightLoader";
import { Loader } from "./loader/Loader";
import { SearchableProduct } from "../util/searchable-products";

type AllProductsTableSortField = "productName" | "productId";

const sortByProductId = createSortByStringFn<SearchableProduct>("productId");
const sortByProductName =
  createSortByStringFn<SearchableProduct>("productName");

type SelectProductsTableProps = {
  selectedProductIds: number[];
  selectEnabled: Boolean;
  searchQuery: string;
  onSelect: (product: { id: number; name: string }) => void;
  defaultPageSize?: number;
};
export const SelectProductsTable = ({
  selectedProductIds,
  selectEnabled,
  searchQuery,
  onSelect,
  defaultPageSize,
}: SelectProductsTableProps) => {
  const { products: allProducts, productsLoading } = useContext(DataContext);
  const isEmpty = useMemo(() => {
    return !productsLoading && allProducts.length === 0;
  }, [allProducts, productsLoading]);

  const [page, setPage] = useState(0);
  const pageSize = typeof defaultPageSize === "number" ? defaultPageSize : 10;
  const [sortField, setSortField] =
    useState<AllProductsTableSortField>("productName");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [filteredSortedProducts, setFilteredSortedProducts] = useState<
    SearchableProduct[]
  >([]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    [setPage]
  );
  const handleSortingChange = React.useCallback(
    (field: AllProductsTableSortField) => {
      if (sortField === field) {
        // reverse sort on same field
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // set new sort field
        setSortField(field);
      }
      // set to first page in any case
      setPage(0);
    },
    [sortField, sortOrder, setSortField, setSortOrder]
  );

  useEffect(() => {
    const searchLower = searchQuery.toLowerCase();

    const filteredProducts =
      searchQuery.length > 0
        ? allProducts?.filter((product) => {
            return (
              product.productName?.toLowerCase().includes(searchLower) ||
              product.productId.toString().includes(searchQuery)
            );
          })
        : allProducts;
    if (sortField === "productId") {
      setFilteredSortedProducts(sortByProductId(filteredProducts, sortOrder));
    } else {
      setFilteredSortedProducts(sortByProductName(filteredProducts, sortOrder));
    }
    setPage(0);
  }, [allProducts, searchQuery, sortField, sortOrder]);

  const getRow = React.useCallback(
    (index: number) => {
      const emptyRow = (
        <StyledFixedHeightTableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row"></TableCell>
          <TableCell align="right"></TableCell>
          <TableCell align="right"></TableCell>
        </StyledFixedHeightTableRow>
      );
      if (!filteredSortedProducts || !filteredSortedProducts[index]) {
        return emptyRow;
      }
      const product = filteredSortedProducts[index];
      return (
        <StyledFixedHeightTableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          role="row"
          data-testid="mappable-product-tr"
        >
          <TableCell component="th" scope="row">
            {product.productName}
          </TableCell>
          <TableCell align="right">{product.productId}</TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              onClick={() =>
                onSelect({
                  id: Number(product.productId),
                  name: product.productName!,
                })
              }
              disabled={
                selectedProductIds.includes(Number(product.productId)) ||
                !selectEnabled
              }
              aria-label={`Add ${product.productName} to Selected Items`}
              data-testid="add-product-to-mapped-elements"
            >
              <ArrowForward />
            </Button>
          </TableCell>
        </StyledFixedHeightTableRow>
      );
    },
    [filteredSortedProducts, selectedProductIds, selectEnabled, onSelect]
  );

  return (
    <Grid
      mobile={12}
      padding={0}
      role="region"
      aria-label="Products Table"
      data-testid="mappable-products-table"
    >
      <Stack>
        {productsLoading && (
          <FixedHeightTableLoader pageSize={pageSize} isEmpty={isEmpty}>
            <Loader />
          </FixedHeightTableLoader>
        )}
        {!productsLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="List of All Products">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell scope="col" role="columnheader">
                    Product Name
                    <TableSortLabel
                      active={sortField === "productName"}
                      direction={sortOrder}
                      onClick={() => handleSortingChange("productName")}
                      data-testid="sort-by-product-name"
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell align="right" scope="col" role="columnheader">
                    Product ID
                    <TableSortLabel
                      active={sortField === "productId"}
                      direction={sortOrder}
                      onClick={() => handleSortingChange("productId")}
                      data-testid="sort-by-product-id"
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="right"
                    scope="col"
                    role="columnheader"
                  ></TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              <TableBody>
                {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isEmpty && (
          <TablePagination
            component="div"
            count={filteredSortedProducts.length}
            onPageChange={handlePageChange}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            aria-label="Table Pagination"
          />
        )}
      </Stack>
    </Grid>
  );
};

const StyledFixedHeightTableRow = styled(TableRow)({
  height: 48,
});
