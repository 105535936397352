import {
  realGetAccessToken,
  realGasAuthenticationToken,
  realRefreshToken,
  realExpireRefreshToken,
} from "./api/auth";
import { paths } from "./gettoken.types";
import {
  mockGetAccessToken,
  mockGasAuthenticationToken,
  mockRefreshToken,
  mockExpireRefreshToken,
} from "./mock/auth";
import {
  mockableGetRequest,
  mockablePostRequest,
} from "./mockable-api-request";

export const getAccessToken = mockableGetRequest<
  paths["/outhtoken/accessToken"]
>(realGetAccessToken, mockGetAccessToken);

export const refreshToken = mockablePostRequest<
  paths["/outhtoken/refreshToken"]
>(realRefreshToken, mockRefreshToken);

export const gasAuthenticationToken = mockablePostRequest<
  paths["/outhtoken/gasAuthenticationToken"]
>(realGasAuthenticationToken, mockGasAuthenticationToken);

export const expireRefreshToken = mockablePostRequest<
  paths["/outhtoken/logout"]
>(realExpireRefreshToken, mockExpireRefreshToken);