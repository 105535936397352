import * as React from "react";
import { Typography, Grid, TableHead } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withLayout } from "../hoc/with-layout";
import { AddEditRelationship } from "../components/AddEditRelationshipForm";
import { useNavigate } from "react-router-dom";

export const CreateRelationship = withLayout(() => {
  const navigate = useNavigate();

  return (
    <StyledRelativeContainer>
      <Grid
        mobile={12}
        container
        sx={{ mb: 6, justifyContent: "space-between" }}
      >
        <Typography variant="h1">Create Relationship</Typography>
      </Grid>
      <AddEditRelationship
        data={{
          relationTypeName: "",
          variants: [],
        }}
        onSaveSuccess={() => navigate("/relationships")}
      />
    </StyledRelativeContainer>
  );
}, "Create Relationship");

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  fontSize: theme.typography.small.fontSize,
}));
