import {
  Box,
  TextField,
  Typography,
  Grid,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { withLayout } from "../hoc/with-layout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { ApiError, isDefined } from "../utils";
import { Loader } from "../components/loader/Loader";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { useCustomQuery } from "../hooks/use-custom-query";
import {
  deleteWorkflowStep,
  getWorkflowManagementData,
} from "../data/miscellaneous";
import { WorkflowStepModal } from "../components/WorkflowStepModal";
import { AddRowLink } from "../components/AddRowLink";
import { AddCircle } from "@mui/icons-material";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import { AxiosError } from "axios";
import { ResendFormModal } from "../components/ResendFormModal";
import {
  WorkflowElement,
  WorkflowStep,
  formatWorkflowElement,
} from "../util/workflows";

export const Workflows = withLayout(() => {
  const queryClient = useQueryClient();
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole } = useContext(RoleContext);

  const [selectedElementId, setSelectedElementId] = useState<number>(0);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number>(0);
  const [stepToDelete, setStepToDelete] = useState<number | null>(null);
  const [isAddStepModalOpen, setIsAddStepModalOpen] = useState<boolean>(false);
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getWorkflowManagementData"],
    () =>
      getWorkflowManagementData(apiClient)({
        countryCode: selectedCountry!,
        roleId: selectedRole!,
      })
  );

  const workflowElements: WorkflowElement[] = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    return data.data.dataList
      .map((rawWorkflowElement) => formatWorkflowElement(rawWorkflowElement))
      .filter(isDefined);
  }, [data]);

  const selectedElement: WorkflowElement | undefined = useMemo(() => {
    const selectedElement = workflowElements.find(
      (we) => we.elementId === selectedElementId
    );
    return selectedElement;
  }, [selectedElementId, workflowElements]);

  const existingStepsForSelectedWorkflow: WorkflowStep[] = useMemo(() => {
    const selectedWorkflow = selectedElement?.workFlowList.find(
      (sew) => sew.workFlowId === selectedWorkflowId
    );
    if (typeof selectedWorkflow === "undefined") {
      return [];
    }
    return selectedWorkflow.workFlowStepsList;
  }, [selectedWorkflowId, selectedElement]);

  const deleteWorkflowStepRequest = deleteWorkflowStep(apiClient);
  const { mutate: doDeleteWorkflowStep, isLoading } = useMutation(
    () => {
      return deleteWorkflowStepRequest({
        countryCode: selectedCountry!,
        workflowId: selectedWorkflowId!,
        levelId: stepToDelete!,
        roleId: selectedRole!,
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Workflow step was successfully deleted`);
        setStepToDelete(null);
        setIsRetryDeleteModalOpen(false);
        queryClient.invalidateQueries(["getWorkflowManagementData"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryDeleteModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const handleElementSelect = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedElementId(
      !isNaN(Number(event.target.value)) ? Number(event.target.value) : 0
    );
  const handleWorkflowSelect = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedWorkflowId(
      !isNaN(Number(event.target.value)) ? Number(event.target.value) : 0
    );
  const handleSaveWorkflowSuccess = () => {
    setSuccessMessage(`Workflow step was added successfully`);
    setIsAddStepModalOpen(false);
    queryClient.invalidateQueries(["getWorkflowManagementData"]);
  };
  const handleRetryDelete = () => {
    setIsRetryDeleteModalOpen(false);
    doDeleteWorkflowStep();
  };
  const handleRetryDeleteCancel = () => {
    setIsRetryDeleteModalOpen(false);
    setStepToDelete(null);
  };

  return (
    <StyledRelativeContainer>
      {isFetching && <Loader />}
      {!isFetching && isSuccess && (
        <Box sx={{ flexGrow: 1, px: 0 }}>
          <Grid container>
            <Grid container mobile={9} sx={{ alignItems: "center", mb: 4 }}>
              <Grid>
                <Typography variant="h2">Workflow Management</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} margin={0}>
              {workflowElements.length > 0 && (
                <Grid mobile={12} paddingLeft={0} marginTop={2}>
                  <StyledTextField
                    fullWidth
                    label="Element"
                    select
                    value={selectedElementId}
                    onChange={handleElementSelect}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{ native: true }}
                  >
                    <option key={0} value={0}>
                      Select an element
                    </option>
                    {workflowElements.map((element) => (
                      <option key={element.elementId} value={element.elementId}>
                        {element.elementType}
                      </option>
                    ))}
                  </StyledTextField>
                </Grid>
              )}
              {selectedElement && selectedElement.workFlowList.length > 0 && (
                <Grid mobile={12} paddingLeft={0} marginTop={2}>
                  <StyledTextField
                    fullWidth
                    label="Workflow"
                    select
                    value={selectedWorkflowId}
                    onChange={handleWorkflowSelect}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{ native: true }}
                  >
                    <option key={0} value={0}>
                      Select a workflow
                    </option>
                    {selectedElement.workFlowList.map((workflow) => (
                      <option
                        key={workflow.workFlowId}
                        value={workflow.workFlowId}
                      >
                        {workflow.workFlowName}
                      </option>
                    ))}
                  </StyledTextField>
                </Grid>
              )}
              {existingStepsForSelectedWorkflow.length > 0 && (
                <Grid mobile={12} paddingLeft={0} marginTop={2}>
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="workflow steps table"
                      data-testid="workflow-steps-table"
                      role="table"
                    >
                      <StyledTableHead>
                        <TableRow role="row">
                          <TableCell role="columnheader">Level</TableCell>
                          <TableCell role="columnheader">Step Name</TableCell>
                          <TableCell align="right" role="columnheader">
                            Role
                          </TableCell>
                          <TableCell align="right" role="columnheader">
                            Step Description
                          </TableCell>
                          <TableCell align="center" role="columnheader">
                            Action
                          </TableCell>
                        </TableRow>
                      </StyledTableHead>
                      <TableBody>
                        {existingStepsForSelectedWorkflow.map(
                          (workflowStep) => {
                            return (
                              <StyledTableRow
                                key={workflowStep.level}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                data-testid="workflow-step-tr"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  role="row"
                                  data-testid="workflow-step-td-level"
                                >
                                  {workflowStep.level}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  role="row"
                                  data-testid="workflow-step-td-stepName"
                                >
                                  {workflowStep.stepName}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  role="row"
                                  align="right"
                                  data-testid="workflow-step-td-role"
                                >
                                  {workflowStep.role}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  role="row"
                                  align="right"
                                  data-testid="workflow-step-td-stepDescription"
                                >
                                  {workflowStep.stepDescription}
                                </TableCell>
                                <TableCell align="center" role="cell">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    role="group"
                                  >
                                    {workflowStep.deleteEnable === "Yes" &&
                                      existingStepsForSelectedWorkflow.length >
                                        1 && (
                                        <Tooltip title="Delete Step">
                                          <IconButton
                                            aria-label="delete button"
                                            onClick={() =>
                                              setStepToDelete(
                                                workflowStep.level
                                              )
                                            }
                                            role="button"
                                            data-testid="delete-workflow-step-btn"
                                            sx={{ color: "#DA291C" }}
                                          >
                                            <DeleteForeverIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid paddingLeft={0} marginTop={1}>
                    <AddRowLink
                      aria-label="Add new workflow step"
                      id="add-workflow-step"
                      data-testid="add-workflow-step"
                      onClick={() => setIsAddStepModalOpen(true)}
                    >
                      <AddCircle aria-label="add new workflow step button" />
                      <Typography>Add Step</Typography>
                    </AddRowLink>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      {isAddStepModalOpen && selectedElement && (
        <WorkflowStepModal
          workflowId={selectedWorkflowId}
          selectedElementName={selectedElement.elementType}
          onSaveSuccess={handleSaveWorkflowSuccess}
          onCancel={() => setIsAddStepModalOpen(false)}
        />
      )}
      {typeof stepToDelete === "number" && (
        <ConfirmActionModal
          open={!isRetryDeleteModalOpen}
          loading={isLoading}
          message="Are you sure you want to delete this step?"
          onConfirm={() => doDeleteWorkflowStep()}
          onCancel={() => setStepToDelete(null)}
        />
      )}
      <ResendFormModal
        open={isRetryDeleteModalOpen}
        onResend={handleRetryDelete}
        onCancel={handleRetryDeleteCancel}
        description="An error occurred while deleting this item"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "Workflow Management");

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
