import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { Loader } from "./loader/Loader";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../axios-provider";
import { getItemCategoryMapping, saveItemCategoryMapping } from "../data/items";
import { RoleContext } from "../role-provider";
import { components } from "../data/items-v2.types";
import { ApiError, ValidCategoryMapping, isCategoryMapping } from "../utils";
import { useForm } from "react-hook-form";
import { useItem } from "../pages/item";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";

type CategoryMappingType = components["schemas"]["CategoryMapping"];

export const CategoryMapping = () => {
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const { itemId, editable, isLive } = useItem();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);

  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getItemCategoryMapping", { itemId }],
    () =>
      getItemCategoryMapping(apiClient)({
        itemId: String(itemId)!,
        countryCode: selectedCountry!,
      })
  );

  const categoryMappingOptions = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    const validCategoryMappingOptions = data?.data.dataList.filter(
      (CategoryMapping): CategoryMapping is ValidCategoryMapping => {
        return isCategoryMapping(CategoryMapping) === true;
      }
    );
    return validCategoryMappingOptions.map((cat: CategoryMappingType) => ({
      categoryName: cat.categoryName,
      categoryId: cat.categoryId,
      defaultCategory: cat.defaultCategory,
    }));
  }, [data]);
  useEffect(() => {
    setCurrentCategoryMapping(categoryMappingOptions);
    setInitialCategoryMapping(categoryMappingOptions);
  }, [categoryMappingOptions]);

  const [currentCategoryMapping, setCurrentCategoryMapping] = useState(
    categoryMappingOptions
  );
  const [initialCategoryMapping, setInitialCategoryMapping] = useState(
    categoryMappingOptions
  );
  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && categoryMappingOptions.length === 0;
  }, [isFetching, isSuccess, categoryMappingOptions]);

  const saveItemCategoryMappingRequest = saveItemCategoryMapping(apiClient);
  const { mutate, isLoading } = useMutation(
    () => {
      const itemCategoryMapping = {
        itemId: Number(itemId),
        dataList: currentCategoryMapping,
      };

      return saveItemCategoryMappingRequest(itemCategoryMapping, {
        countryCode: selectedCountry!,
        roleId: selectedRole!,
      });
    },
    {
      onMutate: () => saveItemCategoryMappingRequest,
      onSuccess: () => {
        setSuccessMessage(`Item ${itemId} was saved successfully`);
        queryClient.invalidateQueries(["getItemCategoryMapping", { itemId }]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const onSubmit = () => mutate();

  const handleCheckboxChange = (index: number) => {
    const updatedCategoryMappingOptions = currentCategoryMapping.map(
      (category, i) => {
        return {
          ...category,
          defaultCategory: i === index ? "Y" : "N",
        };
      }
    );
    setCurrentCategoryMapping(updatedCategoryMappingOptions);
  };
  const handleReset = () => {
    setCurrentCategoryMapping(initialCategoryMapping);
  };
  const { handleSubmit } = useForm({
    defaultValues: categoryMappingOptions,
  });

  return (
    <StyledRelativeContainer container spacing={1}>
      {(isLoading || isFetching) && <Loader />}
      <Grid container>
        <Grid
          container
          mobile={12}
          sx={{ my: 3, justifyContent: "space-between" }}
        >
          <Typography variant="h2">Category Mapping</Typography>
          <StyledSecondaryButton
            variant="contained"
            disabled={!isLive}
            onClick={() => {
              navigate(`/items/${itemId}/compare-with-live`);
            }}
          >
            Compare with live
          </StyledSecondaryButton>
        </Grid>
        <Grid container mobile={12} sx={{ alignItems: "center", m: 1 }}>
          <Typography variant="h6" sx={{ mr: 2 }}>
            Item Name:
          </Typography>
          <Typography variant="h4">{data?.data.itemName}</Typography>
        </Grid>
        <Grid mobile={12}>
          <Stack spacing={1} marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TableContainer component={Paper}>
                <Table aria-label="Category Mapping Table">
                  <StyledDashboardTableHead>
                    <TableRow>
                      <TableCell>Default Category</TableCell>
                      <TableCell>Category Name</TableCell>
                      <TableCell>Category Id</TableCell>
                    </TableRow>
                  </StyledDashboardTableHead>
                  <TableBody>
                    {currentCategoryMapping.map((category, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Checkbox
                            disabled={!!isReaderRole || !editable}
                            checked={category.defaultCategory === "Y"}
                            id={`defaultCategory_${category.categoryId}`}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </TableCell>
                        <TableCell>{category.categoryName}</TableCell>
                        <TableCell>{category.categoryId}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {isEmpty && (
                  <StyledEmptyBox>
                    <Typography variant="h6">{`This item is not mapped in any category`}</Typography>
                  </StyledEmptyBox>
                )}
              </TableContainer>
            </form>
          </Stack>
          {!isEmpty && !isReaderRole && editable && (
            <Grid
              mobile={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <StyledSecondaryButton
                size="large"
                type="reset"
                variant="contained"
                onClick={handleReset}
              >
                Reset
              </StyledSecondaryButton>
              <StyledSaveButton
                size="large"
                type="submit"
                variant="contained"
                sx={{ mr: 2 }}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </StyledSaveButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          onSubmit();
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledSaveButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 180,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
