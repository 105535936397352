import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { Controller, useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import { ApiError, SANITIZE_OPTS_NO_TAGS } from "../utils";
import { useCustomQuery } from "../hooks/use-custom-query";
import { getUOMByCountry, saveNutrient } from "../data/miscellaneous";
import { useAxios } from "../axios-provider";
import { Loader } from "./loader/Loader";
import { NutritionFact } from "../util/nutrition-facts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ErrorAlertSnackbar } from "./AlertSnackbar";
import { ResendFormModal } from "./ResendFormModal";
import { paths } from "../data/miscellaneous.types";

type NutritionFactFormData = Record<string, Partial<NutritionFact>>;

type NutritionFactFormModalProps = {
  onSuccess: () => void;
  onCancel: () => void;
  isOpen: boolean;
  nutrientFact?: NutritionFactFormData;
  language?: string;
};

type SaveMutationQueryParameters =
  paths["/exp/v1/dna/miscellaneous/saveNutrient"]["post"]["parameters"]["query"];

export const NutritionFactFormModal = ({
  nutrientFact,
  language,
  onSuccess,
  onCancel,
  isOpen,
}: NutritionFactFormModalProps) => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const {
    selectableLanguages,
    selectedCountry,
    selectedRole: roleId,
  } = useContext(RoleContext);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [retryModalOpen, setIsRetryModalOpen] = useState(false);

  const { data: uomData, isLoading } = useCustomQuery(
    ["getUOMByCountry", { selectedCountry }],
    () => getUOMByCountry(apiClient)({ countryCode: selectedCountry! })
  );
  const saveNutrientRequest = saveNutrient(apiClient);

  const saveMutation = useMutation(
    (data: NutritionFactFormData) => {
      const tempData = { ...data };

      let queryParameters: SaveMutationQueryParameters = {
        roleId: roleId!,
        countryCode: selectedCountry!,
      };

      if (nutrientFact && language) {
        queryParameters.nutrientId = nutrientFact[language].nutrientFactId;
        queryParameters.languageCode = language;
        for (const lang of selectableLanguages) {
          if (lang.languageCode !== language) {
            delete tempData[lang.languageCode];
          }
        }
      }
      return saveNutrientRequest(tempData, queryParameters);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getNutritionFactsByCountry",
          { selectedCountry },
        ]);
        onSuccess();
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const emptyFormData = useMemo(() => {
    const emptyData: NutritionFactFormData = {};
    for (const lang of selectableLanguages) {
      emptyData[lang.languageCode] = {
        nutrientFactName: "",
        uomId: undefined,
        uom: undefined,
        womenGDA: undefined,
        menGDA: undefined,
        childGDA: undefined,
      };
    }
    return emptyData;
  }, [selectableLanguages]);

  const onSaveNutFactForm = useCallback(
    (data: NutritionFactFormData) => {
      const tempData = { ...data };
      for (const lang of selectableLanguages) {
        tempData[lang.languageCode] = {
          ...tempData[lang.languageCode],
          uomId: Number(tempData[lang.languageCode].uomId),
        };
      }
      saveMutation.mutate(tempData);
    },
    [saveMutation, selectableLanguages]
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors: formErrors },
  } = useForm<NutritionFactFormData>({ values: nutrientFact });

  // Populate the form with the nutrient fact data (for edits)
  // or empty data (for new nutrient facts)
  useEffect(() => {
    if (nutrientFact) {
      const updatedNutrientFacts = { ...nutrientFact };

      // Iterate over selectable languages to update unit of measure (uom) IDs
      if (uomData?.data.uomlist) {
        for (const language of selectableLanguages) {
          const languageCode = language.languageCode;
          const currentData = updatedNutrientFacts[languageCode];

          // Find the correct uom ID based on the uom name
          const uomId = uomData.data.uomlist[languageCode].find(
            (uom) => uom.uom === currentData.uom
          )?.uomId;

          // Update the uom ID for the current language in the nutrient facts
          updatedNutrientFacts[languageCode] = { ...currentData, uomId: uomId };
        }
      }

      // Reset the form with the updated nutrient facts
      reset(updatedNutrientFacts);
    } else {
      // Reset the form with empty data (new nutrient fact)
      reset(emptyFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nutrientFact, isOpen]);

  return (
    <>
      <ErrorAlertSnackbar
        onClose={() => setErrorMessage(null)}
        message={errorMessage}
      />
      <ResendFormModal
        open={retryModalOpen}
        onCancel={() => setIsRetryModalOpen(false)}
        onResend={() => handleSubmit(onSaveNutFactForm)()}
      />
      <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="desktop">
        <form onSubmit={handleSubmit(onSaveNutFactForm)}>
          <DialogTitle>Add Nutrition Fact</DialogTitle>
          <DialogContent>
            {isLoading || saveMutation.isLoading ? (
              <Grid container sx={{ minHeight: 200 }}>
                <Grid>
                  <Loader />
                </Grid>
              </Grid>
            ) : (
              <>
                <DialogContentText>Language Name</DialogContentText>
                {selectableLanguages.map((lang, langRowIndex) => {
                  return (
                    <Grid
                      key={lang.languageCode}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 3,
                      }}
                    >
                      <Grid sx={{ width: 125 }}>
                        <Typography variant="h6">
                          {lang.languageName}
                        </Typography>
                      </Grid>
                      <Grid>
                        <TextField
                          error={
                            !!formErrors[lang.languageCode]?.nutrientFactName
                          }
                          helperText={
                            formErrors[lang.languageCode]?.nutrientFactName
                              ?.message
                          }
                          label="Nutrition Fact Name"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(
                            `${lang.languageCode}.nutrientFactName`,
                            {
                              required: "Nutrition Fact Name is required",
                            }
                          )}
                          onBlur={(event) =>
                            setValue(
                              `${lang.languageCode}.nutrientFactName`,
                              DOMPurify.sanitize(
                                event.target.value,
                                SANITIZE_OPTS_NO_TAGS
                              )
                                .toString()
                                .trim()
                            )
                          }
                        />
                      </Grid>
                      <Grid>
                        <Controller
                          name={`${lang.languageCode}.uomId`}
                          control={control}
                          render={({
                            field: { onChange: controllerOnChange, value },
                          }) => (
                            <TextField
                              label="Unit of Measure"
                              select
                              disabled={!!langRowIndex}
                              sx={{ minWidth: 186 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={!!formErrors[lang.languageCode]?.uomId}
                              helperText={
                                formErrors[lang.languageCode]?.uomId?.message
                              }
                              onChange={(e) => {
                                const newUomId = e.target.value;
                                for (const l of selectableLanguages) {
                                  if (l.languageCode !== lang.languageCode)
                                    setValue(
                                      `${l.languageCode}.uomId`,
                                      Number(newUomId)
                                    );
                                }
                                controllerOnChange(newUomId);
                              }}
                              value={value || ""}
                            >
                              <MenuItem></MenuItem>
                              {uomData?.data.uomlist &&
                                uomData?.data.uomlist[lang.languageCode]
                                  .sort((a, b) =>
                                    a.uom!.localeCompare(
                                      b.uom!,
                                      lang.languageCode,
                                      {
                                        sensitivity: "base",
                                      }
                                    )
                                  )
                                  .map((uom) => (
                                    <MenuItem key={uom.uomId} value={uom.uomId}>
                                      {uom.uom}
                                    </MenuItem>
                                  ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label="Women (GDA)"
                          disabled={!!langRowIndex}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="number"
                          sx={{ width: 100 }}
                          {...register(`${lang.languageCode}.womenGDA`)}
                          onBlur={(event) => {
                            for (const lang of selectableLanguages) {
                              setValue(
                                `${lang.languageCode}.womenGDA`,
                                event.target.value
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label="Men (GDA)"
                          disabled={!!langRowIndex}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="number"
                          sx={{ width: 100 }}
                          {...register(`${lang.languageCode}.menGDA`)}
                          onBlur={(event) => {
                            for (const lang of selectableLanguages) {
                              setValue(
                                `${lang.languageCode}.menGDA`,
                                event.target.value
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label="Child (GDA)"
                          disabled={!!langRowIndex}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: 100 }}
                          type="number"
                          {...register(`${lang.languageCode}.childGDA`)}
                          onBlur={(event) => {
                            for (const lang of selectableLanguages) {
                              setValue(
                                `${lang.languageCode}.childGDA`,
                                event.target.value
                              );
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              variant="contained"
              onClick={onCancel}
              color="error"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disableRipple
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
