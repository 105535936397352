import { AxiosHeaders } from "axios";
import countriesAndRoles from "./json/countries-and-roles.json";
import { realGetCountryAndRoleList } from "../api/rolecountry";

export const mockGetCountryAndRoleList: ReturnType<
  typeof realGetCountryAndRoleList
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: countriesAndRoles,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
