import {
  Button,
  Paper,
  Typography,
  Stack,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  IconButton,
  TablePagination,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useContext, useEffect, useMemo, useState } from "react";
import { ArrowForward, AddCircle } from "@mui/icons-material";
import { StyledDashboardTableHead } from "../pages/dashboard";
import React from "react";
import { RoleContext } from "../role-provider";
import { ValidMutexGroup } from "../utils";
import EditIcon from "@mui/icons-material/Edit";
import { AddRowLink } from "./AddRowLink";
import { FixedHeightTableLoader } from "./FixedHeightLoader";
import { Loader } from "./loader/Loader";

type SelectMutexTableProps = {
  itemId: number;
  selectedMutexIds: number[];
  selectEnabled: Boolean;
  allMutexGroups: ValidMutexGroup[];
  isFetching: boolean;
  isSuccess: boolean;
  searchQuery: string;
  onSelect: (mutex: { id: number; name: string }) => void;
  onClickNewMutex: () => void;
  onClickEditMutex: (mutex: { id: number; name: string }) => void;
  defaultPageSize?: number;
};
export const SelectMutexTable = ({
  itemId,
  searchQuery,
  allMutexGroups,
  selectedMutexIds,
  selectEnabled,
  onSelect,
  isFetching,
  isSuccess,
  onClickNewMutex,
  onClickEditMutex,
  defaultPageSize,
}: SelectMutexTableProps) => {
  const { isReaderRole } = useContext(RoleContext);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && allMutexGroups.length === 0;
  }, [allMutexGroups, isFetching, isSuccess]);
  const [page, setPage] = useState(0);
  const [filteredMutexGroups, setFilteredMutexGroups] =
    useState<ValidMutexGroup[]>(allMutexGroups);
  const pageSize = typeof defaultPageSize === "number" ? defaultPageSize : 10;

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  useEffect(() => {
    setFilteredMutexGroups(
      searchQuery.length > 0
        ? allMutexGroups?.filter((mutex) => {
            return (
              mutex.mutexName
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              mutex.mutexId.toString().includes(searchQuery)
            );
          })
        : allMutexGroups
    );
    setPage(0);
  }, [allMutexGroups, searchQuery]);

  const getRow = React.useCallback(
    (index: number) => {
      if (!filteredMutexGroups || !filteredMutexGroups[index]) {
        return (
          <StyledFixedHeightTableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </StyledFixedHeightTableRow>
        );
      }
      const mutexGroup = filteredMutexGroups[index];
      return (
        <StyledFixedHeightTableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="mappable-mutex-group-tr"
        >
          <TableCell>{mutexGroup.mutexName}</TableCell>
          <TableCell component="th" scope="row">
            {mutexGroup.elementName}
          </TableCell>
          <TableCell align="right">
            {!isReaderRole && (
              <>
                <IconButton
                  disabled={!selectEnabled}
                  onClick={() =>
                    onClickEditMutex({
                      id: mutexGroup.mutexId,
                      name: mutexGroup.mutexName,
                    })
                  }
                >
                  <EditIcon aria-label="edit Mutex button" />
                </IconButton>
              </>
            )}
          </TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              onClick={() =>
                onSelect({
                  id: Number(mutexGroup.mutexId),
                  name: mutexGroup.mutexName,
                })
              }
              disabled={
                selectedMutexIds.includes(Number(mutexGroup.mutexId)) ||
                !selectEnabled
              }
              data-testid="add-mutex-group-to-mapped-elements"
            >
              <ArrowForward />
            </Button>
          </TableCell>
        </StyledFixedHeightTableRow>
      );
    },
    [
      isReaderRole,
      filteredMutexGroups,
      selectEnabled,
      selectedMutexIds,
      onSelect,
      onClickEditMutex,
    ]
  );

  return (
    <Grid
      mobile={12}
      padding={0}
      role="region"
      aria-label="Mutex Table"
      data-testid="mappable-mutex-groups-table"
    >
      <Stack>
        {isFetching && (
          <FixedHeightTableLoader pageSize={pageSize} isEmpty={isEmpty}>
            <Loader />
          </FixedHeightTableLoader>
        )}
        {!isFetching && isSuccess && (
          <TableContainer component={Paper}>
            <Table aria-label="List of Items">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell scope="col" role="columnheader">
                    Name
                  </TableCell>
                  <TableCell scope="col" role="columnheader">
                    Options
                  </TableCell>
                  <TableCell align="right" scope="col" role="columnheader">
                    Edit
                  </TableCell>
                  <TableCell
                    align="right"
                    scope="col"
                    role="columnheader"
                  ></TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              <TableBody>
                {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <StyledActionPanel
          container
          sx={
            isReaderRole || !selectEnabled
              ? { justifyContent: "flex-end" }
              : { justifyContent: "space-between" }
          }
        >
          {!isReaderRole && selectEnabled && (
            <AddRowLink onClick={onClickNewMutex}>
              <AddCircle aria-label="add new item button" />
              <Typography>Add New Mutex Group</Typography>
            </AddRowLink>
          )}
          {!isEmpty && (
            <TablePagination
              component="div"
              count={filteredMutexGroups.length}
              onPageChange={handlePageChange}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[pageSize]}
            />
          )}
        </StyledActionPanel>
      </Stack>
    </Grid>
  );
};

const StyledFixedHeightTableRow = styled(TableRow)({
  height: 48,
});
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "space-between",
  alignItems: "center",
});
