import { createRealGetRequest, createRealPostRequest } from ".";
import { paths } from "../gettoken.types";

export const realGetAccessToken = createRealGetRequest<
  paths["/outhtoken/accessToken"]
>("/outhtoken/accesstoken");

export const realRefreshToken = createRealPostRequest<
  paths["/outhtoken/refreshToken"]
>("/outhtoken/refreshToken");

export const realGasAuthenticationToken = createRealPostRequest<
  paths["/outhtoken/gasAuthenticationToken"]
>("/outhtoken/gasAuthenticationToken");

export const realExpireRefreshToken =
  createRealPostRequest<paths["/outhtoken/logout"]>("/outhtoken/logout");