import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { useForm } from "react-hook-form";
import { useAxios } from "../axios-provider";
import { getCustomFooters } from "../data/miscellaneous";
import { StyledDashboardTableHead } from "./CategoryMapping";
import { isMasterFooter } from "../utils";
import { Loader } from "./loader/Loader";
import { useCustomQuery } from "../hooks/use-custom-query";
import { EmptyState } from "./EmptyState";
type FooterModalProps = {
  onAddFooter: (data: FooterType[]) => void;
  onCancel?: () => void;
  mappedFooters?: FooterType[];
  language?: string;
};
type FooterType = {
  footerText?: string;
  footerId?: number;
};
export const ManageFootersModal = ({
  onAddFooter,
  onCancel,
  language,
  mappedFooters = [],
}: FooterModalProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry } = useContext(RoleContext);
  const { handleSubmit } = useForm<FooterType[]>();
  const [selectedFooters, setSelectedFooters] =
    useState<FooterType[]>(mappedFooters);

  const { data, isFetching } = useCustomQuery(["getCustomFooters"], () =>
    getCustomFooters(apiClient)({
      countryCode: selectedCountry!,
      languageCode: language!,
    })
  );
  const masterFooters = useMemo(() => {
    if (typeof data?.data.footersList === "undefined") {
      return [];
    }
    const validFooters = data?.data.footersList.filter(
      (maybeFooter): maybeFooter is FooterType => {
        return isMasterFooter(maybeFooter) === true;
      }
    );
    return validFooters;
  }, [data]);
  useEffect(() => {
    setSelectedFooters(mappedFooters);
  }, [mappedFooters]);
  const handleCheckboxChange = (footer: FooterType, isChecked: boolean) => {
    const updatedSelectedFooters = isChecked
      ? [...selectedFooters, footer]
      : selectedFooters.filter((f) => f.footerId !== footer.footerId);
    setSelectedFooters(updatedSelectedFooters);
    onAddFooter(updatedSelectedFooters);
  };
  const handleSave = () => {
    onAddFooter(selectedFooters);
  };
  return (
    <Dialog open={true} onClose={onCancel}>
      {isFetching && <Loader />}
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledRelativeContainer>
          <DialogTitle id="footer-dialog-title">
            Custom Footers Master List
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid mobile={12}>
                <Stack spacing={1} marginBottom="20px">
                  <TableContainer component={Paper}>
                    <Table aria-label="Footer List Table">
                      <StyledDashboardTableHead>
                        <TableRow>
                          <TableCell width="10%">Select</TableCell>
                          <TableCell width="90%">Footer Text</TableCell>
                        </TableRow>
                      </StyledDashboardTableHead>
                      <TableBody>
                      { masterFooters.length > 0 ? (
                        masterFooters.map((footer) => (
                          <StyledTableRow key={footer.footerId}>
                            <TableCell>
                              <Checkbox
                                checked={selectedFooters.some(
                                  (f) => f.footerId === footer.footerId
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(footer, e.target.checked)
                                }
                              />
                            </TableCell>
                            <TableCell>{footer.footerText}</TableCell>
                          </StyledTableRow>
                            ))
                          ) : (
                            <EmptyState>No records found</EmptyState>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledActionButton variant="contained" onClick={onCancel}>
              Close
            </StyledActionButton>
          </DialogActions>
        </StyledRelativeContainer>
      </form>
    </Dialog>
  );
};
const StyledActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
}));
const StyledRelativeContainer = styled(Grid)({
  width: "70vw",
  margin: 0,
  position: "relative",
});
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
