import { Navigate, useOutlet, useLocation } from "react-router-dom";
import { AuthContext } from "../auth-provider";
import { useContext } from "react";
import { RoleContext } from "../role-provider";

export const ProtectedRoute = () => {
  const { accessToken, setRedirectDestination } = useContext(AuthContext);
  const { selectedCountry, selectedRole } = useContext(RoleContext);

  const outlet = useOutlet();
  const location = useLocation();

  if (!accessToken) {
    setRedirectDestination(`${location.pathname}${location.search}`);
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  if (!selectedCountry || !selectedRole) {
    // user has not selected country/role
    return <Navigate to="/switch-role" />;
  }
  return <>{outlet}</>;
};
