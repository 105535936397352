import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Button,
  IconButton,
  TableContainer,
  Select,
  MenuItem,
  InputLabel,
  TableCell,
  Checkbox,
  styled,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SelectChangeEvent } from "@mui/material/Select";
import { useContext, useEffect, useMemo, useState } from "react";
import { ArrowForward, DeleteOutline } from "@mui/icons-material";
import { StyledDashboardTableHead } from "./CategoryMapping";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import {
  getCoopMappedDataByItemID,
  getRegionsList,
  saveItemCoopMapping,
} from "../data/items";
import {
  ApiError,
  ValidCoOpLocation,
  isCoOpLocation,
  isRegionList,
} from "../utils";
import { useItem } from "../pages/item";
import { Loader } from "./loader/Loader";
import { useNavigate } from "react-router-dom";
import { StyledDangerButton, UnsavedChangesModal } from "./UnsavedChangesModal";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

export const CoOpMapping = () => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { itemId, editable, isLive } = useItem();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const regionsQuery = useCustomQuery(
    ["getRegionList"],
    () =>
      getRegionsList(apiClient)({
        countryCode: selectedCountry!,
      }),
    true,
    { staleTime: 1000 * 60 * 5 }
  );

  const fieldOffices = useMemo(() => {
    const maybeRegionList = regionsQuery.data?.data.regionList;
    if (isRegionList(maybeRegionList)) {
      return maybeRegionList;
    }
    return {};
  }, [regionsQuery.data]);

  const [selectedRegion, setSelectedRegion] = useState("");

  const coOpsForSelectedFieldOffice = useMemo(() => {
    if (fieldOffices && selectedRegion.length > 0) {
      const coOpsInRegion = fieldOffices[selectedRegion];
      return coOpsInRegion;
    }
    return [];
  }, [fieldOffices, selectedRegion]);

  useEffect(() => {
    const firstRegionKey = Object.keys(fieldOffices)[0];
    if (firstRegionKey) {
      setSelectedRegion(firstRegionKey);
    }
  }, [fieldOffices]);

  const handleRegionChange = (event: SelectChangeEvent<string>) => {
    setSelectedRegion(event.target.value);
  };

  const mappedCoOpsQuery = useCustomQuery(
    ["getCoopMappedDataByItemID", { itemId }],
    () =>
      getCoopMappedDataByItemID(apiClient)({
        countryCode: selectedCountry!,
        itemId: itemId,
      })
  );
  const mappedCoOps = useMemo(() => {
    if (mappedCoOpsQuery.data?.data.coopList === undefined) {
      return [];
    }
    const validCoOps = mappedCoOpsQuery.data?.data.coopList.filter(
      (maybeCoOpLocation): maybeCoOpLocation is ValidCoOpLocation => {
        return isCoOpLocation(maybeCoOpLocation) === true;
      }
    );
    return validCoOps;
  }, [mappedCoOpsQuery.data]);
  const saveItemCoopMappingRequest = saveItemCoopMapping(apiClient);
  const { mutate, isLoading } = useMutation(
    () => {
      const itemCoopMapping = {
        itemId: Number(itemId),
        coopList: editableMappedCoOps.map((item) => ({
          coopId: Number(item.coopId),
        })),
      };

      return saveItemCoopMappingRequest(itemCoopMapping, {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onMutate: () => saveItemCoopMappingRequest,
      onSuccess: () => {
        setSuccessMessage(`Item ${itemId} Co-op mappings saved successfully`);
        queryClient.invalidateQueries([
          "getCoopMappedDataByItemID",
          { itemId },
        ]);
        queryClient.invalidateQueries(["item/getCompareCurrent", { itemId }]);
        setValue("mappedCoOps", mappedCoOps, { shouldDirty: true });
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      mappedCoOps: mappedCoOps,
    },
  });
  useEffect(() => {
    setValue("mappedCoOps", mappedCoOps, { shouldDirty: true });
  }, [mappedCoOps, setValue]);

  const handleLeaveNavigation = () => {
    reset({ mappedCoOps });
    setShowUnsavedChangesModal(false);
  };
  const editableMappedCoOps = watch("mappedCoOps");

  const addCoopLocation = (coop: ValidCoOpLocation) => {
    if (!isMapped(coop)) {
      const newMappedCoOps = [...editableMappedCoOps, coop];
      setValue("mappedCoOps", newMappedCoOps, { shouldDirty: true });
    }
  };
  const addAllCoOpsFromSelectedRegion = () => {
    const allUniqueCoOps = [
      ...editableMappedCoOps,
      ...coOpsForSelectedFieldOffice.filter(
        (coop) =>
          !editableMappedCoOps.find(
            (mappedCoop) => mappedCoop.coopId === coop.coopId
          )
      ),
    ];
    setValue("mappedCoOps", allUniqueCoOps, { shouldDirty: true });
  };

  const removeCoopLocation = (coopId: number) => {
    const updatedCoOps = editableMappedCoOps.filter((coOp) => {
      return coOp.coopId !== coopId;
    });
    setValue("mappedCoOps", updatedCoOps, { shouldDirty: true });
  };
  const nationalLevel = useMemo(() => {
    if (mappedCoOpsQuery.data?.data.nationalLevelFlag === undefined) {
      return [];
    }
    return mappedCoOpsQuery.data?.data.nationalLevelFlag;
  }, [mappedCoOpsQuery.data]);
  const [isNationalLevel, setIsNationalLevel] = useState(nationalLevel === "Y");
  useEffect(() => {
    setIsNationalLevel(nationalLevel === "Y" || mappedCoOps.length === 0);
  }, [mappedCoOps.length, nationalLevel]);
  const handleSelectAllNationalLevel = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setIsNationalLevel(isChecked);
    if (isChecked) {
      setValue("mappedCoOps", [], { shouldDirty: true });
    }
  };
  const isMapped = (coop: ValidCoOpLocation) => {
    return !!editableMappedCoOps.find((item) => item.coopId === coop.coopId);
  };
  const isEmpty = useMemo(() => {
    return (
      !mappedCoOpsQuery.isFetching &&
      mappedCoOpsQuery.isSuccess &&
      editableMappedCoOps.length === 0
    );
  }, [
    mappedCoOpsQuery.isFetching,
    mappedCoOpsQuery.isSuccess,
    editableMappedCoOps,
  ]);

  const onSubmit = () => {
    mutate();
  };

  return (
    <StyledRelativeContainer>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="save-item-co-op-mapping"
      >
        <Grid container sx={{ mb: 6 }}>
          <Grid mobile={12}>
            <Grid container sx={{ justifyContent: "space-between", my: 3 }}>
              <Grid>
                <Typography variant="h2">Co-Op Mapping</Typography>
              </Grid>
              <StyledSecondaryButton
                variant="contained"
                disabled={!isLive}
                onClick={() => {
                  navigate(`/items/${itemId}/compare-with-live`);
                }}
              >
                Compare with live
              </StyledSecondaryButton>
            </Grid>
          </Grid>
          {isLoading && <Loader />}
          <Grid mobile={6}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Typography variant="h2">All Co-Ops</Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 3 }}>
                {!isReaderRole && editable && (
                  <>
                    <Typography noWrap variant="h6" sx={{ pr: 2 }}>
                      Select for National Level
                    </Typography>
                    <Checkbox
                      checked={isNationalLevel}
                      onChange={handleSelectAllNationalLevel}
                      data-testid="cy-national-level-checkbox"
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid mobile={12} sx={{ my: 2 }}>
              <InputLabel id="region-select-label">Field Office</InputLabel>
              <Select
                labelId="region-select-label"
                id="region-select"
                value={selectedRegion}
                fullWidth
                onChange={handleRegionChange}
                label="Field Office"
                data-testid="co-op-region-selector"
              >
                {Object.keys(fieldOffices).map((region, index) => (
                  <MenuItem
                    key={index}
                    value={region}
                    data-testid="co-op-region-option"
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid>{region}</Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid
            mobile={6}
            container
            alignItems="flex-end"
            sx={{ mb: 1, pl: 2 }}
          >
            <Typography variant="h2">Selected Co-Ops</Typography>
          </Grid>
          <Grid mobile={6}>
            <Grid mobile={12}>
              <Grid>
                <TableContainer component={Paper} aria-label="Items Table">
                  <Table aria-label="simple table">
                    <StyledDashboardTableHead>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="col"
                          role="columnheader"
                          id="name-header"
                        >
                          Co-Op Name
                        </TableCell>
                        <TableCell
                          align="right"
                          component="th"
                          scope="col"
                          role="columnheader"
                          id="add-header"
                        >
                          Add
                        </TableCell>
                      </TableRow>
                    </StyledDashboardTableHead>
                  </Table>
                  <Table>
                    <TableBody>
                      {!isReaderRole && editable && (
                        <TableRow
                          sx={{
                            height: 48,
                          }}
                        >
                          <>
                            <TableCell align="right">
                              <Typography noWrap variant="h6">
                                Select All Co-Ops in this Region
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <StyledButton
                                variant="contained"
                                onClick={addAllCoOpsFromSelectedRegion}
                                disabled={isNationalLevel}
                              >
                                Add All
                              </StyledButton>
                            </TableCell>
                          </>
                        </TableRow>
                      )}
                      {coOpsForSelectedFieldOffice.map((item, index) => {
                        return isCoOpLocation(item) ? (
                          <TableRow
                            key={item.coopId}
                            sx={{
                              height: 48,
                              outline: isMapped(item)
                                ? "2px solid rgba(255, 188, 13, 0.7)"
                                : "",
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            data-testid="co-op-tr"
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              data-testid="co-op-td-name"
                              sx={{
                                pl: isMapped(item) ? 3 : 1,
                              }}
                            >
                              {item.coopName}
                            </TableCell>
                            <TableCell align="right">
                              {!isReaderRole && editable && (
                                <Button
                                  color="primary"
                                  onClick={() => addCoopLocation(item)}
                                  disabled={isMapped(item) || isNationalLevel}
                                  aria-label={`Add ${item.coopName} to Selected Co-ops`}
                                  data-testid="add-co-op-to-selected"
                                >
                                  <ArrowForward />
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid mobile={6}>
            <TableContainer component={Paper}>
              <Table>
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>Co-Op Name</TableCell>
                    <TableCell>Co-Op Id</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                <TableBody>
                  {editableMappedCoOps.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        height: 48,
                      }}
                      data-testid="selected-co-op-tr"
                    >
                      <TableCell>{item.coopName}</TableCell>
                      <TableCell>{item.coopId}</TableCell>
                      <TableCell>
                        {!isReaderRole && editable && (
                          <IconButton
                            onClick={() => removeCoopLocation(item.coopId!)}
                          >
                            <DeleteOutline color="warning" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {isEmpty && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No Co-op locations selected`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
            <Grid container sx={{ pr: 1, pt: 3 }} justifyContent="flex-end">
              {!isReaderRole && editable && (
                <>
                  <StyledDangerButton
                    variant="contained"
                    size="large"
                    aria-label="cancel"
                    disabled={!isDirty}
                    onClick={() => setShowUnsavedChangesModal(true)}
                  >
                    Cancel
                  </StyledDangerButton>
                  <StyledButton
                    sx={{ ml: 4 }}
                    type="submit"
                    variant="contained"
                    size="large"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </StyledButton>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        title="Confirmation"
        confirmButton="Yes"
        denyButton="No"
        description="Are you sure you want to cancel?"
        onClose={() => setShowUnsavedChangesModal(false)}
        onLeave={handleLeaveNavigation}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          onSubmit();
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 180,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
