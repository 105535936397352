import {
  Add,
  ArrowDownward,
  ArrowUpward,
  DeleteForever,
  Edit,
  Save,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useAxios } from "../axios-provider";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ConfirmNavigateAway } from "../components/ConfirmNavigateAway";
import { LanguageSelector } from "../components/LanguageSelector";
import { ResendFormModal } from "../components/ResendFormModal";
import { Loader } from "../components/loader/Loader";
import {
  deleteByNutritionFactID,
  getNutritionFactsByCountry,
  saveDisplayOrder,
} from "../data/miscellaneous";
import { withLayout } from "../hoc/with-layout";
import { useCustomQuery } from "../hooks/use-custom-query";
import { RoleContext } from "../role-provider";
import type { NutritionFact } from "../util/nutrition-facts";
import { ApiError, createSortByNumberFn } from "../utils";
import { StyledDashboardTableHead } from "./dashboard";
import { NutritionFactFormModal } from "../components/NutritionFactFormModal";
import { ConfirmActionModal } from "../components/ConfirmActionModal";

export const NutritionFacts = withLayout(() => {
  const {
    defaultLanguage,
    selectedCountry,
    selectedRole,
    selectableLanguages,
    isReaderRole,
  } = useContext(RoleContext);
  const { apiClient } = useAxios();

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    defaultLanguage
  );

  const [isRetryModalOpen, setIsRetryModalOpen] = useState(false);
  const [retryAction, setRetryAction] = useState<[string, () => void]>([
    "",
    () => {},
  ]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sortOrder, setSortOrder] =
    useState<{ nutrientFactId: number; displayOrder: number }[]>();
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingNutritionId, setDeletingNutritionId] = useState<number>();
  const [editingNutritionFact, setEditingNutritionFact] =
    useState<Record<string, NutritionFact>>();

  const queryClient = useQueryClient();
  const {
    data,
    isLoading: isListLoading,
    isRefetching: isListRefetching,
  } = useCustomQuery(["getNutritionFactsByCountry", { selectedCountry }], () =>
    getNutritionFactsByCountry(apiClient)({ countryCode: selectedCountry! })
  );

  const deleteMutation = useMutation(
    (nutrientFactId: number) =>
      deleteByNutritionFactID(apiClient)({
        countryCode: selectedCountry!,
        roleId: selectedRole!.toString(),
        nutrientFactId,
      }),
    {
      onSuccess: () => {
        setSuccessMessage("Nutrition Fact deleted successfully");
        queryClient.invalidateQueries([
          "getNutritionFactsByCountry",
          { selectedCountry },
        ]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setRetryAction([
            "An error occurred while deleting the Nutrition Fact. Please try again.",
            onRetryDelete,
          ]);
          setIsRetryModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const onClickDelete = useCallback(
    (nutrientFactId: number) => () => {
      setDeletingNutritionId(nutrientFactId);
      setIsDeleteModalOpen(true);
    },
    [setDeletingNutritionId, setIsDeleteModalOpen]
  );

  const onCancelDelete = useCallback(() => {
    setDeletingNutritionId(undefined);
    setIsDeleteModalOpen(false);
  }, [setDeletingNutritionId, setIsDeleteModalOpen]);

  const onConfirmDelete = useCallback(() => {
    if (deletingNutritionId) {
      deleteMutation.mutate(deletingNutritionId);
    }
    setIsDeleteModalOpen(false);
  }, [deleteMutation, deletingNutritionId]);

  const onRetryDelete = useCallback(() => {
    if (deletingNutritionId) {
      deleteMutation.mutate(deletingNutritionId);
    }
  }, [deleteMutation, deletingNutritionId]);

  const onResend = useCallback(() => {
    setIsRetryModalOpen(false);
    retryAction[1]();
  }, [retryAction]);

  const onCancelResend = useCallback(() => {
    setDeletingNutritionId(undefined);
    setIsRetryModalOpen(false);
  }, [setDeletingNutritionId, setIsRetryModalOpen]);

  const displayOrderMutation = useMutation(
    () =>
      saveDisplayOrder(apiClient)(sortOrder!, {
        countryCode: selectedCountry!,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getNutritionFactsByCountry",
          { selectedCountry },
        ]);
        setSuccessMessage(`Display order updated successfully`);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setRetryAction([
            "An error occurred while saving the display order. Please try again.",
            onSaveDisplayOrder,
          ]);
          setIsRetryModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const onEditClick = useCallback(
    (nutritionFact: NutritionFact) => () => {
      setIsCreateModalOpen(true);
      setEditingNutritionFact(
        selectableLanguages.reduce(
          (acc, lang) => ({
            ...acc,
            [lang.languageCode]:
              data?.data.nutrientFacts[lang.languageCode].find(
                (n) => n.nutrientFactId === nutritionFact.nutrientFactId
              ) || {},
          }),
          {}
        )
      );
    },
    [data?.data.nutrientFacts, selectableLanguages]
  );

  // Sort the data by display order
  useEffect(() => {
    if (data && selectedLanguage) {
      setIsSortOrderChanged(false);
      const sortByDisplayOrder =
        createSortByNumberFn<NutritionFact>("displayOrder");
      let tempData = data.data.nutrientFacts[selectedLanguage];
      tempData = sortByDisplayOrder(tempData, "asc");

      setSortOrder(
        tempData.map((x) => ({
          nutrientFactId: x.nutrientFactId,
          displayOrder: x.displayOrder,
        }))
      );
    }
  }, [data, selectedLanguage]);

  const moveUp = useCallback(
    (index: number) => {
      if (!sortOrder || !index) return;

      const tempSortOrder = [...sortOrder];

      // swap the items
      [tempSortOrder[index], tempSortOrder[index - 1]] = [
        tempSortOrder[index - 1],
        tempSortOrder[index],
      ];

      // update the display order
      const newSortOrder = tempSortOrder.map(({ nutrientFactId }, i) => ({
        nutrientFactId,
        displayOrder: i + 1,
      }));

      setSortOrder(newSortOrder);
      setIsSortOrderChanged(true);
    },
    [sortOrder]
  );

  const moveDown = useCallback(
    (index: number) => {
      if (!sortOrder || index >= sortOrder.length) return;

      const newItems = [...sortOrder];
      [newItems[index], newItems[index + 1]] = [
        newItems[index + 1],
        newItems[index],
      ];
      setSortOrder(
        newItems.map(({ nutrientFactId }, i) => ({
          nutrientFactId,
          displayOrder: i + 1,
        }))
      );

      setIsSortOrderChanged(true);
    },
    [sortOrder]
  );

  const onSaveDisplayOrder = useCallback(
    () => displayOrderMutation.mutate(),
    [displayOrderMutation]
  );

  const onClickAddNew = useCallback(() => {
    setIsCreateModalOpen(!isCreateModalOpen);
  }, [isCreateModalOpen]);

  const onFormModalCancel = useCallback(() => {
    setIsCreateModalOpen(false);
    setEditingNutritionFact(undefined);
  }, []);

  const onFormModalSuccess = useCallback(() => {
    setIsCreateModalOpen(false);
    setEditingNutritionFact(undefined);
    setSuccessMessage("Nutrition Fact saved successfully");
  }, []);

  if (isListLoading) {
    return (
      <Grid container sx={{ minHeight: 360 }}>
        <Grid mobile={12}>
          <Loader />
        </Grid>
      </Grid>
    );
  }

  return (
    <Stack spacing={4}>
      <SuccessAlertSnackbar
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
      />
      <ConfirmActionModal
        open={isDeleteModalOpen}
        loading={deleteMutation.isLoading}
        message="Are you sure you want to delete this Nutrition Fact?"
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography variant="h1">Nutrition Facts</Typography>
        </Grid>
        {!isReaderRole && (
          <Grid>
            <Button
              startIcon={<Add />}
              variant="contained"
              color="primary"
              sx={(theme) => ({ color: theme.palette.text.primary })}
              onClick={() => onClickAddNew()}
            >
              Add Nutrition Fact
            </Button>
            <NutritionFactFormModal
              isOpen={isCreateModalOpen}
              onCancel={onFormModalCancel}
              language={selectedLanguage!}
              nutrientFact={editingNutritionFact}
              onSuccess={onFormModalSuccess}
            />
          </Grid>
        )}
      </Grid>
      {selectedLanguage && (
        <Grid container justifyContent="flex-end">
          <Grid>
            <LanguageSelector
              selectedLanguage={selectedLanguage}
              onChange={setSelectedLanguage}
            />
          </Grid>
        </Grid>
      )}

      {!isReaderRole && (
        <Grid container justifyContent="flex-end">
          <Grid>
            <StyledButton
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={onSaveDisplayOrder}
              disabled={!isSortOrderChanged}
            >
              Save Display Order
            </StyledButton>
            <ConfirmNavigateAway
              isDirty={isSortOrderChanged && !displayOrderMutation.isSuccess}
            />
            <SuccessAlertSnackbar
              message={successMessage}
              onClose={() => setSuccessMessage(null)}
            />
            <ErrorAlertSnackbar
              message={errorMessage}
              onClose={() => setErrorMessage(null)}
            />
            <ResendFormModal
              open={isRetryModalOpen}
              onResend={onResend}
              onCancel={onCancelResend}
              description={retryAction[0]}
            />
          </Grid>
        </Grid>
      )}
      <Grid>
        <TableContainer component={Paper}>
          <Table
            aria-label="nutrition facts table"
            data-testid="nutrition-facts-table"
            role="table"
          >
            {displayOrderMutation.isLoading && <Loader />}
            {isListRefetching && <Loader />}
            <StyledDashboardTableHead>
              <TableRow role="row">
                <TableCell role="columnheader">Nutrition Fact Name</TableCell>
                <TableCell role="columnheader">UOM</TableCell>
                <TableCell role="columnheader">Women (GDA)</TableCell>
                <TableCell role="columnheader">Men (GDA)</TableCell>
                <TableCell role="columnheader">Children (GDA)</TableCell>
                <TableCell role="columnheader" align="right">
                  Display Order
                </TableCell>
                {!isReaderRole && <TableCell align="center">Actions</TableCell>}
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {sortOrder &&
                selectedLanguage &&
                sortOrder.map(({ nutrientFactId, displayOrder }, i) => {
                  const fact = data?.data.nutrientFacts[selectedLanguage].find(
                    (n) => n.nutrientFactId === nutrientFactId
                  );
                  if (!fact) return <div key={nutrientFactId}></div>;

                  return (
                    <TableRow role="row" key={nutrientFactId}>
                      <TableCell>{fact.nutrientFactName}</TableCell>
                      <TableCell>{fact.uom}</TableCell>
                      <TableCell>{fact.womenGDA}</TableCell>
                      <TableCell>{fact.menGDA}</TableCell>
                      <TableCell>{fact.childGDA}</TableCell>
                      <TableCell align="right">
                        <Typography
                          color={
                            fact.displayOrder - 1 === i ? "initial" : "#C08B00"
                          }
                        >
                          {displayOrder}
                        </Typography>
                      </TableCell>
                      {!isReaderRole && (
                        <TableCell>
                          <Grid container spacing={0}>
                            <Grid>
                              <IconButton
                                color="primary"
                                onClick={() => moveUp(i)}
                                title="Move Up"
                                disabled={!i}
                                disableRipple
                              >
                                <ArrowUpward />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <IconButton
                                color="primary"
                                onClick={() => moveDown(i)}
                                title="Move Down"
                                disabled={i === sortOrder.length - 1}
                                disableRipple
                              >
                                <ArrowDownward />
                              </IconButton>
                            </Grid>
                            <Grid flex={1}></Grid>
                            <Grid>
                              <IconButton
                                color="error"
                                onClick={onClickDelete(fact.nutrientFactId)}
                                title="Delete"
                                disableRipple
                              >
                                <DeleteForever />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <IconButton
                                onClick={onEditClick(fact)}
                                title="Edit"
                                disableRipple
                              >
                                <Edit />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Stack>
  );
}, "Nutrition Facts");

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
