import { AxiosInstance, AxiosResponse } from "axios";

// GET
export type ApiPathGet = {
  get: {
    parameters: { query: any };
    responses: { "200": { content: { "application/json": any } } };
  };
};
type MockApiGetFn<T extends ApiPathGet> = (
  params: T["get"]["parameters"]["query"]
) => Promise<
  AxiosResponse<T["get"]["responses"]["200"]["content"]["application/json"]>
>;
type RealApiGetFn<T extends ApiPathGet> = (
  client: AxiosInstance,
  forceMock?: boolean
) => MockApiGetFn<T>;

// PUT
export type ApiPathPut = {
  put: {
    requestBody: { content: { "application/json": any } };
    parameters: { query: any };
    responses: { "200": { content: { "application/json": any } } };
  };
};
type MockApiPutFn<T extends ApiPathPut> = (
  body: T["put"]["requestBody"]["content"]["application/json"],
  query?: T["put"]["parameters"]["query"]
) => Promise<
  AxiosResponse<T["put"]["responses"]["200"]["content"]["application/json"]>
>;
type RealApiPutFn<T extends ApiPathPut> = (
  client: AxiosInstance,
  forceMock?: boolean
) => MockApiPutFn<T>;

// POST
export type ApiPathPost = {
  post: {
    requestBody: { content: { "application/json": any } };
    parameters: { query: any };
    responses: { "200": { content: { "application/json": any } } };
  };
};
type MockApiPostFn<T extends ApiPathPost> = (
  body: T["post"]["requestBody"]["content"]["application/json"],
  query?: T["post"]["parameters"]["query"]
) => Promise<
  AxiosResponse<T["post"]["responses"]["200"]["content"]["application/json"]>
>;
type RealApiPostFn<T extends ApiPathPost> = (
  client: AxiosInstance,
  forceMock?: boolean
) => MockApiPostFn<T>;

// POST FILE
export type ApiPathPostFile = {
  post: {
    requestBody: { content: { "multipart/form-data": FormData } };
    parameters: { query: any };
    responses: { "200": { content: { "application/json": any } } };
  };
};
type MockApiPostFileFn<T extends ApiPathPostFile> = (
  body: T["post"]["requestBody"]["content"]["multipart/form-data"],
  query?: T["post"]["parameters"]["query"]
) => Promise<
  AxiosResponse<T["post"]["responses"]["200"]["content"]["application/json"]>
>;
type RealApiPostFileFn<T extends ApiPathPostFile> = (
  client: AxiosInstance,
  forceMock?: boolean
) => MockApiPostFileFn<T>;

// DELETE
export type ApiPathDelete = {
  delete: {
    parameters: { query: any };
    responses: { "200": { content: { "application/json": any } } };
  };
};
type MockApiDeleteFn<T extends ApiPathDelete> = (
  params: T["delete"]["parameters"]["query"]
) => Promise<
  AxiosResponse<T["delete"]["responses"]["200"]["content"]["application/json"]>
>;
type RealApiDeleteFn<T extends ApiPathDelete> = (
  client: AxiosInstance,
  forceMock?: boolean
) => MockApiDeleteFn<T>;

export const mockableDeleteRequest = <T extends ApiPathDelete>(
  realRequest: RealApiDeleteFn<T>,
  mockRequest: MockApiDeleteFn<T>
): RealApiDeleteFn<T> => {
  return (client: AxiosInstance, forceMock?: boolean) => {
    if (Number(process.env.REACT_APP_API_MOCK) || Boolean(forceMock)) {
      // For mock request:
      // - ignore client argument
      // - return a function that accepts same input params
      return (params: T["delete"]["parameters"]["query"]) =>
        mockRequest(params);
    }
    return realRequest(client);
  };
};
export const mockableGetRequest = <T extends ApiPathGet>(
  realRequest: RealApiGetFn<T>,
  mockRequest: MockApiGetFn<T>
): RealApiGetFn<T> => {
  return (client: AxiosInstance, forceMock?: boolean) => {
    if (Number(process.env.REACT_APP_API_MOCK) || Boolean(forceMock)) {
      // For mock request:
      // - ignore client argument
      // - return a function that accepts same input params
      return (params: T["get"]["parameters"]["query"]) => mockRequest(params);
    }
    return realRequest(client);
  };
};

export const mockablePutRequest = <T extends ApiPathPut>(
  realRequest: RealApiPutFn<T>,
  mockRequest: MockApiPutFn<T>
): RealApiPutFn<T> => {
  return (client: AxiosInstance, forceMock?: boolean) => {
    if (Number(process.env.REACT_APP_API_MOCK) || Boolean(forceMock)) {
      // For mock request:
      // - ignore client argument
      // - return a function that accepts same request body
      return (
        body: T["put"]["requestBody"]["content"]["application/json"],
        query: T["put"]["parameters"]["query"]
      ) => mockRequest(body, query);
    }
    return realRequest(client);
  };
};

export const mockablePostRequest = <T extends ApiPathPost>(
  realRequest: RealApiPostFn<T>,
  mockRequest: MockApiPostFn<T>
): RealApiPostFn<T> => {
  return (client: AxiosInstance, forceMock?: boolean) => {
    if (Number(process.env.REACT_APP_API_MOCK) || Boolean(forceMock)) {
      // For mock request:
      // - ignore client argument
      // - return a function that accepts same request body
      return (
        body: T["post"]["requestBody"]["content"]["application/json"],
        query: T["post"]["parameters"]["query"]
      ) => mockRequest(body, query);
    }
    return realRequest(client);
  };
};

export const mockablePostFileRequest = <T extends ApiPathPostFile>(
  realRequest: RealApiPostFileFn<T>,
  mockRequest: MockApiPostFileFn<T>
): RealApiPostFileFn<T> => {
  return (client: AxiosInstance, forceMock?: boolean) => {
    if (Number(process.env.REACT_APP_API_MOCK) || Boolean(forceMock)) {
      // For mock request:
      // - ignore client argument
      // - return a function that accepts same request body
      return (
        body: T["post"]["requestBody"]["content"]["multipart/form-data"],
        query: T["post"]["parameters"]["query"]
      ) => mockRequest(body, query);
    }
    return realRequest(client);
  };
};
