import { styled } from "@mui/material/styles";
import { Box, Chip, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { withLayout } from "../hoc/with-layout";
import { useContext, useEffect, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { useCustomQuery } from "../hooks/use-custom-query";
import { getElementLiveStatus } from "../data/items";
import { ProductData, formatProductsWithDisplayOrder } from "../utils";
import { getProducts } from "../data/products";

const pageURIs = [
  {
    title: "Products & Ingredients",
    path: "products-ingredients",
    showInTabMenu: true,
  },
  {
    title: "Nutrition Information",
    path: "product-nutrition",
    showInTabMenu: true,
  },
  {
    title: "Review and Submit",
    path: "product-review-and-submit",
    showInTabMenu: true,
  },
  {
    title: "Compare With Live",
    path: "compare-with-live",
    showInTabMenu: false,
  },
];

const tabs = pageURIs.filter((uri) => uri.showInTabMenu === true);

type ProductContextType = {
  productId: string;
  editable: boolean;
  isLive: boolean;
  language: string;
  setLanguage: (language: string) => void;
};

export function useProduct() {
  return useOutletContext<ProductContextType>();
}

const EditProductComponent = () => {
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const { defaultLanguage, selectedCountry, selectedRole } =
    useContext(RoleContext);
  const { productId } = useParams();
  const { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const activeURI = pathParts[pathParts.length - 1];
  const activePage = pageURIs.find((uri) => uri.path === activeURI);
  const activeTab = tabs.find((tab) => tab.path === activePage?.path);

  const [isLive, setIsLive] = useState(false);

  if (!productId) {
    throw new Error("productId is required");
  }
  if (!activePage) {
    throw new Error("Invalid URI");
  }
  const { data } = useCustomQuery(
    ["getProductsList", { selectedCountry }],
    () =>
      getProducts(apiClient)({
        countryCode: selectedCountry!,
        roleId: selectedRole!,
      }),
    selectedCountry !== null && selectedRole !== null
  );

  const products: ProductData[] = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    const validProducts: ProductData[] = formatProductsWithDisplayOrder(
      data.data.dataList
    );

    return validProducts;
  }, [data]);
  const [language, setLanguage] = useState<string>(
    typeof defaultLanguage === "string" ? defaultLanguage : "EN"
  );

  const productName = useMemo(() => {
    if (products.length > 0) {
      const product = products.find((p) => p.productId === Number(productId));
      if (!product) {
        const errorMessage = `No product found matching id ${productId}`;
        navigate(`/error?message=${errorMessage}`);
        return;
      }
      return product.productName;
    }
    return "";
  }, [products, productId, navigate]);

  const editable = useMemo(() => {
    const product = products.find((p) => p.productId === Number(productId));
    if (product?.showEdit === "Y") {
      return true;
    } else {
      return false;
    }
  }, [products, productId]);

  const getElementLiveStatusQuery = useCustomQuery(
    [
      "getElementLiveStatus",
      {
        productId,
        countryCode: selectedCountry,
      },
    ],
    () =>
      getElementLiveStatus(apiClient)({
        elementId: productId,
        countryCode: selectedCountry!,
      })
  );
  useEffect(() => {
    if (
      getElementLiveStatusQuery.isSuccess &&
      typeof getElementLiveStatusQuery.data.data.live === "string"
    ) {
      setIsLive(getElementLiveStatusQuery.data.data.live === "Y");
    }
  }, [getElementLiveStatusQuery.isSuccess, getElementLiveStatusQuery.data]);

  const ProductWithLayout = useMemo(() => {
    return withLayout(() => {
      return (
        <StyledFlexBox>
          <Grid container>
            {activeTab && (
              <>
                <StyledTitleHeader container mobile={9}>
                  <Grid>
                    <Typography variant="h1">{productName}</Typography>
                  </Grid>
                  {isLive ? (
                    <Chip label="Live" color="success" />
                  ) : (
                    <Chip label="Not Live" />
                  )}
                </StyledTitleHeader>

                <StyledCenteredContainer mobile={12}>
                  <StyledTabs
                    value={activeTab.path}
                    scrollButtons="auto"
                    centered
                    aria-label="tab buttons"
                    TabIndicatorProps={{
                      sx: { backgroundColor: "#FFBC0D" },
                    }}
                  >
                    {tabs.map((tab) => (
                      <StyledTab
                        key={tab.path}
                        label={tab.title}
                        aria-label={tab.title}
                        component={Link}
                        to={`/products/${productId}/${tab.path}`}
                        value={tab.path}
                        role="tab"
                      />
                    ))}
                  </StyledTabs>
                </StyledCenteredContainer>
              </>
            )}

            <Grid mobile={12}>
              <Outlet
                context={{ productId, editable, isLive, language, setLanguage }}
              />
            </Grid>
          </Grid>
        </StyledFlexBox>
      );
    }, activePage.title);
  }, [
    activePage,
    activeTab,
    editable,
    isLive,
    language,
    productId,
    productName,
  ]);

  return <ProductWithLayout />;
};

export const Product = EditProductComponent;

const StyledFlexBox = styled(Box)({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
});
const StyledTitleHeader = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(4),
}));
const StyledCenteredContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  width: "100%",
  borderBottom: "1px solid #F6F6F6",
  marginBottom: theme.spacing(4),
}));
const StyledTabs = styled(Tabs)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #F6F6F6",
  padding: theme.spacing(0, 2),
}));
const StyledTab = styled(Tab)(({ theme }) => ({
  color: "black",
  fontSize: 18,
  fontFamily: "Speedee-Bold, Arial, Helvetica, sans-serif",
  letterSpacing: "-0.15px",
  textTransform: "initial",
  lineHeight: "28px",
  borderBottom: "2px solid #F6F6F6",
  "&.Mui-selected": {
    backgroundColor: "#F6F6F6",
    borderWidth: "4px",
    color: "black",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
})) as typeof Tab;
