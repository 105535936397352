import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Typography, Box } from '@mui/material';
import { useAxios } from "../axios-provider";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button } from "@mui/material";
import { updateFortifiedItems, getIsFortifiedItemValueQuery, getFortifiedItemsListQuery } from "../data/items";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { RoleContext } from "../role-provider";
import { useItem } from "../pages/item";
import MultiSelect from "./MultiSelect";
import { AxiosResponse } from "axios";
import { UseQueryResult } from "@tanstack/react-query";

type UpdateFortifiedItem = {
  nutrientFactId: number;
  display: string;
};

interface FortifiedItemProps {
  selectedLanguage: string | null;
}
interface FortifiedItemListData {
  message?: string;
  dataList?: {
    nutrientFactId?: number;
    display?: string;
    name?: string;
  }[];
}

const FortifiedItem: React.FC<FortifiedItemProps> = ({ selectedLanguage }) => {
  const { itemId } = useItem();
  const { selectedCountry, isReaderRole, isLegalRole } =
    useContext(RoleContext);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [unSelectedItems, setUnSelectedItems] = useState<number[]>([]);
  const [isFortifiedItemList, setIsFortifiedItemList] =
    useState<UseQueryResult<AxiosResponse<FortifiedItemListData>, unknown>>();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isFortifiedItemListQuery = useQuery({
    queryKey: [
      "getFortifiedItemsListQuery",
      { CountryCode: selectedCountry, itemId, LanguageCode: selectedLanguage },
    ],
    queryFn: () =>
      getFortifiedItemsListQuery(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (!isFortifiedItemListQuery.isLoading && isFortifiedItemListQuery.data) {
      setIsFortifiedItemList(isFortifiedItemListQuery);
    }
  }, [
    isFortifiedItemListQuery,
    isFortifiedItemListQuery.isLoading,
    isFortifiedItemListQuery.data,
  ]);

  const updateFortifiedItemRequest = updateFortifiedItems(apiClient);
  const saveUpdateFortifiedItemsRequest = useMutation(
    (updateFortifiedItems: UpdateFortifiedItem[]) => {
      return updateFortifiedItemRequest(updateFortifiedItems, {
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage("Data has been updated successfully.");
        queryClient.invalidateQueries([
          "getFortifiedItemsListQuery",
          {
            itemId,
            CountryCode: selectedCountry,
            LanguageCode: selectedLanguage,
          },
        ]);
      },
      onError: (error) => setErrorMessage(String(error)),
    }
  );

  const isFortifiedItemValue = useQuery({
    queryKey: [
      "getIsFortifiedItemValueQuery",
      { CountryCode: selectedCountry, itemId },
    ],
    queryFn: () =>
      getIsFortifiedItemValueQuery(apiClient)({
        itemId: String(itemId),
        countryCode: selectedCountry!,
      }),
    onError: (error) => setErrorMessage(String(error)),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
  });

  const handleMultiSelectChange = (
    newSelectedItems: number[],
    newUnSelectedItems: number[]
  ) => {
    setSelectedItems(newSelectedItems);
    setUnSelectedItems(newUnSelectedItems);
  };

  const handleSaveClick = () => {
    const checkedData = selectedItems.map((id) => ({
      nutrientFactId: id,
      display: "Yes",
    }));
    const unCheckedData = unSelectedItems.map((id) => ({
      nutrientFactId: id,
      display: "No",
    }));
    const finalPayload = [...checkedData, ...unCheckedData];
    saveUpdateFortifiedItemsRequest.mutate(finalPayload);
  };

  return isFortifiedItemValue.data?.data?.dataList?.[0]?.value === "Yes" &&
    isFortifiedItemList ? (
    <>
      <Grid container mobile={12} marginTop={4} spacing={0}>
        <Grid container mobile={3}>
          <Typography variant="h4">Is Fortified Item</Typography>
        </Grid>
        <Box
          border={2}
          borderColor="grey.500"
          borderRadius={4}
          padding={2}
          sx={{ width: "65%" }}
        >
          <Grid container mobile={9}>
            <Grid container mobile={14}>
              <Typography variant="h6">Is display</Typography>
              <MultiSelect
                selectedItems={selectedItems}
                unSelectedItems={unSelectedItems}
                handleChange={handleMultiSelectChange}
                selectedLanguage={selectedLanguage}
                isFortifiedItemList={isFortifiedItemList}
              />
              <StyledButton
                variant="contained"
                size="large"
                type="submit"
                disabled={!!isReaderRole || !!isLegalRole}
                onClick={handleSaveClick}
              >
                Save
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </>
  ) : null;
};

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));

export default FortifiedItem;
