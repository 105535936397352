import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  styled,
} from "@mui/material";

type UnsavedChangesModalProps = {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
  confirmButton?: string;
  denyButton?: string;
  title?: string;
  description?: string;
};

export const UnsavedChangesModal = ({
  onClose,
  onLeave,
  open,
  confirmButton = "Leave",
  denyButton = "Stay",
  title = "Unsaved Changes",
  description = "You have unsaved changes. Are you sure you want to leave?",
}: UnsavedChangesModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="unsaved-changes-title"
      aria-describedby="unsaved-changes-description"
    >
      <DialogTitle id="unsaved-changes-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="unsaved-changes-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-evenly" }}>
        <StyledButton variant="contained" size="large" onClick={onClose}>
          {denyButton}
        </StyledButton>
        <StyledDangerButton
          variant="contained"
          size="large"
          color="error"
          onClick={onLeave}
          autoFocus
        >
          {confirmButton}
        </StyledDangerButton>
      </DialogActions>
    </Dialog>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
