import { styled } from "@mui/material/styles";
import { MenuItem, TextField } from "@mui/material";
import { useContext } from "react";
import { RoleContext } from "../role-provider";

type LangageSelectorProps = {
  selectedLanguage: string;
  onChange: (selectedLanguage: string) => void;
  disabled?: boolean;
};

export const LanguageSelector = ({
  selectedLanguage,
  onChange,
  disabled,
}: LangageSelectorProps) => {
  const forceDefault = typeof disabled !== "undefined" ? disabled : false;
  const { selectableLanguages } = useContext(RoleContext);

  if (forceDefault) {
    let selectedLanguageName = "";
    const selectedLanguageInfo = selectableLanguages.find((language) => {
      return (
        language.languageCode.toLowerCase() === selectedLanguage.toLowerCase()
      );
    });
    if (selectedLanguageInfo) {
      selectedLanguageName = selectedLanguageInfo.languageName;
    }

    return (
      <StyledTextField
        label="Language"
        disabled={true}
        select
        value={selectedLanguage}
      >
        <MenuItem key={0} value={selectedLanguage}>
          {selectedLanguageName}
        </MenuItem>
      </StyledTextField>
    );
  }
  return selectableLanguages.length > 0 ? (
    <StyledTextField
      defaultValue={selectedLanguage}
      label="Language"
      InputLabelProps={{
        shrink: true,
      }}
      select
      onChange={(e) => onChange(e.target.value)}
      data-testid="open-language-selector"
    >
      {selectableLanguages.map((language, index) => (
        <MenuItem key={index} value={language.languageCode}>
          {language.languageName}
        </MenuItem>
      ))}
    </StyledTextField>
  ) : (
    <></>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 200,
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
