import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const Logo: React.FC = () => {
  return (
    <StyledBox component="span">
      <img src="/mcdonalds-golden-arches.png" alt="logo" width="76px" />
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
}));