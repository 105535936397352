type LocalizationFormat = {
  locale: string;
  thousandSeparatorRegex: RegExp;
  decimalSeparatorRegex: RegExp;
};

function getLocalizationFormatForMarketCode(
  marketCode: string
): LocalizationFormat | undefined {
  switch (marketCode) {
    case "DK":
    case "NL":
      return {
        locale: "de-DE",
        thousandSeparatorRegex: /\./g,
        decimalSeparatorRegex: /,/g,
      };
    case "HU":
    case "SE":
    case "FI":
      return {
        locale: "fi-FI",
        thousandSeparatorRegex: /\s/g,
        decimalSeparatorRegex: /,/g,
      };
    case "TW":
      return {
        locale: "en-US",
        thousandSeparatorRegex: /,/g,
        decimalSeparatorRegex: /\./g,
      };
  }
}

export function formatLocalized(input: string, marketCode: string): string {
  const format = getLocalizationFormatForMarketCode(marketCode);
  if (!format) {
    // we're in a non-localized market. return input as is
    return input;
  }
  // Otherwise convert to float to ensure correct decimal handling, then to Localized format
  let num = parseFloat(input);
  if (num === Math.floor(num)) {
    // strip trailing zeroes
    num = Math.floor(num);
  }
  // Ensure we preserve all decimal places for conversion
  const decimalPlaces = (input.split(".")[1] || "").length;
  const localizedString = num.toLocaleString(format.locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: 20,
  });

  return localizedString;
}

export function localizedNumStringOrPlaceholder(
  input: string,
  marketCode: string,
  placeholder: string
) {
  if (
    input.toLowerCase() === placeholder.toLowerCase() ||
    isNaN(parseFloat(input))
  ) {
    // `placeholder` is the only allowed non-numeric value
    return placeholder;
  }
  return formatLocalized(input, marketCode);
}

export function localizedNumStringOrEmpty(input: string, marketCode: string) {
  return localizedNumStringOrPlaceholder(input, marketCode, "");
}

export function localizedNumStringOrNA(input: string, marketCode: string) {
  return localizedNumStringOrPlaceholder(input, marketCode, "N/A");
}

export function localizedStringValueToFloat(input: string, marketCode: string) {
  const format = getLocalizationFormatForMarketCode(marketCode);
  if (!format) {
    // we're in a non-localized market. input should already be parsable
    return parseFloat(input);
  }
  const { thousandSeparatorRegex, decimalSeparatorRegex } = format;
  return parseFloat(
    // remove thousand separator and replace decimal separator with '.'
    input
      .replace(thousandSeparatorRegex, "")
      .replace(decimalSeparatorRegex, ".")
  );
}

export function standardizedNumStringOrPlaceholder(
  input: string,
  marketCode: string,
  placeholder: string
) {
  if (input.toLowerCase() === placeholder.toLowerCase()) {
    return placeholder;
  }
  const floatVal = localizedStringValueToFloat(input, marketCode);
  return !isNaN(floatVal) ? String(floatVal) : placeholder;
}

export function standardizedNumStringOrEmpty(
  input: string,
  marketCode: string
) {
  return standardizedNumStringOrPlaceholder(input, marketCode, "");
}

export function standardizedNumStringOrNA(input: string, marketCode: string) {
  return standardizedNumStringOrPlaceholder(input, marketCode, "N/A");
}
