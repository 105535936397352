import { useCallback, useContext, useMemo, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import { getCompareLive, getCompareCurrent } from "../data/categories";
import {
  FormattedCategoryCompareDetails,
  formatCategoryCompare,
} from "../utils";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import { LanguageSelector } from "../components/LanguageSelector";
import { useCustomQuery } from "../hooks/use-custom-query";

type CategoryAttributeName = keyof Omit<
  FormattedCategoryCompareDetails,
  "items"
>;

export const CategoryCompareWithLive = () => {
  const { categoryId } = useParams();
  const { selectedCountry, defaultLanguage } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    defaultLanguage
  );

  if (!categoryId) {
    throw new Error("categoryId is required");
  }

  const compareLiveQuery = useCustomQuery(
    [
      "category/getCompareLive",
      {
        categoryId,
        countryCode: selectedCountry,
        languageCode: selectedLanguage,
      },
    ],
    () =>
      getCompareLive(apiClient)({
        categoryId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );
  const liveCompareData = useMemo(() => {
    if (compareLiveQuery.data?.data.category) {
      return formatCategoryCompare(compareLiveQuery.data.data.category);
    }
    return undefined;
  }, [compareLiveQuery.data]);

  const compareCurrentQuery = useCustomQuery(
    [
      "category/getCompareCurrent",
      {
        categoryId,
        countryCode: selectedCountry,
        languageCode: selectedLanguage,
      },
    ],
    () =>
      getCompareCurrent(apiClient)({
        categoryId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );
  const currentCompareData = useMemo(() => {
    if (compareCurrentQuery.data?.data.category) {
      return formatCategoryCompare(compareCurrentQuery.data.data.category);
    }
    return undefined;
  }, [compareCurrentQuery.data]);

  const isFetching = useMemo(
    () =>
      Boolean(compareLiveQuery.isFetching || compareCurrentQuery.isFetching),
    [compareLiveQuery.isFetching, compareCurrentQuery.isFetching]
  );
  const isError = useMemo(
    () => Boolean(compareLiveQuery.isError && compareCurrentQuery.isError),
    [compareLiveQuery.isError, compareCurrentQuery.isError]
  );

  const getLiveAttributeValue = useCallback(
    (key: CategoryAttributeName) => {
      if (liveCompareData) {
        return liveCompareData[key];
      }
    },
    [liveCompareData]
  );

  const getCurrentAttributeValue = useCallback(
    (key: CategoryAttributeName) => {
      if (currentCompareData) {
        return currentCompareData[key];
      }
    },
    [currentCompareData]
  );

  const liveMenuItems = useMemo(() => {
    if (!liveCompareData) {
      return [];
    }
    return liveCompareData.items.item;
  }, [liveCompareData]);

  const currentMenuItems = useMemo(() => {
    if (!currentCompareData) {
      return [];
    }
    return currentCompareData.items.item;
  }, [currentCompareData]);

  const pageTitle = useMemo(() => {
    const categoryName = getLiveAttributeValue("categoryName");
    return categoryName
      ? `Compare With Live (${categoryName})`
      : `Compare With Live`;
  }, [getLiveAttributeValue]);

  const getAttributeRow = useCallback(
    (key: CategoryAttributeName, fieldName: string) => {
      const liveValue = getLiveAttributeValue(key);
      const currentValue = getCurrentAttributeValue(key);
      if (typeof liveValue !== "string" && typeof currentValue !== "string") {
        // render empty if both live & current are blank
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell>--</TableCell>
            <TableCell>--</TableCell>
          </StyledTableRow>
        );
      }
      if (typeof currentValue === "string" && typeof liveValue !== "string") {
        // show changed if current has value but live is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <StyledTableCellWithDiff>{currentValue}</StyledTableCellWithDiff>
            <TableCell>--</TableCell>
          </StyledTableRow>
        );
      }
      if (typeof liveValue === "string" && typeof currentValue !== "string") {
        // show changed if live has value but current is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <StyledTableCellWithDiff>--</StyledTableCellWithDiff>
            <TableCell>{liveValue}</TableCell>
          </StyledTableRow>
        );
      }
      // both live and current have value - show changed if values do not match
      const hasDiff = () => {
        if (typeof currentValue !== typeof liveValue) {
          return true;
        }
        return Boolean(
          typeof currentValue === "string" &&
            typeof liveValue === "string" &&
            currentValue !== liveValue
        );
      };
      return (
        <StyledTableRow
          key={key}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row">
            {fieldName}
          </TableCell>
          {hasDiff() ? (
            <StyledTableCellWithDiff>{currentValue}</StyledTableCellWithDiff>
          ) : (
            <TableCell>{currentValue}</TableCell>
          )}
          <TableCell>{liveValue}</TableCell>
        </StyledTableRow>
      );
    },
    [getLiveAttributeValue, getCurrentAttributeValue]
  );

  const CategoryCompareMarketingDetailsTable = () => {
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <TableContainer component={Paper}>
          <Table aria-label="Category Compare Marketing Details Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Current Value</TableCell>
                <TableCell>Live Value</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {getAttributeRow("categoryType", "Category Type")}
              {getAttributeRow("categoryName", "Category Name")}
              {getAttributeRow(
                "categoryMarketingName",
                "Category Marketing Name"
              )}
              {getAttributeRow("categoryId", "Category ID")}
              {getAttributeRow("doNotShow", "Do Not Show")}
              {getAttributeRow("categoryDescription", "Category Description")}
              {getAttributeRow("categoryComments", "Category Comments")}
              {getAttributeRow("categoryKeywords", "Category Keywords")}
            </TableBody>
          </Table>
          {isError && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No category marketing information to display`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </StyledRelativeContainer>
    );
  };

  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid mobile={12}>
        <Grid mobile={12}>
          <Typography variant="h1">{pageTitle}</Typography>
        </Grid>
        <Grid
          mobile={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ backgroundColor: "#DA291C" }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid
          container
          mobile={12}
          sx={{ mb: 2, mt: 2, justifyContent: "flex-end" }}
        >
          <LanguageSelector
            selectedLanguage={selectedLanguage!}
            onChange={(language) => setSelectedLanguage(language)}
          />
        </Grid>
        <Grid mobile={12}>
          <Stack spacing={1} marginBottom="20px">
            <Grid container spacing={1}>
              <Grid mobile={12} sx={{ mt: 4 }}>
                <Typography variant="h2">Marketing Information</Typography>
              </Grid>
              <Grid mobile={12}>
                <CategoryCompareMarketingDetailsTable />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <Grid container mobile={12} spacing={1}>
        <Grid container mobile={12}>
          <Typography variant="h2">Menu Item Information</Typography>
        </Grid>
        <Grid container mobile={6} alignContent="flex-start">
          <Grid mobile={12}>
            <Typography variant="h4" color="#DA291C">
              Current Menu Items
            </Typography>
          </Grid>
          <Grid mobile={12}>
            <TableContainer component={Paper}>
              <Table aria-label="Current Menu Items in Category">
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>Item Id</TableCell>
                    <TableCell>Item Name</TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {currentMenuItems.length > 0 && (
                  <TableBody>
                    {currentMenuItems.map((itemMapping, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{itemMapping.itemId}</TableCell>
                        <TableCell>{itemMapping.itemName}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
              {currentMenuItems.length === 0 && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No items to display`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container mobile={6} alignContent="flex-start">
          <Grid mobile={12}>
            <Typography variant="h4" color="#DA291C">
              Live Menu Items
            </Typography>
          </Grid>
          <Grid mobile={12}>
            <TableContainer component={Paper}>
              <Table aria-label="Live Menu Items in Category">
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>Item Id</TableCell>
                    <TableCell>Item Name</TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {liveMenuItems.length > 0 && (
                  <TableBody>
                    {liveMenuItems.map((itemMapping, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{itemMapping.itemId}</TableCell>
                        <TableCell>{itemMapping.itemName}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
              {liveMenuItems.length === 0 && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No items to display`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </StyledRelativeContainer>
  );
};

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 36,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
const StyledTableCellWithDiff = styled(TableCell)({
  backgroundColor: "#FCEFCC",
});
