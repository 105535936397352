import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { useProduct } from "../pages/product";
import { ProductMarketingForm } from "./ProductMarketingForm";
import { useQueryClient } from "@tanstack/react-query";

export const ProductMarketingInfoEdit = () => {
  const { productId, editable, isLive, language, setLanguage } = useProduct();
  const queryClient = useQueryClient();

  return (
    <StyledRelativeContainer>
      <ProductMarketingForm
        productId={productId}
        editable={editable}
        isLive={isLive}
        language={language}
        setLanguage={setLanguage}
        saveSuccessMessage={`Product ${productId} was saved successfully`}
        onSaveSuccess={() => {
          queryClient.invalidateQueries(["getProductsList"]);
          queryClient.invalidateQueries(["getAllProductsList"]);
          queryClient.invalidateQueries(["getProductDetails"]);
          queryClient.invalidateQueries(["product/getCompareCurrent"]);
          queryClient.invalidateQueries(["product/getCompareLive"]);
        }}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
