import { useLocation, Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type BreadcrumbConfig = {
  [key: string]: {
    displayName: string;
  };
};
type Breadcrumb = {
  path: string;
  displayName: string;
};
const breadcrumbConfig: BreadcrumbConfig = {
  items: { displayName: "Items" },
  "nutrition-facts": { displayName: "Nutrition Facts" },
  "item-marketing-information": {
    displayName: "Marketing Information",
  },
  "product-mapping": { displayName: "Item Product Mapping" },
  nutrition: { displayName: "Nutrition information" },
  "category-mapping": { displayName: "Category Mapping" },
  "relationship-mapping": {
    displayName: "Relationship Mapping",
  },
  "goes-well-with": {
    displayName: "Goes Well With",
  },
  "co-op-mapping": {
    displayName: "Co-Op Mapping",
  },
  "item-review-and-submit": {
    displayName: "Review & Submit",
  },
  "create-item": {
    displayName: "Create Item",
  },
  "archive-item": {
    displayName: "Archive Item",
  },
  "exceptions-report": {
    displayName: "Exceptions Report",
  },
  "compare-with-live": {
    displayName: "Compare With Live",
  },
  products: { displayName: "Products" },
  "products-ingredients": {
    displayName: "Products & Ingredients",
  },
  "product-nutrition": {
    displayName: "Nutrition Information",
  },
  "product-review-and-submit": {
    displayName: "Review & Submit",
  },
  "create-product": {
    displayName: "Create Product",
  },
  categories: { displayName: "Categories" },
  "category-marketing-information": {
    displayName: "Marketing Information",
  },
  "category-item-mapping": {
    displayName: "Category Item Mapping",
  },
  "category-review-and-submit": {
    displayName: "Review & Submit",
  },
  "create-category": {
    displayName: "Create Category",
  },
  "archive-category": {
    displayName: "Archive Category",
  },
  "view-user": {
    displayName: "View User",
  },
  "add-edit-user": {
    displayName: "Add User",
  },
  "user-form": {
    displayName: "Edit User",
  },
  "push-live-detail": {
    displayName: "Push Live Detail",
  },
  "push-live-event": {
    displayName: "Push Live Elements",
  },
  "push-live-event-details": {
    displayName: "Push Live Event",
  },
  "edit-push-live-event": {
    displayName: "Edit Push Live Event",
  },
  // "view-logo": {
  //   displayName: "View Logo",
  // },
  "custom-footers": {
    displayName: "Custom Footers",
  },
  "create-footers": {
    displayName: "Create Footers",
  },
  "bulk-upload": {
    displayName: "Bulk Upload",
  },
  "audit-trail": {
    displayName: "Audit Trail",
  },
  relationships: {
    displayName: "Relationships",
  },
  "edit-relationship": {
    displayName: "Edit Relationship",
  },
  "create-relationship": {
    displayName: "Create Relationship",
  },
  "rounding-rules": {
    displayName: "Rounding Rules",
  },
  "create-rounding-rule": {
    displayName: "Create Rounding Rule",
  },
  "edit-rounding-rule": {
    displayName: "Edit Rounding Rule",
  },
};
export const BreadcrumbComponent = () => {
  const location = useLocation();
  // Split the URL path into segments and filter out any empty strings (which might occur with trailing slashes)
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Generate breadcrumbs by mapping over the path segments and transforming them into breadcrumb objects
  const breadcrumbs = pathnames
    .map((segment, index): Breadcrumb | null => {
      const config = breadcrumbConfig[segment];

      // If there's no specific config for the current segment, don't create a breadcrumb for it
      if (!config) return null;

      const displayName = config.displayName;

      // Construct the path for this breadcrumb by joining the segments up to the current one
      const path = `/${pathnames.slice(0, index + 1).join("/")}`;
      return { path, displayName };
    })
    // Filter out any null values to ensure we only have valid breadcrumbs
    .filter((breadcrumb): breadcrumb is Breadcrumb => breadcrumb !== null);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="large" />}
      sx={{ mb: 2 }}
    >
      {breadcrumbs.length !== 0 && (
        <Link variant="h6" component={RouterLink} to="/">
          Home
        </Link>
      )}
      {breadcrumbs.map(({ path, displayName }, index) => {
        // Different Styling for the last breadcrumb in the array
        const last = breadcrumbs.length - 1 === index;
        return (
          <Link
            component={RouterLink}
            to={path}
            key={path}
            sx={{ textDecorationColor: last ? "#000000" : "initial" }}
          >
            <Typography variant="h6">{displayName}</Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
