import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteSelectedItemRelationship,
  getItemRelationshipMapping,
  getRelationTypes,
} from "../data/items";
import {
  DefaultRelationshipMappingType,
  MappedRelationshipMappingType,
  RelationType,
  isDefaultRelationshipMapping,
  isMappedItemRelationshipMapping,
  isRelationType,
} from "../utils";
import { Loader } from "./loader/Loader";
import { useItem } from "../pages/item";
import { EditRelationshipMappingModal } from "./EditRelationshipMappingModal";
import { AddRowLink } from "./AddRowLink";
import { AddCircle, DeleteOutline } from "@mui/icons-material";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";

export const RelationshipMapping = () => {
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const { itemId, itemName, editable, isLive } = useItem();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const queryClient = useQueryClient();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const addNewMappingEnabled = useMemo(
    () => !isReaderRole && editable && typeof itemName === "string",
    [isReaderRole, editable, itemName]
  );

  const getItemRelationshipMappingQuery = useCustomQuery(
    ["getItemRelationshipMapping", { itemId }],
    () =>
      getItemRelationshipMapping(apiClient)({
        itemId: Number(itemId)!,
        countryCode: selectedCountry!,
      })
  );

  const handleSaveSuccess = () => {
    setIsEditRelationModalOpen(false);
    queryClient.invalidateQueries(["getItemRelationshipMapping", { itemId }]);
    queryClient.invalidateQueries(["item/getCompareCurrent", { itemId }]);
    setSuccessMessage("Relationship mapping saved successfully");
  };

  const defaultRelationshipMappings = useMemo(() => {
    if (
      getItemRelationshipMappingQuery.data?.data.defaultItemList === undefined
    ) {
      return [];
    }
    const validDefaultRelationshipMappings =
      getItemRelationshipMappingQuery.data?.data.defaultItemList.filter(
        (
          maybeDefaultRelationshipMapping
        ): maybeDefaultRelationshipMapping is DefaultRelationshipMappingType => {
          return (
            isDefaultRelationshipMapping(maybeDefaultRelationshipMapping) ===
            true
          );
        }
      );
    return validDefaultRelationshipMappings;
  }, [getItemRelationshipMappingQuery.data]);

  const isDefaultRelationshipMappingsEmpty = useMemo(() => {
    return (
      !getItemRelationshipMappingQuery.isFetching &&
      getItemRelationshipMappingQuery.isSuccess &&
      defaultRelationshipMappings.length === 0
    );
  }, [
    getItemRelationshipMappingQuery.isFetching,
    getItemRelationshipMappingQuery.isSuccess,
    defaultRelationshipMappings,
  ]);

  const mappedRelationshipMappings = useMemo(() => {
    if (
      getItemRelationshipMappingQuery.data?.data.mappedItemList === undefined
    ) {
      return [];
    }

    const validMappedRelationshipMappings =
      getItemRelationshipMappingQuery.data?.data.mappedItemList.filter(
        (maybeAttribute): maybeAttribute is MappedRelationshipMappingType => {
          return isMappedItemRelationshipMapping(maybeAttribute) === true;
        }
      );
    return validMappedRelationshipMappings;
  }, [getItemRelationshipMappingQuery.data]);

  const isMappedRelationshipMappingsEmpty = useMemo(() => {
    return (
      !getItemRelationshipMappingQuery.isFetching &&
      getItemRelationshipMappingQuery.isSuccess &&
      mappedRelationshipMappings.length === 0
    );
  }, [
    getItemRelationshipMappingQuery.isFetching,
    getItemRelationshipMappingQuery.isSuccess,
    mappedRelationshipMappings,
  ]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] =
    useState<DefaultRelationshipMappingType | null>(null);

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setItemToDelete(null);
  };

  const [isEditRelationModalOpen, setIsEditRelationModalOpen] =
    useState<boolean>(false);
  const [editableRelationshipId, setEditableRelationshipId] = useState<
    number | null
  >(null);

  const handleOpenEdit = (relationshipId: number) => {
    setEditableRelationshipId(relationshipId);
    setIsEditRelationModalOpen(true);
  };

  const handleOpenCreate = () => {
    setEditableRelationshipId(null);
    setIsEditRelationModalOpen(true);
  };

  const handleClose = () => {
    setEditableRelationshipId(null);
    setIsEditRelationModalOpen(false);
  };

  const getRelationTypesQuery = useCustomQuery(["getRelationTypes"], () =>
    getRelationTypes(apiClient)({
      countryCode: selectedCountry!,
    })
  );
  const relationTypes = useMemo(() => {
    if (getRelationTypesQuery.data?.data.relationList === undefined) {
      return [];
    }
    const validRelationTypesAttributes =
      getRelationTypesQuery.data?.data.relationList.filter(
        (maybeAttribute): maybeAttribute is RelationType => {
          return isRelationType(maybeAttribute) === true;
        }
      );
    return validRelationTypesAttributes;
  }, [getRelationTypesQuery.data]);
  const {
    mutate: deleteRelationshipMutation,
    isLoading: isDeleteRelationshipLoading,
  } = useMutation((item: DefaultRelationshipMappingType) =>
    deleteSelectedItemRelationship(apiClient)({
      defaultItemId: item.defaultItemId,
      selectedRelationId: item.relationShipNameId,
      countryCode: selectedCountry!,
      roleId: String(selectedRole!),
    })
  );
  const handleDelete = () => {
    if (itemToDelete) {
      deleteRelationshipMutation(itemToDelete, {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "getItemRelationshipMapping",
            { itemId },
          ]);
          queryClient.invalidateQueries(["item/getCompareCurrent", { itemId }]);
          setSuccessMessage("Selected Item Relationship deleted successfully");
        },
        onError: (error) => {
          setErrorMessage(
            `Error deleting Selected Item Relationship: ${error}`
          );
        },
      });
      setItemToDelete(null);
      setOpenConfirmation(false);
    }
  };

  return (
    <>
      <StyledRelativeContainer container>
        {(getItemRelationshipMappingQuery.isFetching ||
          isDeleteRelationshipLoading) && <Loader />}
        <Grid
          container
          mobile={12}
          marginY={3}
          justifyContent={"space-between"}
        >
          <Typography variant="h2">Relationship Mapping</Typography>
          <StyledSecondaryButton
            variant="contained"
            disabled={!isLive}
            onClick={() => {
              navigate(`/items/${itemId}/compare-with-live`);
            }}
          >
            Compare with live
          </StyledSecondaryButton>
        </Grid>
        <Grid
          container
          mobile={12}
          marginBottom={4}
          data-testid="item-default-relationship-mappings"
        >
          <TableContainer component={Paper}>
            <Table aria-label="Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Default Item</TableCell>
                  <TableCell>Relationship Name</TableCell>
                  <TableCell>Grouped Items</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {!isDefaultRelationshipMappingsEmpty && (
                <TableBody>
                  {defaultRelationshipMappings.map((item) => {
                    const groups = item.groupedItems.split(",");
                    return (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{item.defaultItem}</TableCell>
                        <TableCell>{item.relationShipName}</TableCell>

                        <TableCell>
                          {groups.map((item, index) => (
                            <div key={index}>{item.trim()}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {!isReaderRole && editable && (
                            <>
                              <IconButton
                                onClick={() =>
                                  handleOpenEdit(item.relationShipNameId)
                                }
                              >
                                <EditIcon aria-label="edit button" />
                              </IconButton>
                              <IconButton
                                sx={{ color: "#DA291C" }}
                                onClick={() => {
                                  setItemToDelete(item);
                                  setOpenConfirmation(true);
                                }}
                                disabled={
                                  !!isReaderRole ||
                                  !editable ||
                                  defaultRelationshipMappings.length === 0
                                }
                                aria-label={`Delete ${item.defaultItem}`}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {isDefaultRelationshipMappingsEmpty && (
              <StyledEmptyBox>
                <Typography variant="h6">{`This item is not default item in any relationship`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
          {addNewMappingEnabled && (
            <Grid paddingLeft={0} marginTop={1}>
              <AddRowLink
                aria-label="Add new relationship mapping"
                id="add-relationship-mapping"
                data-testid="add-relationship-mapping"
                onClick={handleOpenCreate}
              >
                <AddCircle aria-label="add new relationship mapping button" />
                <Typography>New Relationship Mapping</Typography>
              </AddRowLink>
            </Grid>
          )}
        </Grid>
        <Grid container mobile={12} marginBottom={4}>
          <TableContainer component={Paper}>
            <Table aria-label="Category Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Also Mapped to</TableCell>
                  <TableCell>Mapped Relationship Name</TableCell>
                  <TableCell>Mapped Grouped Items</TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {!isMappedRelationshipMappingsEmpty && (
                <TableBody>
                  {mappedRelationshipMappings.map((map) => {
                    const mapGroup = map.mappedGroupItems.split(",");
                    return (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{map.alsoMapped}</TableCell>
                        <TableCell>{map.mappedRelationShipName}</TableCell>
                        <TableCell>
                          {mapGroup.map((item, index) => (
                            <div key={index}>{item.trim()}</div>
                          ))}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {isMappedRelationshipMappingsEmpty && (
              <StyledEmptyBox>
                <Typography variant="h6">{`This item is not associated with any other relationship`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
        </Grid>
        <div>
          <Dialog
            open={openConfirmation}
            onClose={handleCloseConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete Item Relationship?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ backgroundColor: "#DA291C" }}
                variant="contained"
                onClick={handleCloseConfirmation}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDelete} autoFocus>
                <Typography>Delete</Typography>
              </Button>
            </DialogActions>
          </Dialog>
          {relationTypes.length > 0 && isEditRelationModalOpen && (
            <EditRelationshipMappingModal
              preSelectedRelationId={editableRelationshipId}
              defaultItemId={itemId}
              defaultItemName={itemName}
              relationTypes={relationTypes}
              onSaveSuccess={handleSaveSuccess}
              onConfirmClose={handleClose}
            />
          )}
        </div>
        <SuccessAlertSnackbar
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
        />
        <ErrorAlertSnackbar
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      </StyledRelativeContainer>
    </>
  );
};

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 36,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
