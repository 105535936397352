import EditIcon from "@mui/icons-material/Edit";
import SummarizeIcon from "@mui/icons-material/Summarize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Link,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { AddRowLink } from "../components/AddRowLink";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { withLayout } from "../hoc/with-layout";
import { EmptyState } from "../components/EmptyState";
import { useCallback, useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { DataContext } from "../data-provider";
import { Loader } from "../components/loader/Loader";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SearchInput } from "../components/SearchInput";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import {
  ApiError,
  ValidCategory,
  ValidCategoryWithDisplayOrder,
  createSortByStringFn,
  createSortByNumberFn,
  formatCategoriesForDisplay,
  ItemByCategoryData,
  formatMappedCategoryItems,
  ItemWithCategories,
} from "../utils";
import {
  ArrowDownward,
  ArrowUpward,
  Cancel,
  LowPriority,
  Save,
} from "@mui/icons-material";
import TableSortIcon from "../components/TableSortIcon";
import { softDeleteItem } from "../data/items";
import { useAxios } from "../axios-provider";
import { AxiosError } from "axios";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import { ResendFormModal } from "../components/ResendFormModal";
import {
  getSelectedItemByCategoryId,
  softDeleteCategory,
} from "../data/categories";
import { useForm } from "react-hook-form";
import { saveElementDisplayOrder } from "../data/miscellaneous";
import { useCustomQuery } from "../hooks/use-custom-query";

const sortByDisplayOrder =
  createSortByNumberFn<ValidCategoryWithDisplayOrder>("displayOrder");
const sortByName =
  createSortByStringFn<ValidCategoryWithDisplayOrder>("categoryName");
const sortByType =
  createSortByStringFn<ValidCategoryWithDisplayOrder>("categoryType");
const sortById =
  createSortByStringFn<ValidCategoryWithDisplayOrder>("categoryId");
const sortByStatus =
  createSortByStringFn<ValidCategoryWithDisplayOrder>("status");

type ItemRowsProps = {
  categoryId: number;
  categoryItems: ItemWithCategories[];
  onClickDelete: (itemId: number) => void;
};
type SoftDeleteItemFormValues = {
  itemId: number;
  deleteReason: string;
};
type SoftDeleteCategoryFormValues = {
  categoryId: number;
  deleteReason: string;
};

function ItemRows({ categoryId, categoryItems, onClickDelete }: ItemRowsProps) {
  const navigate = useNavigate();
  const { isReaderRole } = useContext(RoleContext);

  const [page, setPage] = useState(0);
  const pageSize = 10;

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    [setPage]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (!categoryItems[index]) {
        return (
          <StyledItemTableRow
            key={`empty-category-item-row-${index}`}
            data-testid="item-child-tr"
            role="row"
          >
            <StyledItemTableCell width="10%" role="cell" />
            <StyledItemTableCell
              align="left"
              component="th"
              scope="row"
              width="25%"
              indent={true}
              role="cell"
            ></StyledItemTableCell>
            <StyledItemTableCell
              align="left"
              width="15%"
              role="cell"
            ></StyledItemTableCell>
            <StyledItemTableCell
              align="left"
              width="15%"
              role="cell"
            ></StyledItemTableCell>
            <StyledItemTableCell
              align="left"
              width="10%"
              role="cell"
            ></StyledItemTableCell>
            <StyledItemTableCell
              role="cell"
              align="left"
              width="15%"
              data-testid="item-td-compare-with-live"
            ></StyledItemTableCell>
            <StyledItemTableCell
              align="center"
              width="10%"
              role="cell"
            ></StyledItemTableCell>
          </StyledItemTableRow>
        );
      }
      const item = categoryItems[index];
      return (
        <StyledItemTableRow
          key={`${categoryId}-${item.itemId}`}
          data-testid="item-child-tr"
          role="row"
        >
          <StyledItemTableCell width="10%" role="cell" />
          <StyledItemTableCell
            align="left"
            component="th"
            scope="row"
            width="25%"
            indent={true}
            role="cell"
          >
            {item.itemName.length > 70
              ? `${item.itemName.substring(0, 70)}...`
              : item.itemName}
          </StyledItemTableCell>
          <StyledItemTableCell align="left" width="15%" role="cell">
            {item.itemType}
          </StyledItemTableCell>
          <StyledItemTableCell align="left" width="15%" role="cell">
            {item.itemId}
          </StyledItemTableCell>
          <StyledItemTableCell align="left" width="10%" role="cell">
            {item.status}
          </StyledItemTableCell>
          <StyledItemTableCell
            role="cell"
            align="left"
            width="15%"
            data-testid="item-td-compare-with-live"
          >
            <StyledBlueLink
              role="link"
              tabIndex={0}
              href={`/items/${item.itemId}/compare-with-live`}
            >
              Compare With Live
            </StyledBlueLink>
          </StyledItemTableCell>
          <StyledItemTableCell align="center" width="10%" role="cell">
            {!isReaderRole && item.showEdit === "Y" && (
              <>
                <IconButton
                  onClick={() => {
                    navigate(
                      `/items/${item.itemId}/item-marketing-information`
                    );
                  }}
                  role="button"
                >
                  <EditIcon aria-label="edit button" />
                </IconButton>
                <IconButton
                  aria-label="delete button"
                  onClick={() => onClickDelete(item.itemId!)}
                  role="button"
                  data-testid="delete-item-btn"
                  sx={{ color: "#DA291C" }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </>
            )}
            {(isReaderRole || item.showEdit === "N") && (
              <>
                <IconButton
                  onClick={() => {
                    navigate(
                      `/items/${item.itemId}/item-marketing-information`
                    );
                  }}
                  role="button"
                >
                  <SummarizeIcon aria-label="view button" />
                </IconButton>
              </>
            )}
          </StyledItemTableCell>
        </StyledItemTableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryItems, categoryId, isReaderRole]
  );

  return (
    <>
      <Box>
        {categoryItems && (
          <>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="category-items">
                <TableBody>
                  {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                    getRow(page * pageSize + i)
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <StyledActionPanel container>
              <TablePagination
                component="div"
                count={categoryItems.length}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[pageSize]}
                aria-label="Table pagination"
                data-testid="category-items-pagination"
                SelectProps={{
                  MenuProps: {
                    classes: { list: "items-pagination-list" },
                  },
                }}
              />
            </StyledActionPanel>
          </>
        )}
      </Box>
    </>
  );
}

type CategoryRowProps = {
  category: ValidCategory;
  onClickDeleteCategory: (categoryId: number) => void;
  onClickDeleteItem: (itemId: number) => void;
};

function CategoryRow({
  category,
  onClickDeleteCategory,
  onClickDeleteItem,
}: CategoryRowProps) {
  const navigate = useNavigate();
  const { isReaderRole, selectedCountry } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const categoryId = category.categoryId;
  if (typeof categoryId === "undefined") {
    throw new Error("categoryId must be defined!");
  }
  const { getItemsByCategoryId } = useContext(DataContext);
  const categoryItems = getItemsByCategoryId(String(categoryId));
  const isUnassignedCategory = categoryId === 0;
  const [open, setOpen] = useState(false);

  const { data, isFetching } = useCustomQuery(
    [
      "getSelectedItemByCategoryId",
      {
        categoryId,
        countryCode: selectedCountry,
      },
    ],
    () =>
      getSelectedItemByCategoryId(apiClient)({
        countryCode: selectedCountry!,
        categoryId: String(categoryId!),
      }),
    open && !isUnassignedCategory && selectedCountry !== null
  );

  const orderedCategoryItems: ItemWithCategories[] = useMemo(() => {
    if (isUnassignedCategory) {
      return categoryItems;
    } else if (typeof data?.data.dataList !== "undefined") {
      const itemsByDisplayOrder: ItemWithCategories[] = [];
      const itemMappings: ItemByCategoryData[] = formatMappedCategoryItems(
        data?.data.dataList
      );
      for (const item of itemMappings) {
        const categoryItem = categoryItems.find(
          (ci) => ci.itemId === item.itemId
        );
        if (categoryItem) {
          itemsByDisplayOrder.push(categoryItem);
        }
      }
      return itemsByDisplayOrder;
    }
    return [];
  }, [data, categoryItems, isUnassignedCategory]);

  return (
    <>
      <StyledCollapsibleCategoryTableRow
        open={open}
        data-testid="category-parent-tr"
        role="row"
      >
        <StyledCategoryTableCell align="center" width="10%" role="cell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            data-testid="category-parent-expand-collapse"
            role="button"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledCategoryTableCell>
        <StyledCategoryTableCell
          role="cell"
          component="th"
          scope="row"
          width="25%"
          align="left"
        >
          {category.categoryName}
        </StyledCategoryTableCell>
        <StyledCategoryTableCell role="cell" align="left" width="15%">
          {category.categoryType}
        </StyledCategoryTableCell>
        <StyledCategoryTableCell role="cell" align="left" width="15%">
          {!isUnassignedCategory && category.categoryId}
        </StyledCategoryTableCell>
        <StyledCategoryTableCell role="cell" align="left" width="10%">
          {category.status}
        </StyledCategoryTableCell>
        <StyledCategoryTableCell
          role="cell"
          align="left"
          width="15%"
          data-testid="category-td-compare-with-live"
        >
          {!isUnassignedCategory && (
            <StyledBlueLink
              role="link"
              tabIndex={0}
              href={`/categories/${category.categoryId}/compare-with-live`}
            >
              Compare With Live
            </StyledBlueLink>
          )}
        </StyledCategoryTableCell>
        <StyledCategoryTableCell role="cell" align="center" width="10%">
          {!isReaderRole && category.showEdit === "Y" && (
            <>
              <Tooltip title="Edit Category">
                <IconButton
                  onClick={() => {
                    navigate(
                      `/categories/${categoryId}/category-marketing-information`
                    );
                  }}
                  role="button"
                >
                  <EditIcon aria-label="edit button" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Category">
                <IconButton
                  onClick={() => onClickDeleteCategory(category.categoryId!)}
                  sx={{ color: "#DA291C" }}
                  role="button"
                >
                  <DeleteForeverIcon aria-label="delete button" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(isReaderRole || category.showEdit === "N") && (
            <Tooltip title="View Category">
              <IconButton
                onClick={() => {
                  navigate(
                    `/categories/${categoryId}/category-marketing-information`
                  );
                }}
                role="button"
              >
                <SummarizeIcon aria-label="view button" />
              </IconButton>
            </Tooltip>
          )}
        </StyledCategoryTableCell>
      </StyledCollapsibleCategoryTableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7} role="cell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            {isFetching && <Loader />}
            {orderedCategoryItems !== null && (
              <ItemRows
                categoryId={categoryId}
                categoryItems={orderedCategoryItems}
                onClickDelete={(itemId: number) => onClickDeleteItem(itemId)}
              ></ItemRows>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export const Categories = withLayout(() => {
  const navigate = useNavigate();
  const { canEdit } = useContext(RoleContext);
  const { categoryList, categoryListLoading } = useContext(DataContext);
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const [isEditDisplayOrderOn, setIsEditDisplayOrderOn] =
    useState<boolean>(false);
  const [itemToSoftDeleteId, setItemToSoftDeleteId] = useState<number | null>(
    null
  );
  const [isRetrySoftDeleteItemModalOpen, setIsRetrySoftDeleteItemModalOpen] =
    useState<boolean>(false);
  const [categoryToSoftDeleteId, setCategoryToSoftDeleteId] = useState<
    number | null
  >(null);
  const [
    isRetrySoftDeleteCategoryModalOpen,
    setIsRetrySoftDeleteCategoryModalOpen,
  ] = useState<boolean>(false);
  const [deleteReasonMemo, setDeleteReasonMemo] = React.useState<string>("");

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const categories: ValidCategoryWithDisplayOrder[] = useMemo(
    () => formatCategoriesForDisplay(categoryList),
    [categoryList]
  );

  const { mutate: doSoftDeleteItem, isLoading: isSoftDeleteItemLoading } =
    useMutation(
      (data: SoftDeleteItemFormValues) => {
        return softDeleteItem(apiClient)(
          { deleteReason: data.deleteReason },
          {
            itemId: data.itemId,
            countryCode: selectedCountry!,
            roleId: Number(selectedRole!),
          }
        );
      },
      {
        onSuccess: () => {
          setSuccessMessage(`Item was successfully deleted`);
          setItemToSoftDeleteId(null);
          setDeleteReasonMemo("");
          setIsRetrySoftDeleteItemModalOpen(false);
          queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            // expired token - parse out "deleteReason" from submitted form data to allow retry
            const formData = JSON.parse(error.config?.data || {});
            const { deleteReason } = formData;
            setDeleteReasonMemo(
              typeof deleteReason === "string" ? deleteReason : ""
            );
            setIsRetrySoftDeleteItemModalOpen(true);
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            setErrorMessage(String(errorMessage.message));
          } else {
            setErrorMessage(String(error));
          }
        },
      }
    );

  const {
    mutate: doSoftDeleteCategory,
    isLoading: isSoftDeleteCategoryLoading,
  } = useMutation(
    (data: SoftDeleteCategoryFormValues) =>
      softDeleteCategory(apiClient)(
        { deleteReason: data.deleteReason },
        {
          categoryId: data.categoryId,
          countryCode: selectedCountry!,
          roleId: Number(selectedRole!),
        }
      ),
    {
      onSuccess: () => {
        setSuccessMessage(`Category was successfully deleted`);
        setCategoryToSoftDeleteId(null);
        setDeleteReasonMemo("");
        setIsRetrySoftDeleteCategoryModalOpen(false);
        queryClient.invalidateQueries([
          "getAllCategoriesList",
          { selectedCountry },
        ]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          // expired token - parse out "deleteReason" from submitted form data to allow retry
          const formData = JSON.parse(error.config?.data || {});
          const { deleteReason } = formData;
          setDeleteReasonMemo(
            typeof deleteReason === "string" ? deleteReason : ""
          );
          setIsRetrySoftDeleteCategoryModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const handleOpenEdit = useCallback(() => setIsEditDisplayOrderOn(true), []);
  const handleCancelEdit = useCallback(
    () => setIsEditDisplayOrderOn(false),
    []
  );
  const handleSaveDisplayOrderSuccess = useCallback(() => {
    setSuccessMessage(`Category display order was saved successfully`);
    setIsEditDisplayOrderOn(false);
  }, []);

  return (
    <>
      <Stack
        spacing={1}
        marginBottom="20px"
        aria-label="categories list"
        role="group"
      >
        <Grid container justifyContent="space-between">
          <Typography variant="h4">View Categories & Meal Bundle</Typography>
          {canEdit && (
            <AddRowLink
              onClick={() => navigate(`/categories/create-category`)}
              data-testid="add-new-category-button"
            >
              <AddCircle aria-label="add new category button" />
              <Typography>Add New Category</Typography>
            </AddRowLink>
          )}
        </Grid>
      </Stack>
      <StyledRelativeContainer>
        {categoryListLoading ? (
          <Loader />
        ) : (
          <>
            {isEditDisplayOrderOn ? (
              <CategoriesWithEditableDisplayOrderTable
                categories={categories}
                onCancelEdit={handleCancelEdit}
                onSaveSuccess={handleSaveDisplayOrderSuccess}
              />
            ) : (
              <CategoriesWithSortAndSearchTable
                categories={categories}
                onEnableDisplayOrderEdit={handleOpenEdit}
                onClickSoftDeleteCategory={(categoryId: number) =>
                  setCategoryToSoftDeleteId(categoryId)
                }
                onClickSoftDeleteItem={(itemId: number) =>
                  setItemToSoftDeleteId(itemId)
                }
              />
            )}
          </>
        )}
        {typeof categoryToSoftDeleteId === "number" && (
          <ConfirmActionModal
            open={!isRetrySoftDeleteCategoryModalOpen}
            loading={isSoftDeleteCategoryLoading}
            message="Are you sure you want to delete this category?"
            onConfirm={(reason) => {
              doSoftDeleteCategory({
                categoryId: categoryToSoftDeleteId!,
                deleteReason: reason,
              });
            }}
            onCancel={() => setCategoryToSoftDeleteId(null)}
            showReason={true}
          />
        )}
        {typeof itemToSoftDeleteId === "number" && (
          <ConfirmActionModal
            open={!isRetrySoftDeleteItemModalOpen}
            loading={isSoftDeleteItemLoading}
            message="Are you sure you want to delete this item?"
            onConfirm={(reason) => {
              doSoftDeleteItem({
                itemId: itemToSoftDeleteId!,
                deleteReason: reason,
              });
            }}
            onCancel={() => setItemToSoftDeleteId(null)}
            showReason={true}
          />
        )}
        <ResendFormModal
          open={isRetrySoftDeleteCategoryModalOpen}
          onResend={() => {
            setIsRetrySoftDeleteCategoryModalOpen(false);
            if (typeof categoryToSoftDeleteId === "number") {
              doSoftDeleteCategory({
                categoryId: categoryToSoftDeleteId!,
                deleteReason: deleteReasonMemo,
              });
            }
          }}
          onCancel={() => {
            setCategoryToSoftDeleteId(null);
            setIsRetrySoftDeleteCategoryModalOpen(false);
            setDeleteReasonMemo("");
          }}
          description="An error occurred while deleting this category"
        />
        <ResendFormModal
          open={isRetrySoftDeleteItemModalOpen}
          onResend={() => {
            setIsRetrySoftDeleteItemModalOpen(false);
            if (typeof itemToSoftDeleteId === "number") {
              doSoftDeleteItem({
                itemId: itemToSoftDeleteId!,
                deleteReason: deleteReasonMemo,
              });
            }
          }}
          onCancel={() => {
            setItemToSoftDeleteId(null);
            setIsRetrySoftDeleteItemModalOpen(false);
            setDeleteReasonMemo("");
          }}
          description="An error occurred while deleting this item"
        />
        <SuccessAlertSnackbar
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
        />
        <ErrorAlertSnackbar
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      </StyledRelativeContainer>
    </>
  );
}, "Categories");

type CategoriesWithSortAndSearchTableProps = {
  categories: ValidCategoryWithDisplayOrder[];
  onEnableDisplayOrderEdit: () => void;
  onClickSoftDeleteCategory: (categoryId: number) => void;
  onClickSoftDeleteItem: (itemId: number) => void;
};
const CategoriesWithSortAndSearchTable = ({
  categories,
  onEnableDisplayOrderEdit,
  onClickSoftDeleteCategory,
  onClickSoftDeleteItem,
}: CategoriesWithSortAndSearchTableProps) => {
  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<ValidCategoryWithDisplayOrder>("categories", {
    page: 0,
    pageSize: 10,
    sortField: "displayOrder",
    sortOrder: "asc",
    search: "",
  });

  const { isReaderRole } = useContext(RoleContext);

  const filteredAndSortedCategories = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredCategories =
      search.length > 0
        ? categories.filter((category) => {
            return (
              category.categoryName?.toLowerCase().includes(searchLower) ||
              category.categoryId.toString().includes(search)
            );
          })
        : categories;
    if (sortField === "categoryName") {
      return sortByName(filteredCategories, sortOrder);
    } else if (sortField === "categoryId") {
      return sortById(filteredCategories, sortOrder);
    } else if (sortField === "categoryType") {
      return sortByType(filteredCategories, sortOrder);
    } else if (sortField === "status") {
      return sortByStatus(filteredCategories, sortOrder);
    }
    return sortByDisplayOrder(filteredCategories, "asc");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, categories, sortField, sortOrder]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (!filteredAndSortedCategories[index]) {
        return (
          <StyledEmptyTableRow data-testid="category-parent-tr" role="row">
            <TableCell align="center" width="10%" role="cell"></TableCell>
            <TableCell
              role="cell"
              component="th"
              scope="row"
              width="25%"
              align="left"
            ></TableCell>
            <TableCell role="cell" align="left" width="15%"></TableCell>
            <TableCell role="cell" align="left" width="15%"></TableCell>
            <TableCell role="cell" align="left" width="10%"></TableCell>
            <TableCell
              role="cell"
              align="left"
              width="15%"
              data-testid="category-td-compare-with-live"
            ></TableCell>
            <TableCell role="cell" align="center" width="10%"></TableCell>
          </StyledEmptyTableRow>
        );
      }
      const category = filteredAndSortedCategories[index];
      return (
        <CategoryRow
          category={category}
          onClickDeleteCategory={() =>
            onClickSoftDeleteCategory(category.categoryId!)
          }
          onClickDeleteItem={(itemId: number) => onClickSoftDeleteItem(itemId)}
          key={category.categoryId}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredAndSortedCategories]
  );

  return (
    <>
      <StyledTableControls>
        <Grid container justifyContent="flex-end" gap={2}>
          <SearchInput
            value={search}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            aria-label="Search categories"
            testId="category-search"
          />
          {!isReaderRole && (
            <StyledButton
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={onEnableDisplayOrderEdit}
            >
              Edit Display Order
            </StyledButton>
          )}
        </Grid>
      </StyledTableControls>
      <Grid marginTop={3}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" role="table">
            <StyledCategoryTableHead>
              <TableRow sx={{ borderBottom: "unset" }} role="row">
                <TableCell></TableCell>
                <TableCell role="columnheader" align="left">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="categoryName"
                    label="Name"
                    aria-label="Sort by name"
                    testId="sort-by-category-name"
                  />
                </TableCell>
                <TableCell role="columnheader" align="left">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="categoryType"
                    label="Type"
                    aria-label="Sort by category type"
                    testId="sort-by-category-type"
                  />
                </TableCell>
                <TableCell role="columnheader" align="left">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="categoryId"
                    label="Id"
                    aria-label="Sort by id"
                    testId="sort-by-category-id"
                  />
                </TableCell>
                <TableCell role="columnheader" align="left">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="status"
                    label="Status"
                    aria-label="Sort by status"
                    testId="sort-by-category-status"
                  />
                </TableCell>
                <TableCell role="columnheader" align="left">
                  Compare With Live
                </TableCell>
                <TableCell role="columnheader" align="center">
                  Action
                </TableCell>
              </TableRow>
            </StyledCategoryTableHead>
            <TableBody>
              {filteredAndSortedCategories.length > 0 ? (
                Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )
              ) : (
                <EmptyState>No records found</EmptyState>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={filteredAndSortedCategories.length}
            onPageChange={handlePageChange}
            page={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={pageSize}
            aria-label="Table pagination"
            rowsPerPageOptions={[5, 10, 25]}
            data-testid="categories-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "categories-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>
      </Grid>
    </>
  );
};

type CategoriesWithEditableDisplayOrderTableProps = {
  categories: ValidCategoryWithDisplayOrder[];
  onCancelEdit: () => void;
  onSaveSuccess: () => void;
};

const CategoriesWithEditableDisplayOrderTable = ({
  categories,
  onCancelEdit,
  onSaveSuccess,
}: CategoriesWithEditableDisplayOrderTableProps) => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { isReaderRole, selectedCountry, selectedRole } =
    useContext(RoleContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const assignedCategoriesByDisplayOrder = useMemo(() => {
    return sortByDisplayOrder(
      categories.filter((category) => category.categoryName !== "Unassigned"),
      "asc"
    );
  }, [categories]);

  const {
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<{
    elementList: ValidCategoryWithDisplayOrder[];
  }>({
    defaultValues: {
      elementList: assignedCategoriesByDisplayOrder,
    },
  });
  const categoriesWithEditableDisplayOrder = watch("elementList");

  const saveElementDisplayOrderRequest = saveElementDisplayOrder(apiClient);
  const { mutate: doSaveDisplayOrder, isLoading: isDisplayOrderSaving } =
    useMutation(
      () => {
        return saveElementDisplayOrderRequest(
          {
            elementList: categoriesWithEditableDisplayOrder.map(
              (category, index) => ({
                displayOrder: index,
                elementId: category.categoryId,
              })
            ),
          },
          {
            countryCode: selectedCountry!,
            roleId: selectedRole!,
            elementType: "Category",
          }
        );
      },
      {
        onMutate: () => saveElementDisplayOrderRequest,
        onSuccess: () => {
          queryClient.invalidateQueries([
            "getAllCategoriesList",
            { selectedCountry },
          ]);
          onSaveSuccess();
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            setIsResendModalOpen(true);
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            setErrorMessage(String(errorMessage.message));
          } else {
            setErrorMessage(String(error));
          }
        },
      }
    );

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    []
  );
  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const handleMoveItem = useCallback(
    (index: number, direction: string) => {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      const updatedProducts = [...categoriesWithEditableDisplayOrder];
      [updatedProducts[index], updatedProducts[newIndex]] = [
        updatedProducts[newIndex],
        updatedProducts[index],
      ];
      setValue("elementList", updatedProducts, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoriesWithEditableDisplayOrder]
  );

  const handleMoveToTop = useCallback(
    (index: number) => {
      const updatedProducts = [...categoriesWithEditableDisplayOrder];
      const [productToMove] = updatedProducts.splice(index, 1);
      updatedProducts.unshift(productToMove);
      setValue("elementList", updatedProducts, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoriesWithEditableDisplayOrder]
  );

  const handleRetrySaveDisplayOrder = useCallback(() => {
    setIsResendModalOpen(false);
    doSaveDisplayOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelRetrySaveDisplayOrder = useCallback(
    () => setIsResendModalOpen(false),
    []
  );

  const getEmptyRow = useCallback((index: number) => {
    return (
      <StyledEmptyTableRow
        data-testid="category-by-display-order-parent-tr"
        role="row"
      >
        <TableCell
          role="cell"
          component="th"
          scope="row"
          width="30%"
          align="left"
        ></TableCell>
        <TableCell role="cell" align="left" width="15%"></TableCell>
        <TableCell role="cell" align="left" width="15%"></TableCell>
        <TableCell role="cell" align="left" width="10%"></TableCell>
        <TableCell
          role="cell"
          align="center"
          width="15%"
          data-testid="category-by-display-order-td-display-order"
        ></TableCell>
        <TableCell
          role="cell"
          align="center"
          width="15%"
          data-testid="category-by-display-order-td-action"
        ></TableCell>
      </StyledEmptyTableRow>
    );
  }, []);

  const getRow = useCallback(
    (index: number) => {
      if (
        !categoriesWithEditableDisplayOrder ||
        !categoriesWithEditableDisplayOrder[index]
      ) {
        return getEmptyRow(index);
      }
      const category = categoriesWithEditableDisplayOrder[index];
      const isUnassignedCategory = category.categoryId === 0;
      return (
        <StyledCategoryTableRow
          data-testid="category-by-display-order-parent-tr"
          role="row"
        >
          <StyledCategoryTableCell
            role="cell"
            component="th"
            scope="row"
            width="30%"
            align="left"
          >
            {category.categoryName}
          </StyledCategoryTableCell>
          <StyledCategoryTableCell role="cell" align="left" width="15%">
            {category.categoryType}
          </StyledCategoryTableCell>
          <StyledCategoryTableCell role="cell" align="left" width="15%">
            {!isUnassignedCategory && category.categoryId}
          </StyledCategoryTableCell>
          <StyledCategoryTableCell role="cell" align="left" width="10%">
            {category.status}
          </StyledCategoryTableCell>
          <StyledCategoryTableCell role="cell" align="center" width="15%">
            {category.displayOrder}
          </StyledCategoryTableCell>
          <StyledCategoryTableCell
            role="cell"
            align="center"
            width="15%"
            data-testid="category-by-display-order-td-action"
          >
            {index !== 0 && (
              <IconButton
                color="primary"
                onClick={() => handleMoveItem(index, "up")}
                aria-label={`Move ${category.categoryName} up`}
              >
                <ArrowUpward />
              </IconButton>
            )}
            {index !== categoriesWithEditableDisplayOrder.length - 1 && (
              <IconButton
                color="primary"
                onClick={() => handleMoveItem(index, "down")}
                aria-label={`Move ${category.categoryName} down`}
              >
                <ArrowDownward />
              </IconButton>
            )}
            {index !== 0 && (
              <Tooltip title="Move to Top">
                <IconButton
                  color="primary"
                  onClick={() => handleMoveToTop(index)}
                  aria-label={`Move ${category.categoryName} to top`}
                  style={{ transform: "rotate(180deg)" }}
                >
                  <LowPriority />
                </IconButton>
              </Tooltip>
            )}
          </StyledCategoryTableCell>
        </StyledCategoryTableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoriesWithEditableDisplayOrder, isReaderRole]
  );
  return (
    <StyledRelativeContainer>
      <StyledTableControls>
        <Grid container justifyContent="flex-end" gap={2}>
          <StyledButton
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={() => doSaveDisplayOrder()}
            disabled={!isDirty || isDisplayOrderSaving}
          >
            Save Display Order
          </StyledButton>
          <Tooltip title="Cancel edit display order">
            <IconButton
              onClick={onCancelEdit}
              aria-label="Cancel edit display order"
              disabled={isDisplayOrderSaving}
            >
              <Cancel color="error" />
            </IconButton>
          </Tooltip>
        </Grid>
      </StyledTableControls>
      {isDisplayOrderSaving && <Loader />}
      <Grid marginTop={3}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" role="table">
            <StyledCategoryTableHead>
              <TableRow sx={{ borderBottom: "unset" }} role="row">
                <TableCell role="columnheader" align="left">
                  Name
                </TableCell>
                <TableCell role="columnheader" align="left">
                  Type
                </TableCell>
                <TableCell role="columnheader" align="left">
                  Id
                </TableCell>
                <TableCell role="columnheader" align="left">
                  Status
                </TableCell>
                <TableCell role="columnheader" align="center">
                  Display Order
                </TableCell>
                <TableCell role="columnheader" align="center">
                  Action
                </TableCell>
              </TableRow>
            </StyledCategoryTableHead>
            <TableBody>
              {categoriesWithEditableDisplayOrder.length > 0 ? (
                Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )
              ) : (
                <EmptyState>No records found</EmptyState>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={categoriesWithEditableDisplayOrder.length}
            onPageChange={handlePageChange}
            page={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={pageSize}
            aria-label="Table pagination"
            rowsPerPageOptions={[5, 10, 25]}
            data-testid="categories-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "categories-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>
      </Grid>
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={handleRetrySaveDisplayOrder}
        onCancel={handleCancelRetrySaveDisplayOrder}
        description={"An error occurred saving the product display order."}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  margin: 0,
  position: "relative",
  justifyContent: "center",
});
const StyledCategoryTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledCategoryTableRow = styled(TableRow)({
  height: 48,
  borderBottom: "unset",
  backgroundColor: "white",
});
const StyledCollapsibleCategoryTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "open",
})<TableRowProps & { open: boolean }>(({ open, theme }) => ({
  height: 48,
  borderBottom: "unset",
  backgroundColor: "white",
  ...(open && {
    backgroundColor: theme.palette.secondary.light,
  }),
}));
const StyledEmptyTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledBlueLink = styled(Link)(({ theme }) => ({
  color: theme.typography.button.color,
  textDecorationColor: theme.typography.button.color,
  fontSize: theme.typography.small.fontSize,
}));
const StyledCategoryTableCell = styled(TableCell)({
  borderBottom: "unset",
});
const StyledItemTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  height: 48,
}));
const StyledItemTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "indent",
})<TableCellProps & { indent?: boolean }>(({ indent }) => ({
  ...(!!indent && {
    paddingLeft: 32,
  }),
}));
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});
const StyledTableControls = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingRight: 0,
  paddingBottom: theme.spacing(1),
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));