import { AxiosHeaders } from "axios";
import categories from "./json/categories.json";
import {
  realGetAllCategoriesList,
  realGetArchiveCategory,
  realGetCategories,
  realGetCategoryMarketingInformation,
  realGetCategoryReviewSubmit,
  realGetCompareCurrent,
  realGetCompareLive,
  realGetSelectedItemByCategoryId,
  realHardDeleteCategory,
  realRestoreCategory,
  realSaveCategoryMarketingInformation,
  realSaveCategoryReviewSubmit,
  realSaveSelectedItemByCategoryId,
  realSoftDeleteCategory,
} from "../api/categories";
import { components } from "../categories.types";
import { delay } from ".";

export const mockGetCategories: ReturnType<typeof realGetCategories> = async ({
  countryCode = "US",
  languageCode = "EN",
  category_type_id = undefined,
}) => {
  const allCategories = getCategories();
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      CategoriesData: allCategories,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetCategoryMarketingInformation: ReturnType<
  typeof realGetCategoryMarketingInformation
> = async ({ countryCode = "US", languageCode = "EN", categoryId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      attributeList: [
        {
          id: 1,
          keyName: "category_type",
          name: "Category Type",
          value: "Core Category",
        },
        {
          id: 2,
          keyName: "category_name",
          name: "Category Name",
          value: "Desserts & Shakes",
        },
        {
          id: 3,
          keyName: "category_marketing_name",
          name: "Category Marketing Name",
          value: "Desserts & Shakes",
        },
        {
          id: 5,
          keyName: "category_id",
          name: "Category ID",
          value: "100007",
        },
        {
          id: 57,
          keyName: "do_not_show",
          name: "Do Not Show",
          value: "Core",
        },
        {
          id: 4,
          keyName: "category_description",
          name: "Category Description",
          value: "",
        },
        {
          id: 10,
          keyName: "category_comments",
          name: "Category Comments",
          value: "",
        },
        {
          id: 13,
          keyName: "category_keywords",
          name: "Category Keywords",
          value: "",
        },
      ],
      dropdownList: {
        do_not_show: [
          {
            value: "Core",
            id: 314,
          },
          {
            value: "Promotional",
            id: 315,
          },
          {
            value: "Do not Show",
            id: 316,
          },
        ],
        category_type: [
          {
            value: "Core Category",
            id: 1,
          },
          {
            value: "Promotional Category",
            id: 2,
          },
          {
            value: "Set Category",
            id: 3,
          },
          {
            value: "Value Category Bundle",
            id: 4,
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetAllCategoriesList: ReturnType<
  typeof realGetAllCategoriesList
> = async ({ countryCode = "US", languageCode = "EN", roleId = 2 }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      dataList: [
        {
          categoryId: 100000,
          categoryName: "Burgers",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 1,
        },
        {
          categoryId: 100002,
          categoryName: "Breakfast",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 2,
        },
        {
          categoryId: 100003,
          categoryName: "Salads",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 3,
        },
        {
          categoryId: 100004,
          categoryName: "Snacks & Sides",
          status: "Delete",
          categoryType: "Core Category",
          showEdit: "N",
          displayOrder: 4,
        },
        {
          categoryId: 100005,
          categoryName: "Beverages",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 5,
        },
        {
          categoryId: 100006,
          categoryName: "McCafé",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 6,
        },
        {
          categoryId: 100007,
          categoryName: "Desserts & Shakes",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 7,
        },
        {
          categoryId: 100008,
          categoryName: "Condiments",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 7,
        },
        {
          categoryId: 100009,
          categoryName: "Dollar Menu",
          status: "Active",
          categoryType: "Set Category",
          showEdit: "Y",
          displayOrder: 8,
        },
        {
          categoryId: 100010,
          categoryName: "Meal Predefined",
          status: "Active",
          categoryType: "Value Category Bundle",
          showEdit: "Y",
          displayOrder: 9,
        },
        {
          categoryId: 100011,
          categoryName: "Mighty Kids Meals",
          status: "Active",
          categoryType: "Value Category Bundle",
          showEdit: "Y",
          displayOrder: 10,
        },
        {
          categoryId: 100012,
          categoryName: "Happy Meals",
          status: "Active",
          categoryType: "Value Category Bundle",
          showEdit: "Y",
          displayOrder: 11,
        },
        {
          categoryId: 100013,
          categoryName: "Extra Value Meals",
          status: "Active",
          categoryType: "Value Category Bundle",
          showEdit: "Y",
          displayOrder: 12,
        },
        {
          categoryId: 100014,
          categoryName: "Favorites Under 400",
          status: "Active",
          categoryType: "Value Category Bundle",
          showEdit: "Y",
          displayOrder: 13,
        },
        {
          categoryId: 100015,
          categoryName: "All Day Breakfast",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 14,
        },
        {
          categoryId: 100016,
          categoryName: "McPick 2",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 15,
        },
        {
          categoryId: 100017,
          categoryName: "Pick 2",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 16,
        },
        {
          categoryId: 100018,
          categoryName: "ADB Biscuit",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 17,
        },
        {
          categoryId: 100019,
          categoryName: "ADB Muffin",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 18,
        },
        {
          categoryId: 100020,
          categoryName: "Happy Meal - Protein",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 19,
        },
        {
          categoryId: 100021,
          categoryName: "Happy Meal - Side",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 20,
        },
        {
          categoryId: 100022,
          categoryName: "Happy Meal - Drinks",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 21,
        },
        {
          categoryId: 100023,
          categoryName: "Happy Meal - Drinks",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 22,
        },
        {
          categoryId: 100024,
          categoryName: "Mighty Kids Meal - Protein ",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 23,
        },
        {
          categoryId: 100025,
          categoryName: "Mighty Kids Meal - Drinks",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 24,
        },
        {
          categoryId: 100026,
          categoryName: "Mighty Kids Meal - Sides",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 25,
        },
        {
          categoryId: 100027,
          categoryName: "All Day Breakfast - McMuffin Menu",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 26,
        },
        {
          categoryId: 100028,
          categoryName: "EVM - Breakfast",
          status: "Active",
          categoryType: "Value Category Bundle",
          showEdit: "Y",
          displayOrder: 27,
        },
        {
          categoryId: 100029,
          categoryName: "EVM - Rest of Day",
          status: "Active",
          categoryType: "Value Category Bundle",
          showEdit: "Y",
          displayOrder: 28,
        },
        {
          categoryId: 100031,
          categoryName: "McPick 2 0816",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 29,
        },
        {
          categoryId: 100032,
          categoryName: "Happy Meal",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 30,
        },
        {
          categoryId: 100033,
          categoryName: "Signature Crafted",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 31,
        },
        {
          categoryId: 100034,
          categoryName: "McPick 2 2017",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 32,
        },
        {
          categoryId: 100035,
          categoryName: "McPick 2 2017 Row 2",
          status: "Add",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 33,
        },
        {
          categoryId: 100036,
          categoryName: "McCafé® Bakery",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 34,
        },
        {
          categoryId: 100037,
          categoryName: "Meals-Change Sides",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 35,
        },
        {
          categoryId: 100038,
          categoryName: "Meals-Change Drinks",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 36,
        },
        {
          categoryId: 100039,
          categoryName: "Full Menu Items",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 37,
        },
        {
          categoryId: 100040,
          categoryName: "AM D123 Menu",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 38,
        },
        {
          categoryId: 100041,
          categoryName: "PM D123 Menu",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 39,
        },
        {
          categoryId: 100042,
          categoryName: "D123 Menu",
          status: "Active",
          categoryType: "Promotional Category",
          showEdit: "Y",
          displayOrder: 40,
        },
        {
          categoryId: 100043,
          categoryName: "Menu Hacks",
          status: "Active",
          categoryType: "Core Category",
          showEdit: "Y",
          displayOrder: 41,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetSelectedItemByCategoryId: ReturnType<
  typeof realGetSelectedItemByCategoryId
> = async ({ countryCode = "US", languageCode = "EN", categoryId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      categoryName: "Beverages",
      dataList: [
        {
          itemId: 200611,
          itemName: "Coca-Cola(Small)",
          displayOrder: 1,
        },
        {
          itemId: 200615,
          itemName: "Sprite(Small)",
          displayOrder: 2,
        },
        {
          itemId: 200613,
          itemName: "Dr Pepper(Small)",
          displayOrder: 3,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

function getCategories(): components["schemas"]["CategoriesData"][] {
  return categories.map((category) => {
    return {
      categoryId: category.id,
      categoryName: category.name,
    };
  });
}
export const mockSaveCategoryMarketingInformation: ReturnType<
  typeof realSaveCategoryMarketingInformation
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
      categoryId: 200734,
      attributeList: [
        {
          id: 15,
          keyName: "item_type",
          name: "Item Type",
          value: "Item Collection",
        },
        {
          id: 16,
          keyName: "time_of_day",
          name: "Time Of Day",
          value: "Whole Day",
        },
        {
          id: 17,
          keyName: "item_name",
          name: "Item Name",
          value: "10 piece Chicken McNuggets® Meal",
        },
        {
          id: 18,
          keyName: "item_marketing_name",
          name: "Item Marketing Name",
          value: "10 piece Chicken McNuggets® Meal",
        },
        {
          id: 439,
          keyName: "short_name",
          name: "Short Name",
          value: "chicken-mcnuggets-10-piece-meal",
        },
        {
          id: 201,
          keyName: "genesis_item_name",
          name: "Genesis Item Name",
          value: "",
        },
        {
          id: 21,
          keyName: "item_id",
          name: "Item ID",
          value: "200734",
        },
        {
          id: 22,
          keyName: "menu_item_no",
          name: "RFM Id / Menu Item No / External Id / Import Id",
          value: "200734",
        },
        {
          id: 203,
          keyName: "genesis_menu_item_no",
          name: "Genesis Menu Item No.",
          value: "",
        },
        {
          id: 58,
          keyName: "do_not_show",
          name: "Do Not Show",
          value: "Core",
        },
        {
          id: 200,
          keyName: "serves",
          name: "Serves",
          value: "",
        },
        {
          id: 19,
          keyName: "item_name_special_symbols",
          name: "Item Name Special Symbols",
          value: "",
        },
        {
          id: 20,
          keyName: "description",
          name: "Description",
          value:
            'The McDonald\'s 10 piece Chicken McNuggets® Combo Meal features 10 tender and delicious Chicken McNuggets® made with all white meat chicken—plus our World Famous Fries® and your choice of a Medium McDonald’s drink. There are 950 calories in a Chicken McNuggets® Combo Meal with medium Fries and a medium Coca-Cola®. Get it today when you <a href="https://www.mcdonalds.com/us/en-us/mobile-order-and-pay.html">Mobile Order & Pay</a> or get it delivered with <a href="https://www.mcdonalds.com/us/en-us/mcdelivery.html">McDelivery®</a>.',
        },
        {
          id: 31,
          keyName: "item_comments",
          name: "Item Comments",
          value: "",
        },
        {
          id: 28,
          keyName: "keywords",
          name: "Keywords",
          value: "10 piece mcnuggets, mcnuggets meal",
        },
        {
          id: 60,
          keyName: "item_ingredient_statement",
          name: "Ingredient Statement",
          value: "",
        },
        {
          id: 12,
          keyName: "item_additional_text_ingredient_statement",
          name: "Additional Text For Ingredient Statement",
          value: "",
        },
        {
          id: 40,
          keyName: "item_allergen",
          name: "Item Allergen",
          value: "",
        },
        {
          id: 30,
          keyName: "item_additional_allergen",
          name: "Additional Allergen",
          value: "",
        },
        {
          id: 26,
          keyName: "text",
          name: "Nutrition Quality Statement Text",
          value: "",
        },
        {
          id: 627,
          keyName: "item_meta_title",
          name: "Item Meta Title",
          value: "10 Piece Chicken McNuggets® Meal | McDonald's",
        },
        {
          id: 633,
          keyName: "item_meta_description",
          name: "Item Meta Description",
          value:
            "Enjoy crispy Chicken McNuggets® made with all white-meat chicken in our 10 pc McNuggets Combo Meal with fries and a drink-- get it today with McDelivery®!",
        },
        {
          id: 628,
          keyName: "customization_label",
          name: "Customization Label",
          value: "",
        },
        {
          id: 629,
          keyName: "customization_button",
          name: "Customization Button",
          value: "",
        },
        {
          id: 630,
          keyName: "show_product_images",
          name: "Show Product Images?",
          value: "No",
        },
        {
          id: 1564,
          keyName: "logo_1",
          name: "Logo 1",
          value: "No",
        },
        {
          id: 1565,
          keyName: "logo_2",
          name: "Logo 2",
          value: "No",
        },
        {
          id: 1566,
          keyName: "logo_3",
          name: "Logo 3",
          value: "No",
        },
        {
          id: 1631,
          keyName: "item_status_flag",
          name: "Status Flag",
          value: "None",
        },
        {
          id: 631,
          keyName: "specialization_text1",
          name: "Specialization Text 1",
          value: "",
        },
        {
          id: 632,
          keyName: "specialization_text2",
          name: "Specialization Text 2",
          value: "",
        },
        {
          id: 1437,
          keyName: "sitemap",
          name: "SiteMap",
          value: "No",
        },
        {
          id: 1438,
          keyName: "frequency",
          name: "Frequency",
          value: "",
        },
        {
          id: 1439,
          keyName: "priority",
          name: "Priority",
          value: "",
        },
        {
          id: 33,
          keyName: "attach_item_thumbnail_image",
          name: "Thumbnail Image",
          value: "",
        },
        {
          id: 34,
          keyName: "attach_item_hero_image",
          name: "Hero Image",
          value: "h-mcdonalds-Chicken-McNuggets-10-piece-Extra-Value-Meals.jpg",
        },
        {
          id: 634,
          keyName: "attach_transparent_icon_image",
          name: "Transparent Icon Image",
          value: "",
        },
        {
          id: 38,
          keyName: "attach_meal_bundle_image",
          name: "Meal Bundle Image",
          value: "",
        },
        {
          id: 23,
          keyName: "has_components",
          name: "Has Components",
          value: "No",
        },
        {
          id: 41,
          keyName: "footer",
          name: "Footer",
          value: "Footer",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveSelectedItemByCategoryId: ReturnType<
  typeof realSaveSelectedItemByCategoryId
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
      categoryId: 100007,
      dataList: [
        {
          itemId: 200101,
          itemName: "McFlurry with Oreo Cookies ",
          displayOrder: 1,
        },
        {
          itemId: 203754,
          itemName: "Chips Ahoy! McFlurry (Regular)",
          displayOrder: 2,
        },
        {
          itemId: 200096,
          itemName: "McFlurry with M&M'S Chocolate Candies",
          displayOrder: 2,
        },
        {
          itemId: 203678,
          itemName: "Chips Ahoy! McFlurry (Snack Size)",
          displayOrder: 4,
        },
        {
          itemId: 200062,
          itemName: "Vanilla Cone ",
          displayOrder: 5,
        },
        {
          itemId: 200107,
          itemName: "Chocolate Shake (Small) ",
          displayOrder: 6,
        },
        {
          itemId: 200108,
          itemName: "Vanilla Shake (Small) ",
          displayOrder: 7,
        },
        {
          itemId: 200109,
          itemName: "Strawberry Shake (Small) ",
          displayOrder: 8,
        },
        {
          itemId: 200074,
          itemName: "Hot Fudge Sundae ",
          displayOrder: 9,
        },
        {
          itemId: 200081,
          itemName: "Hot Caramel Sundae ",
          displayOrder: 10,
        },
        {
          itemId: 200007,
          itemName: "Baked Hot Apple Pie ",
          displayOrder: 11,
        },
        {
          itemId: 200009,
          itemName: "Chocolate Chip Cookie ",
          displayOrder: 12,
        },
        {
          itemId: 203671,
          itemName: "Snickerdoodle McFlurry (Regular)",
          displayOrder: 13,
        },
        {
          itemId: 203471,
          itemName: "Netherland's McFlurry Stroopwafel (Regular)",
          displayOrder: 14,
        },
        {
          itemId: 203470,
          itemName: "Netherland's McFlurry Stroopwafel (Snack Size)",
          displayOrder: 15,
        },
        {
          itemId: 203184,
          itemName: "Hot Caramel Sundae Compostable Packaging",
          displayOrder: 16,
        },
        {
          itemId: 200635,
          itemName: "Shamrock Shake (Medium)",
          displayOrder: 17,
        },
        {
          itemId: 200636,
          itemName: "Shamrock Shake (Large)",
          displayOrder: 18,
        },
        {
          itemId: 200144,
          itemName: "Strawberry & Crème Pie ",
          displayOrder: 19,
        },
        {
          itemId: 200013,
          itemName: "Oatmeal Raisin Cookie ",
          displayOrder: 20,
        },
        {
          itemId: 200052,
          itemName: "Chocolate Shake (Medium) ",
          displayOrder: 21,
        },
        {
          itemId: 201115,
          itemName: "Fried Banana Pie",
          displayOrder: 22,
        },
        {
          itemId: 201123,
          itemName: "Chocolate Dipped Cone (Large)",
          displayOrder: 23,
        },
        {
          itemId: 201133,
          itemName: "Chocolate Ice Cream (1 scoop)",
          displayOrder: 24,
        },
        {
          itemId: 200058,
          itemName: "Chocolate Shake (Large) ",
          displayOrder: 25,
        },
        {
          itemId: 200621,
          itemName: "Strawberry Shake (Medium) ",
          displayOrder: 26,
        },
        {
          itemId: 200622,
          itemName: "Strawberry Shake (Large) ",
          displayOrder: 27,
        },
        {
          itemId: 200623,
          itemName: "Vanilla Shake (Medium) ",
          displayOrder: 28,
        },
        {
          itemId: 200624,
          itemName: "Vanilla Shake (Large) ",
          displayOrder: 29,
        },
        {
          itemId: 200329,
          itemName: "Chocolate Dipped Cone",
          displayOrder: 30,
        },
        {
          itemId: 200505,
          itemName: "Caramel Apple Sundae",
          displayOrder: 31,
        },
        {
          itemId: 200670,
          itemName: "McFlurry with Reese's Peanut Butter Cup",
          displayOrder: 32,
        },
        {
          itemId: 200163,
          itemName: "Baked Cherry Pie †",
          displayOrder: 33,
        },
        {
          itemId: 200375,
          itemName: "Baked Holiday Pie",
          displayOrder: 34,
        },
        {
          itemId: 200470,
          itemName: "Baked Pumpkin Pie ",
          displayOrder: 35,
        },
        {
          itemId: 200357,
          itemName: "Baked Sweet Potato Pie †",
          displayOrder: 36,
        },
        {
          itemId: 200263,
          itemName: "Sugar Cookie ",
          displayOrder: 37,
        },
        {
          itemId: 200618,
          itemName: "McFlurry with M&M'S Chocolate Candies (Snack Size)",
          displayOrder: 38,
        },
        {
          itemId: 200619,
          itemName: "McFlurry with Oreo Cookies (Snack Size)",
          displayOrder: 39,
        },
        {
          itemId: 200041,
          itemName: "Barq's® Root Beer Float (16 fl oz cup) †",
          displayOrder: 40,
        },
        {
          itemId: 200042,
          itemName: "Barq's® Root Beer Float (21 fl oz cup) †",
          displayOrder: 41,
        },
        {
          itemId: 200051,
          itemName: "White Buttermints †",
          displayOrder: 42,
        },
        {
          itemId: 200071,
          itemName: "Fried Apple Pie ",
          displayOrder: 43,
        },
        {
          itemId: 200112,
          itemName: "Barq's® Root Beer Float (32 fl oz cup) †",
          displayOrder: 44,
        },
        {
          itemId: 200310,
          itemName: "Ronald Birthday Cake Chocolate ",
          displayOrder: 45,
        },
        {
          itemId: 200320,
          itemName: "Ronald Birthday Cake White ",
          displayOrder: 46,
        },
        {
          itemId: 200456,
          itemName: "Apple Pie Ala Mode ",
          displayOrder: 47,
        },
        {
          itemId: 200468,
          itemName: "Peppermint Hot Chocolate with Nonfat Milk (Medium)",
          displayOrder: 48,
        },
        {
          itemId: 200638,
          itemName: "McFlurry with Reese's Peanut Butter Cup (Snack Size)",
          displayOrder: 49,
        },
        {
          itemId: 200774,
          itemName: "Fried Cherry Pie",
          displayOrder: 50,
        },
        {
          itemId: 200829,
          itemName: "Sweet Potato and Creme Pie",
          displayOrder: 51,
        },
        {
          itemId: 200830,
          itemName: "Pumpkin and Creme Pie",
          displayOrder: 52,
        },
        {
          itemId: 201913,
          itemName: "Chocolate Peanut Butter Ice Cream (1 scoop)",
          displayOrder: 53,
        },
        {
          itemId: 201910,
          itemName: "Boston Cooler Float (Medium)",
          displayOrder: 54,
        },
        {
          itemId: 201881,
          itemName: "Reese's Peanut Butter Cup Candy Pieces",
          displayOrder: 55,
        },
        {
          itemId: 201674,
          itemName: "Oregon Strawberry Ice Cream (1 scoop no cone)",
          displayOrder: 56,
        },
        {
          itemId: 201670,
          itemName: "Coffee Almond Fudge Ice Cream (1 scoop)",
          displayOrder: 57,
        },
        {
          itemId: 201668,
          itemName: "Twist Cone (Large)",
          displayOrder: 58,
        },
        {
          itemId: 201665,
          itemName: "Vanilla Bean Ice Cream (2 scoops)",
          displayOrder: 59,
        },
        {
          itemId: 201642,
          itemName: "Hot Caramel Topping",
          displayOrder: 60,
        },
        {
          itemId: 201634,
          itemName: "Vanilla Cone (Large)",
          displayOrder: 61,
        },
        {
          itemId: 201633,
          itemName: "Mint Chocolate Chip Ice Cream (2 scoops)",
          displayOrder: 62,
        },
        {
          itemId: 201610,
          itemName: "Guava and Creme Pie",
          displayOrder: 63,
        },
        {
          itemId: 201604,
          itemName: "Caramel Butter Pecan Ice Cream (1 scoop)",
          displayOrder: 64,
        },
        {
          itemId: 201601,
          itemName: "McFlurry made with Kit Kat (Snack Size)",
          displayOrder: 65,
        },
        {
          itemId: 201575,
          itemName: "Chocolate Cone ",
          displayOrder: 66,
        },
        {
          itemId: 201524,
          itemName: "Fried Taro Pie",
          displayOrder: 67,
        },
        {
          itemId: 201513,
          itemName: "Vanilla Bean Ice Cream (1 scoop)",
          displayOrder: 68,
        },
        {
          itemId: 201487,
          itemName: "Orange Sherbet (1 scoop)",
          displayOrder: 69,
        },
        {
          itemId: 201480,
          itemName: "Peach Fried Pie",
          displayOrder: 70,
        },
        {
          itemId: 201426,
          itemName: "Caramel Butter Pecan Ice Cream (2 scoops)",
          displayOrder: 71,
        },
        {
          itemId: 201354,
          itemName: "Oregon Strawberry Ice Cream (2 scoops)",
          displayOrder: 72,
        },
        {
          itemId: 201322,
          itemName: "Udderly Chocolate Ice Cream (1 scoop)",
          displayOrder: 73,
        },
        {
          itemId: 201275,
          itemName: "Plain Sundae",
          displayOrder: 74,
        },
        {
          itemId: 201224,
          itemName: "Cookie Dough Ice Cream (1 scoop)",
          displayOrder: 75,
        },
        {
          itemId: 201194,
          itemName: "Chocolate Cone (Large)",
          displayOrder: 76,
        },
        {
          itemId: 201059,
          itemName: "Udderly Chocolate Ice Cream (2 scoops)",
          displayOrder: 77,
        },
        {
          itemId: 201036,
          itemName: "Fried Coconut (Haupia) Pie",
          displayOrder: 78,
        },
        {
          itemId: 200997,
          itemName: "Mint Chocolate Chip Ice Cream (1 scoop)",
          displayOrder: 79,
        },
        {
          itemId: 200964,
          itemName: "Fried Blueberry Pie",
          displayOrder: 80,
        },
        {
          itemId: 200900,
          itemName: "Orange Sherbet (2 scoops)",
          displayOrder: 81,
        },
        {
          itemId: 200926,
          itemName: "Twist Cone ",
          displayOrder: 82,
        },
        {
          itemId: 202212,
          itemName: "Cookie Dough Ice Cream (1 scoop)  ",
          displayOrder: 83,
        },
        {
          itemId: 202592,
          itemName: "Fried Guava Pie",
          displayOrder: 84,
        },
        {
          itemId: 202288,
          itemName: "Cherry and Creme Pie",
          displayOrder: 85,
        },
        {
          itemId: 202612,
          itemName: "Mixed Berry and Lemon Creme Pie",
          displayOrder: 86,
        },
        {
          itemId: 203078,
          itemName: "Oreo McFlurry Sandwich",
          displayOrder: 87,
        },
        {
          itemId: 203079,
          itemName: "M&M McFlurry Sandwich",
          displayOrder: 88,
        },
        {
          itemId: 202706,
          itemName: "Chocolate Dipped Twist Ice Cream Cone",
          displayOrder: 89,
        },
        {
          itemId: 202705,
          itemName: "Chocolate Dipped Chocolate Ice Cream Cone (Large)",
          displayOrder: 90,
        },
        {
          itemId: 202707,
          itemName: "Chocolate Dipped Chocolate Ice Cream Cone",
          displayOrder: 91,
        },
        {
          itemId: 202708,
          itemName: "Chocolate Dipped Twist Ice Cream Cone",
          displayOrder: 92,
        },
        {
          itemId: 202147,
          itemName: "Birthday Cake Dipped Cone",
          displayOrder: 93,
        },
        {
          itemId: 202305,
          itemName: "Caramel Sundae Waffle Cone",
          displayOrder: 94,
        },
        {
          itemId: 202452,
          itemName: "Hot Fudge Sundae Compostable Packaging",
          displayOrder: 95,
        },
        {
          itemId: 203071,
          itemName: "Baked Cherry and Creme Pie",
          displayOrder: 96,
        },
        {
          itemId: 203670,
          itemName: "Snickerdoodle McFlurry (Snack Size)",
          displayOrder: 97,
        },
        {
          itemId: 203751,
          itemName: "Oreo Shamrock Mcflurry (Snack Size) ",
          displayOrder: 98,
        },
        {
          itemId: 203688,
          itemName: "Caramel Brownie McFlurry®",
          displayOrder: 99,
        },
        {
          itemId: 203684,
          itemName: "Caramel Brownie McFlurry® Snack",
          displayOrder: 100,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetArchiveCategory: ReturnType<
  typeof realGetArchiveCategory
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          id: 100001,
          name: "Chicken and Sandwiches",
        },
      ],
      totalRecordCount: 1,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockHardDeleteCategory: ReturnType<
  typeof realHardDeleteCategory
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockRestoreCategory: ReturnType<
  typeof realRestoreCategory
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSoftDeleteCategory: ReturnType<
  typeof realSoftDeleteCategory
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetCompareCurrent: ReturnType<
  typeof realGetCompareCurrent
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      category: {
        categoryComments: "",
        categoryDescription: "",
        categoryID: 100007,
        categoryKeywords: "",
        categoryMarketingName: "Desserts & Shakes",
        categoryName: "Desserts & Shakes",
        categoryType: "Core Category",
        doNotShow: "Core",
        items: {
          item: [
            {
              itemId: 200101,
              itemName: "McFlurry with Oreo Cookies",
            },
            {
              itemId: 200096,
              itemName: "McFlurry with M&M'S Chocolate Candies",
            },
            {
              itemId: 200062,
              itemName: "Vanilla Cone",
            },
            {
              itemId: 200107,
              itemName: "Chocolate Shake (Small)",
            },
            {
              itemId: 200108,
              itemName: "Vanilla Shake (Small)",
            },
            {
              itemId: 200109,
              itemName: "Strawberry Shake (Small)",
            },
            {
              itemId: 200074,
              itemName: "Hot Fudge Sundae",
            },
            {
              itemId: 200081,
              itemName: "Hot Caramel Sundae",
            },
            {
              itemId: 200007,
              itemName: "Baked Hot Apple Pie",
            },
            {
              itemId: 200009,
              itemName: "Chocolate Chip Cookie",
            },
            {
              itemId: 203184,
              itemName: "Hot Caramel Sundae Compostable Packaging",
            },
            {
              itemId: 200635,
              itemName: "Shamrock Shake (Medium)",
            },
            {
              itemId: 200636,
              itemName: "Shamrock Shake (Large)",
            },
            {
              itemId: 200144,
              itemName: "Strawberry & Crème Pie",
            },
            {
              itemId: 200052,
              itemName: "Chocolate Shake (Medium)",
            },
            {
              itemId: 201133,
              itemName: "Chocolate Ice Cream (1 scoop)",
            },
            {
              itemId: 200058,
              itemName: "Chocolate Shake (Large)",
            },
            {
              itemId: 200621,
              itemName: "Strawberry Shake (Medium)",
            },
            {
              itemId: 200622,
              itemName: "Strawberry Shake (Large)",
            },
            {
              itemId: 200623,
              itemName: "Vanilla Shake (Medium)",
            },
            {
              itemId: 200624,
              itemName: "Vanilla Shake (Large)",
            },
            {
              itemId: 200618,
              itemName: "McFlurry with M&M'S Chocolate Candies (Snack Size)",
            },
            {
              itemId: 200619,
              itemName: "McFlurry with Oreo Cookies (Snack Size)",
            },
            {
              itemId: 200468,
              itemName: "Peppermint Hot Chocolate with Nonfat Milk (Medium)",
            },
            {
              itemId: 200829,
              itemName: "Sweet Potato and Creme Pie",
            },
            {
              itemId: 200830,
              itemName: "Pumpkin and Creme Pie",
            },
            {
              itemId: 201913,
              itemName: "Chocolate Peanut Butter Ice Cream (1 scoop)",
            },
            {
              itemId: 201674,
              itemName: "Oregon Strawberry Ice Cream (1 scoop no cone)",
            },
            {
              itemId: 201670,
              itemName: "Coffee Almond Fudge Ice Cream (1 scoop)",
            },
            {
              itemId: 201668,
              itemName: "Twist Cone (Large)",
            },
            {
              itemId: 201634,
              itemName: "Vanilla Cone (Large)",
            },
            {
              itemId: 201610,
              itemName: "Guava and Creme Pie",
            },
            {
              itemId: 201604,
              itemName: "Caramel Butter Pecan Ice Cream (1 scoop)",
            },
            {
              itemId: 201575,
              itemName: "Chocolate Cone",
            },
            {
              itemId: 201524,
              itemName: "Fried Taro Pie",
            },
            {
              itemId: 201513,
              itemName: "Vanilla Bean Ice Cream (1 scoop)",
            },
            {
              itemId: 201487,
              itemName: "Orange Sherbet (1 scoop)",
            },
            {
              itemId: 201322,
              itemName: "Udderly Chocolate Ice Cream (1 scoop)",
            },
            {
              itemId: 201275,
              itemName: "Plain Sundae",
            },
            {
              itemId: 201194,
              itemName: "Chocolate Cone (Large)",
            },
            {
              itemId: 201036,
              itemName: "Fried Coconut (Haupia) Pie",
            },
            {
              itemId: 200997,
              itemName: "Mint Chocolate Chip Ice Cream (1 scoop)",
            },
            {
              itemId: 200926,
              itemName: "Twist Cone",
            },
            {
              itemId: 202452,
              itemName: "Hot Fudge Sundae Compostable Packaging",
            },
            {
              itemId: 202592,
              itemName: "Fried Guava Pie",
            },
            {
              itemId: 202288,
              itemName: "Cherry and Creme Pie",
            },
            {
              itemId: 202612,
              itemName: "Mixed Berry and Lemon Creme Pie",
            },
            {
              itemId: 203078,
              itemName: "Oreo McFlurry Sandwich",
            },
            {
              itemId: 203079,
              itemName: "M&M McFlurry Sandwich",
            },
            {
              itemId: 202706,
              itemName: "Chocolate Dipped Twist Ice Cream Cone",
            },
            {
              itemId: 202707,
              itemName: "Chocolate Dipped Chocolate Ice Cream Cone",
            },
            {
              itemId: 202305,
              itemName: "Caramel Sundae Waffle Cone",
            },
            {
              itemId: 202204,
              itemName: "Bacon Bits",
            },
            {
              itemId: 202212,
              itemName: "Cookie Dough Ice Cream (1 scoop)",
            },
            {
              itemId: 203071,
              itemName: "Baked Cherry and Creme Pie",
            },
            {
              itemId: 203751,
              itemName: "Oreo Shamrock Mcflurry (Snack Size)",
            },
            {
              itemId: 204244,
              itemName: "Grimace's Birthday Shake (Large)",
            },
            {
              itemId: 204246,
              itemName: "Grimace's Birthday Shake (Medium)",
            },
            {
              itemId: 204248,
              itemName: "Grimace's Birthday Shake (Small)",
            },
          ],
        },
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetCompareLive: ReturnType<
  typeof realGetCompareLive
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      category: {
        categoryComments: "",
        categoryDescription: "",
        categoryID: 100007,
        categoryKeywords: "",
        categoryMarketingName: "Desserts & Shakes",
        categoryName: "Desserts & Shakes",
        categoryType: "Core Category",
        doNotShow: "Core",
        items: {
          item: [
            {
              itemId: 200101,
              itemName: "McFlurry with Oreo Cookies",
            },
            {
              itemId: 200096,
              itemName: "McFlurry with M&M'S Chocolate Candies",
            },
            {
              itemId: 200062,
              itemName: "Vanilla Cone",
            },
            {
              itemId: 200107,
              itemName: "Chocolate Shake (Small)",
            },
            {
              itemId: 200108,
              itemName: "Vanilla Shake (Small)",
            },
            {
              itemId: 200109,
              itemName: "Strawberry Shake (Small)",
            },
            {
              itemId: 200074,
              itemName: "Hot Fudge Sundae",
            },
            {
              itemId: 200081,
              itemName: "Hot Caramel Sundae",
            },
            {
              itemId: 200007,
              itemName: "Baked Hot Apple Pie",
            },
            {
              itemId: 200009,
              itemName: "Chocolate Chip Cookie",
            },
            {
              itemId: 203184,
              itemName: "Hot Caramel Sundae Compostable Packaging",
            },
            {
              itemId: 200635,
              itemName: "Shamrock Shake (Medium)",
            },
            {
              itemId: 200636,
              itemName: "Shamrock Shake (Large)",
            },
            {
              itemId: 200144,
              itemName: "Strawberry & Crème Pie",
            },
            {
              itemId: 200052,
              itemName: "Chocolate Shake (Medium)",
            },
            {
              itemId: 201133,
              itemName: "Chocolate Ice Cream (1 scoop)",
            },
            {
              itemId: 200058,
              itemName: "Chocolate Shake (Large)",
            },
            {
              itemId: 200621,
              itemName: "Strawberry Shake (Medium)",
            },
            {
              itemId: 200622,
              itemName: "Strawberry Shake (Large)",
            },
            {
              itemId: 200623,
              itemName: "Vanilla Shake (Medium)",
            },
            {
              itemId: 200624,
              itemName: "Vanilla Shake (Large)",
            },
            {
              itemId: 200618,
              itemName: "McFlurry with M&M'S Chocolate Candies (Snack Size)",
            },
            {
              itemId: 200619,
              itemName: "McFlurry with Oreo Cookies (Snack Size)",
            },
            {
              itemId: 200468,
              itemName: "Peppermint Hot Chocolate with Nonfat Milk (Medium)",
            },
            {
              itemId: 200829,
              itemName: "Sweet Potato and Creme Pie",
            },
            {
              itemId: 200830,
              itemName: "Pumpkin and Creme Pie",
            },
            {
              itemId: 201913,
              itemName: "Chocolate Peanut Butter Ice Cream (1 scoop)",
            },
            {
              itemId: 201674,
              itemName: "Oregon Strawberry Ice Cream (1 scoop no cone)",
            },
            {
              itemId: 201670,
              itemName: "Coffee Almond Fudge Ice Cream (1 scoop)",
            },
            {
              itemId: 201668,
              itemName: "Twist Cone (Large)",
            },
            {
              itemId: 201634,
              itemName: "Vanilla Cone (Large)",
            },
            {
              itemId: 201610,
              itemName: "Guava and Creme Pie",
            },
            {
              itemId: 201604,
              itemName: "Caramel Butter Pecan Ice Cream (1 scoop)",
            },
            {
              itemId: 201575,
              itemName: "Chocolate Cone",
            },
            {
              itemId: 201524,
              itemName: "Fried Taro Pie",
            },
            {
              itemId: 201513,
              itemName: "Vanilla Bean Ice Cream (1 scoop)",
            },
            {
              itemId: 201487,
              itemName: "Orange Sherbet (1 scoop)",
            },
            {
              itemId: 201322,
              itemName: "Udderly Chocolate Ice Cream (1 scoop)",
            },
            {
              itemId: 201275,
              itemName: "Plain Sundae",
            },
            {
              itemId: 201194,
              itemName: "Chocolate Cone (Large)",
            },
            {
              itemId: 201036,
              itemName: "Fried Coconut (Haupia) Pie",
            },
            {
              itemId: 200997,
              itemName: "Mint Chocolate Chip Ice Cream (1 scoop)",
            },
            {
              itemId: 200926,
              itemName: "Twist Cone",
            },
            {
              itemId: 202452,
              itemName: "Hot Fudge Sundae Compostable Packaging",
            },
            {
              itemId: 202592,
              itemName: "Fried Guava Pie",
            },
            {
              itemId: 202288,
              itemName: "Cherry and Creme Pie",
            },
            {
              itemId: 202612,
              itemName: "Mixed Berry and Lemon Creme Pie",
            },
            {
              itemId: 203078,
              itemName: "Oreo McFlurry Sandwich",
            },
            {
              itemId: 203079,
              itemName: "M&M McFlurry Sandwich",
            },
            {
              itemId: 202706,
              itemName: "Chocolate Dipped Twist Ice Cream Cone",
            },
            {
              itemId: 202707,
              itemName: "Chocolate Dipped Chocolate Ice Cream Cone",
            },
            {
              itemId: 202305,
              itemName: "Caramel Sundae Waffle Cone",
            },
            {
              itemId: 202204,
              itemName: "Bacon Bits",
            },
            {
              itemId: 202212,
              itemName: "Cookie Dough Ice Cream (1 scoop)",
            },
            {
              itemId: 203071,
              itemName: "Baked Cherry and Creme Pie",
            },
            {
              itemId: 203751,
              itemName: "Oreo Shamrock Mcflurry (Snack Size)",
            },
            {
              itemId: 204244,
              itemName: "Grimace's Birthday Shake (Large)",
            },
            {
              itemId: 204246,
              itemName: "Grimace's Birthday Shake (Medium)",
            },
            {
              itemId: 204248,
              itemName: "Grimace's Birthday Shake (Small)",
            },
          ],
        },
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetCategoryReviewSubmit: ReturnType<
  typeof realGetCategoryReviewSubmit
> = async ({ countryCode = "US" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      appliedWorkFlowId: 5,
      currentStep: "Marketing",
      previousStep: "",
      nextStep: "Nutritionist",
      categoryName: "Happy Meal - Drinks",
      previousComments: [
        {
          userName: "User,Test",
          date: "12/12/2023",
          comments: "Country Admin approves Happy Meal - Drinks Category",
          role: "Country Administrator",
        },
      ],
      workFlowList: [
        {
          id: 1,
          name: "No Workflow",
          description: "No Workflow for Category",
        },
        {
          id: 2,
          name: "Single Worflow",
          description: "Only one step for category",
        },
        {
          id: 3,
          name: "Two Workflow",
          description: "Two step for category",
        },
        {
          id: 4,
          name: "Multiple workflow",
          description: "Multiple step for category",
        },
        {
          id: 5,
          name: "All Team Workflow",
          description: "All step for category",
        },
      ],
      checkListAndAckList: [
        {
          checkList: "Marketing Information",
          ack: "Marketing Information has been validated.",
        },
        {
          checkList: "Category Item Mapping",
          ack: "Category Item Mapping has been validated.",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveCategoryReviewSubmit: ReturnType<
  typeof realSaveCategoryReviewSubmit
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};