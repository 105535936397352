import { components as miscellaneousComponents } from "../data/miscellaneous.types";

export type RelationshipType = Omit<
  miscellaneousComponents["schemas"]["RelationshipType"],
  "laguageRelationList"
> & {
  languages: Record<string, string>;
};

export type RelationshipVariantLanguage =
  miscellaneousComponents["schemas"]["RelationshipVariantLanguage"];
export type RelationshipVariantLanguageData =
  miscellaneousComponents["schemas"]["RelationshipVariantLanguageData"];
export type RelationshipVariant =
  miscellaneousComponents["schemas"]["RelationshipVariant"];

export function formatRelationshipType(
  rawRelationshipType: any
): RelationshipType | undefined {
  const { relationTypeId, relationTypeName, laguageRelationList } =
    rawRelationshipType;
  if (!isNaN(Number(relationTypeId))) {
    const languageList =
      typeof laguageRelationList === "object" ? laguageRelationList : {};
    return {
      relationTypeId: Number(relationTypeId),
      relationTypeName:
        typeof relationTypeName === "string" ? relationTypeName : "",
      // filter out any languages not matching { [key: string]: string }
      languages: Object.entries(languageList).reduce((acc, [key, value]) => {
        if (typeof key === "string" && typeof value === "string") {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, string>),
    };
  }
}

function isLanguageDetail(
  rawDetails: any
): rawDetails is RelationshipVariantLanguage {
  return (
    typeof rawDetails === "object" &&
    rawDetails !== null &&
    typeof rawDetails.value === "string" &&
    typeof rawDetails.abbreviationValue === "string"
  );
}

export function formatRelationshipVariant(
  rawRelationshipVariant: any
): RelationshipVariant | undefined {
  if (
    typeof rawRelationshipVariant !== "object" ||
    rawRelationshipVariant === null
  ) {
    return undefined;
  }
  const { relationId, languageData } = rawRelationshipVariant;
  if (isNaN(Number(relationId))) {
    return undefined;
  }
  if (
    typeof languageData !== "object" ||
    languageData === null ||
    Object.keys(languageData).length === 0
  ) {
    return undefined;
  }

  let isValid = true;
  const cleanLanguageData: RelationshipVariantLanguageData = {};

  // Validate each languageData key-value pair
  for (const [lang, details] of Object.entries(languageData)) {
    if (isLanguageDetail(details)) {
      cleanLanguageData[lang] = details;
    } else {
      isValid = false;
    }
  }
  if (!isValid && Object.keys(cleanLanguageData).length > 0) {
    // If at least one language data pair is valid, return the cleaned object
    return { relationId, languageData: cleanLanguageData };
  } else if (!isValid) {
    // If no valid language data pairs, return undefined
    return undefined;
  }

  // If all pairs are valid, return data as is
  return rawRelationshipVariant;
}
