import {
  createRealGetRequest,
  createRealPostRequest,
  createRealPutRequest,
  createRealDeleteRequest,
} from ".";

import { paths } from "../dashboard.types";

export const realGetPendingActionList = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/getPendingActionList"]
>("/dashboard/getPendingActionList");

export const realShowRecentFeedUpdates = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/showRecentFeedUpdates"]
>("/dashboard/showRecentFeedUpdates");

export const realGetRecentFeedUpdates = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/getRecentFeedUpdate"]
>("/dashboard/getRecentFeedUpdate");

export const realGetReadyPushLiveList = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/getReadyPushLiveList"]
>("/dashboard/getReadyPushLiveList");

export const realGetSchedulePushLiveList = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/getSchedulePushLiveList"]
>("/dashboard/getSchedulePushLiveList");

export const realGetTopTenRecentMenu = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/getTopTenRecentMenu"]
>("/dashboard/getTopTenRecentMenu");

export const realGetFoodPromotionList = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/getFoodPromotionList"]
>("/dashboard/getFoodPromotionList");

export const realAddFoodPromotion = createRealPostRequest<
  paths["/exp/v1/dna/dashboard/saveFoodPromotion"]
>("/dashboard/saveFoodPromotion");

export const realDeleteFoodPromotion = createRealDeleteRequest<
  paths["/exp/v1/dna/dashboard/deleteFoodPromotion"]
>("/dashboard/deleteFoodPromotion");

// @todo handle dynamic urls
// export const realPutFoodPromotion = (foodPromotionId: string) =>
//   createRealPutRequest<
//     paths["/v1/dna/dashboard/foodPromotion/{foodPromotionId}"]
//   >(`/dashboard/foodPromotion/${foodPromotionId}`);

export const realGetEmailPreferences = createRealGetRequest<
  paths["/exp/v1/dna/dashboard/getEmailPreferences"]
>("/dashboard/getEmailPreferences");

export const realSaveEmailPreferences = createRealPutRequest<
  paths["/exp/v1/dna/dashboard/saveEmailPreferences"]
>("/dashboard/saveEmailPreferences");
