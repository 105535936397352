import * as React from "react";
import { styled } from "@mui/material/styles";

export const FixedHeightTableLoader = styled("div", {
  shouldForwardProp: (prop) =>
    !["pageSize", "withActionPanel", "isEmpty"].includes(String(prop)),
})<
  React.HTMLAttributes<HTMLDivElement> & {
    pageSize: number;
    withActionPanel?: Boolean;
    isEmpty?: Boolean;
  }
>(({ pageSize, withActionPanel, isEmpty }) => {
  const addedHeight = !!withActionPanel ? 100 : 32;
  const getNonEmptyTableHeight = () => addedHeight + pageSize * 48;
  return {
    height: Boolean(isEmpty) ? 120 : getNonEmptyTableHeight(),
    position: "relative",
  };
});
