import { styled } from "@mui/material/styles";
import { useItem } from "../pages/item";
import { ItemMarketingForm } from "./ItemMarketingForm";
import { Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

export const ItemMarketingInfoEdit = () => {
  const {
    itemId,
    isLive,
    editable,
    attributes,
    footers,
    collectionItems,
    dropDownList,
    language,
    setLanguage,
  } = useItem();
  const queryClient = useQueryClient();

  return (
    <StyledRelativeContainer>
      <ItemMarketingForm
        itemId={itemId}
        editable={editable}
        isLive={isLive}
        attributes={attributes}
        collectionItems={collectionItems}
        dropDownList={dropDownList}
        footers={footers}
        language={language}
        setLanguage={setLanguage}
        saveSuccessMessage={`Item ${itemId} was saved successfully`}
        onSaveSuccess={() => {
          queryClient.invalidateQueries(["getItemsList"]);
          queryClient.invalidateQueries(["getItemMarketingInformation"]);
          queryClient.invalidateQueries(["item/getCompareCurrent"]);
          queryClient.invalidateQueries(["item/getCompareLive"]);
        }}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
