import {
  realGetAllCategoriesList,
  realGetArchiveCategory,
  realGetCategories,
  realGetCategoryMarketingInformation,
  realGetCategoryReviewSubmit,
  realGetCompareCurrent,
  realGetCompareLive,
  realGetSelectedItemByCategoryId,
  realHardDeleteCategory,
  realRestoreCategory,
  realSaveCategoryMarketingInformation,
  realSaveCategoryReviewSubmit,
  realSaveSelectedItemByCategoryId,
  realSoftDeleteCategory,
} from "./api/categories";
import { realGetItemsByCategoryId } from "./api/itemsbycategory";
import { paths as categoryPaths, paths } from "./categories.types";
import { paths as itemsByCategoryPaths } from "./itemsbycategory.types";
import {
  mockGetAllCategoriesList,
  mockGetArchiveCategory,
  mockGetCategories,
  mockGetCategoryMarketingInformation,
  mockGetCategoryReviewSubmit,
  mockGetCompareCurrent,
  mockGetCompareLive,
  mockGetSelectedItemByCategoryId,
  mockHardDeleteCategory,
  mockRestoreCategory,
  mockSaveCategoryMarketingInformation,
  mockSaveCategoryReviewSubmit,
  mockSaveSelectedItemByCategoryId,
  mockSoftDeleteCategory,
} from "./mock/categories";
import { getItemsByCategoryIdFromMockData } from "./mock/itemsbycategoryid";
import {
  mockableDeleteRequest,
  mockableGetRequest,
  mockablePostRequest,
  mockablePutRequest,
} from "./mockable-api-request";

export const getCategories = mockableGetRequest<
  categoryPaths["/exp/v1/dna/category/getCategories"]
>(realGetCategories, mockGetCategories);

export const getItemsByCategoryId = mockableGetRequest<
  itemsByCategoryPaths["/exp/v1/dna/categories/getItemByCategoryId"]
>(realGetItemsByCategoryId, getItemsByCategoryIdFromMockData);

export const getCategoryMarketingInformation = mockableGetRequest<
  paths["/exp/v1/dna/category/getCategoryMarketingInformation"]
>(realGetCategoryMarketingInformation, mockGetCategoryMarketingInformation);

export const getAllCategoriesList = mockableGetRequest<
  paths["/exp/v1/dna/category/getAllCategoriesList"]
>(realGetAllCategoriesList, mockGetAllCategoriesList);

export const getSelectedItemByCategoryId = mockableGetRequest<
  paths["/exp/v1/dna/category/getSelectedItemByCategoryId"]
>(realGetSelectedItemByCategoryId, mockGetSelectedItemByCategoryId);

export const saveCategoryMarketingInformation = mockablePostRequest<
  paths["/exp/v1/dna/category/saveCategoryMarketingInformation"]
>(realSaveCategoryMarketingInformation, mockSaveCategoryMarketingInformation);

export const saveSelectedItemByCategoryId = mockablePostRequest<
  paths["/exp/v1/dna/category/saveSelectedItemByCategoryId"]
>(realSaveSelectedItemByCategoryId, mockSaveSelectedItemByCategoryId);

export const getArchiveCategory = mockableGetRequest<
  paths["/exp/v1/dna/category/getArchiveCategory"]
>(realGetArchiveCategory, mockGetArchiveCategory);

export const hardDeleteCategory = mockableDeleteRequest<
  paths["/exp/v1/dna/category/hardDeleteCategory"]
>(realHardDeleteCategory, mockHardDeleteCategory);

export const restoreCategory = mockablePutRequest<
  paths["/exp/v1/dna/category/restoreCategory"]
>(realRestoreCategory, mockRestoreCategory);

export const softDeleteCategory = mockablePutRequest<
  paths["/exp/v1/dna/category/softDeleteCategory"]
>(realSoftDeleteCategory, mockSoftDeleteCategory);

export const getCompareCurrent = mockableGetRequest<
  paths["/exp/v1/dna/category/getCompareCurrent"]
>(realGetCompareCurrent, mockGetCompareCurrent);

export const getCompareLive = mockableGetRequest<
  paths["/exp/v1/dna/category/getCompareLive"]
>(realGetCompareLive, mockGetCompareLive);

export const getCategoryReviewSubmit = mockableGetRequest<
  paths["/exp/v1/dna/category/getCategoryReviewAndSubmit"]
>(realGetCategoryReviewSubmit, mockGetCategoryReviewSubmit);

export const saveCategoryReviewSubmit = mockablePostRequest<
  paths["/exp/v1/dna/category/saveCategoryReviewSubmit"]
>(realSaveCategoryReviewSubmit, mockSaveCategoryReviewSubmit);