import { Button, Card, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useAxios } from "../axios-provider";
import { useContext, useEffect, useMemo, useState } from "react";
import { components } from "../data/products.types";
import { RoleContext } from "../role-provider";
import { getProductNutrients, saveProductNutrients } from "../data/products";
import { useProduct } from "../pages/product";
import { Loader } from "./loader/Loader";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { useNavigate } from "react-router-dom";
import {
  ApiError,
  ValidNutritionFact,
  formatAndFilterNutritionFacts,
} from "../utils";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";
import {
  localizedNumStringOrNA,
  standardizedNumStringOrNA,
} from "../util/number-localization";

type ProductNutritionType = components["schemas"]["nutrition"];

type NutritionFormValues = {
  nutrients: ValidNutritionFact[];
};

export const ProductsNutrition = () => {
  const { productId, editable, isLive } = useProduct();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const { apiClient } = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const { data, isFetching } = useCustomQuery(
    ["getNutrientsList", { productId }],
    () =>
      getProductNutrients(apiClient)({
        productId,
        countryCode: selectedCountry!,
      })
  );

  const allNutrients = useMemo(() => {
    if (data?.data.nutritionList === undefined) {
      return [];
    }
    const validNutritionFacts: ValidNutritionFact[] =
      formatAndFilterNutritionFacts(data?.data.nutritionList, false);
    return validNutritionFacts.map((nutritionFact) => ({
      ...nutritionFact,
      nutrientValue: localizedNumStringOrNA(
        nutritionFact.nutrientValue,
        selectedCountry!
      ),
      dvValue: localizedNumStringOrNA(nutritionFact.dvValue, selectedCountry!),
      hundredGramPerProduct: localizedNumStringOrNA(
        nutritionFact.hundredGramPerProduct,
        selectedCountry!
      ),
    }));
  }, [data, selectedCountry]);

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<NutritionFormValues>({
    defaultValues: {
      nutrients: allNutrients,
    },
  });
  const nutrients = watch("nutrients");

  useEffect(() => {
    setValue("nutrients", allNutrients);
    reset({ nutrients: allNutrients });
  }, [allNutrients, reset, setValue]);

  const saveProductNutrientsRequest = saveProductNutrients(apiClient);
  const { mutate, isLoading } = useMutation(
    () => {
      const formattedNutritionFacts = nutrients.map((nutritionFact) => {
        return {
          ...nutritionFact,
          nutrientValue: standardizedNumStringOrNA(
            nutritionFact.nutrientValue,
            selectedCountry!
          ),
          dvValue: standardizedNumStringOrNA(
            nutritionFact.dvValue,
            selectedCountry!
          ),
          hundredGramPerProduct: standardizedNumStringOrNA(
            nutritionFact.hundredGramPerProduct,
            selectedCountry!
          ),
        };
      });
      return saveProductNutrientsRequest(formattedNutritionFacts, {
        countryCode: selectedCountry!,
        roleId: selectedRole!,
        productId: Number(productId),
      });
    },
    {
      onMutate: () => saveProductNutrientsRequest,
      onSuccess: () => {
        queryClient.invalidateQueries(["getNutrientsList", { productId }]);
        queryClient.invalidateQueries(["product/getCompareCurrent"]);
        setSuccessMessage(`Product ${productId} was saved successfully`);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const loading = useMemo(
    () => Boolean(isFetching || isLoading),
    [isFetching, isLoading]
  );

  const onSubmit = () => mutate();

  return (
    <StyledRelativeContainer
      container
      aria-label="Nutrition Information"
      aria-live="polite"
    >
      {loading && <Loader />}
      <StyledNutrientFactsContainer loading={loading}>
        <StyledAutowidthCardContent mobile={12}>
          <Grid container margin={0} padding={0}>
            <StyledTitleBar container mobile={12}>
              <Typography variant="h2">Nutrition Info</Typography>
              <StyledSecondaryButton
                variant="contained"
                disabled={!isLive}
                onClick={() => {
                  navigate(`/products/${productId}/compare-with-live`);
                }}
              >
                Compare with live
              </StyledSecondaryButton>
            </StyledTitleBar>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} aria-label="Nutrient Fields">
              {nutrients.map(
                (nutritionFact: ProductNutritionType, index: number) => (
                  <Grid
                    container
                    mobile={6}
                    marginBottom={1}
                    role="group"
                    aria-label={`Nutrient ${index + 1}`}
                    key={index}
                  >
                    <StyledNutrientFactHeader mobile={3}>
                      <Typography variant="h6" sx={{ wordBreak: "break-word" }}>
                        {nutritionFact
                          .nutrientFactName!.charAt(0)
                          .toUpperCase() +
                          nutritionFact.nutrientFactName!.slice(1)}
                      </Typography>
                    </StyledNutrientFactHeader>
                    <StyledNutrientFactSection mobile={3}>
                      <Controller
                        name={`nutrients.${index}.nutrientValue`}
                        control={control}
                        defaultValue={nutritionFact.nutrientValue}
                        render={({ field }) => (
                          <StyledTextField
                            label={nutritionFact.uomName}
                            disabled={!!isReaderRole || !editable}
                            {...field}
                            InputLabelProps={{ shrink: true }}
                            onFocus={() => {
                              setValue(
                                `nutrients.${index}.nutrientValue`,
                                standardizedNumStringOrNA(
                                  getValues(`nutrients.${index}.nutrientValue`),
                                  selectedCountry!
                                )
                              );
                            }}
                            onBlur={(e) => {
                              setValue(
                                `nutrients.${index}.nutrientValue`,
                                localizedNumStringOrNA(
                                  e.target.value,
                                  selectedCountry!
                                )
                              );
                              field.onBlur();
                            }}
                          />
                        )}
                      />
                    </StyledNutrientFactSection>
                    <StyledNutrientFactSection mobile={3}>
                      <Controller
                        name={`nutrients.${index}.dvValue`}
                        control={control}
                        defaultValue={nutritionFact.dvValue}
                        render={({ field }) => (
                          <StyledTextField
                            label="%div"
                            disabled={!!isReaderRole || !editable}
                            {...field}
                            InputLabelProps={{ shrink: true }}
                            onFocus={() => {
                              setValue(
                                `nutrients.${index}.dvValue`,
                                standardizedNumStringOrNA(
                                  getValues(`nutrients.${index}.dvValue`),
                                  selectedCountry!
                                )
                              );
                            }}
                            onBlur={(e) => {
                              setValue(
                                `nutrients.${index}.dvValue`,
                                localizedNumStringOrNA(
                                  e.target.value,
                                  selectedCountry!
                                )
                              );
                              field.onBlur();
                            }}
                          />
                        )}
                      />
                    </StyledNutrientFactSection>
                    <StyledNutrientFactSection mobile={3}>
                      <Controller
                        name={`nutrients.${index}.hundredGramPerProduct`}
                        control={control}
                        defaultValue={nutritionFact.hundredGramPerProduct}
                        render={({ field }) => (
                          <StyledTextField
                            label="per 100g"
                            disabled={!!isReaderRole || !editable}
                            {...field}
                            InputLabelProps={{ shrink: true }}
                            onFocus={() => {
                              setValue(
                                `nutrients.${index}.hundredGramPerProduct`,
                                standardizedNumStringOrNA(
                                  getValues(
                                    `nutrients.${index}.hundredGramPerProduct`
                                  ),
                                  selectedCountry!
                                )
                              );
                            }}
                            onBlur={(e) => {
                              setValue(
                                `nutrients.${index}.hundredGramPerProduct`,
                                localizedNumStringOrNA(
                                  e.target.value,
                                  selectedCountry!
                                )
                              );
                              field.onBlur();
                            }}
                          />
                        )}
                      />
                    </StyledNutrientFactSection>
                  </Grid>
                )
              )}
            </Grid>
            {!isReaderRole && editable && (
              <Grid
                mobile={12}
                sx={{
                  p: 0,
                  my: 4,
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <StyledSecondaryButton
                  variant="contained"
                  size="large"
                  aria-label="Reset"
                  disabled={!isDirty}
                  onClick={() => reset()}
                >
                  Reset
                </StyledSecondaryButton>
                <StyledSubmitButton
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit
                </StyledSubmitButton>
              </Grid>
            )}
          </form>
        </StyledAutowidthCardContent>
      </StyledNutrientFactsContainer>
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          onSubmit();
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledTitleBar = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  marginLeft: 0,
  marginRight: 0,
  display: "flex",
  justifyContent: "space-between",
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
}));
const StyledNutrientFactHeader = styled(Grid)({
  display: "flex",
  alignItems: "center",
});
const StyledNutrientFactSection = styled(Grid)({
  justifyContent: "flex-end",
});
const StyledRelativeContainer = styled(Grid)({
  display: "flex",
  margin: 0,
  position: "relative",
  justifyContent: "center",
});
const StyledAutowidthCardContent = styled(Grid)(({ theme }) => ({
  width: "auto",
  padding: theme.spacing(4),
  paddingTop: 0,
}));
const StyledNutrientFactsContainer = styled(Card, {
  shouldForwardProp: (prop) => prop !== "loading",
})<{
  loading?: boolean;
}>(({ loading }) => ({
  ...(loading && {
    width: "100%",
    height: "100vh",
  }),
}));
const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
