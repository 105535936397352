import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withLayout } from "../hoc/with-layout";
import {
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Typography,
  Link,
  TableCell,
  Tooltip,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Loader } from "../components/loader/Loader";
import { Cancel } from "@mui/icons-material";
import { FixedHeightTableLoader } from "../components/FixedHeightLoader";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import AddCircle from "@mui/icons-material/AddCircle";
import { AddRowLink } from "../components/AddRowLink";
import { StyledDashboardTableHead } from "./dashboard";
import { useCallback, useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import {
  ApiError,
  ProductData,
  createSortByNumberFn,
  createSortByStringFn,
  formatProductsWithDisplayOrder,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { SearchInput } from "../components/SearchInput";
import TableSortIcon from "../components/TableSortIcon";
import { useForm } from "react-hook-form";
import { saveElementDisplayOrder } from "../data/miscellaneous";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { AxiosError } from "axios";
import {
  ArrowDownward,
  ArrowUpward,
  LowPriority,
  Save,
} from "@mui/icons-material";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";
import { useCustomQuery } from "../hooks/use-custom-query";
import { getProducts } from "../data/products";

export const Products = withLayout(() => {
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const { isReaderRole, selectedCountry, selectedRole } =
    useContext(RoleContext);

  const [isEditDisplayOrderOn, setIsEditDisplayOrderOn] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { data, isFetching } = useCustomQuery(
    ["getProductsList", { selectedCountry }],
    () =>
      getProducts(apiClient)({
        countryCode: selectedCountry!,
        roleId: selectedRole!,
      }),
    selectedCountry !== null && selectedRole !== null
  );

  const products: ProductData[] = useMemo(() => {
    if (data?.data.dataList === undefined) {
      return [];
    }
    const validProducts: ProductData[] = formatProductsWithDisplayOrder(
      data.data.dataList
    );

    return validProducts;
  }, [data]);

  const handleOpenEdit = useCallback(() => setIsEditDisplayOrderOn(true), []);
  const handleCancelEdit = useCallback(
    () => setIsEditDisplayOrderOn(false),
    []
  );
  const handleSaveSuccess = useCallback(() => {
    setSuccessMessage(`Product display order was saved successfully`);
    setIsEditDisplayOrderOn(false);
  }, []);

  return (
    <>
      <Stack aria-label="products list">
        <Grid item columns={12}>
          <Stack spacing={3}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h2">View Products </Typography>
              </Grid>
              {!isReaderRole && (
                <AddRowLink
                  onClick={() => navigate(`/products/create-product`)}
                >
                  <AddCircle aria-label="add new product button" />
                  <Typography>Add New Product</Typography>
                </AddRowLink>
              )}
            </Grid>
            {isFetching ? (
              <Loader />
            ) : (
              <>
                {isEditDisplayOrderOn ? (
                  <ProductsWithEditableDisplayOrderTable
                    products={products}
                    onCancelEdit={handleCancelEdit}
                    onSaveSuccess={handleSaveSuccess}
                  />
                ) : (
                  <ProductsWithSortAndSearchTable
                    products={products}
                    onEnableDisplayOrderEdit={handleOpenEdit}
                  />
                )}
              </>
            )}
          </Stack>
        </Grid>
      </Stack>
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </>
  );
}, "Products");

type ProductsWithSortAndSearchTableProps = {
  products: ProductData[];
  onEnableDisplayOrderEdit: () => void;
};
const ProductsWithSortAndSearchTable = ({
  products,
  onEnableDisplayOrderEdit,
}: ProductsWithSortAndSearchTableProps) => {
  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<ProductData>("products", {
    page: 0,
    pageSize: 25,
    sortField: "displayOrder",
    sortOrder: "asc",
    search: "",
  });
  const navigate = useNavigate();

  const { isReaderRole } = useContext(RoleContext);

  const sortByDisplayOrder = createSortByNumberFn<ProductData>("displayOrder");
  const sortByProductId = createSortByNumberFn<ProductData>("productId");
  const sortByProductName = createSortByStringFn<ProductData>("productName");
  const sortByProductStatus =
    createSortByStringFn<ProductData>("productStatus");

  const productsFilteredBySearch = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredProducts =
      search.length > 0
        ? products?.filter((product) => {
            return (
              product.productName?.toLowerCase().includes(searchLower) ||
              product.productId.toString().includes(search)
            );
          })
        : products;
    if (sortField === "productId") {
      return sortByProductId(filteredProducts, sortOrder);
    } else if (sortField === "productStatus") {
      return sortByProductStatus(filteredProducts, sortOrder);
    } else if (sortField === "productName") {
      return sortByProductName(filteredProducts, sortOrder);
    }
    return sortByDisplayOrder(filteredProducts, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, products, sortField, sortOrder]);

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = useCallback(
    (index: number) => {
      if (!productsFilteredBySearch || !productsFilteredBySearch[index]) {
        return getEmptyRow(index);
      }
      const product = productsFilteredBySearch[index];
      return (
        <StyledTableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="product-tr"
          role="row"
        >
          <TableCell
            role="cell"
            component="th"
            scope="row"
            data-testid="product-td-name"
          >
            {product.productName}
          </TableCell>
          <TableCell role="cell" align="right" data-testid="product-td-id">
            {product.productId}
          </TableCell>
          <TableCell role="cell" align="right" data-testid="product-td-status">
            {product.productStatus}
          </TableCell>
          {/* <TableCell
            role="cell"
            align="right"
            data-testid="product-td-display-order"
          >
            {product.displayOrder}
          </TableCell> */}
          <TableCell
            role="cell"
            align="right"
            data-testid="product-td-compare-with-live"
          >
            <StyledBlueLink
              href={`/products/${product.productId}/compare-with-live`}
            >
              Compare With Live
            </StyledBlueLink>
          </TableCell>
          <TableCell role="cell" align="center">
            {!isReaderRole && product.showEdit === "Y" && (
              <IconButton
                onClick={() => {
                  navigate(
                    `/products/${product.productId}/products-ingredients`
                  );
                }}
                role="button"
                aria-label="edit button"
              >
                <EditIcon />
              </IconButton>
            )}
            {(isReaderRole || product.showEdit === "N") && (
              <IconButton
                onClick={() => {
                  navigate(
                    `/products/${product.productId}/products-ingredients`
                  );
                }}
                role="button"
                aria-label="view button"
                data-testid="view-product-btn"
              >
                <SummarizeIcon />
              </IconButton>
            )}
          </TableCell>
        </StyledTableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsFilteredBySearch, isReaderRole]
  );

  const getEmptyRow = useCallback((index: number) => {
    return (
      <StyledTableRow
        key={index}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        data-testid="products-placeholder-tr"
        role="row"
      >
        <TableCell
          component="th"
          scope="row"
          data-testid="products-td-name"
          role="cell"
        ></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
      </StyledTableRow>
    );
  }, []);

  return (
    <>
      <StyledTableControls>
        <Grid container justifyContent="flex-end" gap={2}>
          <SearchInput
            value={search}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            aria-label="Search products"
            testId="product-search"
          />
          {/* {!isReaderRole && (
            <StyledButton
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={onEnableDisplayOrderEdit}
            >
              Edit Display Order
            </StyledButton>
          )} */}
        </Grid>
      </StyledTableControls>
      <FixedHeightTableLoader pageSize={pageSize} withActionPanel={true}>
        <TableContainer component={Paper}>
          <Table
            aria-label="simple table"
            role="table"
            data-testid="product-table"
          >
            <StyledDashboardTableHead>
              <TableRow role="row">
                <TableCell role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="productName"
                    label="Name"
                    aria-label="Sort by name"
                    testId="sort-by-product-name"
                  />
                </TableCell>
                <TableCell align="right" role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="productId"
                    label="Product Id"
                    aria-label="Sort by id"
                    testId="sort-by-product-id"
                  />
                </TableCell>
                <TableCell align="right" role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="productStatus"
                    label="Status"
                    aria-label="Sort by status"
                    testId="sort-by-product-status"
                  />
                </TableCell>
                {/* <TableCell align="right" role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="displayOrder"
                    label="Display Order"
                    aria-label="Sort by display order"
                    testId="sort-by-product-display-order"
                  />
                </TableCell> */}
                <TableCell align="right" role="columnheader">
                  Compare With Live
                </TableCell>
                <TableCell align="center" role="columnheader">
                  Action
                </TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                getRow(page * pageSize + i)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={productsFilteredBySearch.length}
            onPageChange={handlePageChange}
            page={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={pageSize}
            aria-label="Table Pagination"
            rowsPerPageOptions={[5, 10, 25]}
            data-testid="products-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "products-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>
      </FixedHeightTableLoader>
    </>
  );
};

type ProductsWithEditableDisplayOrderTableProps = {
  products: ProductData[];
  onCancelEdit: () => void;
  onSaveSuccess: () => void;
};
const ProductsWithEditableDisplayOrderTable = ({
  products,
  onCancelEdit,
  onSaveSuccess,
}: ProductsWithEditableDisplayOrderTableProps) => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { isReaderRole, selectedCountry, selectedRole } =
    useContext(RoleContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<{
    elementList: ProductData[];
  }>({
    defaultValues: {
      elementList: products,
    },
  });
  const productsWithEditableDisplayOrder = watch("elementList");

  const saveElementDisplayOrderRequest = saveElementDisplayOrder(apiClient);
  const { mutate: doSaveDisplayOrder, isLoading: isDisplayOrderSaving } =
    useMutation(
      () => {
        return saveElementDisplayOrderRequest(
          {
            elementList: productsWithEditableDisplayOrder.map(
              (product, index) => ({
                displayOrder: index,
                elementId: product.productId,
              })
            ),
          },
          {
            countryCode: selectedCountry!,
            roleId: selectedRole!,
            elementType: "Product",
          }
        );
      },
      {
        onMutate: () => saveElementDisplayOrderRequest,
        onSuccess: () => {
          queryClient.invalidateQueries([
            "getProductsList",
            { selectedCountry },
          ]);
          onSaveSuccess();
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            setIsResendModalOpen(true);
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            setErrorMessage(String(errorMessage.message));
          } else {
            setErrorMessage(String(error));
          }
        },
      }
    );

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    []
  );
  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const handleMoveItem = useCallback(
    (index: number, direction: string) => {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      const updatedProducts = [...productsWithEditableDisplayOrder];
      [updatedProducts[index], updatedProducts[newIndex]] = [
        updatedProducts[newIndex],
        updatedProducts[index],
      ];
      setValue("elementList", updatedProducts, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsWithEditableDisplayOrder]
  );

  const handleMoveToTop = useCallback(
    (index: number) => {
      const updatedProducts = [...productsWithEditableDisplayOrder];
      const [productToMove] = updatedProducts.splice(index, 1);
      updatedProducts.unshift(productToMove);
      setValue("elementList", updatedProducts, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsWithEditableDisplayOrder]
  );

  const handleRetrySaveDisplayOrder = useCallback(() => {
    setIsResendModalOpen(false);
    doSaveDisplayOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelRetrySaveDisplayOrder = useCallback(
    () => setIsResendModalOpen(false),
    []
  );

  const getEmptyRow = useCallback((index: number) => {
    return (
      <StyledTableRow
        key={index}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        data-testid="products-placeholder-tr"
        role="row"
      >
        <TableCell
          component="th"
          scope="row"
          data-testid="products-td-name"
          role="cell"
        ></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
      </StyledTableRow>
    );
  }, []);

  const getRow = useCallback(
    (index: number) => {
      if (
        !productsWithEditableDisplayOrder ||
        !productsWithEditableDisplayOrder[index]
      ) {
        return getEmptyRow(index);
      }
      const product = productsWithEditableDisplayOrder[index];
      return (
        <StyledTableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="product-tr"
          role="row"
        >
          <TableCell
            role="cell"
            component="th"
            scope="row"
            data-testid="product-td-name"
          >
            {product.productName}
          </TableCell>
          <TableCell role="cell" align="right" data-testid="product-td-id">
            {product.productId}
          </TableCell>
          <TableCell role="cell" align="right" data-testid="product-td-status">
            {product.productStatus}
          </TableCell>
          <TableCell
            role="cell"
            align="right"
            data-testid="product-td-display-order"
          >
            {product.displayOrder}
          </TableCell>
          <TableCell role="cell" align="right">
            {index !== 0 && (
              <IconButton
                color="primary"
                onClick={() => handleMoveItem(index, "up")}
                aria-label={`Move ${product.productName} up`}
              >
                <ArrowUpward />
              </IconButton>
            )}
            {index !== productsWithEditableDisplayOrder.length - 1 && (
              <IconButton
                color="primary"
                onClick={() => handleMoveItem(index, "down")}
                aria-label={`Move ${product.productName} down`}
              >
                <ArrowDownward />
              </IconButton>
            )}
            {index !== 0 && (
              <Tooltip title="Move to Top">
                <IconButton
                  color="primary"
                  onClick={() => handleMoveToTop(index)}
                  aria-label={`Move ${product.productName} to top`}
                  style={{ transform: "rotate(180deg)" }}
                >
                  <LowPriority />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </StyledTableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsWithEditableDisplayOrder, isReaderRole]
  );
  return (
    <>
      <StyledTableControls>
        <Grid container justifyContent="flex-end" gap={2}>
          <StyledButton
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={() => doSaveDisplayOrder()}
            disabled={!isDirty || isDisplayOrderSaving}
          >
            Save Display Order
          </StyledButton>
          <Tooltip title="Cancel edit display order">
            <IconButton
              onClick={onCancelEdit}
              aria-label="Cancel edit display order"
              disabled={isDisplayOrderSaving}
            >
              <Cancel color="error" />
            </IconButton>
          </Tooltip>
        </Grid>
      </StyledTableControls>
      <FixedHeightTableLoader pageSize={pageSize} withActionPanel={true}>
        {isDisplayOrderSaving && <Loader />}
        <TableContainer component={Paper}>
          <Table
            aria-label="simple table"
            role="table"
            data-testid="product-table"
          >
            <StyledDashboardTableHead>
              <TableRow role="row">
                <TableCell role="columnheader">Name</TableCell>
                <TableCell align="right" role="columnheader">
                  Product Id
                </TableCell>
                <TableCell align="right" role="columnheader">
                  Status
                </TableCell>
                <TableCell align="right" role="columnheader">
                  Display Order
                </TableCell>
                <TableCell align="center" role="columnheader">
                  Action
                </TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                getRow(page * pageSize + i)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={products.length}
            onPageChange={handlePageChange}
            page={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={pageSize}
            aria-label="Table Pagination"
            rowsPerPageOptions={[5, 10, 25]}
            data-testid="products-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "products-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>

        <ErrorAlertSnackbar
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <ResendFormModal
          open={isResendModalOpen}
          onResend={handleRetrySaveDisplayOrder}
          onCancel={handleCancelRetrySaveDisplayOrder}
          description={"An error occurred saving the product display order."}
        />
      </FixedHeightTableLoader>
    </>
  );
};

const StyledTableControls = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingRight: 0,
  paddingBottom: theme.spacing(1),
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});
const StyledBlueLink = styled(Link)(({ theme }) => ({
  color: theme.typography.button.color,
  textDecorationColor: theme.typography.button.color,
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
