import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Grid,
  TablePagination,
  Typography,
  TableSortLabel,
  Button,
} from "@mui/material";
import { Loader } from "./loader/Loader";
import { FixedHeightTableLoader } from "./FixedHeightLoader";
import { StyledDashboardTableHead } from "../pages/dashboard";
import { useCallback, useContext, useEffect, useState } from "react";
import { createSortByStringFn, ItemWithCategories } from "../utils";
import { ArrowForward } from "@mui/icons-material";
import { SortOrder } from "../data/mock/types";
import { DataContext } from "../data-provider";
import { SearchInput } from "./SearchInput";

type ItemTableSortField = "itemId" | "itemName" | "itemType";

const sortByName = createSortByStringFn<ItemWithCategories>("itemName");
const sortByType = createSortByStringFn<ItemWithCategories>("itemType");
const sortById = createSortByStringFn<ItemWithCategories>("itemId");

type SelectItemsTableProps = {
  selectedItemIds: number[];
  selectEnabled: Boolean;
  onSelect: (item: { id: number; name: string }) => void;
  defaultPageSize?: number;
};
export const SelectItemsTable = ({
  selectedItemIds,
  selectEnabled,
  onSelect,
  defaultPageSize,
}: SelectItemsTableProps) => {
  const { items: allItems, itemsLoading } = useContext(DataContext);
  const [page, setPage] = useState(0);
  const pageSize = typeof defaultPageSize === "number" ? defaultPageSize : 10;
  const [sortField, setSortField] = useState<ItemTableSortField>("itemName");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredSortedItems, setFilteredSortedItems] =
    useState<ItemWithCategories[]>(allItems);

  useEffect(() => {
    const searchLower = searchQuery.toLowerCase();

    const filteredItems =
      searchQuery.length > 0
        ? allItems?.filter((item) => {
            return (
              item.itemName?.toLowerCase().includes(searchLower) ||
              item.itemId.toString().includes(searchQuery)
            );
          })
        : allItems;
    if (sortField === "itemId") {
      setFilteredSortedItems(sortById(filteredItems, sortOrder));
    } else if (sortField === "itemType") {
      setFilteredSortedItems(sortByType(filteredItems, sortOrder));
    } else {
      setFilteredSortedItems(sortByName(filteredItems, sortOrder));
    }
    setPage(0);
  }, [searchQuery, allItems, sortField, sortOrder]);

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    [setPage]
  );
  const handleSortingChange = useCallback(
    (field: ItemTableSortField) => {
      if (sortField === field) {
        // reverse sort on same field
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // set new sort field
        setSortField(field);
      }
      // set to first page in any case
      setPage(0);
    },
    [sortField, sortOrder, setSortField, setSortOrder]
  );

  const getRow = useCallback(
    (index: number) => {
      if (!filteredSortedItems || !filteredSortedItems[index]) {
        return (
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="items-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="items-td-relationName"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      const item = filteredSortedItems[index];
      return (
        <StyledTableRow
          key={item.itemId}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="mappable-item-tr"
        >
          <TableCell
            component="th"
            scope="row"
            data-testid="item-td-relationName"
          >
            {item.itemName}
          </TableCell>
          <TableCell component="th" scope="row" data-testid="item-td-type">
            {item.itemType}
          </TableCell>
          <TableCell align="right">{item.itemId}</TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              onClick={() =>
                onSelect({
                  id: item.itemId,
                  name: item.itemName!,
                })
              }
              disabled={selectedItemIds.includes(item.itemId) || !selectEnabled}
              data-testid="add-item-to-map"
            >
              <ArrowForward />
            </Button>
          </TableCell>
        </StyledTableRow>
      );
    },
    [selectEnabled, filteredSortedItems, selectedItemIds, onSelect]
  );

  return (
    <>
      <Grid item columns={12}>
        <Grid
          container
          justifyContent="space-between"
          marginTop="20px"
          marginBottom="20px"
        >
          <Grid item>
            <Typography variant="h2">Items</Typography>
          </Grid>
          <Grid item>
            <SearchInput
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
              testId="item-search"
            />
          </Grid>
        </Grid>
        <FixedHeightTableLoader pageSize={pageSize} withActionPanel={true}>
          {itemsLoading && <Loader />}
          <TableContainer component={Paper}>
            <Table aria-label="simple table" data-testid="mappable-item-table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>
                    Name
                    <TableSortLabel
                      active={sortField === "itemName"}
                      direction={sortOrder}
                      onClick={() => handleSortingChange("itemName")}
                      data-testid="sort-by-item-relationName"
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Type
                    <TableSortLabel
                      active={sortField === "itemType"}
                      direction={sortOrder}
                      onClick={() => handleSortingChange("itemType")}
                      data-testid="sort-by-item-type"
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    Id
                    <TableSortLabel
                      active={sortField === "itemId"}
                      direction={sortOrder}
                      onClick={() => handleSortingChange("itemId")}
                      data-testid="sort-by-item-id"
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell align="right">Add</TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              <TableBody>
                {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <StyledActionPanel container>
            <TablePagination
              component="div"
              count={filteredSortedItems.length}
              onPageChange={handlePageChange}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[10]}
              data-testid="items-pagination"
              SelectProps={{
                MenuProps: {
                  classes: { list: "items-pagination-list" },
                },
              }}
            />
          </StyledActionPanel>
        </FixedHeightTableLoader>
      </Grid>
    </>
  );
};

const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "space-between",
  alignItems: "center",
});
