import { AxiosHeaders } from "axios";
import {
  realGetAuditTrailData,
  realGetSearchedAuditTrailData,
} from "../api/audittrail";

export const mockGetAuditTrailData: ReturnType<
  typeof realGetAuditTrailData
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      auditTypeList: [
        {
          auditTypeId: 1,
          auditTypeName: "Category",
        },
        {
          auditTypeId: 2,
          auditTypeName: "Item",
        },
        {
          auditTypeId: 3,
          auditTypeName: "Product",
        },
      ],
      usersList: [
        {
          userId: "e0018409",
          userName: "Bulk Upload Utility  | (e0018409)",
        },
        {
          userId: "e0018413",
          userName: "DNA_Business_User_1  | (e0018413)",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetSearchedAuditTrailData: ReturnType<
  typeof realGetSearchedAuditTrailData
> = async ({ auditTypeId, startDate, endDate, userId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          auditType: "PushLive",
          auditAction: "Created",
          auditSubtype: "Push Live",
          actionStatement: "All the Categories are ready for Push live",
          username: "Test User | (e1598526)",
          role: "Country Administrator",
          dateOfAction: "Mon, 20 Nov 2023",
        },
        {
          auditType: "PushLive",
          auditAction: "Created",
          auditSubtype: "Push Live",
          actionStatement: "All the Items are ready for Push live",
          username: "Test User | (e1598526)",
          role: "Country Administrator",
          dateOfAction: "Mon, 20 Nov 2023",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
