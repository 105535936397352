import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef } from "react";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const defaultDuration = 3000;

type AlertSnackbarProps = {
  message: string | null;
  duration?: number;
  onClose: () => void;
};

export const SuccessAlertSnackbar = ({
  message,
  duration,
  onClose,
}: AlertSnackbarProps) => {
  const displayDuration =
    typeof duration === "number" ? duration : defaultDuration;
  return (
    <Snackbar
      open={typeof message === "string"}
      autoHideDuration={displayDuration}
      onClose={onClose}
      role="status"
      aria-live="polite"
    >
      <Alert severity="success">{message}</Alert>
    </Snackbar>
  );
};

export const ErrorAlertSnackbar = ({
  message,
  duration,
  onClose,
}: AlertSnackbarProps) => {
  const displayDuration =
    typeof duration === "number" ? duration : defaultDuration;
  return (
    <Snackbar
      open={typeof message === "string"}
      autoHideDuration={displayDuration}
      onClose={onClose}
      role="alert"
      aria-live="assertive"
    >
      <Alert severity="error">{message}</Alert>
    </Snackbar>
  );
};
