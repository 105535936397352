import categories from "../mock/json/categories.json";
import items from "../mock/json/item-list.json";
import products from "../mock/json/products.json";

export function getAllIds() {
  const productIds = products.map((product) => product.genesisId);
  const itemIds = items.map((item) => item.itemId);
  return {
    productIds,
    itemIds,
  };
}

export function delay(seconds: number) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export function getCategoryById(categoryId: number) {
  const category = categories.find((cat) => cat.id === categoryId);
  return category
    ? {
        ...category,
      }
    : undefined;
}
