import React, { useCallback, useContext, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Menu, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../auth-provider";
import { useNavigate } from "react-router-dom";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { expireRefreshToken } from "../data/auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { saveEmailPreferences } from "../data/dashboard";
import { ConfirmActionModal } from "./ConfirmActionModal";
import { ResendFormModal } from "./ResendFormModal";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { AxiosError } from "axios";
import { ApiError } from "../utils";

const PROFILE_MENU_WIDTH = 270;

export const ProfileMenu: React.FC = () => {
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const menuItemRefs = useRef<(HTMLElement | null)[]>([]);
  const { logout, refreshToken } = useContext(AuthContext);
  const { selectedCountry, selectedRoleName, isEmailPreferenceOn } =
    useContext(RoleContext);
  const [open, setOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [pendingEmailPreferenceChange, setPendingEmailPreferenceChange] =
    useState<"Y" | "N" | null>(null);
  const [
    isRetryEmailPreferenceChangeModalOpen,
    setIsRetryEmailPreferenceChangeModalOpen,
  ] = useState<boolean>(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const expireRefreshTokenRequest = expireRefreshToken(apiClient);
  const expireRefreshTokenMutation = useMutation(
    ({ token }: { token: string }) => {
      return expireRefreshTokenRequest({
        token,
      });
    },
    {
      onSuccess: () => {
        return logout();
      },
      onError: () => {
        // swallow error - failed request indicates refresh token is already expired on API side
        return logout();
      },
    }
  );

  const handleLogoutClick = () => {
    if (typeof refreshToken !== "string") {
      return logout("Refresh token does not exist");
    }
    return expireRefreshTokenMutation.mutate({ token: refreshToken });
  };
  const handleSwitchRoleClick = () => {
    navigate(`/switch-role?country=${selectedCountry}`);
  };
  const handleToggleEmailPreferencesClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setPendingEmailPreferenceChange(event.target.checked ? "Y" : "N"),
    []
  );
  const handleSaveSuccess = useCallback(() => {
    setSuccessMessage(`Role status was updated successfully`);
    setPendingEmailPreferenceChange(null);
    setIsRetryEmailPreferenceChangeModalOpen(false);
    queryClient.invalidateQueries(["getEmailPreferences"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSaveError = useCallback((error: AxiosError) => {
    if (error?.response?.status === 401) {
      setIsRetryEmailPreferenceChangeModalOpen(true);
    } else if (error.response?.data) {
      const errorMessage: ApiError = error.response.data as ApiError;
      setErrorMessage(String(errorMessage.message));
    } else {
      setErrorMessage(String(error));
    }
  }, []);
  const handleResendSaveRequest = useCallback(() => {
    setIsRetryEmailPreferenceChangeModalOpen(false);
    if (pendingEmailPreferenceChange !== null) {
      savePendingEmailPreferencesChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingEmailPreferenceChange]);
  const handleCancelResendSaveRequest = useCallback(() => {
    setPendingEmailPreferenceChange(null);
    setIsRetryEmailPreferenceChangeModalOpen(false);
  }, []);

  const saveEmailPreferencesRequest = saveEmailPreferences(apiClient);
  const {
    mutate: savePendingEmailPreferencesChange,
    isLoading: isSaveEmailPreferencesChangeLoading,
  } = useMutation(
    () => {
      return saveEmailPreferencesRequest(
        { notification: pendingEmailPreferenceChange! },
        {
          countryCode: selectedCountry!,
        }
      );
    },
    {
      onSuccess: handleSaveSuccess,
      onError: handleSaveError,
    }
  );

  const onMenuItemKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    const currentFocusedIndex = menuItemRefs.current.findIndex(
      (ref) => ref === document.activeElement
    );
    if (event.key === "Escape") {
      setOpen(false);
    }
    if (event.key === "Tab" && event.shiftKey === false) {
      event.preventDefault();
      if (currentFocusedIndex === menuItemRefs.current.length - 1) {
        // current focused item is last in the menu, close it
        setOpen(false);
      } else if (
        currentFocusedIndex >= 0 &&
        currentFocusedIndex < menuItemRefs.current.length - 1
      ) {
        // Move focus to the next item
        menuItemRefs.current[currentFocusedIndex + 1]?.focus();
      }
    }
  };
  const onRequestMenuClose = (
    event: React.KeyboardEvent<HTMLElement>,
    reason: string
  ) => {
    if (reason === "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <>
      <StyledProfileButton
        onClick={toggleOpen}
        aria-label="profile of current user"
        aria-expanded={open ? "true" : "false"}
        aria-haspopup="true"
        ref={buttonRef}
        endIcon={<ArrowDropDownIcon />}
        disableRipple
      >
        {`${selectedCountry} - ${selectedRoleName}`}
        <AccountCircle />
      </StyledProfileButton>
      {buttonRef.current && (
        <StyledMenu
          anchorEl={buttonRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onKeyDown={onMenuItemKeydown}
          onClose={onRequestMenuClose}
        >
          <MenuItem
            component="button"
            ref={(el: HTMLButtonElement) => (menuItemRefs.current[0] = el)}
            onClick={handleSwitchRoleClick}
            aria-label="switch user role"
            aria-haspopup="true"
            sx={{ minWidth: PROFILE_MENU_WIDTH }}
          >
            <SwitchAccountIcon />
            <Typography sx={{ ml: 1 }}>Switch Role</Typography>
          </MenuItem>
          <MenuItem
            component="div"
            ref={(el: HTMLDivElement) => (menuItemRefs.current[1] = el)}
            aria-label="toggle email preferences"
            aria-haspopup="true"
            sx={{ minWidth: PROFILE_MENU_WIDTH }}
          >
            <EmailIcon />
            <Typography sx={{ ml: 1 }}>Email Notifications</Typography>
            <Switch
              checked={isEmailPreferenceOn}
              onChange={handleToggleEmailPreferencesClick}
              name="emailPreferenceSwitch"
            />
          </MenuItem>
          <MenuItem
            component="button"
            ref={(el: HTMLButtonElement) => (menuItemRefs.current[2] = el)}
            onClick={handleLogoutClick}
            aria-label="log out current user"
            aria-haspopup="true"
            sx={{ minWidth: PROFILE_MENU_WIDTH }}
          >
            <LogoutIcon />
            <Typography sx={{ ml: 1 }}>Log Out</Typography>
          </MenuItem>
        </StyledMenu>
      )}
      {pendingEmailPreferenceChange !== null && (
        <ConfirmActionModal
          open={!isRetryEmailPreferenceChangeModalOpen}
          loading={isSaveEmailPreferencesChangeLoading}
          message={`Are you sure you want to turn ${
            pendingEmailPreferenceChange === "Y" ? "on" : "off"
          } email notifications?`}
          onConfirm={() => savePendingEmailPreferencesChange()}
          onCancel={() => setPendingEmailPreferenceChange(null)}
        />
      )}
      <ResendFormModal
        open={isRetryEmailPreferenceChangeModalOpen}
        onResend={handleResendSaveRequest}
        onCancel={handleCancelResendSaveRequest}
        description="An error occurred updating email preferences"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

const StyledProfileButton = styled(Button)(({ theme }) => ({
  minWidth: PROFILE_MENU_WIDTH,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: 10,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  color: theme.palette.text.primary,
  fontFamily: theme.typography.normal.fontFamily,
  textTransform: "none",
  "&:hover": {
    outline: "none",
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.primary.light,
  },
  "& .MuiButton-endIcon": {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const StyledMenu = styled(Menu)({
  "& .MuiMenu-list": {
    padding: 0,
  },
});
