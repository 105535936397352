import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";
import { NutritionInfo } from "./components/NutritionInfo";
import { Categories } from "./pages/categories";
import { Dashboard } from "./pages/dashboard";
import { Item } from "./pages/item";
import { muiTheme } from "./theme";
import { LoggedOutView } from "./pages/logged-out";
import { Auth } from "./pages/auth";
import { ProtectedRoute } from "./hoc/protected-route";
import { AuthProvider } from "./auth-provider";
import { DataProvider } from "./data-provider";
import { RoleProvider } from "./role-provider";
import { AxiosProvider } from "./axios-provider";
import { UrlSearchPageSortContextProvider } from "./url-search-page-sort-provider";
import { Products } from "./pages/products";
import { Items } from "./pages/items";
import { CategoryMapping } from "./components/CategoryMapping";
import { Product } from "./pages/product";
import { SwitchRoleView } from "./pages/switch-role";
import { ProductsNutrition } from "./components/ProductsNutrition";
import { Category } from "./pages/category";
import { ItemProductMapping } from "./components/ItemProductMapping";
import { CategoryItemMapping } from "./components/CategoryItemMapping";
import { CategoryReviewAndSubmit } from "./components/CategoryReviewAndSubmit";
import { PushLiveDetail } from "./components/PushLiveDetail";
import { GoesWellWith } from "./components/GoesWellWith";
import { CoOpMapping } from "./components/CoOpMapping";
import { ProductCompareWithLive } from "./pages/product-compare-live";
import { ItemCompareWithLive } from "./pages/item-compare-live";
import { RelationshipMapping } from "./components/RelationshipMapping";
import { CreateItem } from "./pages/create-item";
import { ItemMarketingInfoEdit } from "./components/ItemMarketingInfoEdit";
import { ItemReviewAndSubmit } from "./components/ItemReviewAndSubmit";
import { ProductMarketingInfoEdit } from "./components/ProductMarketingInfoEdit";
import { CreateProduct } from "./pages/create-product";
import { CreateFooter } from "./pages/create-footer";
import { PushLiveEvent } from "./components/PushLiveEvent";
import { ProductReviewAndSubmit } from "./components/ProductReviewAndSubmit";
import { CategoryMarketingInfoEdit } from "./components/CategoryMarketingInfoEdit";
import { CreateCategory } from "./pages/create-category";
import { ArchiveCategory } from "./components/ArchiveCategory";
import { BulkUpload } from "./pages/bulk-upload";
import { ExceptionsReport } from "./pages/exceptions-report";
import { CustomFooter } from "./pages/custom-footers";
import { ArchiveItem } from "./components/ArchiveItem";
import { CategoryCompareWithLive } from "./pages/category-compare-live";
import { NotFoundComponent } from "./components/NotFoundConponent";
import { AuditTrail } from "./components/AuditTrail";
import { ViewUser } from "./components/ViewUser";
import { UserForm } from "./components/UserForm";
import { RolesAndPrivileges } from "./pages/roles-and-privileges";
import { Workflows } from "./pages/workflows";
import { RelationshipTypes } from "./pages/relationship-types";
import { EditRelationship } from "./pages/edit-relationship";
import { CreateRelationship } from "./pages/create-relationship";
import { NutritionFacts } from "./pages/nutrition-facts";
//import { AddFoodPromo } from "./pages/add-food-promo";
// import { UnitOfMeasurement } from "./components/UnitOfMeasurement";
// import { CreateEditRoundingRule } from "./pages/create-edit-rounding-rule";
// import { RoundingRules } from "./pages/rounding-rules";
// import { ViewLogo } from "./pages/view-logo";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthProvider />}>
        <Route element={<AxiosProvider />}>
          <Route element={<RoleProvider />}>
            <Route path="/login" element={<LoggedOutView />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/switch-role" element={<SwitchRoleView />} />
            <Route element={<ProtectedRoute />}>
              <Route path="*" element={<NotFoundComponent />} />

              <Route element={<DataProvider />}>
                <Route path="/push-live-detail" element={<PushLiveDetail />} />
                <Route
                  path="push-live-detail/push-live-event"
                  element={<PushLiveEvent />}
                />
                <Route
                  path="/push-live-detail/:pushLiveEventId/edit-push-live-event"
                  element={<PushLiveEvent />}
                />
                <Route
                  path="/push-live-detail/:pushliveEventDetailsId/push-live-event-details"
                  element={<PushLiveEvent />}
                />
                <Route
                  path="/push-live-event-details/:pushliveEventDetailsId"
                  element={<PushLiveEvent />}
                />
                <Route path="/bulk-upload" element={<BulkUpload />} />
                <Route path="/view-role" element={<RolesAndPrivileges />} />
                <Route path="/workflows" element={<Workflows />} />
                <Route element={<UrlSearchPageSortContextProvider />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />

                 {/* <Route path="/view-status" element={<ViewStatus />} /> */}

                  {/* <Route path="/view-logo" element={<ViewLogo />} /> */}

                  <Route path="/custom-footers" element={<CustomFooter />} />
                  <Route
                    path="/custom-footers/create-footers"
                    element={<CreateFooter />}
                  />
                  <Route
                    path="/exceptions-report"
                    element={<ExceptionsReport />}
                  />
                  <Route path="/nutrition-facts" element={<NutritionFacts />} />
                  {/* <Route
                    path="/add-food-promotion"
                    element={<AddFoodPromo />}
                  /> */}
                  {/* <Route
                    path="/unit-of-measurement"
                    element={<UnitOfMeasurement />}
                  /> */}
                  <Route path="/audit-trail" element={<AuditTrail />} />
                  <Route path="/view-user" element={<ViewUser />} />
                  <Route
                    path="/view-user/add-edit-user"
                    element={<UserForm />}
                  />
                  <Route
                    path="/view-user/:userId/user-form"
                    element={<UserForm />}
                  />
                  <Route path="/archive-item" element={<ArchiveItem />} />
                  <Route path="/items/create-item" element={<CreateItem />} />
                  <Route path="/items/:itemId" element={<Item />}>
                    <Route
                      path="item-marketing-information"
                      element={<ItemMarketingInfoEdit />}
                    />
                    <Route
                      path="product-mapping"
                      element={<ItemProductMapping />}
                    />
                    <Route path="nutrition" element={<NutritionInfo />} />
                    <Route
                      path="category-mapping"
                      element={<CategoryMapping />}
                    />
                    <Route
                      path="relationship-mapping"
                      element={<RelationshipMapping />}
                    />
                    <Route path="goes-well-with" element={<GoesWellWith />} />
                    <Route path="co-op-mapping" element={<CoOpMapping />} />
                    <Route
                      path="item-review-and-submit"
                      element={<ItemReviewAndSubmit />}
                    />
                    <Route
                      path="compare-with-live"
                      element={<ItemCompareWithLive />}
                    />
                  </Route>
                  <Route path="/items" element={<Items />} />
                  <Route
                    path="/products/create-product"
                    element={<CreateProduct />}
                  />
                  <Route path="/products/:productId" element={<Product />}>
                    <Route
                      path="products-ingredients"
                      element={<ProductMarketingInfoEdit />}
                    />
                    <Route
                      path="product-nutrition"
                      element={<ProductsNutrition />}
                    />
                    <Route
                      path="product-review-and-submit"
                      element={<ProductReviewAndSubmit />}
                    />
                    <Route
                      path="compare-with-live"
                      element={<ProductCompareWithLive />}
                    />
                  </Route>
                  <Route path="/products" element={<Products />} />
                  <Route
                    path="/archive-category"
                    element={<ArchiveCategory />}
                  />

                  <Route
                    path="/categories/create-category"
                    element={<CreateCategory />}
                  />
                  <Route path="/categories/:categoryId" element={<Category />}>
                    <Route
                      path="category-marketing-information"
                      element={<CategoryMarketingInfoEdit />}
                    />
                    <Route
                      path="category-item-mapping"
                      element={<CategoryItemMapping />}
                    />
                    <Route
                      path="category-review-and-submit"
                      element={<CategoryReviewAndSubmit />}
                    />
                    <Route
                      path="compare-with-live"
                      element={<CategoryCompareWithLive />}
                    />
                  </Route>
                  <Route path="/categories" element={<Categories />} />
                  <Route
                    path="/relationships"
                    element={<RelationshipTypes />}
                  />
                  <Route
                    path="/relationships/:relationshipTypeId/edit-relationship"
                    element={<EditRelationship />}
                  />
                  <Route
                    path="/relationships/create-relationship"
                    element={<CreateRelationship />}
                  />
                  {/* <Route path="/rounding-rules" element={<RoundingRules />} />
                  <Route
                    path="rounding-rules/create-rounding-rule"
                    element={<CreateEditRoundingRule />}
                  />
                  <Route
                    path="rounding-rules/:roundingRuleId/edit-rounding-rule"
                    element={<CreateEditRoundingRule />}
                  />
                  <Route
                    path="/common-attributes"
                    element={<ManageCommonAttributes />}
                  /> */}

                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </>
  )
);

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
