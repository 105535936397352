import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withLayout } from "../hoc/with-layout";
import {
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AddCircle from "@mui/icons-material/AddCircle";
import { Link, useNavigate } from "react-router-dom";
import {
  getFoodPromotionList,
  getPendingActionList,
  getReadyPushLiveList,
  getRecentFeedUpdates,
  getSchedulePushLiveList,
  getTopTenRecentMenu,
  deleteFoodPromotion,
} from "../data/dashboard";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader } from "../components/loader/Loader";
import { components } from "../data/dashboard.types";
import { FixedHeightTableLoader } from "../components/FixedHeightLoader";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import { AddRowLink } from "../components/AddRowLink";
import { useAxios } from "../axios-provider";
import { AxiosError } from "axios";
import { useContext, useMemo, useState } from "react";
import {
  convertToUniversalDateTime,
  createSortByDateFn,
  createSortByStringFn,
  formatUpdateDate,
  ApiError,
} from "../utils";

import { RoleContext } from "../role-provider";
import { DeleteForever, OpenInBrowser } from "@mui/icons-material";
import { SearchInput } from "../components/SearchInput";
import TableSortIcon from "../components/TableSortIcon";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import { EditFoodPromoModal } from "../components/EditFoodPromoModal";

import {
  SuccessAlertSnackbar,
  ErrorAlertSnackbar,
} from "../components/AlertSnackbar";

type RecentFeedUpdatesData = components["schemas"]["RecentFeedUpdatesData"];
type FoodPromotionData = components["schemas"]["FoodPromotionData"];
type PendingActionData = components["schemas"]["PendingData"];
type ReadyPushLiveData = components["schemas"]["ReadyPushLiveData"];
type ScheduledPushLiveData = components["schemas"]["SchedulePushLiveData"];
type TopTenRecentMenuData = components["schemas"]["TopTenRecentMenuData"];

type TrimmedTextWithTooltipProps = {
  text: string;
  maxLength: number;
};
const TrimmedTextWithTooltip: React.FC<TrimmedTextWithTooltipProps> = ({
  text,
  maxLength,
}) => {
  const isTextTrimmed = text.length > maxLength;
  return (
    <Tooltip title={isTextTrimmed ? text : ""} placement="top">
      <span>{isTextTrimmed ? `${text.substring(0, maxLength)}...` : text}</span>
    </Tooltip>
  );
};

const FeedUpdatesTable = () => {
  const navigate = useNavigate();

  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<RecentFeedUpdatesData>("recent-feed-updates", {
    page: 0,
    pageSize: 10,
    sortField: "updatedOn",
    sortOrder: "desc",
    search: "",
  });
  const { selectedCountry, showRecentFeedUpdates, isReaderRole } =
    useContext(RoleContext);
  const { apiClient } = useAxios();

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["recentFeedUpdates", selectedCountry],
    () =>
      getRecentFeedUpdates(apiClient)({
        countryCode: selectedCountry!,
      }),
    selectedCountry !== null && showRecentFeedUpdates !== null
  );

  const sortByUpdatedOn =
    createSortByDateFn<RecentFeedUpdatesData>("updatedOn");
  const sortByName = createSortByStringFn<RecentFeedUpdatesData>("name");
  const sortByType = createSortByStringFn<RecentFeedUpdatesData>("type");
  const sortByGenesisId =
    createSortByStringFn<RecentFeedUpdatesData>("importId");
  const allUpdates = useMemo(() => {
    const allUpdates = data?.data.dataList?.filter(
      (maybeUpdate) => maybeUpdate !== undefined
    );
    return allUpdates ?? [];
  }, [data]);

  const filteredAndSortedUpdates = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredUpdates =
      search.length > 0
        ? allUpdates?.filter((update) => {
            return (
              update.name?.toLowerCase().includes(searchLower) ||
              update.importId?.toString().includes(search)
            );
          })
        : allUpdates;

    if (sortField === "name") {
      return sortByName(filteredUpdates, sortOrder);
    } else if (sortField === "genesisId") {
      return sortByGenesisId(filteredUpdates, sortOrder);
    } else if (sortField === "type") {
      return sortByType(filteredUpdates, sortOrder);
    }
    return sortByUpdatedOn(filteredUpdates, sortOrder);
  }, [
    allUpdates,
    sortOrder,
    sortField,
    search,
    sortByUpdatedOn,
    sortByName,
    sortByType,
    sortByGenesisId,
  ]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && allUpdates.length === 0;
  }, [isFetching, isSuccess, allUpdates]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const handleActionClick = React.useCallback(
    (feedUpdate: RecentFeedUpdatesData) => {
      if (feedUpdate.type?.toLowerCase() === "item") {
        navigate(`/items/${feedUpdate.elementId}/item-marketing-information`);
      } else if (feedUpdate.type?.toLowerCase() === "product") {
        navigate(`/products/${feedUpdate.elementId}/products-ingredients`);
      }
    },
    [navigate]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (!filteredAndSortedUpdates || !filteredAndSortedUpdates[index]) {
        // return blank "filler" row
        return (
          <StyledTableRow
            key={`table-row-${index}`}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="recent-feed-updates-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="recent-feed-updates-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      const update = filteredAndSortedUpdates[index];
      return (
        <StyledTableRow
          key={`${JSON.stringify(update)}`}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="recent-feed-updates-tr"
          aria-labelledby={`recent-feed-update-row-${update.name}`}
        >
          <TableCell>{update.type}</TableCell>
          <TableCell
            component="th"
            scope="row"
            data-testid="recent-feed-updates-td-name"
          >
            <TrimmedTextWithTooltip text={update.name!} maxLength={100} />
          </TableCell>
          <TableCell>{update.importId}</TableCell>
          <TableCell data-testid="recent-feed-updates-td-updated-on">
            {update.updatedOn}
          </TableCell>
          <TableCell align="right">
            {!isReaderRole && update.showEdit === "Y" && (
              <>
                <IconButton onClick={() => handleActionClick(update)}>
                  <EditIcon aria-label="edit button" />
                </IconButton>
              </>
            )}
            {(!!isReaderRole || update.showEdit === "N") && (
              <IconButton onClick={() => handleActionClick(update)}>
                <SummarizeIcon aria-label="view button" />
              </IconButton>
            )}
          </TableCell>
        </StyledTableRow>
      );
    },
    [isReaderRole, filteredAndSortedUpdates, handleActionClick]
  );
  return (
    <>
      <Grid item columns={12}>
        <Stack spacing={1} marginBottom="20px" marginTop="20px">
          <Grid container justifyContent="space-between">
            <Typography variant="h4" id="recent-feed-updates-table-title">
              Recent Feed Updates
            </Typography>
            {!isEmpty && (
              <SearchInput
                value={search}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                testId="recent-feed-updates-search"
                aria-label="Search recent feed updates"
                aria-labelledby="recent-feed-updates-search-label"
                id="recent-feed-updates-search-input"
              />
            )}
          </Grid>
          <FixedHeightTableLoader
            pageSize={pageSize}
            isEmpty={isEmpty}
            withActionPanel={true}
          >
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
              <Table
                aria-label="List of recent feed updates"
                data-testid="recent-feed-updates-table"
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="type"
                        label="Type"
                        testId="recent-feed-updates-table-sort-by-type"
                        aria-label="Sort by type"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="name"
                        label="Name"
                        testId="recent-feed-updates-table-sort-by-name"
                        aria-label="Sort by name"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="importId"
                        label="Import Id"
                        testId="recent-feed-updates-table-sort-by-id"
                        aria-label="Sort by ID"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="updatedOn"
                        label="Updated On"
                        testId="recent-feed-updates-table-sort-by-updated-on"
                        aria-label="Sort by updated on"
                      />
                    </TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {!isEmpty && (
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                )}
              </Table>
              {isEmpty && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No recent feed updates to display`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
            {!isEmpty && (
              <TablePagination
                component="div"
                count={filteredAndSortedUpdates.length}
                onPageChange={handlePageChange}
                page={page}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[5, 10, 25]}
                data-testid="recent-feed-updates-pagination"
                SelectProps={{
                  MenuProps: {
                    classes: { list: "recent-feed-updates-pagination-list" },
                  },
                }}
                aria-labelledby="recent-feed-updates-pagination-label"
                id="recent-feed-updates-pagination"
              />
            )}
          </FixedHeightTableLoader>
        </Stack>
      </Grid>
    </>
  );
};
// Food Promotions disabled in version 9.7
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FoodPromotionsTable = () => {
  const { canEdit } = useContext(RoleContext);
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortOrder,
    sortField,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<FoodPromotionData>("food-promotions", {
    page: 0,
    pageSize: 5,
    sortField: "startDate",
    sortOrder: "desc",
    search: "",
  });
  const { selectedCountry, isReaderRole } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getFoodPromotionList", selectedCountry],
    () =>
      getFoodPromotionList(apiClient)({
        countryCode: selectedCountry!,
      }),
    selectedCountry !== null
  );
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [foodPromotionDeleteId, setFoodPromotionDeleteId] = useState<
    number | null
  >(null);
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] =
    useState<boolean>(false);
  const queryClient = useQueryClient();
  const [showEditFoodPromoModal, setShowEditFoodPromoModal] = useState<
    boolean | null
  >(false);
  const [selectedFoodPromoData, setSelectedFoodPromoData] =
    useState<FoodPromotionData | null>(null);

  const getSelectedFoodPromo = (getFoodPromotionList: FoodPromotionData) => {
    setShowEditFoodPromoModal(true);
    setSelectedFoodPromoData(getFoodPromotionList);
  };
  const deleteFoodPromotionRequest = deleteFoodPromotion(apiClient);

  const { mutate: doHardDelete, isLoading: isHardDeleteLoading } = useMutation(
    (foodPromotionId?: number) => {
      return deleteFoodPromotionRequest({
        foodPromotionId,
        countryCode: selectedCountry!,
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Food Promotion was deleted successfully`);
        setFoodPromotionDeleteId(null);
        setIsRetryDeleteModalOpen(false);
        queryClient.invalidateQueries(["getFoodPromotionList"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryDeleteModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const sortByStart = createSortByDateFn<FoodPromotionData>("startDate");
  const sortByEnd = createSortByDateFn<FoodPromotionData>("endDate");
  const sortByCreatedBy = createSortByStringFn<FoodPromotionData>("createdBy");
  const sortByCreatedOn = createSortByStringFn<FoodPromotionData>("createdOn");
  const sortByProjectName =
    createSortByStringFn<FoodPromotionData>("projectName");
  const sortByUpdatedOn = createSortByStringFn<FoodPromotionData>("updatedOn");
  const sortByUpdatedBy = createSortByStringFn<FoodPromotionData>("updatedBy");

  const allFoodPromos = useMemo(() => {
    const records = data?.data.dataList?.filter(
      (maybePromo) => maybePromo !== undefined
    );
    return records ?? [];
  }, [data]);

  const filteredAndSortedFoodPromos = useMemo(() => {
    const filteredUpdates =
      search.length > 0
        ? allFoodPromos?.filter((promo) => {
            return promo.projectName
              ?.toLowerCase()
              .includes(search.toLowerCase());
          })
        : allFoodPromos;
    if (sortField === "startDate") {
      return sortByStart(filteredUpdates, sortOrder);
    } else if (sortField === "endDate") {
      return sortByEnd(filteredUpdates, sortOrder);
    } else if (sortField === "createdBy") {
      return sortByCreatedBy(filteredUpdates, sortOrder);
    } else if (sortField === "createdOn") {
      return sortByCreatedOn(filteredUpdates, sortOrder);
    } else if (sortField === "projectName") {
      return sortByProjectName(filteredUpdates, sortOrder);
    } else if (sortField === "updatedOn") {
      return sortByUpdatedOn(filteredUpdates, sortOrder);
    } else if (sortField === "updatedBy") {
      return sortByUpdatedBy(filteredUpdates, sortOrder);
    }
    return sortByProjectName(filteredUpdates, sortOrder);
  }, [
    search,
    allFoodPromos,
    sortField,
    sortByProjectName,
    sortOrder,
    sortByStart,
    sortByEnd,
    sortByCreatedBy,
    sortByCreatedOn,
    sortByUpdatedOn,
    sortByUpdatedBy,
  ]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && allFoodPromos.length === 0;
  }, [isFetching, isSuccess, allFoodPromos]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (!filteredAndSortedFoodPromos || !filteredAndSortedFoodPromos[index]) {
        // return blank "filler" row
        return (
          <StyledTableRow
            key={`table-row-${index}`}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="recent-feed-updates-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="recent-feed-updates-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell data-testid="recent-feed-updates-td-updated-on"></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      const item = filteredAndSortedFoodPromos[index];

      return (
        <StyledTableRow
          key={`${JSON.stringify(item)}`}
          sx={{
            height: 48.5,
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="food-promos-tr"
          aria-labelledby={`food-promo-row-${item.projectName}`}
        >
          <TableCell
            component="th"
            scope="row"
            data-testid="food-promos-td-name"
          >
            {item.projectName}
          </TableCell>
          <TableCell data-testid="food-promos-td-updated-on">
            {convertToUniversalDateTime(item.startDate)}
          </TableCell>
          <TableCell data-testid="food-promos-td-updated-on">
            {convertToUniversalDateTime(item.endDate)}
          </TableCell>
          <TableCell>{item.createdBy}</TableCell>
          <TableCell>{item.createdOn}</TableCell>
          <TableCell>{item.updatedBy}</TableCell>
          <TableCell>{convertToUniversalDateTime(item.updatedOn)}</TableCell>
          <TableCell align="right">
            {!isReaderRole && (
              <>
                <Tooltip title="Edit Food Promotion">
                  <IconButton
                    aria-label="edit button"
                    role="button"
                    data-testid="edit-food-promo-btn"
                  >
                    <EditIcon
                      aria-label="edit button"
                      onClick={() => getSelectedFoodPromo(item)}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete Food Promotion">
                  <IconButton
                    sx={{ color: "#DA291C" }}
                    disabled={!!isReaderRole}
                    aria-label="delete button"
                    onClick={() =>
                      setFoodPromotionDeleteId(item.foodPromotionId!)
                    }
                    role="button"
                  >
                    <DeleteForever />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </TableCell>
        </StyledTableRow>
      );
    },
    [filteredAndSortedFoodPromos, isReaderRole]
  );
  const handleEditFoodPromoSuccess = () => {
    setSuccessMessage(`Food promotion was saved successfully`);
    setShowEditFoodPromoModal(false);
    queryClient.invalidateQueries(["getFoodPromotionList"]);
  };
  return (
    <>
      <Grid item columns={12}>
        <Stack spacing={1} marginBottom="20px" marginTop="20px">
          <Grid container justifyContent="space-between">
            <Typography variant="h4">Food Promotions</Typography>
            {!isEmpty && (
              <SearchInput
                value={search}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                aria-label="Search food promotions"
                aria-labelledby="food-promotions-search-label"
                id="food-promotions-search-input"
                testId="food-promotions-search"
              />
            )}
          </Grid>
          <FixedHeightTableLoader
            pageSize={pageSize}
            withActionPanel={true}
            isEmpty={isEmpty}
          >
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
              <Table
                aria-label="List of food promotions"
                data-testid="food-promotions-table"
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="projectName"
                        label="Project Name"
                        testId="sort-by-project-name"
                        aria-label="Sort by name"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="startDate"
                        label="Start Date"
                        testId="sort-by-promo-date"
                        aria-label="Sort by start date"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="endDate"
                        label="End Date"
                        testId="sort-by-promo-date"
                        aria-label="Sort by end date"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="createdBy"
                        label="Created By"
                        testId="sort-by-created-by"
                        aria-label="Sort by created by"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="createdOn"
                        label="Created On"
                        testId="sort-by-created-on"
                        aria-label="Sort by created on"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="updatedBy"
                        label="Updated By"
                        aria-label="Sort by update by"
                        testId="sort-by-update-by"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="updatedOn"
                        label="Updated On"
                        testId="sort-by-update-date"
                        aria-label="Sort by update date"
                      />
                    </TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {!isEmpty && (
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                )}
              </Table>
              {isEmpty && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No Food Promotions to display`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
            <StyledAlignedContainer container>
              {canEdit && (
                <div>
                  <AddRowLink
                    onClick={() => navigate(`/add-food-promotion`)}
                    data-testid="add-food-promotion"
                  >
                    <AddCircle aria-label="add-food-promotion" />
                    <Typography>New Food Promotion</Typography>
                  </AddRowLink>
                </div>
              )}

              {!isEmpty && (
                <div>
                  <TablePagination
                    component="div"
                    count={filteredAndSortedFoodPromos.length}
                    onPageChange={handlePageChange}
                    page={page}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[5, 10, 25]}
                    data-testid="food-promos-pagination"
                    SelectProps={{
                      MenuProps: {
                        classes: { list: "food-promos-pagination-list" },
                      },
                    }}
                    aria-labelledby="food-promotions-pagination-label"
                    id="food-promotions-pagination"
                  />
                </div>
              )}
            </StyledAlignedContainer>
          </FixedHeightTableLoader>
        </Stack>
      </Grid>
      {typeof foodPromotionDeleteId === "number" && (
        <ConfirmActionModal
          open={!isRetryDeleteModalOpen}
          loading={isHardDeleteLoading}
          message="Delete the selected food promotion??"
          onConfirm={() => doHardDelete(foodPromotionDeleteId!)}
          onCancel={() => setFoodPromotionDeleteId(null)}
        />
      )}
      {showEditFoodPromoModal && (
        <EditFoodPromoModal
          selectedFoodPromo={selectedFoodPromoData}
          onCancel={() => setShowEditFoodPromoModal(false)}
          onSaveSuccess={handleEditFoodPromoSuccess}
        />
      )}
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

const PendingWithTable = () => {
  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<PendingActionData>("pending-data", {
    page: 0,
    pageSize: 5,
    sortField: "updatedOn",
    sortOrder: "desc",
    search: "",
  });
  const { selectedCountry, selectedRole, selectedRoleName } =
    useContext(RoleContext);
  const { apiClient } = useAxios();

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getPendingActionList", selectedRole],
    () =>
      getPendingActionList(apiClient)({
        countryCode: selectedCountry!,
        roleId: Number(selectedRole),
      })
  );
  const sortByUpdatedOn = createSortByDateFn<PendingActionData>("updatedOn");
  const sortByName = createSortByStringFn<PendingActionData>("name");
  const sortByKind = createSortByStringFn<PendingActionData>("type");
  const sortById = createSortByStringFn<PendingActionData>("elementId");
  const sortByChange = createSortByStringFn<PendingActionData>("change");
  const sortByUpdatedBy = createSortByStringFn<PendingActionData>("updatedBy");
  const allPendingActions = useMemo(() => {
    const allPendingActions = data?.data.dataList?.filter(
      (maybeAction) => maybeAction !== undefined
    );
    return allPendingActions ?? [];
  }, [data]);

  const filteredAndSortedActions = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredActions =
      search.length > 0
        ? allPendingActions?.filter((action) => {
            return (
              action.name?.toLowerCase().includes(searchLower) ||
              action.elementId?.toString().includes(search)
            );
          })
        : allPendingActions;
    if (sortField === "updatedOn") {
      return sortByUpdatedOn(filteredActions, sortOrder);
    } else if (sortField === "name") {
      return sortByName(filteredActions, sortOrder);
    } else if (sortField === "updatedBy") {
      return sortByUpdatedBy(filteredActions, sortOrder);
    } else if (sortField === "type") {
      return sortByKind(filteredActions, sortOrder);
    } else if (sortField === "change") {
      return sortByChange(filteredActions, sortOrder);
    } else if (sortField === "elementId") {
      return sortById(filteredActions, sortOrder);
    }
    return sortByUpdatedOn(filteredActions, sortOrder);
  }, [
    search,
    allPendingActions,
    sortField,
    sortByUpdatedOn,
    sortOrder,
    sortByName,
    sortByUpdatedBy,
    sortByKind,
    sortById,
    sortByChange,
  ]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && allPendingActions.length === 0;
  }, [isFetching, isSuccess, allPendingActions]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (!filteredAndSortedActions || !filteredAndSortedActions[index]) {
        // return blank "filler" row
        return (
          <StyledTableRow
            key={`table-row-${index}`}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="pending-actions-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="pending-actions-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      const action = filteredAndSortedActions[index];
      let linkUrl = "/";
      if (action.type === "Item") {
        linkUrl = `/items/${action.elementId}/item-marketing-information`;
      } else if (action.type === "Product") {
        linkUrl = `/products/${action.elementId}/products-ingredients`;
      } else if (action.type === "Category") {
        linkUrl = `/categories/${action.elementId}/category-marketing-information`;
      }
      return (
        <StyledTableRow
          key={action.elementId}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="pending-actions-tr"
          aria-labelledby={`pending-action-row-${action.elementId}`}
        >
          <TableCell>{action.type}</TableCell>
          <TableCell>{action.elementId}</TableCell>
          <TableCell data-testid="pending-actions-td-name">
            <Link to={linkUrl}>{action.name}</Link>
          </TableCell>
          <TableCell>{action.change}</TableCell>
          <TableCell data-testid="pending-actions-td-updated-by">
            {action.updatedBy}
          </TableCell>
          <TableCell align="right" data-testid="pending-actions-td-updated-on">
            {convertToUniversalDateTime(action.updatedOn)}
          </TableCell>
        </StyledTableRow>
      );
    },
    [filteredAndSortedActions]
  );

  return (
    <>
      <Grid item columns={12}>
        <Stack spacing={1} marginBottom="20px" marginTop="20px">
          <Grid container justifyContent="space-between">
            <Typography
              variant="h4"
              id="pending-actions-table-title"
            >{`Pending With ${selectedRoleName}`}</Typography>
            {!isEmpty && (
              <SearchInput
                value={search}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                aria-label={`Search items pending review by ${selectedRoleName}`}
                aria-labelledby="pending-actions-search-input-label"
                id="pending-actions-search-input"
                testId="pending-actions-search"
              />
            )}
          </Grid>
          <FixedHeightTableLoader
            pageSize={pageSize}
            withActionPanel={true}
            isEmpty={isEmpty}
          >
            {isFetching && <Loader />}
            <TableContainer
              component={Paper}
              data-testid="pending-actions-table-container"
            >
              <Table
                aria-label={`List of items pending review by ${selectedRoleName}`}
                data-testid="pending-actions-table"
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="type"
                        label="Type"
                        aria-label="Sort by type"
                        testId="pending-actions-table-sort-by-type"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="elementId"
                        label="Id"
                        aria-label="Sort by id"
                        testId="pending-actions-table-sort-by-element-id"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="name"
                        label="Name"
                        aria-label="Sort by name"
                        testId="pending-actions-table-sort-by-name"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="change"
                        label="Change"
                        aria-label="Sort by change"
                        testId="pending-actions-table-sort-by-change"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="updatedBy"
                        label="Updated By"
                        aria-label="Sort by update by"
                        testId="pending-actions-table-sort-by-updated-by"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="updatedOn"
                        label="Updated On"
                        aria-label="Sort by updated on"
                        testId="pending-actions-table-sort-by-updated-on"
                      />
                    </TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {!isEmpty && (
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                )}
              </Table>
              {isEmpty && (
                <StyledEmptyBox>
                  <Typography
                    variant="h6"
                    id="empty-pending-actions"
                  >{`Nothing pending review by ${selectedRoleName}`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
            {!isEmpty && (
              <StyledRightAlignedActionPanel container>
                <TablePagination
                  component="div"
                  count={filteredAndSortedActions.length}
                  onPageChange={handlePageChange}
                  page={page}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[5, 10, 25]}
                  data-testid="pending-actions-pagination"
                  SelectProps={{
                    MenuProps: {
                      classes: { list: "pending-actions-pagination-list" },
                    },
                  }}
                  aria-labelledby="pending-actions-pagination-label"
                  id="pending-actions-pagination"
                />
              </StyledRightAlignedActionPanel>
            )}
          </FixedHeightTableLoader>
        </Stack>
      </Grid>
    </>
  );
};
const TopTenRecentTable = () => {
  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<TopTenRecentMenuData>("top-ten-recent", {
    page: 0,
    pageSize: 5,
    sortField: "updateDate",
    sortOrder: "desc",
    search: "",
  });
  const { selectedCountry } = useContext(RoleContext);
  const { apiClient } = useAxios();

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getTopTenRecentMenu", selectedCountry],
    () =>
      getTopTenRecentMenu(apiClient)({
        countryCode: selectedCountry!,
      })
  );
  const sortByUpdateDate =
    createSortByDateFn<TopTenRecentMenuData>("updateDate");
  const sortByName = createSortByStringFn<TopTenRecentMenuData>("name");
  const sortByStatus = createSortByStringFn<TopTenRecentMenuData>("status");
  const sortByElementId =
    createSortByStringFn<TopTenRecentMenuData>("elementId");
  const sortByUpdateBy = createSortByStringFn<TopTenRecentMenuData>("updateBy");

  const topTenRecentItems = useMemo(() => {
    const topTenRecentItems = data?.data.dataList?.filter(
      (maybeAction) => maybeAction !== undefined
    );
    return topTenRecentItems ?? [];
  }, [data]);

  const filteredAndSortedTopTenRecent = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredTopTenRecent =
      search.length > 0
        ? topTenRecentItems?.filter((item) => {
            return (
              item.name?.toLowerCase().includes(searchLower) ||
              item.elementId?.toString().includes(search)
            );
          })
        : topTenRecentItems;
    if (sortField === "updateDate") {
      return sortByUpdateDate(filteredTopTenRecent, sortOrder);
    } else if (sortField === "name") {
      return sortByName(filteredTopTenRecent, sortOrder);
    } else if (sortField === "updateBy") {
      return sortByUpdateBy(filteredTopTenRecent, sortOrder);
    } else if (sortField === "status") {
      return sortByStatus(filteredTopTenRecent, sortOrder);
    } else if (sortField === "elementId") {
      return sortByElementId(filteredTopTenRecent, sortOrder);
    }
    return sortByUpdateDate(filteredTopTenRecent, sortOrder);
  }, [
    search,
    topTenRecentItems,
    sortField,
    sortByUpdateDate,
    sortOrder,
    sortByName,
    sortByUpdateBy,
    sortByStatus,
    sortByElementId,
  ]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && topTenRecentItems.length === 0;
  }, [isFetching, isSuccess, topTenRecentItems]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (
        !filteredAndSortedTopTenRecent ||
        !filteredAndSortedTopTenRecent[index]
      ) {
        // return blank "filler" row
        return (
          <StyledTableRow
            key={`table-row-${index}`}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="top-ten-recent-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="top-ten-recent-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      const topTen = filteredAndSortedTopTenRecent[index];
      const formattedUpdateDate = formatUpdateDate(topTen.updateDate!);
      return (
        <StyledTableRow
          key={`${JSON.stringify(topTen)}`}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="top-ten-recent-tr"
          aria-labelledby={`top-ten-recent-row-${topTen.elementId}`}
        >
          <TableCell>{topTen.elementId}</TableCell>
          <TableCell data-testid="top-ten-recent-td-name">
            <Link to={`/items/${topTen.elementId}/item-marketing-information`}>
              {topTen.name}
            </Link>
          </TableCell>
          <TableCell data-testid="top-ten-recent-td-update-by">
            {topTen.updateBy}
          </TableCell>
          <TableCell>{topTen.status}</TableCell>
          <TableCell align="right" data-testid="top-ten-recent-td-update-on">
            {formattedUpdateDate}
          </TableCell>
        </StyledTableRow>
      );
    },
    [filteredAndSortedTopTenRecent]
  );

  return (
    <>
      <Grid item columns={12}>
        <Stack spacing={1} marginBottom="20px" marginTop="20px">
          <Grid container justifyContent="space-between">
            <Typography variant="h4">Top 10 Recent Menu Items</Typography>
            {!isEmpty && (
              <SearchInput
                value={search}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                aria-label={`Search top ten recent items`}
                aria-labelledby="top-ten-search-input-label"
                id="top-ten-search-input"
                testId="top-ten-search"
              />
            )}
          </Grid>
          <FixedHeightTableLoader
            pageSize={pageSize}
            withActionPanel={true}
            isEmpty={isEmpty}
          >
            {isFetching && <Loader />}
            <TableContainer
              component={Paper}
              data-testid="top-ten-table-container"
            >
              <Table
                aria-label={`List of top ten recent menu items`}
                data-testid="top-ten-table"
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell sx={{ py: 0 }}>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="elementId"
                        label="Id"
                        aria-label="Sort by id"
                        testId="top-ten-table-sort-by-id"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="name"
                        label="Name"
                        aria-label="Sort by name"
                        testId="top-ten-table-sort-by-name"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="updateBy"
                        label="Updated By"
                        aria-label="Sort by updated by"
                        testId="top-ten-table-sort-by-updated-by"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="status"
                        label="Status"
                        aria-label="Sort by status"
                        testId="top-ten-table-sort-by-status"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="updateDate"
                        label="Updated On"
                        aria-label="Sort by update date"
                        testId="top-ten-table-sort-by-update-date"
                      />
                    </TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {!isEmpty && (
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                )}
              </Table>
              {isEmpty && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No top ten recent updates to display`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
            {!isEmpty && (
              <StyledRightAlignedActionPanel container>
                <TablePagination
                  component="div"
                  count={filteredAndSortedTopTenRecent.length}
                  onPageChange={handlePageChange}
                  page={page}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[5, 10, 25]}
                  data-testid="top-ten-pagination"
                  SelectProps={{
                    MenuProps: {
                      classes: { list: "top-ten-pagination-list" },
                    },
                  }}
                  aria-labelledby="top-ten-pagination-label"
                  id="top-ten-pagination"
                />
              </StyledRightAlignedActionPanel>
            )}
          </FixedHeightTableLoader>
        </Stack>
      </Grid>
    </>
  );
};

const ReadyPushLiveTable = () => {
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortOrder,
    sortField,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<ReadyPushLiveData>("ready-push-live-data", {
    page: 0,
    pageSize: 5,
    sortField: "name",
    sortOrder: "desc",
    search: "",
  });
  const { selectedCountry } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const { data, isFetching, isSuccess } = useCustomQuery(
    ["readyPushLiveList", selectedCountry],
    () =>
      getReadyPushLiveList(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  const sortByStatus = createSortByStringFn<ReadyPushLiveData>("status");
  const sortByName = createSortByStringFn<ReadyPushLiveData>("name");
  const sortByElementId = createSortByStringFn<ReadyPushLiveData>("id");
  const sortByType = createSortByStringFn<ReadyPushLiveData>("type");

  const allReadyPushLives = useMemo(() => {
    const records = data?.data.dataList?.filter(
      (maybeReady) => maybeReady !== undefined
    );
    return records ?? [];
  }, [data]);

  const filteredAndSortedReadyPushLive = useMemo(() => {
    const searchLower = search.toLowerCase();

    const filteredUpdates =
      search.length > 0
        ? allReadyPushLives?.filter((push) => {
            return (
              push.name?.toLowerCase().includes(searchLower) ||
              push.id?.toString().includes(search)
            );
          })
        : allReadyPushLives;
    if (sortField === "name") {
      return sortByName(filteredUpdates, sortOrder);
    } else if (sortField === "status") {
      return sortByStatus(filteredUpdates, sortOrder);
    } else if (sortField === "id") {
      return sortByElementId(filteredUpdates, sortOrder);
    } else if (sortField === "type") {
      return sortByType(filteredUpdates, sortOrder);
    }
    return sortByName(filteredUpdates, sortOrder);
  }, [
    search,
    allReadyPushLives,
    sortField,
    sortByName,
    sortOrder,
    sortByStatus,
    sortByElementId,
    sortByType,
  ]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && allReadyPushLives.length === 0;
  }, [isFetching, isSuccess, allReadyPushLives.length]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (
        !filteredAndSortedReadyPushLive ||
        !filteredAndSortedReadyPushLive[index]
      ) {
        // return blank "filler" row
        return (
          <StyledTableRow
            key={`tale-row-${index}`}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="ready-push-live-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="ready-push-live-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      const item = filteredAndSortedReadyPushLive[index];
      let linkUrl = "/";
      if (item.type?.toLowerCase() === "item") {
        linkUrl = `/items/${item.id}/item-marketing-information`;
      } else if (item.type?.toLowerCase() === "product") {
        linkUrl = `/products/${item.id}/products-ingredients`;
      } else if (item.type?.toLowerCase() === "category") {
        linkUrl = `/categories/${item.id}/category-marketing-information`;
      }
      return (
        <StyledTableRow
          key={`${item.name}-${item.id}`}
          sx={{
            height: 48.5,
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="ready-push-live-tr"
        >
          <TableCell
            component="th"
            scope="row"
            data-testid="ready-push-live-td-type"
            aria-labelledby={`ready-push-live-row-${item.id}`}
          >
            {item.type}
          </TableCell>
          <TableCell>{item.id}</TableCell>
          <TableCell data-testid="ready-push-live-td-name">
            <Link to={linkUrl}>{item.name}</Link>
          </TableCell>
          <TableCell align="right">{item.status}</TableCell>
        </StyledTableRow>
      );
    },
    [filteredAndSortedReadyPushLive]
  );
  return (
    <>
      <Grid item columns={12}>
        <Stack spacing={1} marginBottom="20px" marginTop="20px">
          <Grid container spacing={0} justifyContent="space-between">
            <Typography variant="h4" id="ready-push-live-table-title">
              Ready for Push Live
            </Typography>
            {!isEmpty && (
              <>
                <AddRowLink
                  aria-label="push live detail table"
                  id="push-live-detail-link"
                  onClick={() => {
                    navigate(`/push-live-detail`);
                  }}
                >
                  <OpenInBrowser aria-label="open push live link" />
                  <Typography variant="body2">Show Details</Typography>
                </AddRowLink>
                <SearchInput
                  value={search}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  endAdornment={<SearchIcon sx={{ opacity: 0.5 }} />}
                  placeholder="Search"
                  aria-label="Search for items ready for push live"
                  aria-labelledby="ready-push-live-search-input-label"
                  id="ready-push-live-search-input"
                  testId="ready-push-live-search"
                />
              </>
            )}
          </Grid>
          <FixedHeightTableLoader
            pageSize={pageSize}
            withActionPanel={true}
            isEmpty={isEmpty}
          >
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
              <Table
                aria-label="List of items ready for push live"
                data-testid="ready-push-live-table"
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="type"
                        label="Type"
                        testId="sort-by-type"
                        aria-label="Sort by type"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="id"
                        label="Id"
                        testId="sort-by-element-id"
                        aria-label="Sort by ID"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="name"
                        label="Name"
                        testId="sort-by-name"
                        aria-label="Sort by name"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="status"
                        label="Status"
                        testId="sort-by-status"
                        aria-label="Sort by status"
                      />
                    </TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {!isEmpty && (
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                )}
              </Table>
              {isEmpty && (
                <StyledEmptyBox>
                  <Typography
                    variant="h6"
                    id="empty-ready-push-live"
                  >{`Nothing ready for push live`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
            {!isEmpty && (
              <StyledRightAlignedActionPanel container>
                <TablePagination
                  component="div"
                  count={filteredAndSortedReadyPushLive.length}
                  onPageChange={handlePageChange}
                  page={page}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[5, 10, 25]}
                  data-testid="ready-push-live-pagination"
                  SelectProps={{
                    MenuProps: {
                      classes: { list: "ready-push-live-pagination-list" },
                    },
                  }}
                  aria-labelledby="ready-push-live-pagination-label"
                  id="ready-push-live-pagination"
                />
              </StyledRightAlignedActionPanel>
            )}
          </FixedHeightTableLoader>
        </Stack>
      </Grid>
    </>
  );
};
const ScheduledPushLiveTable = () => {
  const {
    page,
    pageSize,
    sortOrder,
    sortField,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<ScheduledPushLiveData>("schedule-push-live", {
    page: 0,
    pageSize: 5,
    sortField: "name",
    sortOrder: "desc",
    search: "",
  });
  const { selectedCountry } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const { data, isFetching, isSuccess } = useCustomQuery(
    ["schedulePushLiveList", selectedCountry],
    () =>
      getSchedulePushLiveList(apiClient)({
        countryCode: selectedCountry!,
      }),
    selectedCountry !== null
  );

  const sortByName = createSortByStringFn<ScheduledPushLiveData>("name");
  const sortById = createSortByStringFn<ScheduledPushLiveData>("id");
  const sortByType = createSortByStringFn<ScheduledPushLiveData>("type");

  const allSchedulePushLives = useMemo(() => {
    const records = data?.data.dataList?.filter(
      (maybeSchedule) => maybeSchedule !== undefined
    );
    return records ?? [];
  }, [data]);

  const filteredAndSortedSchedulePushLive = useMemo(() => {
    const searchLower = search.toLowerCase();

    const filteredUpdates =
      search.length > 0
        ? allSchedulePushLives?.filter((push) => {
            return (
              push.name?.toLowerCase().includes(searchLower) ||
              push.id?.toString().includes(search)
            );
          })
        : allSchedulePushLives;
    if (sortField === "name") {
      return sortByName(filteredUpdates, sortOrder);
    } else if (sortField === "id") {
      return sortById(filteredUpdates, sortOrder);
    } else if (sortField === "type") {
      return sortByType(filteredUpdates, sortOrder);
    }
    return sortByName(filteredUpdates, sortOrder);
  }, [
    search,
    allSchedulePushLives,
    sortField,
    sortByName,
    sortOrder,
    sortById,
    sortByType,
  ]);

  const isEmpty = useMemo(() => {
    return !isFetching && isSuccess && allSchedulePushLives.length === 0;
  }, [isFetching, isSuccess, allSchedulePushLives]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );
  const getRow = React.useCallback(
    (index: number) => {
      if (
        !filteredAndSortedSchedulePushLive ||
        !filteredAndSortedSchedulePushLive[index]
      ) {
        // return blank "filler" row
        return (
          <StyledTableRow
            key={`table-row-${index}`}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="schedule-push-live-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="schedulepush-live-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      const item = filteredAndSortedSchedulePushLive[index];

      return (
        <StyledTableRow
          key={`${item.name}-${item.id}`}
          sx={{
            height: 48.5,
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="schedule-push-live-tr"
          aria-labelledby={`schedule-push-live-row-${item.id}`}
        >
          <TableCell
            component="th"
            scope="row"
            data-testid="schedule-push-live-td-name"
          >
            {item.type}
          </TableCell>
          <TableCell>{item.id}</TableCell>
          <TableCell align="right" data-testid="schedule-push-td-name">
            {item.name}
          </TableCell>
        </StyledTableRow>
      );
    },
    [filteredAndSortedSchedulePushLive]
  );
  return (
    <>
      <Grid item columns={12}>
        <Stack spacing={1} marginBottom="20px" marginTop="20px">
          <Grid container justifyContent="space-between">
            <Typography variant="h4">Scheduled for Push Live</Typography>
            {!isEmpty && (
              <SearchInput
                value={search}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                aria-labelledby="search-input-label"
                aria-label="Search for scheduled push live"
                id="scheduled-pushlive-search-input"
                testId="scheduled-pushlive-search"
              />
            )}
          </Grid>
          <FixedHeightTableLoader
            pageSize={pageSize}
            withActionPanel={true}
            isEmpty={isEmpty}
          >
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
              <Table
                aria-label="List of scheduled push live items"
                data-testid="schedule-push-live-table"
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="type"
                        label="Type"
                        aria-label="Sort by type"
                        testId="sort-by-type"
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="id"
                        label="Id"
                        aria-label="Sort by id"
                        testId="sort-by-element-id"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="name"
                        label="Name"
                        aria-label="Sort by name"
                        testId="sort-by-name"
                      />
                    </TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {!isEmpty && (
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                )}
              </Table>
              {isEmpty && (
                <StyledEmptyBox>
                  <Typography
                    variant="h6"
                    id="empty-schedule-push-live"
                  >{`Nothing scheduled for push live`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
            {!isEmpty && (
              <StyledRightAlignedActionPanel container>
                <TablePagination
                  component="div"
                  count={filteredAndSortedSchedulePushLive.length}
                  onPageChange={handlePageChange}
                  page={page}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[5, 10, 25]}
                  id="scheduled-push-live-pagination"
                  data-testid="scheduled-push-live-pagination"
                  SelectProps={{
                    MenuProps: {
                      classes: { list: "scheduled-push-live-pagination-list" },
                    },
                  }}
                  aria-labelledby="scheduled-push-live-pagination-label"
                />
              </StyledRightAlignedActionPanel>
            )}
          </FixedHeightTableLoader>
        </Stack>
      </Grid>
    </>
  );
};

const PushLiveTables = () => {
  return (
    <Grid container spacing={4} marginBottom="20px" marginTop="20px">
      <Grid item mobile={6}>
        <ReadyPushLiveTable />
      </Grid>
      <Grid item mobile={6}>
        <ScheduledPushLiveTable />
      </Grid>
    </Grid>
  );
};

export const Dashboard = withLayout(() => {
  const { selectedRoleName, showRecentFeedUpdates } = useContext(RoleContext);

  return (
    <Stack aria-label="dashboard tables">
      {selectedRoleName !== "Reader" && <PendingWithTable />}
      {showRecentFeedUpdates && <FeedUpdatesTable />}
      <TopTenRecentTable />
      {/* <FoodPromotionsTable /> */}
      <PushLiveTables />
    </Stack>
  );
}, "Dashboard");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRightAlignedActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
const StyledAlignedContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});
