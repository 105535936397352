import {
  realBulkFileUpload,
  realDeleteFooterId,
  realDeletePushLive,
  realDeleteUOM,
  realDeleteUser,
  realEditInstantPushLive,
  realEditPushLive,
  realEditSchedulePushLive,
  realGetAllRolesByCountry,
  realGetApprovedChanges,
  realGetCustomFooters,
  realGetPushLiveDetails,
  realGetPushliveEventDetails,
  realGetRolesAndPrivileges,
  realGetTimeZones,
  realGetUOMByCountry,
  realGetUnapprovedChanges,
  realGetUserDetailsById,
  realGetUsersData,
  realInstantPushLive,
  realRevertWorkflow,
  realSaveReadyForBulkPushlive,
  realSaveUOM,
  realSaveUser,
  realSaveCustomFooters,
  realSchedulePushLive,
  realGetExceptionReportData,
  realChangeRoleStatus,
  realChangePrivilegeStatus,
  realSaveRole,
  realGetWorkflowManagementData,
  realSaveAddedStepForWorkflowManagement,
  realDeleteWorkflowStep,
  realSaveExceptionsReportData,
  realGetViewRelationshipDetails,
  realGetEditRelationshipDetails,
  realSaveRelationship,
  realDeleteRelationshipType,
  realDeleteRelationship,
  realGetNutritionFactsByCountry,
  realSaveDisplayOrder,
  realSaveNutrient,
  realDeleteByNutritionFactID,
  realSaveElementDisplayOrder,
  realGetViewLogo,
  realDeleteLogo,
  realSaveLogoDetails,
  realGetViewStatus,
  realSaveStatusFlag,
  realDeleteStatusFlag,
  realGetManageAttributes,
  realSaveAttributeDisplayOrder,
} from "./api/miscellaneous";
import { paths } from "./miscellaneous.types";
import {
  mockBulkFileUpload,
  mockChangePrivilegeStatus,
  mockChangeRoleStatus,
  mockDeleteFooterId,
  mockDeletePushLive,
  mockDeleteUOM,
  mockDeleteUser,
  mockDeleteWorkflowStep,
  mockEditInstantPushLive,
  mockEditPushLive,
  mockEditSchedulePushLive,
  mockGetAllRolesByCountry,
  mockGetApprovedChanges,
  mockGetCustomFooters,
  mockGetExceptionReportData,
  mockGetPushLiveDetails,
  mockGetPushliveEventDetails,
  mockGetRolesAndPrivileges,
  mockGetTimeZones,
  mockGetUOMByCountry,
  mockGetUnapprovedChanges,
  mockGetUserDetailsById,
  mockGetUsersData,
  mockGetWorkflowManagementData,
  mockInstantPushLive,
  mockRevertWorkflow,
  mockSaveAddedStepForWorkflowManagement,
  mockSaveExceptionsReportData,
  mockSaveReadyForBulkPushlive,
  mockSaveRole,
  mockSaveUOM,
  mockSaveUser,
  mockSaveCustomFooters,
  mockSchedulePushLive,
  mockGetViewRelationshipDetails,
  mockGetEditRelationshipDetails,
  mockSaveRelationship,
  mockDeleteRelationshipType,
  mockDeleteRelationship,
  mockGetNutritionFactsByCountry,
  mockSaveDisplayOrder,
  mockSaveNutrient,
  mockDeleteByNutritionFactID,
  mockSaveElementDisplayOrder,
  mockGetViewLogo,
  mockDeleleteLogo,
  mockSaveLogoDetails,
  mockGetViewStatus,
  mockSaveStatusFlag,
  mockDeleteStatusFlag,
  mockGetManageAttributes,
  mockSaveAttributeDisplayOrder,
} from "./mock/miscellaneous";
import {
  mockableDeleteRequest,
  mockableGetRequest,
  mockablePostFileRequest,
  mockablePostRequest,
  mockablePutRequest,
} from "./mockable-api-request";

export const getPushLiveDetails = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getPushLiveDetails"]
>(realGetPushLiveDetails, mockGetPushLiveDetails);
export const getApprovedChanges = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getApprovedChanges"]
>(realGetApprovedChanges, mockGetApprovedChanges);
export const getUnapprovedChanges = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUnapprovedChanges"]
>(realGetUnapprovedChanges, mockGetUnapprovedChanges);
export const getTimeZones = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getTimeZones"]
>(realGetTimeZones, mockGetTimeZones);
export const editPushLive = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/editPushLive"]
>(realEditPushLive, mockEditPushLive);
export const getPushliveEventDetails = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getPushliveEventDetails"]
>(realGetPushliveEventDetails, mockGetPushliveEventDetails);
export const deletePushLive = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deletePushLive"]
>(realDeletePushLive, mockDeletePushLive);
export const saveReadyForBulkPushlive = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveReadyForBulkPushlive"]
>(realSaveReadyForBulkPushlive, mockSaveReadyForBulkPushlive);
export const revertWorkflow = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/revertWorkflow"]
>(realRevertWorkflow, mockRevertWorkflow);
export const schedulePushLive = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/schedulePushLive"]
>(realSchedulePushLive, mockSchedulePushLive);
export const editSchedulePushLive = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/editSchedulePushLive"]
>(realEditSchedulePushLive, mockEditSchedulePushLive);
export const instantPushLive = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/instantPushLive"]
>(realInstantPushLive, mockInstantPushLive);
export const editInstantPushLive = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/editInstantPushLive"]
>(realEditInstantPushLive, mockEditInstantPushLive);
export const getUOMByCountry = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUOMByCountry"]
>(realGetUOMByCountry, mockGetUOMByCountry);
export const deleteUOM = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteUOM"]
>(realDeleteUOM, mockDeleteUOM);
export const saveUOM = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveUOM"]
>(realSaveUOM, mockSaveUOM);
export const bulkFileUpload = mockablePostFileRequest<
  paths["/exp/v1/dna/miscellaneous/bulkFileUpload"]
>(realBulkFileUpload, mockBulkFileUpload);
export const getCustomFooters = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getCustomFooters"]
>(realGetCustomFooters, mockGetCustomFooters);
export const saveLogoDetails = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveLogoDetails"]
>(realSaveLogoDetails, mockSaveLogoDetails);
export const deleteLogo = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteLogo"]
>(realDeleteLogo, mockDeleleteLogo);
export const getViewLogo = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getViewLogo"]
>(realGetViewLogo, mockGetViewLogo);
export const getUsersData = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUsersData"]
>(realGetUsersData, mockGetUsersData);
export const getUserDetailsById = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getUserDetailsById"]
>(realGetUserDetailsById, mockGetUserDetailsById);
export const saveUser = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveUser"]
>(realSaveUser, mockSaveUser);
export const deleteUser = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteUser"]
>(realDeleteUser, mockDeleteUser);
export const saveCustomFooters = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveCustomFooters"]
>(realSaveCustomFooters, mockSaveCustomFooters);
export const getAllRolesByCountry = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getAllRolesByCountry"]
>(realGetAllRolesByCountry, mockGetAllRolesByCountry);
export const getRolesAndPrivileges = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRolesAndPrivileges"]
>(realGetRolesAndPrivileges, mockGetRolesAndPrivileges);
export const saveRole = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRole"]
>(realSaveRole, mockSaveRole);
export const changeRoleStatus = mockablePutRequest<
  paths["/exp/v1/dna/miscellaneous/changeRoleStatus"]
>(realChangeRoleStatus, mockChangeRoleStatus);
export const changePrivilegeStatus = mockablePutRequest<
  paths["/exp/v1/dna/miscellaneous/changePrivilegeStatus"]
>(realChangePrivilegeStatus, mockChangePrivilegeStatus);
export const deleteFooterId = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteFooterId"]
>(realDeleteFooterId, mockDeleteFooterId);

export const getExceptionReportData = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getExceptionReportData"]
>(realGetExceptionReportData, mockGetExceptionReportData);

export const getNutritionFactsByCountry = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getNutritionFactsByCountry"]
>(realGetNutritionFactsByCountry, mockGetNutritionFactsByCountry);
export const saveDisplayOrder = mockablePutRequest<
  paths["/exp/v1/dna/miscellaneous/saveDisplayOrder"]
>(realSaveDisplayOrder, mockSaveDisplayOrder);
export const saveNutrient = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveNutrient"]
>(realSaveNutrient, mockSaveNutrient);
export const deleteByNutritionFactID = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteByNutritionFactID"]
>(realDeleteByNutritionFactID, mockDeleteByNutritionFactID);

export const getWorkflowManagementData = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getWorkflowManagementData"]
>(realGetWorkflowManagementData, mockGetWorkflowManagementData);
export const saveAddedStepsForWorkflowManagement = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveAddedStepsForWorkflowManagement"]
>(
  realSaveAddedStepForWorkflowManagement,
  mockSaveAddedStepForWorkflowManagement
);
export const deleteWorkflowStep = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteWorkflowStep"]
>(realDeleteWorkflowStep, mockDeleteWorkflowStep);
export const saveExceptionsReportData = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveExceptionsReportData"]
>(realSaveExceptionsReportData, mockSaveExceptionsReportData);
export const getViewRelationshipDetails = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getViewRelationshipDetails"]
>(realGetViewRelationshipDetails, mockGetViewRelationshipDetails);
export const getEditRelationshipDetails = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getEditRelationshipDetails"]
>(realGetEditRelationshipDetails, mockGetEditRelationshipDetails);
export const saveRelationship = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRelationship"]
>(realSaveRelationship, mockSaveRelationship);
export const deleteRelationshipType = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRelationshipType"]
>(realDeleteRelationshipType, mockDeleteRelationshipType);
export const deleteRelationship = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRelationship"]
>(realDeleteRelationship, mockDeleteRelationship);

export const saveElementDisplayOrder = mockablePutRequest<
  paths["/exp/v1/dna/miscellaneous/saveElementDisplayOrder"]
>(realSaveElementDisplayOrder, mockSaveElementDisplayOrder);
export const getViewStatus = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getViewStatus"]
>(realGetViewStatus, mockGetViewStatus);
export const saveStatusFlag = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveStatusFlag"]
>(realSaveStatusFlag, mockSaveStatusFlag);
export const deleteStatusFlag = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteStatusFlag"]
>(realDeleteStatusFlag, mockDeleteStatusFlag);
export const getManageAttributes = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getManageAttribute"]
>(realGetManageAttributes, mockGetManageAttributes);
export const saveAttributeDisplayOrder = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveAttributeDisplayOrder"]
>(realSaveAttributeDisplayOrder, mockSaveAttributeDisplayOrder);