import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React from "react";
import { useBlocker } from "react-router-dom";

export const ConfirmNavigateAway = ({ isDirty }: { isDirty: boolean }) => {
  const blocker = useBlocker(isDirty);

  const onDialogConfirm = React.useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  const onDialogCancel = React.useCallback(() => {
    blocker.reset?.();
  }, [blocker]);

  React.useEffect(() => {
    if (blocker.state === "blocked" && !isDirty) {
      blocker.reset?.();
    }
  }, [blocker, isDirty]);

  return (
    <Dialog
      data-testid="confirm-nav-away-dialog"
      open={blocker.state === "blocked"}
      onClose={onDialogCancel}
      aria-labelledby="confirm-nav-away-title"
      role="dialog"
    >
      <DialogContent>
        <DialogContentText id="confirm-nav-away-title">
          You have unsaved changes. Are you sure you want to leave?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="confirm-nav-away-cancel"
          color="primary"
          type="button"
          onClick={onDialogCancel}
        >
          Cancel
        </Button>
        <Button
          data-testid="confirm-nav-away-confirm"
          color="warning"
          type="button"
          onClick={onDialogConfirm}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
