import { realGetItemsByCategoryId } from "../api/itemsbycategory";
import { AxiosHeaders } from "axios";

export const getItemsByCategoryIdFromMockData: ReturnType<
  typeof realGetItemsByCategoryId
> = async ({ categoryId, countryCode = "US", languageCode = "EN" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      itemData: [
        {
          itemName: "Baked Hot Apple Pie ",
          itemId: 200007,
        },
        {
          itemName: "Chocolate Chip Cookie ",
          itemId: 200009,
        },
        {
          itemName: "Oatmeal Raisin Cookie ",
          itemId: 200013,
        },
        {
          itemName: "Barq's® Root Beer Float (16 fl oz cup) †",
          itemId: 200041,
        },
        {
          itemName: "Barq's® Root Beer Float (21 fl oz cup) †",
          itemId: 200042,
        },
        {
          itemName: "White Buttermints †",
          itemId: 200051,
        },
        {
          itemName: "Chocolate Shake (Medium) ",
          itemId: 200052,
        },
        {
          itemName: "Chocolate Shake (Large) ",
          itemId: 200058,
        },
        {
          itemName: "Vanilla Cone ",
          itemId: 200062,
        },
        {
          itemName: "Fried Apple Pie ",
          itemId: 200071,
        },
        {
          itemName: "Hot Fudge Sundae ",
          itemId: 200074,
        },
        {
          itemName: "Hot Caramel Sundae ",
          itemId: 200081,
        },
        {
          itemName: "McFlurry with M&M'S Chocolate Candies",
          itemId: 200096,
        },
        {
          itemName: "McFlurry with Oreo Cookies ",
          itemId: 200101,
        },
        {
          itemName: "Chocolate Shake (Small) ",
          itemId: 200107,
        },
        {
          itemName: "Vanilla Shake (Small) ",
          itemId: 200108,
        },
        {
          itemName: "Strawberry Shake (Small) ",
          itemId: 200109,
        },
        {
          itemName: "Barq's® Root Beer Float (32 fl oz cup) †",
          itemId: 200112,
        },
        {
          itemName: "Strawberry & Crème Pie ",
          itemId: 200144,
        },
        {
          itemName: "Baked Cherry Pie †",
          itemId: 200163,
        },
        {
          itemName: "Sugar Cookie ",
          itemId: 200263,
        },
        {
          itemName: "Ronald Birthday Cake Chocolate ",
          itemId: 200310,
        },
        {
          itemName: "Ronald Birthday Cake White ",
          itemId: 200320,
        },
        {
          itemName: "Chocolate Dipped Cone",
          itemId: 200329,
        },
        {
          itemName: "Baked Sweet Potato Pie †",
          itemId: 200357,
        },
        {
          itemName: "Baked Holiday Pie",
          itemId: 200375,
        },
        {
          itemName: "Apple Pie Ala Mode ",
          itemId: 200456,
        },
        {
          itemName: "Peppermint Hot Chocolate with Nonfat Milk (Medium)",
          itemId: 200468,
        },
        {
          itemName: "Baked Pumpkin Pie ",
          itemId: 200470,
        },
        {
          itemName: "Caramel Apple Sundae",
          itemId: 200505,
        },
        {
          itemName: "McFlurry with M&M'S Chocolate Candies (Snack Size)",
          itemId: 200618,
        },
        {
          itemName: "McFlurry with Oreo Cookies (Snack Size)",
          itemId: 200619,
        },
        {
          itemName: "Strawberry Shake (Medium) ",
          itemId: 200621,
        },
        {
          itemName: "Strawberry Shake (Large) ",
          itemId: 200622,
        },
        {
          itemName: "Vanilla Shake (Medium) ",
          itemId: 200623,
        },
        {
          itemName: "Vanilla Shake (Large) ",
          itemId: 200624,
        },
        {
          itemName: "Shamrock Shake (Medium)",
          itemId: 200635,
        },
        {
          itemName: "Shamrock Shake (Large)",
          itemId: 200636,
        },
        {
          itemName: "McFlurry with Reese's Peanut Butter Cup (Snack Size)",
          itemId: 200638,
        },
        {
          itemName: "McFlurry with Reese's Peanut Butter Cup",
          itemId: 200670,
        },
        {
          itemName: "Fried Cherry Pie",
          itemId: 200774,
        },
        {
          itemName: "Sweet Potato and Creme Pie",
          itemId: 200829,
        },
        {
          itemName: "Pumpkin and Creme Pie",
          itemId: 200830,
        },
        {
          itemName: "Orange Sherbet (2 scoops)",
          itemId: 200900,
        },
        {
          itemName: "Twist Cone ",
          itemId: 200926,
        },
        {
          itemName: "Fried Blueberry Pie",
          itemId: 200964,
        },
        {
          itemName: "Mint Chocolate Chip Ice Cream (1 scoop)",
          itemId: 200997,
        },
        {
          itemName: "Fried Coconut (Haupia) Pie",
          itemId: 201036,
        },
        {
          itemName: "Udderly Chocolate Ice Cream (2 scoops)",
          itemId: 201059,
        },
        {
          itemName: "Fried Banana Pie",
          itemId: 201115,
        },
        {
          itemName: "Chocolate Dipped Cone (Large)",
          itemId: 201123,
        },
        {
          itemName: "Chocolate Ice Cream (1 scoop)",
          itemId: 201133,
        },
        {
          itemName: "Chocolate Cone (Large)",
          itemId: 201194,
        },
        {
          itemName: "Cookie Dough Ice Cream (1 scoop)",
          itemId: 201224,
        },
        {
          itemName: "Plain Sundae",
          itemId: 201275,
        },
        {
          itemName: "Udderly Chocolate Ice Cream (1 scoop)",
          itemId: 201322,
        },
        {
          itemName: "Oregon Strawberry Ice Cream (2 scoops)",
          itemId: 201354,
        },
        {
          itemName: "Caramel Butter Pecan Ice Cream (2 scoops)",
          itemId: 201426,
        },
        {
          itemName: "Peach Fried Pie",
          itemId: 201480,
        },
        {
          itemName: "Orange Sherbet (1 scoop)",
          itemId: 201487,
        },
        {
          itemName: "Vanilla Bean Ice Cream (1 scoop)",
          itemId: 201513,
        },
        {
          itemName: "Fried Taro Pie",
          itemId: 201524,
        },
        {
          itemName: "Chocolate Cone ",
          itemId: 201575,
        },
        {
          itemName: "McFlurry made with Kit Kat (Snack Size)",
          itemId: 201601,
        },
        {
          itemName: "Caramel Butter Pecan Ice Cream (1 scoop)",
          itemId: 201604,
        },
        {
          itemName: "Guava and Creme Pie",
          itemId: 201610,
        },
        {
          itemName: "Mint Chocolate Chip Ice Cream (2 scoops)",
          itemId: 201633,
        },
        {
          itemName: "Vanilla Cone (Large)",
          itemId: 201634,
        },
        {
          itemName: "Hot Caramel Topping",
          itemId: 201642,
        },
        {
          itemName: "Vanilla Bean Ice Cream (2 scoops)",
          itemId: 201665,
        },
        {
          itemName: "Twist Cone (Large)",
          itemId: 201668,
        },
        {
          itemName: "Coffee Almond Fudge Ice Cream (1 scoop)",
          itemId: 201670,
        },
        {
          itemName: "Oregon Strawberry Ice Cream (1 scoop no cone)",
          itemId: 201674,
        },
        {
          itemName: "Reese's Peanut Butter Cup Candy Pieces",
          itemId: 201881,
        },
        {
          itemName: "Boston Cooler Float (Medium)",
          itemId: 201910,
        },
        {
          itemName: "Chocolate Peanut Butter Ice Cream (1 scoop)",
          itemId: 201913,
        },
        {
          itemName: "Birthday Cake Dipped Cone",
          itemId: 202147,
        },
        {
          itemName: "Bacon Bits",
          itemId: 202204,
        },
        {
          itemName: "Cookie Dough Ice Cream (1 scoop)  ",
          itemId: 202212,
        },
        {
          itemName: "Cherry and Creme Pie",
          itemId: 202288,
        },
        {
          itemName: "Caramel Sundae Waffle Cone",
          itemId: 202305,
        },
        {
          itemName: "Hot Fudge Sundae Compostable Packaging",
          itemId: 202452,
        },
        {
          itemName: "Fried Guava Pie",
          itemId: 202592,
        },
        {
          itemName: "Mixed Berry and Lemon Creme Pie",
          itemId: 202612,
        },
        {
          itemName: "Chocolate Dipped Chocolate Ice Cream Cone (Large)",
          itemId: 202705,
        },
        {
          itemName: "Chocolate Dipped Twist Ice Cream Cone",
          itemId: 202706,
        },
        {
          itemName: "Chocolate Dipped Chocolate Ice Cream Cone",
          itemId: 202707,
        },
        {
          itemName: "Chocolate Dipped Twist Ice Cream Cone",
          itemId: 202708,
        },
        {
          itemName: "Baked Cherry and Creme Pie",
          itemId: 203071,
        },
        {
          itemName: "Oreo McFlurry Sandwich",
          itemId: 203078,
        },
        {
          itemName: "M&M McFlurry Sandwich",
          itemId: 203079,
        },
        {
          itemName: "Hot Caramel Sundae Compostable Packaging",
          itemId: 203184,
        },
        {
          itemName: "Netherland's McFlurry Stroopwafel (Snack Size)",
          itemId: 203470,
        },
        {
          itemName: "Netherland's McFlurry Stroopwafel (Regular)",
          itemId: 203471,
        },
        {
          itemName: "Snickerdoodle McFlurry (Snack Size)",
          itemId: 203670,
        },
        {
          itemName: "Snickerdoodle McFlurry (Regular)",
          itemId: 203671,
        },
        {
          itemName: "Chips Ahoy! McFlurry (Snack Size)",
          itemId: 203678,
        },
        {
          itemName: "Caramel Brownie McFlurry® Snack",
          itemId: 203684,
        },
        {
          itemName: "Caramel Brownie McFlurry®",
          itemId: 203688,
        },
        {
          itemName: "Oreo Shamrock Mcflurry (Snack Size) ",
          itemId: 203751,
        },
        {
          itemName: "Chips Ahoy! McFlurry (Regular)",
          itemId: 203754,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
