import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import { EidLoginForm } from "../components/EidLoginForm";
import { GasLoginForm } from "../components/GasLoginForm";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export const LoggedOutView: React.FC = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const error =
    typeof params["error"] === "string" ? params["error"] : undefined;

  const renderLoginForm = () => {
    if (process.env.REACT_APP_AUTH_TYPE === "eid") {
      return <EidLoginForm error={error} />;
    } else if (process.env.REACT_APP_AUTH_TYPE === "gas") {
      return <GasLoginForm error={error} />;
    } else {
      return <></>;
    }
  };

  return (
    <StyledFullHeightGrid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item mobile={3}>
        <StyledLoginFormOuter>
          <StyledLoginFormInner>{renderLoginForm()}</StyledLoginFormInner>
        </StyledLoginFormOuter>
      </Grid>
    </StyledFullHeightGrid>
  );
};

const StyledFullHeightGrid = styled(Grid)({
  minHeight: "100vh",
});

const StyledLoginFormOuter = styled(Box)({
  minWidth: 550,
  backgroundColor: "white",
  flex: "1 1 auto",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});

const StyledLoginFormInner = styled(Box)({
  maxWidth: 300,
  px: 3,
  py: 3,
  width: "100%",
});
