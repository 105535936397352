import ArchiveIcon from "@mui/icons-material/Archive";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useContext, useMemo, useState } from "react";
import { useAxios } from "../axios-provider";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import { FixedHeightTableLoader } from "../components/FixedHeightLoader";
import { SearchInput } from "../components/SearchInput";
import TableSortIcon from "../components/TableSortIcon";
import { Loader } from "../components/loader/Loader";
import {
  getExceptionReportData,
  saveExceptionsReportData,
} from "../data/miscellaneous";
import { components } from "../data/miscellaneous.types";
import { withLayout } from "../hoc/with-layout";
import { useCustomQuery } from "../hooks/use-custom-query";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import { RoleContext } from "../role-provider";
import {
  ApiError,
  ExceptionReport,
  convertToUniversalDateTime,
  createSortByArchiveDateFn,
  createSortByDateFn,
  createSortByNumberFn,
  createSortByStringFn,
} from "../utils";
import { StyledDashboardTableHead } from "./dashboard";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";

export const ExceptionsReport = withLayout(() => {
  const { apiClient } = useAxios();
  const { selectedCountry, isReaderRole } = useContext(RoleContext);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
  const [editingExceptionId, setEditingExceptionId] = useState<number>();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [resendFormData, setResendFormData] =
    useState<components["schemas"]["SaveExceptionsReportData"]>();

  const {
    page,
    sortOrder,
    currentSort,
    navigateToPage,
    pageSize,
    search,
    setPageSize,
    setSearchQuery,
    setSorting,
    sortField,
  } = useUrlSearchPageSort<ExceptionReport>("exception-reports", {
    page: 0,
    pageSize: 10,
    sortField: "exceptionId",
    sortOrder: "desc",
    search: "",
  });

  const { data, isLoading } = useCustomQuery(
    ["getExceptionReportData", { selectedCountry }],
    () => getExceptionReportData(apiClient)({ countryCode: selectedCountry! })
  );
  const validReports = useMemo(() => {
    const records = data?.data.dataList?.filter(
      (maybeReport) => maybeReport !== undefined
    );
    return records ?? [];
  }, [data?.data.dataList]);

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );
  const queryClient = useQueryClient();
  const saveExceptionsReport = saveExceptionsReportData(apiClient);
  const updateReport = useMutation(
    (exceptionData: components["schemas"]["SaveExceptionsReportData"]) => {
      setResendFormData(exceptionData);
      return saveExceptionsReport(
        { exceptionList: [exceptionData] },
        { countryCode: selectedCountry! }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getExceptionReportData",
          { selectedCountry },
        ]);

        setSuccessMessage("Exception report updated successfully.");
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const onResolve = useCallback(
    (exceptionId: number) => () => {
      setEditingExceptionId(exceptionId);
      setIsResolveModalOpen(true);
    },
    []
  );
  const onArchive = useCallback(
    (exceptionId: number) => () => {
      setEditingExceptionId(exceptionId);
      setIsArchiveModalOpen(true);
    },
    []
  );

  const filteredAndSortedReports = useMemo(() => {
    const searchLower = search.toLowerCase();

    const filteredReports =
      searchLower.length > 0
        ? validReports?.filter((report) => {
            return (
              report.exceptionId?.toString().includes(searchLower) ||
              report.failedRecord.toLowerCase().includes(searchLower) ||
              report.reason.toLowerCase().includes(searchLower) ||
              report.action.toLowerCase().includes(searchLower)
            );
          })
        : validReports;

    switch (sortField) {
      case "exceptionId":
        return createSortByNumberFn<ExceptionReport>("exceptionId")(
          filteredReports,
          sortOrder
        );
      case "updatedOn":
        return createSortByDateFn<ExceptionReport>("updatedOn")(
          filteredReports,
          sortOrder
        );
      case "reason":
      case "failedRecord":
      case "type":
        return createSortByStringFn<ExceptionReport>(sortField)(
          filteredReports,
          sortOrder
        );
      case "archiveInfo":
        return createSortByArchiveDateFn<ExceptionReport>()(
          filteredReports,
          sortOrder
        );
      default:
        return createSortByStringFn<ExceptionReport>("exceptionId")(
          filteredReports,
          sortOrder
        );
    }
  }, [search, sortField, sortOrder, validReports]);

  const getRow = useCallback(
    (index: number) => {
      if (!filteredAndSortedReports || !filteredAndSortedReports[index]) {
        return (
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="reports-placeholder-tr"
            role="row"
            key={index}
          >
            <TableCell role="cell" data-testid="reports-td-name"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
          </StyledTableRow>
        );
      }
      const report = filteredAndSortedReports[index];
      return (
        <StyledTableRow
          key={report.exceptionId}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="report-tr"
        >
          <TableCell data-testid="report-td-exception-id">
            {report.exceptionId}
          </TableCell>
          <TableCell data-testid="report-td-type">{report.type}</TableCell>
          <TableCell data-testid="report-td-failed-record">
            {report.failedRecord}
          </TableCell>
          <TableCell data-testid="report-td-updated-on">
            {report.reason}
          </TableCell>
          <TableCell data-testid="report-td-updated-on">
            {convertToUniversalDateTime(report.updatedOn)}
          </TableCell>
          <TableCell align="center">
            <div
              style={{ display: "flex", justifyContent: "center" }}
              role="group"
            >
              {!isReaderRole && (
                <>
                  {!report.archiveInfo && (
                    <Tooltip title="Archive Report">
                      <IconButton
                        aria-label="archive report"
                        onClick={onArchive(report.exceptionId)}
                        role="button"
                        data-testid="archive-report-btn"
                        sx={{ color: "#DA291C" }}
                      >
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Resolve Report">
                    <IconButton
                      aria-label="resolve report"
                      onClick={onResolve(report.exceptionId)}
                      role="button"
                      data-testid="resolve-report-btn"
                      sx={{ color: "#264F36" }}
                    >
                      <CheckBoxIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </TableCell>
          <TableCell data-testid="report-td-updated-on" role="row">
            {report.archiveInfo && (
              <>
                {report.archiveInfo.archiveBy}
                <br />
                {convertToUniversalDateTime(report.archiveInfo.archiveDate)}
                <br />
                {report.archiveInfo.archiveReason}
              </>
            )}
          </TableCell>
        </StyledTableRow>
      );
    },
    [filteredAndSortedReports, isReaderRole, onArchive, onResolve]
  );

  return (
    <>
      <ConfirmActionModal
        loading={updateReport.isLoading}
        open={isResolveModalOpen}
        message="Resolve Exception Report"
        onCancel={() => setIsResolveModalOpen(false)}
        onConfirm={() => {
          if (editingExceptionId) {
            updateReport.mutate({
              action: "Resolved",
              exceptionId: editingExceptionId,
            });
          }
          setIsResolveModalOpen(false);
        }}
      />
      <ConfirmActionModal
        loading={updateReport.isLoading}
        open={isArchiveModalOpen}
        showReason
        reasonLabel="Archive Reason"
        message="Archive Exception Report"
        onCancel={() => setIsArchiveModalOpen(false)}
        onConfirm={(archiveReason) => {
          if (editingExceptionId) {
            updateReport.mutate({
              action: "Archived",
              archiveReason,
              exceptionId: editingExceptionId,
            });
          }
          setIsArchiveModalOpen(false);
        }}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          if (resendFormData) {
            updateReport.mutate(resendFormData);
          }
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <Grid item columns={12}>
        <Stack spacing={3}>
          <Grid container justifyContent="space-between">
            <Typography variant="h2">Exceptions Report</Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <SearchInput
                value={search}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                testId="exception-report-search"
                aria-label="Search exception reports"
              />
            </Grid>
          </Grid>
          <Grid>
            <FixedHeightTableLoader pageSize={pageSize} withActionPanel={true}>
              {isLoading && <Loader />}
              <TableContainer component={Paper}>
                <Table
                  aria-label="exception reports table"
                  data-testid="exception-reports-table"
                  role="table"
                >
                  <StyledDashboardTableHead>
                    <TableRow role="row">
                      <TableCell role="columnheader">
                        <TableSortIcon
                          currentSort={currentSort}
                          setSorting={setSorting}
                          field="exceptionId"
                          label="Exception Id"
                          aria-label="Exception Id"
                          testId="sort-by-exception-id"
                        />
                      </TableCell>
                      <TableCell role="columnheader">
                        <TableSortIcon
                          currentSort={currentSort}
                          setSorting={setSorting}
                          field="type"
                          label="Type"
                          aria-label="Sort by exception type"
                          testId="sort-by-exception-type"
                        />
                      </TableCell>
                      <TableCell role="columnheader">
                        <TableSortIcon
                          currentSort={currentSort}
                          setSorting={setSorting}
                          field="failedRecord"
                          label="Failed Record"
                          aria-label="Failed Record"
                          testId="sort-by-failed-record"
                        />
                      </TableCell>
                      <TableCell role="columnheader">Reason</TableCell>
                      <TableCell role="columnheader">
                        <TableSortIcon
                          currentSort={currentSort}
                          setSorting={setSorting}
                          field="updatedOn"
                          label="Updated On"
                          aria-label="Updated On"
                          testId="sort-by-updated-on"
                        />
                      </TableCell>
                      <TableCell role="columnheader" align="center">
                        Action
                      </TableCell>
                      <TableCell role="columnheader">
                        Archive Status
                      </TableCell>
                    </TableRow>
                  </StyledDashboardTableHead>
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <StyledActionPanel container>
                <TablePagination
                  component="div"
                  count={filteredAndSortedReports.length}
                  onPageChange={handlePageChange}
                  page={page}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={pageSize}
                  aria-label="Exception reports table pagination"
                  rowsPerPageOptions={[5, 10, 25]}
                  data-testid="reports-pagination"
                  slotProps={{
                    select: {
                      MenuProps: {
                        classes: { list: "reports-pagination-list" },
                      },
                    },
                  }}
                />
              </StyledActionPanel>
            </FixedHeightTableLoader>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}, "Exceptions Report");

const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});

const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});
