import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Grid, TextField } from "@mui/material";
import { Loader } from "./loader/Loader";
import DOMPurify from "dompurify";
import { SANITIZE_OPTS_NO_TAGS } from "../utils";

type ConfirmActionModalProps = {
  open: boolean;
  loading: boolean;
  message: string;
  onConfirm: (reason: string) => void;
  onCancel: () => void;
  showReason?: boolean;
  reasonLabel?: string;
};

export const ConfirmActionModal = ({
  open,
  loading,
  message,
  onConfirm,
  onCancel,
  showReason,
  reasonLabel,
}: ConfirmActionModalProps) => {
  const includeReason = typeof showReason === "boolean" ? showReason : false;
  const [actionReason, setActionReason] = useState<string>("");

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionReason(event.target.value);
  };

  return (
    <Dialog
      data-testid="confirm-action-dialog"
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-action-title"
      role="dialog"
    >
      <StyledRelativeContainer>
        {loading && <Loader />}
        <DialogContent>
          <DialogContentText id="confirm-action-title">
            {message}
          </DialogContentText>
          {includeReason && (
            <StyledTextField
              fullWidth
              label={reasonLabel || "Delete Reason"}
              multiline
              rows={2}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={(event) =>
                setActionReason(
                  DOMPurify.sanitize(
                    event.target.value,
                    SANITIZE_OPTS_NO_TAGS
                  ).toString()
                )
              }
              value={actionReason}
              onChange={handleReasonChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="cy-confirm-action-cancel"
            color="primary"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            data-testid="cy-confirm-action-confirm"
            color="warning"
            type="button"
            onClick={() => {
              onConfirm(actionReason);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </StyledRelativeContainer>
    </Dialog>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: "30px",
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
