import { FC } from "react";
import styled from "@emotion/styled";
import { Link } from "@mui/material";

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "black",
  backgroundColor: "#fff",
  cursor: "pointer",
  lineHeight: 1,
  width: "auto",
  height: "auto",
  padding: "4px 13px",
  overflow: "visible",
  zIndex: 1202,
  position: "relative",
  left: -9999,
  transform: "translateY(-50%)",

  "&:focus": {
    left: 0,
    backgroundColor: "#fff",
  },
});
export const SkipToMainContent: FC = () => {
  return (
    <StyledLink href="#content" tabIndex={0} role="link">
      Skip to main content
    </StyledLink>
  );
};

const StyledLinkTwo = styled(Link)({
  position: "absolute",
  left: -750,
  textDecoration: "none",
  color: "black",
  backgroundColor: "transparent",
  cursor: "pointer",
  lineHeight: 1,
  width: "auto",
  height: "auto",
  padding: "4px 13px",
  border: "none",
  "&:focus": {
    position: "absolute",
    overflow: "visible",
    left: 0,
    bottom: "100%",
    backgroundColor: "#fff",
    zIndex: 1005,
  },
});

export const SkipToContent: FC = () => {
  return (
    <StyledLinkTwo href="#content" tabIndex={0} role="link">
      Skip Menu Item
    </StyledLinkTwo>
  );
};
