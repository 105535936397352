import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { withLayout } from "../hoc/with-layout";
import { CreateFooterForm } from "../components/CreateFooterForm";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { RoleContext } from "../role-provider";

export const CreateFooter = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { defaultLanguage } = useContext(RoleContext);

  const onSaveSuccess = () => {
    queryClient.invalidateQueries(["getCustomFooters"]);
    navigate(`/custom-footers`);
  };

  const CreateFootertWithLayout = useMemo(() => {
    return withLayout(() => {
      return (
        <StyledRelativeContainer>
          <Box sx={{ flexGrow: 1, px: 0 }}>
            <Grid container>
              <Grid
                container
                mobile={9}
                sx={{ alignItems: "center", mb: 4 }}
              ></Grid>

              <Grid mobile={12}>
                <CreateFooterForm
                  saveSuccessMessage="Footer was created successfully"
                  language={
                    typeof defaultLanguage === "string" ? defaultLanguage : "EN"
                  }
                  setLanguage={() => {}}
                  onSaveSuccess={onSaveSuccess}
                />
              </Grid>
            </Grid>
          </Box>
        </StyledRelativeContainer>
      );
    }, "Add Footer Text");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage]);

  return <CreateFootertWithLayout />;
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
