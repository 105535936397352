import {
  createRealDeleteRequest,
  createRealGetRequest,
  createRealPostRequest,
  createRealPutRequest,
} from ".";
import { paths } from "../categories.types";

export const realGetCategories = createRealGetRequest<
  paths["/exp/v1/dna/category/getCategories"]
>("/category/getCategories");

export const realGetCategoryMarketingInformation = createRealGetRequest<
  paths["/exp/v1/dna/category/getCategoryMarketingInformation"]
>("/category/getCategoryMarketingInformation");

export const realGetAllCategoriesList = createRealGetRequest<
  paths["/exp/v1/dna/category/getAllCategoriesList"]
>("/category/getAllCategoriesList");
export const realGetSelectedItemByCategoryId = createRealGetRequest<
  paths["/exp/v1/dna/category/getSelectedItemByCategoryId"]
>("/category/getSelectedItemByCategoryId");

export const realSaveSelectedItemByCategoryId = createRealPostRequest<
  paths["/exp/v1/dna/category/saveSelectedItemByCategoryId"]
>("/category/saveSelectedItemByCategoryId");

export const realSaveCategoryMarketingInformation = createRealPostRequest<
  paths["/exp/v1/dna/category/saveCategoryMarketingInformation"]
>("/category/saveCategoryMarketingInformation");

export const realGetArchiveCategory = createRealGetRequest<
  paths["/exp/v1/dna/category/getArchiveCategory"]
>("/category/getArchiveCategory");

export const realHardDeleteCategory = createRealDeleteRequest<
  paths["/exp/v1/dna/category/hardDeleteCategory"]
>("/category/hardDeleteCategory");

export const realRestoreCategory = createRealPutRequest<
  paths["/exp/v1/dna/category/restoreCategory"]
>("/category/restoreCategory");

export const realSoftDeleteCategory = createRealPutRequest<
  paths["/exp/v1/dna/category/softDeleteCategory"]
>("/category/softDeleteCategory");

export const realGetCompareCurrent = createRealGetRequest<
  paths["/exp/v1/dna/category/getCompareCurrent"]
>("/category/getCompareCurrent");

export const realGetCompareLive = createRealGetRequest<
  paths["/exp/v1/dna/category/getCompareLive"]
>("/category/getCompareLive");

export const realGetCategoryReviewSubmit = createRealGetRequest<
  paths["/exp/v1/dna/category/getCategoryReviewAndSubmit"]
>("/category/getCategoryReviewAndSubmit");

export const realSaveCategoryReviewSubmit = createRealPostRequest<
  paths["/exp/v1/dna/category/saveCategoryReviewSubmit"]
>("/category/saveCategoryReviewSubmit");
