import { Grid, MenuItem, TextField, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { withLayout } from "../hoc/with-layout";
import { LanguageSelector } from "../components/LanguageSelector";
import { useForm } from "react-hook-form";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { bulkFileUpload } from "../data/miscellaneous";
import { useMutation } from "@tanstack/react-query";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { Loader } from "../components/loader/Loader";
import { AxiosError } from "axios";
import {
  ApiError
} from "../utils";

const TYPE_OPTIONS = [
  {
    id: "item",
    label: "Item",
  },
  {
    id: "product",
    label: "Product",
  },
  {
    id: "itemProductMapping",
    label: "Item-Product Mapping",
  },
];

type BulkUploadFormValues = {
  language: string;
  type: string | null;
  file: File | null;
};
export const BulkUpload = () => {
  const { isReaderRole, selectedCountry, selectedRole, defaultLanguage } =
    useContext(RoleContext);
  const { apiClient } = useAxios();

  const [selectedFileName, setSelectedFileName] = useState("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    defaultLanguage
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm<BulkUploadFormValues>({
    defaultValues: {
      language: selectedLanguage!,
      type: null,
      file: null,
    },
  });
  const selectedType = watch("type");
  const selectedFile = watch("file");

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setError("file", {
        type: "manual",
        message: "Please select a file to upload",
      });
      return;
    }
    const file = e.target.files[0];
    const { name } = file;

    setSelectedFileName(name);
    setValue("file", file);
    clearErrors("file");
  };

  const bulkFileUploadRequest = bulkFileUpload(apiClient);
  const bulkFileUploadMutation = useMutation(
    (data: BulkUploadFormValues) => {
      let hasError = false;
      if (data.file === null) {
        setError("file", {
          type: "manual",
          message: "Please select a file to upload",
        });
        hasError = true;
      }
      if (data.type === null) {
        setError("type", {
          type: "manual",
          message: "Please select a type",
        });
        hasError = true;
      }
      if (hasError) {
        throw new Error("Please fix form errors and try again.");
      }
      const formData = new FormData();
      formData.append("file", data.file!);
      return bulkFileUploadRequest(formData, {
        countryCode: selectedCountry!,
        roleId: selectedRole!,
        languageCode: selectedLanguage!,
        fileType: data.type!,
      });
    },
    {
      onMutate: () => bulkFileUploadRequest,
      onSuccess: () => {
        setSuccessMessage(
          `${getValues("type")} file was uploaded successfully`
        );
        setValue("file", null);
        setValue("type", null);
        setSelectedFileName("");
      },
      onError: (error: AxiosError) => {
        if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  useEffect(() => {
    if (typeof selectedType === "string" && selectedType.length > 0) {
      clearErrors("type");
    }
  }, [selectedType, clearErrors]);

  useEffect(() => {
    if (selectedFile !== null) {
      clearErrors("file");
    }
  }, [selectedFile, clearErrors]);

  const onSubmit = async (data: BulkUploadFormValues) => {
    if (!data.file) {
      setError("file", {
        type: "manual",
        message: "Please select a file to upload",
      });
      return;
    }
    bulkFileUploadMutation.mutate(data);
  };

  const BulkUploadWithLayout = withLayout(() => {
    return (
      <StyledRelativeContainer container>
        {bulkFileUploadMutation.isLoading && <Loader />}
        <Grid mobile={12} item marginBottom={3}>
          <Typography variant="h2">Bulk Upload</Typography>
        </Grid>
        <StyledFullWidthForm
          onSubmit={handleSubmit(onSubmit)}
          data-testid="bulk-upload-form"
        >
          <StyledBulkUploadFormContainer mobile={12}>
            <StyledHorizontalField mobile={8} container>
              <StyledFormLabel mobile={6} item>
                <Typography variant="normalBold">Language</Typography>
              </StyledFormLabel>
              <Grid mobile={6} item>
                <LanguageSelector
                  selectedLanguage={selectedLanguage!}
                  onChange={(language) => setSelectedLanguage(language)}
                />
              </Grid>
            </StyledHorizontalField>
            <StyledHorizontalField mobile={8} container>
              <StyledFormLabel mobile={6} item>
                <Typography variant="normalBold">Type</Typography>
              </StyledFormLabel>
              <Grid mobile={6} item>
                <StyledSelectField
                  label="Type"
                  {...register("type", {
                    required: true,
                  })}
                  value={selectedType}
                  onChange={(e) => setValue("type", e.target.value)}
                  error={!!errors.type}
                  helperText={errors.type && "Please select a type"}
                  select
                  data-testid="open-bulk-upload-type-select"
                  id="bulk-upload-type-select"
                  aria-describedby={errors.type ? "type-error" : undefined}
                >
                  {TYPE_OPTIONS.map((typeOption, index) => (
                    <MenuItem
                      key={index}
                      value={typeOption.id}
                      data-testid="bulk-upload-type-option"
                    >
                      {typeOption.label}
                    </MenuItem>
                  ))}
                </StyledSelectField>
              </Grid>
            </StyledHorizontalField>
            <StyledHorizontalField mobile={8} container>
              <StyledFormLabel mobile={6} item>
                <Typography variant="normalBold">File</Typography>
              </StyledFormLabel>
              <Grid mobile={6} item>
                <Button
                  component="label"
                  variant="contained"
                  style={{
                    color: "black",
                    fontFamily: "Speedee-Bold, Arial, Helvetica, sans-serif",
                    fontSize: 18,
                    fontWeight: 400,
                    textTransform: "none",
                  }}
                  startIcon={<StyledBlackCloudUploadIcon />}
                  data-testid="bulk-upload-open-file"
                >
                  Upload file
                  <StyledFileInput
                    type="file"
                    name="file"
                    onChange={handleFileUpload}
                  />
                </Button>
                {errors.file && (
                  <Typography color="error" variant="body2">
                    {errors.file.message}
                  </Typography>
                )}
                {selectedFileName.length > 0 && (
                  <Typography variant="subtitle1">
                    {selectedFileName}
                  </Typography>
                )}
              </Grid>
            </StyledHorizontalField>
            {!isReaderRole && (
              <StyledSubmitButtonContainer mobile={8} container>
                <StyledButton type="submit" variant="contained" size="large">
                  Submit
                </StyledButton>
              </StyledSubmitButtonContainer>
            )}
          </StyledBulkUploadFormContainer>
        </StyledFullWidthForm>
        <SuccessAlertSnackbar
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
        />
        <ErrorAlertSnackbar
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      </StyledRelativeContainer>
    );
  }, "Bulk Upload");

  return <BulkUploadWithLayout />;
};

const StyledHorizontalField = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
const StyledBulkUploadFormContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledFullWidthForm = styled("form")({
  width: "100%",
});
const StyledFormLabel = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});
const StyledSelectField = styled(TextField)(({ theme }) => ({
  width: 200,
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledBlackCloudUploadIcon = styled(CloudUploadIcon)({
  color: "black",
});
const StyledFileInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const StyledSubmitButtonContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",
  marginTop: theme.spacing(4),
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "black",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));