import * as React from "react";
import {
  Typography,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  IconButton,
  TablePagination,
  Dialog,
  Button,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { withLayout } from "../hoc/with-layout";
import { LanguageSelector } from "../components/LanguageSelector";
import { isMasterFooter } from "../utils";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { useCustomQuery } from "../hooks/use-custom-query";
import { AddRowLink } from "../components/AddRowLink";
import AddCircle from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import { FixedHeightTableLoader } from "../components/FixedHeightLoader";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import { deleteFooterId, getCustomFooters } from "../data/miscellaneous";
import { EditFooterModal } from "../components/EditFooterModal";
import { AxiosError } from "axios";
import { ApiError } from "../utils";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";
import { EmptyState } from "../components/EmptyState";

export type FooterType = {
  footerText?: string;
  footerId?: number;
};
export const CustomFooter = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { canEdit, selectedCountry, defaultLanguage } = useContext(RoleContext);
  const [language, setLanguage] = useState<string | null>(defaultLanguage);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [deletingFooterId, setDeletingFooterId] = useState<number | null>(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [showEditFooterModal, setShowEditFooterModal] = useState<
    boolean | null
  >(false);
  const [selectedFooterData, setSelectedFooterData] =
    useState<FooterType | null>(null);

  const getSelectedFooter = (footer: any) => {
    setShowEditFooterModal(true);
    setSelectedFooterData(footer);
  };

  const { page, pageSize, navigateToPage, setPageSize } =
    useUrlSearchPageSort<FooterType>("custom-footers", {
      page: 0,
      pageSize: 10,
      sortField: "footerText",
      sortOrder: "desc",
      search: "",
    });

  const { data, isFetching } = useCustomQuery(
    ["getCustomFooters", { language }],
    () =>
      getCustomFooters(apiClient)({
        countryCode: selectedCountry!,
        languageCode: language!,
      })
  );
  const customFooters = useMemo(() => {
    if (typeof data?.data.footersList === "undefined") {
      return [];
    }
    const validFooters = data?.data.footersList.filter(
      (maybeFooter): maybeFooter is FooterType => {
        return isMasterFooter(maybeFooter) === true;
      }
    );
    return validFooters;
  }, [data]);

  const getRow = React.useCallback(
    (index: number) => {
      if (!customFooters || !customFooters[index]) {
        return (
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="footers-placeholder-tr"
            role="row"
          >
            <TableCell
              role="cell"
              component="th"
              scope="row"
              data-testid="footers-td-name"
            ></TableCell>
            <TableCell role="cell"></TableCell>
          </StyledTableRow>
        );
      }
      const footer = customFooters[index];
      return (
        <StyledTableRow key={footer.footerId}>
          <TableCell>{footer.footerText}</TableCell>
          <TableCell>
          {canEdit && (
            <>
              <Tooltip title="Edit Footer">
                <IconButton
                  aria-label="edit button"
                  role="button"
                  data-testid="edit-footer-btn"
                >
                  <EditIcon
                    aria-label="edit button"
                    onClick={() => getSelectedFooter(footer)}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Footer">
                <IconButton
                  aria-label="delete button"
                  role="button"
                  data-testid="delete-footer-btn"
                  sx={{ color: "#DA291C" }}
                  onClick={() => handleOpenDeleteConfirmation(footer.footerId!)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
              </>
        )}
        	</TableCell>
        </StyledTableRow>
      );
    },
    [customFooters ,canEdit]
  );

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const handleOpenDeleteConfirmation = (footerId: number) => {
    setIsDeleteConfirmationOpen(true);
    setDeletingFooterId(footerId!);
  };

  const handleCloseDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setDeletingFooterId(null);
  };

  const deleteFooterIdRequest = deleteFooterId(apiClient);
  const deleteMutation = useMutation(
    (footerId: number) => {
      return deleteFooterIdRequest({
        footerId: footerId,
        languageCode: language!,
        countryCode: selectedCountry!,
      });
    },
    {
      onMutate: () => deleteFooterIdRequest,
      onSuccess: () => {
        queryClient.invalidateQueries(["getCustomFooters"]);
        setSuccessMessage("Footer deleted successfully");
        handleCloseDeleteConfirmation();
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsDeleteConfirmationOpen(false);
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
          setIsDeleteConfirmationOpen(false);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const handleDelete = () => {
    if (deletingFooterId) {
      return deleteMutation.mutate(deletingFooterId);
    }
  };

  const handleEditFooterSuccess = () => {
    setSuccessMessage(`Footer was saved successfully`);
    setShowEditFooterModal(false);
    queryClient.invalidateQueries(["getCustomFooters"]);
  };

  const notSupportedMessage = data && data.data && data.data.message;
  return (
    <>
     {notSupportedMessage && notSupportedMessage.includes("not supported") ? (
       <>
       <Typography variant="h2">Custom Footers</Typography>
       <Typography variant="body1">{notSupportedMessage}</Typography>
     </>
    ) : (
      <Grid columns={12}>
        <Stack spacing={3}>
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="h2">Custom Footers</Typography>
            </Grid>
            {canEdit && (
              <AddRowLink
                onClick={() => navigate(`/custom-footers/create-footers`)}
                data-testid="add-new-footer-button"
              >
                <AddCircle aria-label="add new footer button" />
                <Typography>Add New Footer</Typography>
              </AddRowLink>
            )}
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid>
              <LanguageSelector
                selectedLanguage={language!}
                onChange={(language) => setLanguage(language)}
              />
            </Grid>
          </Grid>
          <FixedHeightTableLoader pageSize={pageSize} withActionPanel={true}>
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
              <Table
                aria-label="footers table"
                data-testid="footers-table"
                role="table"
              >
                <StyledCustomFooterTableHead>
                  <TableRow role="row">
                    <TableCell role="columnheader">Footer Text</TableCell>
                    {canEdit ? (
                    <TableCell
                      role="columnheader"
                      align="center"
                      sx={{ width: "10%" }}
                    >
                      Action
                    </TableCell>) : (<div></div>)}
                  </TableRow>
                </StyledCustomFooterTableHead>
                <TableBody>
                {customFooters.length > 0 ? (
                Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )
              ) : (
                <EmptyState>No records found</EmptyState>
              )}
                </TableBody>
              </Table>
            </TableContainer>
            <StyledActionPanel container>
              <TablePagination
                component="div"
                count={customFooters.length}
                onPageChange={handlePageChange}
                page={page}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPage={pageSize}
                aria-label="Table pagination"
                rowsPerPageOptions={[5, 10, 25]}
                data-testid="footers-pagination"
                SelectProps={{
                  MenuProps: {
                    classes: { list: "footers-pagination-list" },
                  },
                }}
              />
            </StyledActionPanel>
          </FixedHeightTableLoader>
        </Stack>
        <Dialog
          open={isDeleteConfirmationOpen}
          onClose={handleCloseDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this footer?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-evenly" }}>
            <StyledButton
              variant="contained"
              size="large"
              onClick={handleCloseDeleteConfirmation}
            >
              Cancel
            </StyledButton>
            <StyledDangerButton
              variant="contained"
              size="large"
              color="error"
              onClick={handleDelete}
              autoFocus
            >
              Delete
            </StyledDangerButton>
          </DialogActions>
        </Dialog>
        {showEditFooterModal && (
          <EditFooterModal
            selectedFooter={selectedFooterData}
            onCancel={() => setShowEditFooterModal(false)}
            language={language}
            onSaveSuccess={handleEditFooterSuccess}
          />
        )}
        <ResendFormModal
          open={isResendModalOpen}
          onResend={() => {
            handleDelete();
            setIsResendModalOpen(false);
          }}
          onCancel={() => setIsResendModalOpen(false)}
        />
        <SuccessAlertSnackbar
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
        />
        <ErrorAlertSnackbar
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      </Grid>)}
    </>
  );
}, "custom footer");

const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignFooters: "center",
});
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});

const StyledCustomFooterTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));

export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
