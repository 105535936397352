import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCategory } from "../pages/category";
import { CategoryMarketingForm } from "./CategoryMarketingForm";
import { useQueryClient } from "@tanstack/react-query";

export const CategoryMarketingInfoEdit = () => {
  const { categoryId, editable, isLive, language, setLanguage } = useCategory();
  const queryClient = useQueryClient();

  return (
    <StyledRelativeContainer>
      <CategoryMarketingForm
        categoryId={categoryId}
        editable={editable}
        isLive={isLive}
        language={language}
        setLanguage={setLanguage}
        saveSuccessMessage={`Category ${categoryId} was saved successfully`}
        onSaveSuccess={() => {
          queryClient.invalidateQueries(["getAllCategoriesList"]);
          queryClient.invalidateQueries(["getCategoryMarketingInformation"]);
          queryClient.invalidateQueries(["category/getCompareCurrent"]);
          queryClient.invalidateQueries(["category/getCompareLive"]);
        }}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
