import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useAxios } from "../axios-provider";
import { ApiError } from "../utils";
import { RoleContext } from "../role-provider";
import { getCustomFooters, saveCustomFooters } from "../data/miscellaneous";
import { Loader } from "./loader/Loader";
import { LanguageSelector } from "./LanguageSelector";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { useNavigate } from "react-router-dom";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { UnsavedChangesModal } from "./UnsavedChangesModal";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

type CustomerFooterFormProps = {
  footerId?: string;

  saveSuccessMessage: string;
  language: string;
  setLanguage: (language: string) => void;
  onSaveSuccess?: () => void;
};
type CreateFooterFormInput = {
  footer_text: string;
};

export const CreateFooterForm = ({
  footerId,
  saveSuccessMessage,
  onSaveSuccess,
}: CustomerFooterFormProps) => {
  const handleSaveSuccess =
    typeof onSaveSuccess === "function" ? onSaveSuccess : () => {};
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const { apiClient } = useAxios();
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const { defaultLanguage } = useContext(RoleContext);
  const [language, setLanguage] = useState<string>(
    typeof defaultLanguage === "string" ? defaultLanguage : "EN"
  );

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm<CreateFooterFormInput>({
    defaultValues: { footer_text: "" },
  });

  const handleLeaveNavigation = () => {
    navigate("/custom-footers");
  };
  const { isFetching } = useCustomQuery(
    [
      "getCustomFooters",
      {
        countryCode: selectedCountry,
        languageCode: language,
      },
    ],
    () =>
      getCustomFooters(apiClient)({
        countryCode: selectedCountry!,
        languageCode: language!,
      })
  );

  const saveCustomFootersRequest = saveCustomFooters(apiClient);
  const { isLoading, mutate } = useMutation(
    (data: CreateFooterFormInput) => {
      const metaData = {
        countryCode: selectedCountry!,
        roleId: String(selectedRole)!,
        languageCode: language!,
      };
      return saveCustomFootersRequest(
        { footerText: data.footer_text },
        metaData
      );
    },
    {
      onMutate: () => saveCustomFootersRequest,
      onSuccess: () => {
        setSuccessMessage(saveSuccessMessage);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const onSubmit = (formData: CreateFooterFormInput) => {
    mutate(formData);
  };

  return (
    <StyledRelativeContainer container spacing={1}>
      {(isFetching || isLoading) && <Loader />}
      <Card sx={{ width: "100%" }}>
        <Grid mobile={12} padding={4} paddingTop={0}>
          <StyledTitleBar container mobile={12}>
            <Typography variant="h2">Add Footer Text</Typography>
          </StyledTitleBar>
          <StyledLanguageSelectorWrapper container mobile={12}>
            <LanguageSelector
              selectedLanguage={language}
              disabled={typeof footerId === "undefined"}
              onChange={(language) => setLanguage(language)}
            />
          </StyledLanguageSelectorWrapper>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="create-footers-form"
          >
            <Grid container spacing={2}>
              <Grid item mobile={12} padding={4} paddingTop={0}>
                <StyledTextField
                  fullWidth
                  sx={{ width: "100%" }}
                  label="Footer Text*"
                  {...register("footer_text", { required: true })}
                  SelectProps={{ native: true }}
                  disabled={!!isReaderRole}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.footer_text}
                  helperText={errors.footer_text && "Footer Text is required"}
                  data-testid="footer-text-select"
                ></StyledTextField>
              </Grid>
            </Grid>
            {!isReaderRole && (
              <Grid
                mobile={12}
                sx={{
                  p: 0,
                  my: 4,
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <StyledButton
                  type="submit"
                  variant="contained"
                  size="large"
                  aria-label="Save Custom Footer"
                  aria-disabled={!!isReaderRole}
                >
                  Save
                </StyledButton>
                <StyledSecondaryButton
                  variant="contained"
                  size="large"
                  aria-label="Reset"
                  disabled={!isDirty}
                  onClick={() => reset()}
                >
                  Reset
                </StyledSecondaryButton>
              </Grid>
            )}
          </form>
        </Grid>
      </Card>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        onLeave={handleLeaveNavigation}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />

      <SuccessAlertSnackbar
        message={successMessage}
        onClose={handleSaveSuccess}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};
const StyledTitleBar = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  display: "flex",
  justifyContent: "space-between",
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledLanguageSelectorWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  justifyContent: "flex-end",
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
