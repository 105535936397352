import {
  createRealDeleteRequest,
  createRealGetRequest,
  createRealPostRequest,
  createRealPutRequest,
} from ".";
import { paths } from "../items-v2.types";

export const realGetItemList =
  createRealGetRequest<paths["/exp/v1/dna/item/getItemList"]>(
    "/item/getItemList"
  );

export const realGetMarketingInformation = createRealGetRequest<
  paths["/exp/v1/dna/item/getItemMarketingInformation"]
>("/item/getItemMarketingInformation");

export const realGetProductsByItemId = createRealGetRequest<
  paths["/exp/v1/dna/item/getProductsByItemID"]
>("/item/getProductsByItemID");

export const realGetIngredientsByItemAndProductIds = createRealGetRequest<
  paths["/exp/v1/dna/item/getIngredientByProductIds"]
>("/item/getIngredientByProductIds");

export const realGetNutritionFactsByProductIds = createRealGetRequest<
  paths["/exp/v1/dna/item/getNutritionFactByProductsIDs"]
>("/item/getNutritionFactByProductIds");
export const realGetItemCategoryMapping = createRealGetRequest<
  paths["/exp/v1/dna/item/getItemCategoryMapping"]
>("/item/getItemCategoryMapping");
export const realGetSelectedItemsGoesWellWith = createRealGetRequest<
  paths["/exp/v1/dna/item/getSelectedItemsGoesWellWith"]
>("/item/getSelectedItemsGoesWellWith");
export const realGetCoopMappedDataByItemID = createRealGetRequest<
  paths["/exp/v1/dna/item/getCoopMappedDataByItemID"]
>("/item/getCoopMappedDataByItemID");

export const realSaveItemCoopMapping = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemCoopMapping"]
>("/item/saveItemCoopMapping");
export const realSaveItemMarketingInformation = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemMarketingInformation"]
>("/item/saveItemMarketingInformation");
export const realSaveIsFortifiedApiRequest = createRealPostRequest<
  paths["/exp/v1/dna/item/saveFortifiedItemsList"]
>("/item/saveFortifiedItemsList");

export const realGetIsFortifiedItemValueQuery = createRealGetRequest<
  paths["/exp/v1/dna/item/getIsFortifiedValue"]
>("/item/getIsFortifiedValue");

export const realGetFortifiedItemsList = createRealGetRequest<
  paths["/exp/v1/dna/item/getFortifiedIems"]
>("/item/getFortifiedIems");

export const realUpdateFortifiedItems = createRealPostRequest<
  paths["/exp/v1/dna/item/updateFortifiedItems"]
>("/item/updateFortifiedItems");

export const realSaveItemCategoryMapping = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemCategoryMapping"]
>("/item/saveItemCategoryMapping");

export const realGetCompareCurrent = createRealGetRequest<
  paths["/exp/v1/dna/item/getCompareCurrent"]
>("/item/getCompareCurrent");

export const realGetCompareLive = createRealGetRequest<
  paths["/exp/v1/dna/item/getCompareLive"]
>("/item/getCompareLive");
export const realSaveItemGoesWellWith = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemGoesWellWith"]
>("/item/saveItemGoesWellWith");

export const realGetRegionsList = createRealGetRequest<
  paths["/exp/v1/dna/item/getRegionsList"]
>("/item/getRegionsList");

export const realGetSelectedElementsMapping = createRealGetRequest<
  paths["/exp/v1/dna/item/getSelectedElementsMapping"]
>("/item/getSelectedElementsMapping");

export const realSaveItemProductMapping = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemProductMapping"]
>("/item/saveItemProductMappingByItemId");

export const realGetAllOptionalItems = createRealGetRequest<
  paths["/exp/v1/dna/item/optionalItemList"]
>("/item/optionalItemList");

export const realGetAllMutexGroups =
  createRealGetRequest<paths["/exp/v1/dna/item/getMutexList"]>(
    "/item/getMutexList"
  );
export const realGetItemRelationshipMapping = createRealGetRequest<
  paths["/exp/v1/dna/item/getItemRelationshipMapping"]
>("/item/getItemRelationshipMapping");

export const realGetSelectedItemRelationship = createRealGetRequest<
  paths["/exp/v1/dna/item/getSelectedItemRelationship"]
>("/item/getSelectedItemRelationship");

export const realGetRelationTypes = createRealGetRequest<
  paths["/exp/v1/dna/item/getRelationTypes"]
>("/item/getRelationTypes");

export const realSaveItemRelationship = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemRelationship"]
>("/item/saveItemRelationship");

export const realItemGetReviewAndSubmit = createRealGetRequest<
  paths["/exp/v1/dna/item/getItemReviewAndSubmit"]
>("/item/getItemReviewAndSubmit");

export const realSaveItemReviewAndSubmit = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemReviewSubmit"]
>("/item/saveItemReviewSubmit");

export const realGetItemServingSize = createRealGetRequest<
  paths["/exp/v1/dna/item/getItemServingSize"]
>("/item/getItemServingSize");

export const realGetUomDetails = createRealGetRequest<
  paths["/exp/v1/dna/item/getUomDetails"]
>("/item/getUomDetails");

export const realRecalculateServingSize = createRealDeleteRequest<
  paths["/exp/v1/dna/item/recalculateServingSize"]
>("/item/recalculateServingSize");

export const realSaveItemServingSize = createRealPostRequest<
  paths["/exp/v1/dna/item/saveItemServingSize"]
>("/item/saveItemServingSize");

export const realGetProductDetailsByMutexId = createRealGetRequest<
  paths["/exp/v1/dna/item/getProductDetailsByMutexId"]
>("/item/getProductDetailsByMutexId");

export const realGetOptionalItemDetailsByMutexId = createRealGetRequest<
  paths["/exp/v1/dna/item/getOptionalItemDetailsByMutexId"]
>("/item/getOptionalItemDetailsByMutexId");

export const realSaveMutexGroup = createRealPostRequest<
  paths["/exp/v1/dna/item/saveMutexGroup"]
>("/item/saveMutexGroup");

export const realGetArchiveItem = createRealGetRequest<
  paths["/exp/v1/dna/item/getArchiveItems"]
>("/item/getArchiveItems");

export const realHardDeleteItem = createRealDeleteRequest<
  paths["/exp/v1/dna/item/hardDeleteItem"]
>("/item/hardDeleteItem");

export const realRestoreItem =
  createRealPutRequest<paths["/exp/v1/dna/item/restoreItem"]>(
    "/item/restoreItem"
  );

export const realSoftDeleteItem = createRealPutRequest<
  paths["/exp/v1/dna/item/softDeleteItem"]
>("/item/softDeleteItem");

export const realSaveNutritionInformationByItemId = createRealPostRequest<
  paths["/exp/v1/dna/item/saveNutritionInformationByItemId"]
>("/item/saveNutritionInformationByItemId");

export const realDeleteSelectedItemRelationship = createRealDeleteRequest<
  paths["/exp/v1/dna/item/deleteSelectedItemRelationship"]
>("/item/deleteSelectedItemRelationship");

export const realGetElementLiveStatus = createRealGetRequest<
  paths["/exp/v1/dna/getElementLiveStatus"]
>("/getElementLiveStatus");
