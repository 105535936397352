import React, { useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { SearchInput } from "./SearchInput";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { withLayout } from "../hoc/with-layout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import {
  editPushLive,
  editSchedulePushLive,
  getApprovedChanges,
  getPushliveEventDetails,
  getTimeZones,
  getUnapprovedChanges,
  instantPushLive,
  revertWorkflow,
  schedulePushLive,
} from "../data/miscellaneous";
import {
  ApiError,
  Element,
  PushLiveChangeset,
  SANITIZE_OPTS_NO_TAGS,
  TimeZoneResponse,
  combinePushLiveResponses,
  formatPushLiveChangeset,
  formatPushLiveMetaData,
  isTimeZone,
} from "../utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { RoleContext } from "../role-provider";
import { useParams } from "react-router-dom";
import { Loader } from "./loader/Loader";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import DOMPurify from "dompurify";
import { useCustomQuery } from "../hooks/use-custom-query";
import { AxiosError } from "axios";
import { ConfirmActionModal } from "./ConfirmActionModal";
import { ResendFormModal } from "./ResendFormModal";
import { useForm } from "react-hook-form";

export const PushLiveEvent = withLayout(() => {
  const { pushLiveEventId, pushliveEventDetailsId } = useParams();

  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid container spacing={3}>
        <Grid mobile={12}>
          <Typography variant="h1" sx={{ margin: "32px 0 16px" }}>
            Push Live Elements
          </Typography>
        </Grid>
        <Grid mobile={12}>
          {pushliveEventDetailsId ? (
            <PastEventReadOnly
              pushliveEventDetailsId={pushliveEventDetailsId}
            />
          ) : (
            <PendingEventEdit pushLiveEventId={pushLiveEventId} />
          )}
        </Grid>
      </Grid>
    </StyledRelativeContainer>
  );
}, "Push Live Event");

type TimeZoneSelectorProps = {
  timeZones?: TimeZoneResponse[];
  selectedTimeZoneId?: number;
  onChange: (selectedTimeZoneId: number) => void;
};

const TimeZoneSelector = ({
  timeZones,
  selectedTimeZoneId,
  onChange,
  error = false,
  helperText = "",
}: TimeZoneSelectorProps & { error?: boolean; helperText?: string }) => {
  return (
    <TextField
      label="Time Zone"
      onChange={(e) => onChange(Number(e.target.value))}
      select
      fullWidth
      error={error}
      helperText={helperText}
      InputLabelProps={{
        shrink: true,
      }}
      SelectProps={{ native: true }}
      value={selectedTimeZoneId || ""}
    >
      <option key="" value=""></option>
      {timeZones &&
        timeZones.map((timeZone) => (
          <option key={timeZone.timeZoneId} value={timeZone.timeZoneId}>
            {timeZone.timeZoneName}
          </option>
        ))}
    </TextField>
  );
};

type DataTableProps = {
  data: Element[];
  title: string;
  selectedElements?: Element[];
  showSearch?: boolean;
  listView?: boolean;
  onCheckboxChange?: (item: Element) => void;
  onSelectByType?: () => void;
};
const DataTable: React.FC<DataTableProps> = ({
  data,
  title,
  selectedElements,
  showSearch,
  listView,
  onCheckboxChange,
  onSelectByType,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(true);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [filteredSelectedData, setFilteredSelectedData] =
    useState<Element[]>(data);

  const selectedElementIds = useMemo(() => {
    if (selectedElements) {
      return selectedElements.map((element) => element.elementId);
    }
    return [];
  }, [selectedElements]);

  const toggleShowSelected = () => {
    setShowSelectedOnly(!showSelectedOnly);
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    setFilteredSelectedData(
      data.filter((row) => {
        if (!showSelectedOnly) {
          const name = row.elementName?.toLowerCase() || "";
          const id = row.elementId?.toString() || "";
          const query = searchQuery.toLowerCase();
          return name.includes(query) || id.includes(query);
        }
        return selectedElementIds.includes(row.elementId);
      })
    );
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showSelectedOnly, searchQuery]);

  const isEmpty = filteredSelectedData.length === 0;

  return (
    <>
      <StyledTableControls>
        <Grid container justifyContent="flex-start" gap={2}>
          <Grid container alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              data-testid="category-parent-expand-collapse"
              role="button"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {open && !listView && (
            <Grid container>
              <StyledButton
                size="small"
                variant="contained"
                onClick={toggleShowSelected}
              >
                {showSelectedOnly ? "Show All" : "Show Selected"}
              </StyledButton>
            </Grid>
          )}
          {open && !isEmpty && !listView && (
            <Grid container>
              <StyledButton
                size="small"
                variant="contained"
                onClick={onSelectByType}
              >
                Select All
              </StyledButton>
            </Grid>
          )}
        </Grid>
        {open && showSearch && (
          <Grid container>
            <SearchInput
              value={searchQuery}
              onChange={handleSearchChange}
              endAdornment={<SearchIcon sx={{ opacity: 0.5 }} />}
              aria-label="Search"
              placeholder="Search"
              testId="push-live-search"
            />
          </Grid>
        )}
      </StyledTableControls>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {isEmpty ? (
          <StyledEmptyBox>
            <Typography>No Push Live Elements</Typography>
          </StyledEmptyBox>
        ) : (
          <>
            <TableContainer>
              <Table aria-label={title}>
                <StyledDashboardTableHead>
                  <TableRow>
                    {!listView && <TableCell>Select</TableCell>}
                    <TableCell>Element Name</TableCell>
                    <TableCell>Element ID</TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                <TableBody>
                  {filteredSelectedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow
                        key={row.elementId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {!listView && (
                          <TableCell component="th" scope="row">
                            <Checkbox
                              size="small"
                              checked={(selectedElements || []).some(
                                (el) => el.elementId === row.elementId
                              )}
                              onChange={() =>
                                onCheckboxChange && onCheckboxChange(row!)
                              }
                            />
                          </TableCell>
                        )}
                        <TableCell>{row.elementName}</TableCell>
                        <TableCell>{row.elementId}</TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredSelectedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Collapse>
    </>
  );
};

interface PastEventReadOnlyProps {
  pushliveEventDetailsId: string;
}
const PastEventReadOnly = ({
  pushliveEventDetailsId,
}: PastEventReadOnlyProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry } = useContext(RoleContext);

  const [pastEventChangeset, setPastEventChangeset] =
    useState<PushLiveChangeset | null>(null);

  const {
    data: pushLiveEventDetailsData,
    isFetching: isFetchingDetailsQuery,
    dataUpdatedAt: pushLiveEventDetailsDataUpdatedAt,
  } = useCustomQuery(
    ["getPushliveEventDetails", { pushLiveEventId: pushliveEventDetailsId }],
    () =>
      getPushliveEventDetails(apiClient)({
        countryCode: selectedCountry!,
        pushLiveEventId: pushliveEventDetailsId!,
      }),
    typeof pushliveEventDetailsId !== "undefined"
  );

  useEffect(() => {
    if (pushLiveEventDetailsData) {
      setPastEventChangeset(
        formatPushLiveChangeset(pushLiveEventDetailsData?.data.dataList)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushLiveEventDetailsDataUpdatedAt]);

  return (
    <StyledRelativeContainer container spacing={1}>
      {isFetchingDetailsQuery && <Loader />}
      {pastEventChangeset && (
        <Grid mobile={12}>
          <Stack spacing={1}>
            <Grid container>
              <Grid mobile={12}>
                <DataTable
                  data={pastEventChangeset.Categories_and_Meal_Bundles}
                  showSearch={
                    pastEventChangeset.Categories_and_Meal_Bundles &&
                    pastEventChangeset.Categories_and_Meal_Bundles.length > 0
                  }
                  listView
                  title="Categories and Meal Bundles"
                />
              </Grid>
              <Grid mobile={12}>
                <DataTable
                  data={pastEventChangeset.Menu_Item}
                  showSearch={
                    pastEventChangeset.Menu_Item &&
                    pastEventChangeset.Menu_Item.length > 0
                  }
                  listView
                  title="Menu Item"
                />
              </Grid>
              <Grid mobile={12}>
                <DataTable
                  data={pastEventChangeset.Products}
                  showSearch={
                    pastEventChangeset.Products &&
                    pastEventChangeset.Products.length > 0
                  }
                  listView
                  title="Products"
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      )}
    </StyledRelativeContainer>
  );
};

interface PendingEventEditProps {
  pushLiveEventId?: string;
}
const PendingEventEdit = ({ pushLiveEventId }: PendingEventEditProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [timezoneError, setTimezoneError] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState<number>();
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const [isRetryRevertModalOpen, setIsRetryRevertModalOpen] = useState(false);
  const [scheduledDate, setScheduledDate] = useState<Dayjs | null>(dayjs());
  const formattedDate = scheduledDate?.format("YYYY-MM-DDTHH:mm:ss[Z]");
  const [scheduleError, setScheduleError] = useState<string | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [reason, setReason] = useState<string>("");
  const [unapprovedChangeset, setUnapprovedChangeset] =
    useState<PushLiveChangeset | null>(null);
  const [combinedChangeset, setCombinedChangeset] =
    useState<PushLiveChangeset | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  const { watch: watchApproved, setValue: setValueApproved } = useForm<{
    elementList: Element[];
  }>({
    defaultValues: {
      elementList: [],
    },
  });
  const selectedApprovedElements = watchApproved("elementList");

  const { watch: watchUnapproved, setValue: setValueUnapproved } = useForm<{
    elementList: Element[];
  }>({
    defaultValues: {
      elementList: [],
    },
  });
  const selectedUnapprovedElements = watchUnapproved("elementList");

  const onCloseSuccessAlert = () => navigate("/push-live-detail");
  const invalidateAll = () => {
    queryClient.invalidateQueries(["getPushLiveDetails"]);
    queryClient.invalidateQueries(["getApprovedChanges"]);
    queryClient.invalidateQueries(["getUnapprovedChanges"]);
    queryClient.invalidateQueries(["editPushLive"]);
    queryClient.invalidateQueries(["getPushliveEventDetails"]);
  };

  const timeZonesQuery = useCustomQuery(["getTimeZone"], () =>
    getTimeZones(apiClient)({
      countryCode: selectedCountry!,
    })
  );
  const timeZones = useMemo(() => {
    if (timeZonesQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validTimeZoneAttributes = timeZonesQuery.data?.data.dataList.filter(
      (maybeAttribute): maybeAttribute is TimeZoneResponse => {
        return isTimeZone(maybeAttribute) === true;
      }
    );
    return validTimeZoneAttributes;
  }, [timeZonesQuery]);

  const {
    data: approvedChangesData,
    isFetching: isFetchingApproved,
    dataUpdatedAt: approvedChangesUpdatedAt,
  } = useCustomQuery(["getApprovedChanges"], () =>
    getApprovedChanges(apiClient)({
      countryCode: selectedCountry!,
    })
  );

  const {
    data: unapprovedChangesData,
    isFetching: isFetchingUnapproved,
    dataUpdatedAt: unapprovedChangesUpdatedAt,
  } = useCustomQuery(["getUnapprovedChanges"], () =>
    getUnapprovedChanges(apiClient)({
      countryCode: selectedCountry!,
    })
  );

  const {
    data: editPushLiveData,
    isFetching: isFetchingEditQuery,
    dataUpdatedAt: editPushLiveDataUpdatedAt,
  } = useCustomQuery(
    ["editPushLive"],
    () =>
      editPushLive(apiClient)({
        countryCode: selectedCountry!,
        pushLiveId: pushLiveEventId!,
      }),
    typeof pushLiveEventId !== "undefined"
  );

  useEffect(() => {
    if (
      pushLiveEventId &&
      approvedChangesData?.data.dataList &&
      unapprovedChangesData?.data.dataList &&
      editPushLiveData?.data.dataList &&
      editPushLiveData?.data.pushLiveData
    ) {
      // editing an existing event
      const approvedChangeset = formatPushLiveChangeset(
        approvedChangesData.data.dataList
      );
      const unapprovedChangeset = formatPushLiveChangeset(
        unapprovedChangesData.data.dataList
      );
      const pushLiveChangeset = formatPushLiveChangeset(
        editPushLiveData.data.dataList
      );
      const pushLiveMetaData = formatPushLiveMetaData(
        editPushLiveData?.data.pushLiveData
      );
      setUnapprovedChangeset(unapprovedChangeset);
      setCombinedChangeset(
        combinePushLiveResponses(approvedChangeset, pushLiveChangeset)
      );
      setReason(pushLiveMetaData.reason);
      setSelectedTimeZone(pushLiveMetaData.timeZoneId);
      setScheduledDate(dayjs(pushLiveMetaData.scheduleTime));

      // existing event -> select ONLY the event changeset initially
      setValueApproved("elementList", Object.values(pushLiveChangeset).flat());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    approvedChangesUpdatedAt,
    unapprovedChangesUpdatedAt,
    editPushLiveDataUpdatedAt,
  ]);

  useEffect(() => {
    if (
      !pushLiveEventId &&
      approvedChangesData?.data.dataList &&
      unapprovedChangesData?.data.dataList
    ) {
      // creating new event
      const approvedChangeset = formatPushLiveChangeset(
        approvedChangesData.data.dataList
      );
      const unapprovedChangeset = formatPushLiveChangeset(
        unapprovedChangesData.data.dataList
      );
      setUnapprovedChangeset(unapprovedChangeset);
      setCombinedChangeset(approvedChangeset);
      // new event -> select ALL Approved elements initially
      setValueApproved("elementList", Object.values(approvedChangeset).flat());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedChangesUpdatedAt, unapprovedChangesUpdatedAt]);

  useEffect(() => {
    if (selectedTimeZone) {
      setTimezoneError(false);
    }
    if (scheduledDate) {
      setScheduleError(null);
    }
  }, [selectedTimeZone, scheduledDate]);

  const revertWorkflowRequest = revertWorkflow(apiClient);
  const { mutate: mutateRevertWorkflow, isLoading: isReverting } = useMutation(
    () => {
      const selectedIds =
        activeTab === 0 ? selectedApprovedIds : selectedUnapprovedIds;
      const workflowData = {
        approvedFlag: activeTab === 0 ? "Y" : "N",
        dataList: selectedIds,
      };

      return revertWorkflowRequest(workflowData, {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onMutate: () => revertWorkflowRequest,
      onSuccess: () => {
        setIsRevertModalOpen(false);
        setSuccessMessage(`Workflow(s) were reverted successfully`);
        queryClient.invalidateQueries(["getItemsList"]);
        queryClient.invalidateQueries(["getProductsList"]);
        queryClient.invalidateQueries(["getAllCategoriesList"]);
        queryClient.invalidateQueries(["getApprovedChanges"]);
        queryClient.invalidateQueries(["getUnapprovedChanges"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryRevertModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const doRevertWorkflow = () => {
    setApiError(null);
    mutateRevertWorkflow();
  };

  const schedulePushLiveRequest = schedulePushLive(apiClient);
  const { mutate: mutateSchedule, isLoading: isSavingSchedule } = useMutation(
    () => {
      const selectedIds =
        activeTab === 0 ? selectedApprovedIds : selectedUnapprovedIds;
      const newEventData = {
        newEvent: "Y",
        eventType: "scheduledType",
        timeZoneId: selectedTimeZone!,
        scheduleTime: String(formattedDate!),
        reason: reason!,
        elementList: selectedIds!,
      };

      return schedulePushLiveRequest(newEventData, {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onMutate: () => schedulePushLiveRequest,
      onSuccess: () => {
        setIsScheduleOpen(false);
        setSuccessMessage(`Scheduled push live event was created successfully`);
        invalidateAll();
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setApiError(
            "There was an error creating the event. Please try again."
          );
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setApiError(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const doCreateScheduledPushLiveEvent = () => mutateSchedule();

  const editSchedulePushLiveRequest = editSchedulePushLive(apiClient);
  const { mutate: mutateEditSchedule, isLoading: isSavingEdit } = useMutation(
    () => {
      const selectedIds =
        activeTab === 0 ? selectedApprovedIds : selectedUnapprovedIds;
      const editEventData = {
        pushLiveEventId: pushLiveEventId!,
        newEvent: "N",
        eventType: "scheduledType",
        timeZoneId: selectedTimeZone!,
        scheduleTime: String(formattedDate!),
        reason: reason!,
        elementList: selectedIds!,
      };

      return editSchedulePushLiveRequest(editEventData, {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onMutate: () => editSchedulePushLiveRequest,
      onSuccess: () => {
        setIsScheduleOpen(false);
        setSuccessMessage(`Scheduled push live event was saved successfully`);
        invalidateAll();
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setApiError("There was an error saving the event. Please try again.");
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setApiError(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const doSaveScheduledPushLiveEvent = () => mutateEditSchedule();

  const instantPushLiveRequest = instantPushLive(apiClient);
  const { mutate: mutateInstant, isLoading: isLoadingInstant } = useMutation(
    () => {
      const selectedIds =
        activeTab === 0 ? selectedApprovedIds : selectedUnapprovedIds;
      const newInstantEventData = {
        newEvent: "Y",
        eventType: "instantType",
        timeZoneId: selectedTimeZone!,
        reason: reason!,
        elementList: selectedIds!,
      };

      return instantPushLiveRequest(newInstantEventData, {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onMutate: () => instantPushLiveRequest,
      onSuccess: () => {
        setInstantModalOpen(false);
        setSuccessMessage(`Instant push live event was saved successfully`);
        invalidateAll();
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setApiError("There was an error saving the event. Please try again.");
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setApiError(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const doCreateInstantPushLiveEvent = () => mutateInstant();

  const editInstantPushLiveRequest = instantPushLive(apiClient);
  const { mutate: mutateEditInstant, isLoading: isSavingEditInstant } =
    useMutation(
      () => {
        const selectedIds =
          activeTab === 0 ? selectedApprovedIds : selectedUnapprovedIds;
        const editInstantEventData = {
          pushLiveEventId: pushLiveEventId!,

          newEvent: "N",
          eventType: "instantType",
          timeZoneId: selectedTimeZone!,
          reason: reason!,
          elementList: selectedIds!,
        };

        return editInstantPushLiveRequest(editInstantEventData, {
          countryCode: selectedCountry!,
          roleId: String(selectedRole!),
        });
      },
      {
        onMutate: () => instantPushLiveRequest,
        onSuccess: () => {
          setInstantModalOpen(false);
          setSuccessMessage(`Instant push live event was saved successfully`);
          invalidateAll();
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            setApiError(
              "There was an error saving the event. Please try again."
            );
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            setApiError(String(errorMessage.message));
          } else {
            setErrorMessage(String(error));
          }
        },
      }
    );

  const doSaveInstantPushLiveEvent = () => mutateEditInstant();

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newTab: number) => setActiveTab(newTab),
    []
  );

  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const toggleScheduleModal = () => {
    setIsScheduleOpen(!isScheduleOpen);
    setApiError(null);
  };
  const [isInstantModalOpen, setInstantModalOpen] = useState(false);
  const toggleInstantModal = () => {
    setInstantModalOpen(!isInstantModalOpen);
    setApiError(null);
  };

  const handleCheckboxChange = (item: Element) => {
    if (activeTab === 0) {
      const isAssignedElChecked = selectedApprovedElements.some(
        (el) => el.elementId === item.elementId
      );
      // check or uncheck the Approved element depending on its current position
      setValueApproved(
        "elementList",
        isAssignedElChecked
          ? selectedApprovedElements.filter(
              (el) => el.elementId !== item.elementId
            )
          : [...selectedApprovedElements, item]
      );
    } else if (activeTab === 1) {
      const isUnassignedElChecked = selectedUnapprovedElements.some(
        (el) => el.elementId === item.elementId
      );
      // check or uncheck the Unapproved element depending on its current position
      setValueUnapproved(
        "elementList",
        isUnassignedElChecked
          ? selectedUnapprovedElements.filter(
              (el) => el.elementId !== item.elementId
            )
          : [...selectedUnapprovedElements, item]
      );
    }
  };
  // const selectedIds = selectedElements.map((element) => element.elementId);
  const selectedApprovedIds = useMemo(() => {
    return selectedApprovedElements !== null
      ? selectedApprovedElements.map((element) => element.elementId)
      : [];
  }, [selectedApprovedElements]);

  const selectedUnapprovedIds = useMemo(() => {
    return selectedUnapprovedElements !== null
      ? selectedUnapprovedElements.map((element) => element.elementId)
      : [];
  }, [selectedUnapprovedElements]);

  const handleSelectAllUnapproved = () => {
    if (selectedUnapprovedElements.length > 0) {
      setValueUnapproved("elementList", []);
    } else if (unapprovedChangeset !== null) {
      setValueUnapproved(
        "elementList",
        Object.values(unapprovedChangeset).flat()
      );
    }
  };
  // Approved element handlers
  const handleSelectAllApproved = () => {
    if (selectedApprovedElements.length > 0) {
      setValueApproved("elementList", []);
    } else if (combinedChangeset !== null) {
      setValueApproved("elementList", Object.values(combinedChangeset).flat());
    }
  };
  const handleSelectAllCategories = () => {
    if (combinedChangeset !== null) {
      updateApprovedSelection(combinedChangeset.Categories_and_Meal_Bundles);
    }
  };
  const handleSelectAllItems = () => {
    if (combinedChangeset !== null) {
      updateApprovedSelection(combinedChangeset.Menu_Item);
    }
  };
  const handleSelectAllProducts = () => {
    if (combinedChangeset !== null) {
      updateApprovedSelection(combinedChangeset.Products);
    }
  };
  // Unapproved element handlers
  const handleSelectAllUnapprovedCategories = () => {
    if (unapprovedChangeset !== null) {
      updateUnapprovedSelection(
        unapprovedChangeset.Categories_and_Meal_Bundles
      );
    }
  };
  const handleSelectAllUnapprovedItems = () => {
    if (unapprovedChangeset !== null) {
      updateUnapprovedSelection(unapprovedChangeset.Menu_Item);
    }
  };
  const handleSelectAllUnapprovedProducts = () => {
    if (unapprovedChangeset !== null) {
      updateUnapprovedSelection(unapprovedChangeset.Products);
    }
  };

  const updateApprovedSelection = (elementsToAddOrRemove: Element[]) => {
    const elementIdsToAddOrRemove = elementsToAddOrRemove.map(
      (element) => element.elementId
    );
    const alreadySelectedElementIds = selectedApprovedElements.map(
      (element) => element.elementId
    );
    // shouldRemove === true if all elements are already checked
    const shouldRemove = elementsToAddOrRemove.every((element) =>
      alreadySelectedElementIds.includes(element.elementId)
    );
    setValueApproved(
      "elementList",
      shouldRemove
        ? selectedApprovedElements.filter(
            (element) => !elementIdsToAddOrRemove.includes(element.elementId)
          )
        : [
            ...selectedApprovedElements,
            ...elementsToAddOrRemove.filter(
              (element) =>
                !alreadySelectedElementIds.includes(element.elementId)
            ),
          ]
    );
  };

  const updateUnapprovedSelection = (elementsToAddOrRemove: Element[]) => {
    const elementIdsToAddOrRemove = elementsToAddOrRemove.map(
      (element) => element.elementId
    );
    const alreadySelectedElementIds = selectedUnapprovedElements.map(
      (element) => element.elementId
    );
    // shouldRemove === true if all elements are already checked
    const shouldRemove = elementsToAddOrRemove.every((element) =>
      alreadySelectedElementIds.includes(element.elementId)
    );
    setValueUnapproved(
      "elementList",
      shouldRemove
        ? selectedUnapprovedElements.filter(
            (element) => !elementIdsToAddOrRemove.includes(element.elementId)
          )
        : [
            ...selectedUnapprovedElements,
            ...elementsToAddOrRemove.filter(
              (element) =>
                !alreadySelectedElementIds.includes(element.elementId)
            ),
          ]
    );
  };

  const validateAndSaveInstant = () => {
    setTimezoneError(false);
    setApiError(null);
    if (!selectedTimeZone) {
      setTimezoneError(true);
      return;
    }
    if (pushLiveEventId) {
      doSaveInstantPushLiveEvent();
    } else {
      doCreateInstantPushLiveEvent();
    }
  };
  const validateAndSaveSchedule = () => {
    setTimezoneError(false);
    setScheduleError(null);
    setApiError(null);
    if (!selectedTimeZone) {
      setTimezoneError(true);
      return;
    }
    if (!scheduledDate) {
      setScheduleError("Please choose a scheduled date and time");
      return;
    }
    if (pushLiveEventId) {
      doSaveScheduledPushLiveEvent();
    } else {
      doCreateScheduledPushLiveEvent();
    }
  };

  const toggleRevertModal = () => {
    setIsRevertModalOpen(!isRevertModalOpen);
  };

  const handleSelectTimeZone = (timeZoneId: number) => {
    setSelectedTimeZone(timeZoneId);
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const isWorking = useMemo(
    () =>
      isFetchingApproved ||
      isFetchingUnapproved ||
      isFetchingEditQuery ||
      isReverting ||
      isSavingEdit ||
      isSavingSchedule ||
      isLoadingInstant ||
      isSavingEditInstant,
    [
      isFetchingApproved,
      isFetchingEditQuery,
      isFetchingUnapproved,
      isLoadingInstant,
      isReverting,
      isSavingEdit,
      isSavingEditInstant,
      isSavingSchedule,
    ]
  );

  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid container mobile={12} spacing={0}>
        <Grid
          mobile={12}
          container
          sx={{ justifyContent: "space-between", mb: 4 }}
        >
          <Grid>
            <Tabs
              TabIndicatorProps={{
                sx: { backgroundColor: "#FFBC0D" },
              }}
              value={activeTab}
              onChange={handleTabChange}
              role="tablist"
            >
              <StyledTab label="Approved" />
              <StyledTab label="Unapproved" />
            </Tabs>
          </Grid>
          <Grid>
            <StyledButton
              variant="contained"
              size="small"
              onClick={
                activeTab === 0
                  ? handleSelectAllApproved
                  : handleSelectAllUnapproved
              }
            >
              Select All | De-Select All
            </StyledButton>
          </Grid>
        </Grid>
        {isWorking && <Loader />}
        <Grid mobile={12}>
          <Stack spacing={1}>
            {activeTab === 0 && combinedChangeset !== null && (
              <>
                <Grid container>
                  {combinedChangeset.Categories_and_Meal_Bundles && (
                    <Grid mobile={12}>
                      <DataTable
                        onCheckboxChange={handleCheckboxChange}
                        selectedElements={selectedApprovedElements}
                        onSelectByType={handleSelectAllCategories}
                        data={
                          combinedChangeset.Categories_and_Meal_Bundles ?? []
                        }
                        showSearch={
                          combinedChangeset.Categories_and_Meal_Bundles &&
                          combinedChangeset.Categories_and_Meal_Bundles.length >
                            0
                        }
                        title="Categories and Meal Bundles"
                      />
                    </Grid>
                  )}
                  {combinedChangeset.Menu_Item && (
                    <Grid mobile={12}>
                      <DataTable
                        onCheckboxChange={handleCheckboxChange}
                        selectedElements={selectedApprovedElements}
                        onSelectByType={handleSelectAllItems}
                        data={combinedChangeset.Menu_Item ?? []}
                        showSearch={
                          combinedChangeset.Menu_Item &&
                          combinedChangeset.Menu_Item.length > 0
                        }
                        title="Menu Item"
                      />
                    </Grid>
                  )}
                  {combinedChangeset.Products && (
                    <Grid mobile={12}>
                      <DataTable
                        onCheckboxChange={handleCheckboxChange}
                        selectedElements={selectedApprovedElements}
                        onSelectByType={handleSelectAllProducts}
                        data={combinedChangeset.Products ?? []}
                        showSearch={
                          combinedChangeset.Products &&
                          combinedChangeset.Products.length > 0
                        }
                        title="Products"
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {activeTab === 1 && unapprovedChangeset !== null && (
              <>
                <Grid container>
                  {unapprovedChangeset.Categories_and_Meal_Bundles && (
                    <Grid mobile={12}>
                      <DataTable
                        onCheckboxChange={handleCheckboxChange}
                        onSelectByType={handleSelectAllUnapprovedCategories}
                        selectedElements={selectedUnapprovedElements}
                        listView={false}
                        data={unapprovedChangeset.Categories_and_Meal_Bundles}
                        showSearch={
                          unapprovedChangeset.Categories_and_Meal_Bundles
                            .length > 0
                        }
                        title="Categories and Meal Bundles"
                      />
                    </Grid>
                  )}
                  {unapprovedChangeset.Menu_Item && (
                    <Grid mobile={12}>
                      <DataTable
                        selectedElements={selectedUnapprovedElements}
                        onCheckboxChange={handleCheckboxChange}
                        onSelectByType={handleSelectAllUnapprovedItems}
                        listView={false}
                        data={unapprovedChangeset.Menu_Item}
                        showSearch={unapprovedChangeset.Menu_Item.length > 0}
                        title="Menu Item"
                      />
                    </Grid>
                  )}
                  {unapprovedChangeset.Products && (
                    <Grid mobile={12}>
                      <DataTable
                        selectedElements={selectedUnapprovedElements}
                        onCheckboxChange={handleCheckboxChange}
                        onSelectByType={handleSelectAllUnapprovedProducts}
                        listView={false}
                        data={unapprovedChangeset.Products}
                        showSearch={unapprovedChangeset.Products.length > 0}
                        title="Products"
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Stack>
        </Grid>
        {!isReaderRole && (
          <Grid container sx={{ mt: 2 }}>
            <>
              <Grid>
                <StyledButton
                  size="small"
                  variant="contained"
                  onClick={toggleRevertModal}
                >
                  Revert Workflow
                </StyledButton>
              </Grid>
            </>
            {activeTab !== 1 && (
              <>
                <Grid>
                  <StyledButton
                    size="small"
                    variant="contained"
                    onClick={toggleInstantModal}
                  >
                    Instant Push Live
                  </StyledButton>
                </Grid>
                <Grid>
                  <StyledButton
                    size="small"
                    variant="contained"
                    onClick={toggleScheduleModal}
                  >
                    Schedule Push Live
                  </StyledButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Dialog open={isScheduleOpen} onClose={toggleScheduleModal}>
        <DialogTitle>Push Live</DialogTitle>
        <DialogContent>
          <StyledMaxWidthGrid container spacing={2}>
            {apiError && (
              <Grid mobile={12}>
                <Alert variant="outlined" severity="error">
                  {apiError}
                </Alert>
              </Grid>
            )}
            <Grid mobile={12}>
              <TimeZoneSelector
                timeZones={timeZones}
                onChange={handleSelectTimeZone}
                selectedTimeZoneId={selectedTimeZone}
                error={timezoneError}
                helperText={timezoneError ? "Please select a timezone." : ""}
              />
            </Grid>
            <Grid mobile={12}>
              <DateTimePicker
                sx={{ width: "100%" }}
                label="Scheduled Time"
                value={scheduledDate}
                minDateTime={dayjs().subtract(1, "minute")}
                onChange={(newValue) => setScheduledDate(newValue)}
              />
              {scheduleError && (
                <Alert variant="outlined" severity="error">
                  {scheduleError}
                </Alert>
              )}
            </Grid>
            <Grid mobile={12}>
              <StyledTextField
                fullWidth
                label="Reason"
                multiline
                rows={6}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(event) =>
                  setReason(
                    DOMPurify.sanitize(
                      event.target.value,
                      SANITIZE_OPTS_NO_TAGS
                    )
                      .toString()
                      .trim()
                  )
                }
                value={reason}
                onChange={handleReasonChange}
              />
            </Grid>
          </StyledMaxWidthGrid>
        </DialogContent>
        <DialogActions>
          <StyledActionButton variant="contained" onClick={toggleScheduleModal}>
            Close
          </StyledActionButton>
          <Button variant="contained" onClick={validateAndSaveSchedule}>
            <Typography>Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isInstantModalOpen} onClose={toggleInstantModal}>
        <DialogTitle>Push Live</DialogTitle>
        <DialogContent>
          <StyledMaxWidthGrid container spacing={2}>
            {apiError && (
              <Grid mobile={12}>
                <Alert variant="outlined" severity="error">
                  {apiError}
                </Alert>
              </Grid>
            )}
            <Grid mobile={12}>
              <TimeZoneSelector
                timeZones={timeZones}
                selectedTimeZoneId={selectedTimeZone}
                onChange={handleSelectTimeZone}
                error={timezoneError}
                helperText={timezoneError ? "Please select a timezone." : ""}
              />
            </Grid>
            <Grid mobile={12}>
              <StyledTextField
                fullWidth
                label="Reason"
                multiline
                rows={8}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(event) =>
                  setReason(
                    DOMPurify.sanitize(
                      event.target.value,
                      SANITIZE_OPTS_NO_TAGS
                    )
                      .toString()
                      .trim()
                  )
                }
                value={reason}
                onChange={handleReasonChange}
              />
            </Grid>
          </StyledMaxWidthGrid>
        </DialogContent>
        <DialogActions>
          <StyledActionButton variant="contained" onClick={toggleInstantModal}>
            Close
          </StyledActionButton>
          <Button variant="contained" onClick={validateAndSaveInstant}>
            <Typography>Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      {isRevertModalOpen && (
        <ConfirmActionModal
          open={!isRetryRevertModalOpen}
          loading={isReverting}
          message="Are you sure you want to revert the selected workflow(s)?"
          onConfirm={doRevertWorkflow}
          onCancel={toggleRevertModal}
        />
      )}

      <ResendFormModal
        open={isRetryRevertModalOpen}
        onResend={() => {
          setIsRetryRevertModalOpen(false);
          doRevertWorkflow();
        }}
        onCancel={() => setIsRetryRevertModalOpen(false)}
        description="An error occurred while reverting the selected workflow(s)"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={onCloseSuccessAlert}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledTab = styled(Tab)({
  color: "black",
  fontFamily: "Speedee-Bold, Arial, Helvetica, sans-serif",
  letterSpacing: "-0.15px",
  textTransform: "initial",
  lineHeight: "28px",
  borderBottom: "2px solid #F6F6F6",
  "&.Mui-selected": {
    backgroundColor: "#F6F6F6",
    borderWidth: "4px",
    color: "black",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
});
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledActionButton = styled(Button)({
  backgroundColor: "#DA291C",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
  marginBottom: theme.spacing(2),
}));
const StyledTableControls = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingRight: 0,
  paddingBottom: theme.spacing(1),
}));
const StyledMaxWidthGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1),
  maxWidth: 720,
}));
