import { AxiosInstance } from "axios";
import {
  ApiPathGet,
  ApiPathPut,
  ApiPathPost,
  ApiPathDelete,
  ApiPathPostFile,
} from "../mockable-api-request";

export const createRealGetRequest =
  <T extends ApiPathGet>(url: string) =>
  (client: AxiosInstance) =>
  (params: T["get"]["parameters"]["query"]) =>
    client.get<T["get"]["responses"]["200"]["content"]["application/json"]>(
      url,
      { params }
    );

export const createRealPutRequest =
  <T extends ApiPathPut>(url: string) =>
  (client: AxiosInstance) => {
    return (
      body: T["put"]["requestBody"]["content"]["application/json"],
      query?: T["put"]["parameters"]["query"]
    ) => {
      let queryParams;
      if (query) {
        queryParams = new URLSearchParams(query);
      }
      const putUrl = queryParams ? `${url}?${queryParams.toString()}` : url;
      return client.put<
        T["put"]["responses"]["200"]["content"]["application/json"]
      >(putUrl, body);
    };
  };

export const createRealPostRequest =
  <T extends ApiPathPost>(url: string) =>
  (client: AxiosInstance) => {
    return (
      body: T["post"]["requestBody"]["content"]["application/json"],
      query?: T["post"]["parameters"]["query"]
    ) => {
      let queryParams;
      if (query) {
        queryParams = new URLSearchParams(query);
      }
      const postUrl = queryParams ? `${url}?${queryParams.toString()}` : url;
      return client.post<
        T["post"]["responses"]["200"]["content"]["application/json"]
      >(postUrl, body);
    };
  };

export const createRealPostFileRequest =
  <T extends ApiPathPostFile>(url: string) =>
  (client: AxiosInstance) => {
    return (
      body: T["post"]["requestBody"]["content"]["multipart/form-data"],
      query?: T["post"]["parameters"]["query"]
    ) => {
      let queryParams;
      if (query) {
        queryParams = new URLSearchParams(query);
      }
      const postUrl = queryParams ? `${url}?${queryParams.toString()}` : url;
      return client.post<
        T["post"]["responses"]["200"]["content"]["application/json"]
      >(postUrl, body);
    };
  };

export const createRealDeleteRequest =
  <T extends ApiPathDelete>(url: string) =>
  (client: AxiosInstance) =>
  (params: T["delete"]["parameters"]["query"]) => {
    const queryParams = new URLSearchParams(params);
    const deleteUrl = `${url}?${queryParams.toString()}`;
    return client.delete<
      T["delete"]["responses"]["200"]["content"]["application/json"]
    >(deleteUrl);
  };
