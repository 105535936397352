import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { ApiError, ValidRole, isRole } from "../utils";
import {
  getAllRolesByCountry,
  saveAddedStepsForWorkflowManagement,
} from "../data/miscellaneous";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { Loader } from "./loader/Loader";
import { useCustomQuery } from "../hooks/use-custom-query";

type AddWorkflowStepForm = {
  roleId: number;
  stepName: string;
  stepDescription: string;
};

type WorkflowStepModalProps = {
  workflowId: number;
  selectedElementName: string;
  onSaveSuccess: () => void;
  onCancel: () => void;
};

export const WorkflowStepModal = ({
  onSaveSuccess,
  onCancel,
  workflowId,
  selectedElementName,
}: WorkflowStepModalProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole: selectedUserRoleId } =
    useContext(RoleContext);
  const [apiError, setApiError] = useState<string | null>(null);

  const { data: getAllRolesByCountryQuery, isFetching } = useCustomQuery(
    ["getAllRolesByCountry", { selectedCountry }],
    () =>
      getAllRolesByCountry(apiClient)({
        countryCode: selectedCountry!,
      })
  );
  const roleList = useMemo(() => {
    if (getAllRolesByCountryQuery?.data.role === undefined) {
      return [];
    }
    const validRoleList = getAllRolesByCountryQuery?.data.role.filter(
      (MaybeRole): MaybeRole is ValidRole => {
        return isRole(MaybeRole) === true;
      }
    );
    return validRoleList;
  }, [getAllRolesByCountryQuery?.data.role]);

  const { handleSubmit, setValue, getValues, watch } =
    useForm<AddWorkflowStepForm>();
  const selectedStepRoleId = watch(`roleId`);

  const selectedStepRoleName: string = useMemo(() => {
    const selectedRole = roleList.find(
      (role) => role.roleId === selectedStepRoleId
    );
    if (typeof selectedRole !== "undefined") {
      return selectedRole.roleName;
    }
    return "";
  }, [selectedStepRoleId, roleList]);

  const saveWorkflowStepRequest =
    saveAddedStepsForWorkflowManagement(apiClient);
  const { mutate: doSaveWorkflowStep, isLoading } = useMutation(
    (data: AddWorkflowStepForm) => {
      const metaData = {
        countryCode: selectedCountry!,
        roleId: selectedUserRoleId!,
        workflowId,
      };
      const body = {
        roleId: String(data.roleId),
        stepName: selectedStepRoleName,
        stepDescription: `${selectedStepRoleName} ${selectedElementName} Step`,
      };
      return saveWorkflowStepRequest(body, metaData);
    },
    {
      onMutate: () => saveWorkflowStepRequest,
      onSuccess: () => onSaveSuccess(),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setApiError(
            "There was an error adding the workflow step. Please try again."
          );
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setApiError(errorMessage.message);
        } else {
          setApiError(String(error));
        }
      },
    }
  );

  const onSave = useCallback(
    (data: AddWorkflowStepForm) => {
      setApiError(null);
      doSaveWorkflowStep(data);
    },
    [setApiError, doSaveWorkflowStep]
  );

  const isWorking = useMemo(
    () => isFetching || isLoading,
    [isFetching, isLoading]
  );

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue("roleId", Number(e.target.value));

  return (
    <Dialog open={true} onClose={onCancel}>
      <form onSubmit={handleSubmit(onSave)}>
        <StyledRelativeContainer>
          {isWorking && <Loader />}
          <DialogTitle id="workflow-step-dialog-title">
            Add Workflow Step
          </DialogTitle>
          <DialogContent>
            {apiError && (
              <Grid marginLeft={3} marginTop={3}>
                <Alert variant="outlined" severity="error">
                  {apiError}
                </Alert>
              </Grid>
            )}
            {roleList.length > 0 && (
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="workflow-step-role-group"
                    value={getValues("roleId")}
                    onChange={handleRadioChange}
                    row
                  >
                    {roleList.map((role) => (
                      <FormControlLabel
                        key={role.roleId}
                        value={role.roleId}
                        control={<Radio />}
                        label={role.roleName}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <StyledActionButton variant="contained" onClick={onCancel}>
              Cancel
            </StyledActionButton>
            <Button type="submit" variant="contained">
              <Typography>Save</Typography>
            </Button>
          </DialogActions>
        </StyledRelativeContainer>
      </form>
    </Dialog>
  );
};
const StyledActionButton = styled(Button)({
  backgroundColor: "#DA291C",
});
const StyledRelativeContainer = styled(Grid)({
  width: "70vw",
  margin: 0,
  position: "relative",
});
