import { useState, useEffect } from "react";
import {
  getCookieValue,
  getLocalStorageValue,
  localStorageAvailable,
  setCookieValue,
  setLocalStorageValue,
} from "../utils";

export const useStorage = (
  keyName: string,
  defaultValue: object | string | null
) => {
  const [storedValue, setStoredValue] = useState(() => {
    const value = getLocalStorageValue(keyName);
    if (value) {
      try {
        // if JSON.parse doesn't error, it's a jsonified object
        return JSON.parse(value);
      } catch (e) {
        // otherwise it's a string
        return value;
      }
    } else {
      setLocalStorageValue(keyName, defaultValue);
      return defaultValue;
    }
  });

  const setValue = (newValue: string | object) => {
    setLocalStorageValue(keyName, newValue);
    setStoredValue(newValue);
  };

  useEffect(() => {
    // Fallback to cookie storage if localStorage is not available
    if (!localStorageAvailable()) {
      const cookieValue = getCookieValue(keyName);
      if (cookieValue) {
        try {
          // if JSON.parse doesn't error, it's a jsonified object
          setStoredValue(JSON.parse(cookieValue));
        } catch (e) {
          // otherwise it's a string
          setStoredValue(cookieValue);
        }
      } else {
        setCookieValue(keyName, defaultValue);
        setStoredValue(defaultValue);
      }
    }
  }, [defaultValue, keyName]);

  return [storedValue, setValue];
};
