import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AddRowLink = styled(Link)(({ theme }) => ({
  ...theme.typography.small,
  color: theme.palette.primary.main,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
  textDecoration: "none",
}));
