import React, { useState } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { SortOrder } from "../data/mock/types";
import { UrlSearchPageSortContext } from "../url-search-page-sort-provider";

type UseUrlSearchPageSortConfigs<T extends object> = {
  page: number;
  pageSize: number;
  sortField: keyof T;
  sortOrder: SortOrder;
  search: string;
};
interface CurrentSortState<T> {
  field: keyof T | null;
  order: "asc" | "desc" | null;
}
export function useUrlSearchPageSort<T extends object>(
  tableName: string,
  configs: UseUrlSearchPageSortConfigs<T>
) {
  const tableMap = React.useContext(UrlSearchPageSortContext);
  if (!tableMap) {
    throw new Error(
      "useUrlSearchPageSort must be used within UrlSearchPageSortContextProvider"
    );
  }

  const tableConfig = tableMap[tableName] ?? configs;
  const [currentSort, setCurrentSort] = useState<CurrentSortState<T>>({
    field: null,
    order: null,
  });

  const navigate = useNavigate();
  const navigateToPage = React.useCallback(
    (value: number) => {
      // change only [page, pageSize] on tables[tableName]
      // (persist all other table configs)
      navigate(
        `?${queryString.stringify({
          tables: JSON.stringify({
            ...tableMap,
            [tableName]: {
              ...tableConfig,
              page: value,
            },
          }),
        })}`
      );
    },
    [tableMap, tableConfig, tableName, navigate]
  );

  const setPageSize = React.useCallback(
    (value: number) => {
      // change only [page, pageSize] on tables[tableName]
      // (persist all other table configs)
      navigate(
        `?${queryString.stringify({
          tables: JSON.stringify({
            ...tableMap,
            [tableName]: {
              ...tableConfig,
              pageSize: value,
              page: 0,
            },
          }),
        })}`
      );
    },
    [tableMap, tableConfig, tableName, navigate]
  );

  const setSearchQuery = React.useCallback(
    (searchQuery: string) => {
      // change only [search, page] on tables[tableName]
      // (persist all other table configs)
      navigate(
        `?${queryString.stringify({
          tables: JSON.stringify({
            ...tableMap,
            [tableName]: {
              ...tableConfig,
              search: searchQuery,
              page: 0,
            },
          }),
        })}`
      );
    },
    [tableMap, tableConfig, tableName, navigate]
  );

  const setSorting = React.useCallback(
    (field: keyof T) => {
      setCurrentSort((prevSort) => {
        const newOrder =
          prevSort.field === field && prevSort.order === "asc" ? "desc" : "asc";
        const updatedTableConfig = {
          ...tableConfig,
          sortField: field,
          sortOrder: newOrder,
          page: 0,
        };

        navigate(
          `?${queryString.stringify({
            tables: JSON.stringify({
              ...tableMap,
              [tableName]: updatedTableConfig,
            }),
          })}`
        );

        return { field, order: newOrder };
      });
    },
    [tableMap, tableConfig, tableName, navigate]
  );

  return {
    ...tableConfig,
    navigateToPage,
    setPageSize,
    setSearchQuery,
    setSorting,
    currentSort,
  };
}
