import queryString from "query-string";
import { useLocation, useOutlet } from "react-router-dom";
import React from "react";
import { mapValues } from "lodash";
import { SortOrder } from "./data/mock/types";

type UrlSearchPageSort = {
  page: number;
  pageSize: number;
  sortField: string | null;
  sortOrder: SortOrder;
  search: string;
  categoryId?: string;
};

type TableMap = {
  [key: string]: UrlSearchPageSort;
};

const DEFAULTS: UrlSearchPageSort = {
  page: 0,
  pageSize: 10,
  sortField: null,
  sortOrder: "desc",
  search: "",
  categoryId: undefined,
};

function parseSearchPageSort(maybeTableConfig: any): UrlSearchPageSort {
  const page = maybeTableConfig.page ? Number(maybeTableConfig.page) : 0;
  const pageSize = maybeTableConfig.pageSize
    ? Number(maybeTableConfig.pageSize)
    : DEFAULTS.pageSize;
  const sortField =
    maybeTableConfig.sortField && typeof maybeTableConfig.sortField === "string"
      ? maybeTableConfig.sortField
      : DEFAULTS.sortField;
  const sortOrder =
    maybeTableConfig.sortOrder &&
    (maybeTableConfig.sortOrder === "asc" ||
      maybeTableConfig.sortOrder === "desc")
      ? maybeTableConfig.sortOrder
      : DEFAULTS.sortOrder;
  const search =
    maybeTableConfig.search && typeof maybeTableConfig.search === "string"
      ? maybeTableConfig.search
      : DEFAULTS.search;
  const categoryId =
    maybeTableConfig.categoryId &&
    typeof maybeTableConfig.categoryId === "string"
      ? maybeTableConfig.categoryId
      : DEFAULTS.categoryId;
  return {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    categoryId,
  };
}

const UrlSearchPageSortContext = React.createContext<TableMap | undefined>(
  undefined
);

const UrlSearchPageSortContextProvider = () => {
  const location = useLocation();
  const outlet = useOutlet();
  const params = queryString.parse(location.search);
  const tables =
    typeof params["tables"] === "string" ? JSON.parse(params["tables"]) : {};
  const tableMap: TableMap = mapValues(tables, (tableConfig) => {
    return parseSearchPageSort(tableConfig);
  });

  return (
    <UrlSearchPageSortContext.Provider value={tableMap}>
      {outlet}
    </UrlSearchPageSortContext.Provider>
  );
};

export { UrlSearchPageSortContext, UrlSearchPageSortContextProvider };
