import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useAxios } from "../axios-provider";
import { AxiosError } from "axios";
import { RoleContext } from "../role-provider";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { saveCustomFooters } from "../data/miscellaneous";
import { ApiError } from "../utils";
import { FooterType } from "../pages/custom-footers";

type EditFooterModalProps = {
  onSaveSuccess: () => void;
  onCancel?: () => void;
  language?: string | null;
  selectedFooter: FooterType | null;
};

export const EditFooterModal = ({
  onSaveSuccess,
  onCancel,
  selectedFooter,
  language,
}: EditFooterModalProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, isReaderRole } = useContext(RoleContext);
  const [apiError, setApiError] = useState<string | null>(null);

  const saveCustomFootersRequest = saveCustomFooters(apiClient);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FooterType>({
    defaultValues: { footerText: selectedFooter?.footerText },
  });

  const onSubmit = (formData: FooterType) => {
    mutate(formData);
  };

  const { mutate } = useMutation(
    (data: FooterType) => {
      const metaData = {
        countryCode: selectedCountry!,
        languageCode: language!,
        footerId: selectedFooter?.footerId!,
      };
      return saveCustomFootersRequest(
        { footerText: data.footerText! },
        metaData
      );
    },
    {
      onMutate: () => saveCustomFootersRequest,
      onSuccess: () => onSaveSuccess(),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setApiError("There was an error updating footer. Please try again.");
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setApiError(errorMessage.message);
        } else {
          setApiError(String(error));
        }
      },
    }
  );

  return (
    <Dialog open={true} onClose={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="create-footers-form">
        <StyledRelativeContainer>
          <DialogTitle id="edit-footer-title">Edit Footer</DialogTitle>
          <DialogContent>
            {apiError && (
              <Grid marginLeft={3} marginTop={3}>
                <Alert variant="outlined" severity="error">
                  {apiError}
                </Alert>
              </Grid>
            )}
            <Grid container spacing={2} mobile={12} padding={4}>
              <Grid item mobile={12} padding={4} paddingTop={0}>
                <StyledTextField
                  fullWidth
                  sx={{ width: "100%" }}
                  label="Footer Text*"
                  {...register("footerText", { required: true })}
                  SelectProps={{ native: true }}
                  disabled={!!isReaderRole}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.footerText}
                  helperText={errors.footerText && "Footer Text is required"}
                  data-testid="footer-text-select"
                ></StyledTextField>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <StyledActionButton variant="contained" onClick={onCancel}>
              Cancel
            </StyledActionButton>
            <Button type="submit" variant="contained">
              <Typography>Save</Typography>
            </Button>
          </DialogActions>
        </StyledRelativeContainer>
      </form>
    </Dialog>
  );
};
const StyledActionButton = styled(Button)({
  backgroundColor: "#DA291C",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledRelativeContainer = styled(Grid)({
  width: "70vw",
  margin: 0,
  position: "relative",
});
