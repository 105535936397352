import React from "react";
import HeightIcon from "@mui/icons-material/Height";
import { Grid, IconButton } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

interface CurrentSortState {
  field: string | null;
  order: "asc" | "desc" | null;
}

interface TableSortIconProps<T> {
  label: string | null;
  testId: string;
  currentSort: CurrentSortState;
  field: keyof T;
  setSorting: (field: keyof T) => void;
}

const TableSortIcon = <T extends object>({
  currentSort,
  field,
  label,
  testId,
  setSorting,
}: TableSortIconProps<T>) => {
  return (
    <Grid
      sx={{
        verticalAlign: "middle",
        whiteSpace: "nowrap",
      }}
    >
      {label}
      <IconButton
        onClick={() => setSorting(field)}
        size="small"
        sx={{
          padding: 0,
          margin: 0,
        }}
        data-testid={testId}
      >
        {currentSort.field === field ? (
          currentSort.order === "asc" ? (
            <ArrowUpward sx={{ fontSize: 18, color: "#292929" }} />
          ) : (
            <ArrowDownward sx={{ fontSize: 18, color: "#292929" }} />
          )
        ) : (
          <HeightIcon sx={{ fontSize: 18, color: "#292929" }} />
        )}
      </IconButton>
    </Grid>
  );
};

export default TableSortIcon;
