import {
  realGetCompareCurrent,
  realGetCompareLive,
  realGetProductDetails,
  realGetProductNutrients,
  realGetProducts,
  realGetSearchableProducts,
  realProductGetReviewAndSubmit,
  realProductSaveReviewAndSubmit,
  realSaveProductDetails,
  realSaveProductNutrients,
} from "./api/products";
import { paths } from "./products.types";
import {
  mockGetProductDetails,
  mockGetProductNutrients,
  mockGetProducts,
  mockSaveProductDetails,
  mockGetCompareCurrent,
  mockGetCompareLive,
  mockSaveProductNutrients,
  mockProductGetReviewAndSubmit,
  mockProductSaveReviewAndSubmit,
  mockGetSearchableProducts,
} from "./mock/products";
import {
  mockableGetRequest,
  mockablePostRequest,
} from "./mockable-api-request";

export const getProducts = mockableGetRequest<
  paths["/exp/v1/dna/product/getProductsList"]
>(realGetProducts, mockGetProducts);

export const getSearchableProducts = mockableGetRequest<
  paths["/exp/v1/dna/item/getAllProductsList"]
>(realGetSearchableProducts, mockGetSearchableProducts);

export const getProductDetails = mockableGetRequest<
  paths["/exp/v1/dna/product/getProductDetails"]
>(realGetProductDetails, mockGetProductDetails);

export const getProductNutrients = mockableGetRequest<
  paths["/exp/v1/dna/product/getNutrientsList"]
>(realGetProductNutrients, mockGetProductNutrients);

export const saveProductDetails = mockablePostRequest<
  paths["/exp/v1/dna/product/saveProdDetails"]
>(realSaveProductDetails, mockSaveProductDetails);

export const saveProductNutrients = mockablePostRequest<
  paths["/exp/v1/dna/product/saveNutrientsList"]
>(realSaveProductNutrients, mockSaveProductNutrients);

export const getCompareCurrent = mockableGetRequest<
  paths["/exp/v1/dna/product/getCompareCurrent"]
>(realGetCompareCurrent, mockGetCompareCurrent);

export const getCompareLive = mockableGetRequest<
  paths["/exp/v1/dna/product/getCompareLive"]
>(realGetCompareLive, mockGetCompareLive);

export const getProductReviewAndSubmit = mockableGetRequest<
  paths["/exp/v1/dna/product/getReviewAndSubmit"]
>(realProductGetReviewAndSubmit, mockProductGetReviewAndSubmit);

export const saveProductReviewAndSubmit = mockablePostRequest<
  paths["/exp/v1/dna/product/saveReviewAndSubmit"]
>(realProductSaveReviewAndSubmit, mockProductSaveReviewAndSubmit);
