import {
  realAddFoodPromotion,
  realGetFoodPromotionList,
  realGetPendingActionList,
  realGetReadyPushLiveList,
  realGetSchedulePushLiveList,
  realShowRecentFeedUpdates,
  realGetRecentFeedUpdates,
  realGetTopTenRecentMenu,
  realDeleteFoodPromotion,
  realGetEmailPreferences,
  realSaveEmailPreferences,
} from "./api/dashboard";
import {
  mockAddFoodPromotion,
  mockGetFoodPromotionList,
  mockGetPendingActionList,
  mockGetReadyPushLiveList,
  mockGetSchedulePushLiveList,
  mockShowRecentFeedUpdates,
  mockGetRecentFeedUpdates,
  mockGetTopTenRecentMenu,
  mockDeleteFoodPromotion,
  mockGetEmailPreferences,
  mockSaveEmailPreferences,
} from "./mock/dashboard";
import {
  mockableGetRequest,
  mockablePostRequest,
  mockableDeleteRequest,
  mockablePutRequest,
} from "./mockable-api-request";
import { paths as dashboardPaths } from "./dashboard.types";

export const getPendingActionList = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/getPendingActionList"]
>(realGetPendingActionList, mockGetPendingActionList);

export const getRecentFeedUpdates = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/getRecentFeedUpdate"]
>(realGetRecentFeedUpdates, mockGetRecentFeedUpdates);

export const showRecentFeedUpdates = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/showRecentFeedUpdates"]
>(realShowRecentFeedUpdates, mockShowRecentFeedUpdates);

export const getReadyPushLiveList = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/getReadyPushLiveList"]
>(realGetReadyPushLiveList, mockGetReadyPushLiveList);

export const getSchedulePushLiveList = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/getSchedulePushLiveList"]
>(realGetSchedulePushLiveList, mockGetSchedulePushLiveList);

export const getTopTenRecentMenu = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/getTopTenRecentMenu"]
>(realGetTopTenRecentMenu, mockGetTopTenRecentMenu);

export const getFoodPromotionList = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/getFoodPromotionList"]
>(realGetFoodPromotionList, mockGetFoodPromotionList);

export const saveFoodPromotion = mockablePostRequest<
  dashboardPaths["/exp/v1/dna/dashboard/saveFoodPromotion"]
>(realAddFoodPromotion, mockAddFoodPromotion);

export const deleteFoodPromotion = mockableDeleteRequest<
  dashboardPaths["/exp/v1/dna/dashboard/deleteFoodPromotion"]
>(realDeleteFoodPromotion, mockDeleteFoodPromotion);

export const getEmailPreferences = mockableGetRequest<
  dashboardPaths["/exp/v1/dna/dashboard/getEmailPreferences"]
>(realGetEmailPreferences, mockGetEmailPreferences);

export const saveEmailPreferences = mockablePutRequest<
  dashboardPaths["/exp/v1/dna/dashboard/saveEmailPreferences"]
>(realSaveEmailPreferences, mockSaveEmailPreferences);
