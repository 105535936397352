import React from "react";
import { Layout } from "../layouts/default";

export const withLayout = (
  WrappedComponent: React.ComponentType<any>,
  title?: string
) => {
  return (props: any) => (
    <Layout title={title}>
      <WrappedComponent {...props} />
    </Layout>
  );
};
