import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Collapse,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddCircle from "@mui/icons-material/AddCircle";
import { withLayout } from "../hoc/with-layout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { Loader } from "../components/loader/Loader";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import {
  changePrivilegeStatus,
  changeRoleStatus,
  getRolesAndPrivileges,
} from "../data/miscellaneous";
import {
  ApiError,
  RoleWithPrivileges,
  formatRoleWithPrivileges,
  isDefined,
} from "../utils";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import { ResendFormModal } from "../components/ResendFormModal";
import { AxiosError } from "axios";
import { CreateRoleModal } from "../components/CreateRoleModal";
import { AddRowLink } from "../components/AddRowLink";
type PendingRoleChange = {
  roleId: number;
  enabled: boolean;
};

type PendingRolePrivilegeChange = {
  roleId: number;
  privilegeId: number;
  enabled: boolean;
};

type RoleAndPrivilegeSelectorProps = {
  role: RoleWithPrivileges;
  onRoleEnabledChange: (enabled: boolean) => void;
  onPrivilegeStatusChange: (privilegeId: number, enabled: boolean) => void;
};

const RoleAndPrivilegeSelector = ({
  role,
  onRoleEnabledChange,
  onPrivilegeStatusChange,
}: RoleAndPrivilegeSelectorProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableControls>
        <Grid container mobile={12} justifyContent="space-between">
          <Grid container alignItems="flex-start" gap={2}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              data-testid="role-parent-expand-collapse"
              role="button"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography variant="h4">{role.roleName}</Typography>
          </Grid>
          <Grid container>
          {role.roleName !== "Country Administrator" && (
            <FormControlLabel
              control={
                <Switch
                  checked={role.isEnable === "Y"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onRoleEnabledChange(event.target.checked)
                  }
                  
                  color="primary"
                  aria-label={`Enable or disable role ${role.roleName}`}
                  aria-labelledby="enable-disable-role-header"
                />
              }
              label="Enabled"
            />
            )} 
          </Grid>
         
        </Grid>
      </StyledTableControls>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TableContainer>
          <Table aria-label={role.roleName}>
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Privilege Name</TableCell>
                <TableCell align="center">Privilege Status</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {role.privilegeList.map((privilege) => (
                <StyledTableRow
                  key={privilege.privilegeId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{privilege.privilegeName}</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={privilege.isEnable === "Y"}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onPrivilegeStatusChange(
                          privilege.privilegeId,
                          event.target.checked
                        )
                      }
                      color="primary"
                      size="small"
                      aria-label={`Enable or disable privilege ${privilege.privilegeName} for role ${role.roleName}`}
                      aria-labelledby="enable-disable-privilege-header"
                    />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
};

export const RolesAndPrivileges = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { selectedCountry, selectedRole } = useContext(RoleContext);

  const [pendingRoleChange, setPendingRoleChange] =
    useState<PendingRoleChange | null>(null);
  const [pendingRolePrivilegeChange, setPendingRolePrivilegeChange] =
    useState<PendingRolePrivilegeChange | null>(null);
  const [isRetryRoleChangeModalOpen, setIsRetryRoleChangeModalOpen] =
    useState<boolean>(false);
  const [isRetryPrivilegeChangeModalOpen, setIsRetryPrivilegeChangeModalOpen] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);

  const rolesAndPrivilegesQuery = useCustomQuery(
    ["getRolesAndPrivileges", { selectedCountry }],
    () =>
      getRolesAndPrivileges(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  const rolesAndPrivileges: RoleWithPrivileges[] = useMemo(() => {
    if (rolesAndPrivilegesQuery.data?.data.dataList) {
      return rolesAndPrivilegesQuery.data.data.dataList
        .map(formatRoleWithPrivileges)
        .filter(isDefined);
    }
    return [];
  }, [rolesAndPrivilegesQuery.data]);

  const changeRoleStatusRequest = changeRoleStatus(apiClient);
  const changePrivilegeStatusRequest = changePrivilegeStatus(apiClient);

  const {
    mutate: savePendingRoleStatusChange,
    isLoading: isChangeRoleStatusLoading,
  } = useMutation(
    () => {
      return changeRoleStatusRequest(
        { isEnable: pendingRoleChange?.enabled ? "Y" : "N" },
        {
          countryCode: selectedCountry!,
          roleId: selectedRole!,
          changedRoleId: pendingRoleChange?.roleId!,
        }
      );
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Role status was updated successfully`);
        setPendingRoleChange(null);
        setIsRetryRoleChangeModalOpen(false);
        queryClient.invalidateQueries(["getRolesAndPrivileges"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryRoleChangeModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const {
    mutate: savePendingRolePrivilegeChange,
    isLoading: isChangeRolePrivilegeLoading,
  } = useMutation(
    () => {
      return changePrivilegeStatusRequest(
        { isEnable: pendingRolePrivilegeChange?.enabled ? "Y" : "N" },
        {
          countryCode: selectedCountry!,
          changedRoleId: pendingRolePrivilegeChange?.roleId!,
          privilegeId: pendingRolePrivilegeChange?.privilegeId!,
        }
      );
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Privilege status was updated successfully`);
        setPendingRolePrivilegeChange(null);
        setIsRetryPrivilegeChangeModalOpen(false);
        queryClient.invalidateQueries(["getRolesAndPrivileges"]);
        queryClient.invalidateQueries(["getUserMenuList"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryPrivilegeChangeModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  return (
    <>
      <StyledRelativeContainer container spacing={1}>
        <Grid
          mobile={12}
          container
          sx={{ mb: 6, justifyContent: "space-between" }}
        >
          <Typography variant="h1">View Role</Typography>
          <AddRowLink
            onClick={() => setIsCreateRoleModalOpen(true)}
            data-testid="add-new-role-button"
          >
            <AddCircle aria-label="add new role button" />
            <Typography>Add New Role</Typography>
          </AddRowLink>
        </Grid>

        {rolesAndPrivilegesQuery.isFetching && <Loader />}
        <Grid mobile={12}>
          {rolesAndPrivileges.map((role) => {
            return (
              <RoleAndPrivilegeSelector
                role={role}
                onRoleEnabledChange={(enabled) => {
                  setPendingRoleChange({
                    roleId: role.roleId,
                    enabled,
                  });
                }}
                onPrivilegeStatusChange={(privilegeId, enabled) => {
                  setPendingRolePrivilegeChange({
                    roleId: role.roleId,
                    privilegeId,
                    enabled,
                  });
                }}
              />
            );
          })}
        </Grid>
      </StyledRelativeContainer>
      {isCreateRoleModalOpen && (
        <CreateRoleModal
          onSaveSuccess={() => {
            setSuccessMessage(`Role was created successfully`);
            setIsCreateRoleModalOpen(false);
            queryClient.invalidateQueries(["getRolesAndPrivileges"]);
          }}
          onCancel={() => setIsCreateRoleModalOpen(false)}
        />
      )}
      {pendingRoleChange !== null && (
        <ConfirmActionModal
          open={!isRetryRoleChangeModalOpen}
          loading={isChangeRoleStatusLoading}
          message={`Are you sure you want to ${
            pendingRoleChange.enabled ? "enable" : "disable"
          } this role?`}
          onConfirm={() => savePendingRoleStatusChange()}
          onCancel={() => setPendingRoleChange(null)}
        />
      )}
      {pendingRolePrivilegeChange !== null && (
        <ConfirmActionModal
          open={!isRetryPrivilegeChangeModalOpen}
          loading={isChangeRolePrivilegeLoading}
          message={`Are you sure you want to ${
            pendingRolePrivilegeChange.enabled ? "enable" : "disable"
          } this privilege for the role?`}
          onConfirm={() => savePendingRolePrivilegeChange()}
          onCancel={() => setPendingRolePrivilegeChange(null)}
        />
      )}
      <ResendFormModal
        open={isRetryRoleChangeModalOpen}
        onResend={() => {
          setIsRetryRoleChangeModalOpen(false);
          if (pendingRoleChange !== null) {
            savePendingRoleStatusChange();
          }
        }}
        onCancel={() => {
          setPendingRoleChange(null);
          setIsRetryRoleChangeModalOpen(false);
        }}
        description="An error occurred updating the role status"
      />
      <ResendFormModal
        open={isRetryPrivilegeChangeModalOpen}
        onResend={() => {
          setIsRetryPrivilegeChangeModalOpen(false);
          if (pendingRolePrivilegeChange !== null) {
            savePendingRolePrivilegeChange();
          }
        }}
        onCancel={() => {
          setPendingRolePrivilegeChange(null);
          setIsRetryPrivilegeChangeModalOpen(false);
        }}
        description="An error occurred updating the privilege status"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}, "Roles & Privileges");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableControls = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingRight: 0,
  paddingBottom: theme.spacing(1),
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
